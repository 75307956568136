import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import BlockButton from 'components/UI/BlockButton';
import config from 'config/api';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconArrow from '@material-ui/icons/ArrowDropDown';
import Box from '@material-ui/core/Box';
import 'react-sticky-header/styles.css';
//import { fetchData } from '../../ducks/asyncFetcher';
import i18n from 'helpers/i18n';

import { isLoggedIn, getCurrentUser, getPendingAccounts } from 'components/UI/Account/ducks/authentication';
import Badge from '@material-ui/core/Badge';

//const AF_KEY = 'home';

const styles = (theme) => {
  return {
    root: {
      /*
      '& .MuiButton-root.right': {
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: 'transparent',
      },
      */
    },
    padding: { padding: theme.spacing(2) },
    profileWrapper: {
      padding: 0,
      marginTop: '-40px',
    },
    profileButton: {
      fontSize: 13,
      padding: '5px 0 0 10px',
      textAlign: 'left',
      display: 'block',
    },
    profileMenu: {
      boxShadow: 'none',
      '& [class*="MuiButton-label"]': {
      },
    },
    badge:{
      color:'#913701',
      '& [class*="MuiBadge-badge"]': {
        top: 9,
        right: -13,
        fontSize:'90%',
        height:16,
        width:16,
        minWidth:16,
      },
    },
    
    buttonMyAccount: {
      fontSize: '0.8rem',
      boxShadow: 'none',
      fontWeight:'normal',
      marginRight: 20,
      textTransform: 'none',
      '&.first': {
        marginLeft: 10,
      },
      marginTop: 0,
      [theme.breakpoints.down('md')]: {
        marginLeft: 20,
        fontSize: 12,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        marginRight: 10,
        marginLeft: 10,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 8,
      },

      '& [class*="MuiButton-label"]': {
        padding: 0,
      },
      '&:after': {
        content: '" "',
        position: 'absolute',
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        width: 0,
        height: 1,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    },
  };
};
class HeaderLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLogginIn: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    //this._setIsLogginIn = this._setIsLogginIn.bind(this);
    //this._onFormComplete = this._onFormComplete.bind(this);
  }

  handleToggle = (e, url) => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleClose = (event, url) => {
    this.setState({ isLogginIn: false });

    if (this.anchorEl.contains(event.target)) {
      return;
    }

    const { history } = this.props;
    this.setState({ open: false }, function () {
      history.push(url);
    });
  };
  /*
  _setIsLogginIn = () => {
    this.setState({ isLogginIn: true });
  };
  */
  /*
  _onFormComplete = () => {
    const { onLoggedIn } = this.props;
    this.setState({ isLogginIn: false }, () => {
      onLoggedIn();
    });
  };
  */
  render() {
    const {
      headerImage,
      isLoggedIn,
      $$user,
      classes,
      pendingAccounts,
    } = this.props;
    const { open, isLogginIn } = this.state;

    const headerType = headerImage || void 0;

    let finalHeaderStyle = {};

    if (headerType) {
      finalHeaderStyle.backgroundPosition = '0 25%';
      finalHeaderStyle.backgroundSize = 'cover';
      finalHeaderStyle.backgroundRepeat = 'no-repeat';
    }
    const $$profileMenu = config.getProfileMenu($$user, { asImmutable: true });
    const userRole = $$user !== undefined ? $$user.get('role') : undefined;
    const showBadge = pendingAccounts > 0;
    const badges={
      account: pendingAccounts,
    }
    return (
      <React.Fragment>
      <If condition={isLogginIn || !isLoggedIn}>
        <Button color="secondary" variant="outlined" id="header_inscription" className={clsx(classes.buttonMyAccount, 'first')} onClick={(e) => {e.preventDefault(); this.props.history.push('/login?action=register')}}>{i18n.get('subscriptionTitle')}</Button>
        <Button color="primary" variant="outlined" id="header_connexion" className={classes.buttonMyAccount} onClick={(e) => {e.preventDefault(); this.props.history.push('/login?action=login')}}>{i18n.get('loginAction')}</Button>
      </If>
    <If
      condition={
        isLogginIn !== true &&
        (userRole === 'member' ||
          userRole === 'admin' ||
          userRole === 'speaker' ||
          userRole === 'staff')
      }
    >
      <Button
        onClick={this.handleToggle}
        endIcon={<IconArrow />}
        buttonRef={(node) => {
          this.anchorEl = node;
        }}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        style={{ visibility: open === true ? 'hidden' : 'visible' }}
        className={classes.buttonMyAccount}
      >

        <If condition={showBadge}>
          <Badge color="error" variant="dot">
            {$$user.get('email')}
          </Badge>
          <Else />
          {$$user.get('email')}
        </If>

      </Button>
      <Popper
        open={open}
        anchorEl={this.anchorEl}
        transition
        disablePortal
        style={{ zIndex: 999 }}
        placement={'bottom-start'}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} id="menu-list-grow">
            <Paper classes={{ root: classes.profileWrapper }}>
              <ClickAwayListener onClickAway={this.handleClose}>
                <Box className={classes.padding}>
                  <BlockButton
                    className={classes.profileMenu}
                    lowerCase={true}
                    label={
                      <span>
                        MON COMPTE
                        <IconArrow
                          style={{
                            verticalAlign: 'middle',
                            marginTop: '-2px',
                          }}
                        />
                      </span>
                    }
                    size="small"
                    variant="regular"
                    onClick={this.handleToggle}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    style={{ borderRadius: '0' }}
                  />
                  <MenuList>
                    {$$profileMenu
                      .map((p, pIdx) => {
                        const badge=p.get('icon') in badges ? badges[p.get('icon')] : 0;
                        if (p.get('target') === '_blank') {
                          return (
                            <MenuItem
                              key={'pm_' + pIdx}
                              onClick={(e) => {
                                window.open(p.get('url'));
                              }}
                              classes={{
                                root: classes.profileButton,
                              }}
                            >
                              {i18n.get(p.get('title'), { pretty: false })}
                            </MenuItem>
                          );
                        } else {
                          return (
                            <MenuItem
                              key={'pm_' + pIdx}
                              onClick={(e) => {
                                this.handleClose(e, p.get('url'));
                              }}
                              classes={{
                                root: classes.profileButton,
                              }}
                            >
                              <If condition={badge!=0}>
                                  <Badge color="error" badgeContent={badge} className={classes.badge}>
                                    {i18n.get(p.get('title'), { pretty: false })}
                                  </Badge>
                                <Else/>
                                  {i18n.get(p.get('title'), { pretty: false })}
                              </If>
                            </MenuItem>
                          );
                        }
                      })
                      .toArray()}
                  </MenuList>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </If>
    </React.Fragment>
);
  }
}

function mapStateToProps(state) {
  const props = {
    isLoggedIn: isLoggedIn(state),
    $$user: getCurrentUser(state),
    pendingAccounts: getPendingAccounts(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onLoggedIn: () => {
      //dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },
  };
}

export default withStyles(styles)(
  withRouter(withWidth()(connect(mapStateToProps, mapDispatchToProps)(HeaderLogin))),
);
