/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';

import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
// import Drawer from '@material-ui/core/Drawer';
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "components/Theme";
import Home from "components/Home/Home";
// import HomeMyEvents from "components/Home/HomeMyEvents";
// import Lives from "components/Lives/Lives";
import LivePlayer from "components/UI/Orchestrator/LivePlayerOrchestrator";
import Replays from "components/Replays/Replays";
import ReplayPlayer from "components/Replays/ReplayPlayer";
import Contact from "components/Contact/Contact";
import Login from "components/Login/Login";
import Help from "components/Help/Help";
//import Help from "components/UI/FAQ";
// import Agenda from 'components/Agenda/Agenda';
import Account from "components/Account/Account";
import Files from "components/UI/Files/Files";
import AdminAccount from "components/UI/Account/AdminAccount";
import AdminEvent from "components/UI/Event/AdminEvent";
import AdminNews from "components/UI/News/AdminNews";
import AdminFaq from "components/UI/Faq/AdminFaq";
import AdminCMS from "components/UI/Cms/AdminCms";
import AdminPodcastTheme from "components/UI/PodcastTheme/AdminPodcastTheme";
import AdminPodcastShow from "components/UI/PodcastShow/AdminPodcastShow";
import AdminTheme from "components/UI/Theme/AdminTheme";
import AdminMessage from "components/UI/Message/AdminMessage";
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import NoMatch from "components/NoMatch";
import Logout from "components/Logout";
import Registration from "components/Registration/Registration";
import Document from "components/Document/Document";
import PodcastIndex from "components/Podcast/PodcastIndex";
import PodcastTheme from "components/Podcast/PodcastTheme";
import PodcastShow from "components/Podcast/PodcastShow";

// import WrapperQuiz from "components/UI/Quiz/WrapperQuiz";
// import Orchestrator from "components/UI/Orchestrator/Orchestrator";
import DetectBrowser from "components/UI/DetectBrowser/DetectBrowser";
import Feedback from "components/UI/Feedback/WrapperFeedback";
import AdminFeedback from "components/UI/Feedback/AdminFeedback";
import AdminOrchestrator from "components/UI/Orchestrator/AdminOrchestrator";
import AdminOrchestratorIndex from "components/UI/Orchestrator/AdminOrchestratorIndex";
import AdminQuiz from "components/UI/Quiz/AdminQuiz";
import AdminUtm from "components/UI/Utm/AdminUtm";
import {
  ResetPasswordRequest,
  ResetPasswordChallenge,
} from "components/UI/ResetPassword";
import queryString from "query-string";
import {
  login as initialeLogin,
  isStateKnown as authStateKnown,
  getCurrentUserRole,
  // isLoggedIn,
} from "../ducks/authentication";
import "../App.css";
import capitalize from "lodash/capitalize";

import EventDetails from 'components/UI/Event/EventDetails';
import EventRegistration from 'components/UI/Event/EventRegistration';

import config from "config/api";

import sheets_download from 'assets/images/sheets_download.svg';//pour exports

//tests
import CMSPage from 'components/UI/Cms/CMSPage';
import SeriesRoute from "components/UI/Serie/route";
import RichEditPage from "components/RichEditPage";
import GPTPubMed from 'components/UI/GPTPubMed/Page';


import Version from "components/UI/Version";

//import "external/qualtrics.js";
//import "external/qualtrics_webconf.js";


const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  disableGlobal:false,
});

//TODO migrer vers un array https://v5.reactrouter.com/web/example/route-config

class Dummy extends Component {
  componentDidMount() {
    this.props.didMount();
  }

  componentWillUnmount() {
    this.props.willUnmount();
  }

  render() {
    return null;
  }
}

const styles = {
  // drawerPaper: {
  //   top: 40,
  //   zIndex: 998,
  //   borderRadius: '0 0 5px 5px',
  //   padding: '5px 3px'
  // },
  // drawerModal: {
  //   zIndex: 997,
  // }
  root: {
    //height: "100%",
    backgroundColor:'#F4F5F5',
  },
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noMatch: null,
    }
  }

  componentDidMount() {
    document.title = process.env.REACT_APP_SITE_TITLE
      ? process.env.REACT_APP_SITE_TITLE
      : "Canal " + capitalize(process.env.REACT_APP_SITE);

    if(window.location.host=='canalpneumo.com'){
      console.log("loading qualtrics");
      import("external/qualtrics.js");
      import("external/qualtrics_webconf.js");
    }else
      console.log("ignoring qualtrics");
      
    this.props.onMount();
  }
  dummyDidMount = () => {
    console.log("noMatch dummyDidMount");
    this.setState({ noMatch: true });
  }

  dummyWillUnmount = () => {
    console.log("noMatch dummyWillUnmount");
    this.setState({ noMatch: false });
  }

render() {
    const {
      // loggedIn,
      userRole,
      authStateKnown,
      classes,
      // width,
      // $$data
    } = this.props;

    const {noMatch}=this.state;
    console.log({noMatch});
  
    return (
      <StylesProvider generateClassName={generateClassName}>
      <div className={classes.root}>
        <Version app="Pneumo v 240229" />
        <ThemeProvider>
          {/*<div id='ZN_eQnH0jefwPrd2Em'></div>*/}
          {/*QUALTRICS*/}
          <CssBaseline />
          <ScrollToTopOnMount />
          <DetectBrowser />
          <If condition={authStateKnown === true}>
            <Router>
              <Switch>
                <Route
                  exact={true}
                  path={"/"}
                  component={({ match }) => <Home />}
                />
                <Route
                  exact={true}
                  path={"/EditorTest"}
                  component={({ match }) => <RichEditPage />}
                />

                  <Route
                  exact={true}
                  path={'/event/:id'}
                  component={({ match }) => <EventDetails eventId={match.params.id} />}
                  />

                {/*<Route
                  exact={true}
                  path={"/subscribe/:id"}
                  component={({ match }) => (
                    <Home subscribe={true} themeId={match.params.id} />
                  )}
                  />*/}

                <Route
                  exact={true}
                  path={'/subscribe/:id'}
                  component={({ match }) => <EventRegistration eventMatchId={match.params.id} />}
                  />
                <Route
                  exact={true}
                  path={'/subscribe'}
                  component={({ match }) => <EventRegistration/>}
                  />



                {/*<Route exact={true} path={'/subscribe'} component={({match}) => (<Home subscribe={true}/>)}/>*/}
                <Route
                  exact={true}
                  path={"/help"}
                  component={({ match }) => <Help />}
                />
                <Route
                  exact={true}
                  path={"/contact"}
                  component={({ match }) => <Contact />}
                />

              <Route path={'/confidentiality'} component={() => <CMSPage page="confidentiality" />} />

              <Route path={'/legal'} component={() => <CMSPage page="legal" />} />

              <Route path={'/cookies'} component={() => <CMSPage page="cookies" />} />


                <Route
                  exact={true}
                  path={"/live/:id"}
                  component={({ match }) => (
                    <React.Fragment>
                      {/*<div id='ZN_2c24GQfxSIwFEi2'></div>*/}
                      <LivePlayer
                        match={match}
                        onPanelToggle={this._panelToggle}
                      />
                    </React.Fragment>
                  )}
                />
                <Route
                  path={"/replays/:id"}
                  component={({ match }) => (
                    <React.Fragment>
                      {/*<div id='ZN_2c24GQfxSIwFEi2'></div>*/}
                      <ReplayPlayer match={match} />
                    </React.Fragment>
                  )}
                />
                <Route
                  path={"/replays"}
                  component={({ match }) => <Replays />}
                />

{/*
                <Route
                  path={"/MesReplays/:page/episode/:id"}
                  component={({ match }) => (
                    <SeriesHome type="replay" page={match.params.page} id={match.params.id}/>
                  )}
                />
                <Route
                  path={"/MesReplays/:page"}
                  component={({ match }) => (
                    <SeriesHome type="replay" page={match.params.page}/>
                  )}
                />


                <Route
                  path={"/MesPodcasts/:page/episode/:id"}
                  component={({ match }) => (
                    <SeriesHome type="podcast" page={match.params.page} id={match.params.id}/>
                  )}
                />
                <Route
                  path={"/MesPodcasts/:page"}
                  component={({ match }) => (
                    <SeriesHome type="podcast" page={match.params.page}/>
                  )}
                />
*/}

                {/*    <Route
                  exact={true}
                  path={"/live"}
                  component={({ match }) => <Lives />}
                />*/}
                <Route
                  exact={true}
                  path={"/login"}
                  component={({ match }) => <Login />}
                />
                <Route
                  exact
                  path={"/_admin"}
                  component={({ match }) => <Login kind="private" />}
                />

                <Route
                  exact={true}
                  path={"/loginSimple"}
                  component={({ match }) => <Login kind="simple" />}
                />
                <Route
                  exact={true}
                  path={"/logout"}
                  component={() => <Logout />}
                />
                <Route
                  path="/rpwd"
                  component={() => (
                    <ResetPasswordRequest
                      showBtCancel={false}
                      onCancel="gotoHome"
                    />
                  )}
                />
                <Route
                  path="/cpwd"
                  component={() => <ResetPasswordChallenge />}
                />

                <Route
                  path="/staff/account/:action/:id"
                  component={({ match }) => <AdminAccount match={match} />}
                />
                <Route
                  path="/staff/account/:action"
                  component={({ match }) => <AdminAccount match={match} />}
                />
                <Route
                  path="/staff/account"
                  component={() => <AdminAccount />}
                />

                <Route
                  path="/staff/event/:action/:id"
                  component={({ match }) => <AdminEvent match={match} />}
                />
                <Route
                  path="/staff/event/:action"
                  component={({ match }) => <AdminEvent match={match} />}
                />
                <Route path="/staff/event" component={() => <AdminEvent />} />

                {/*
                <Route
                  path="/staff/serie/:action/:id"
                  component={({ match }) => <AdminSerie match={match} />}
                />
                <Route
                  path="/staff/serie/:action"
                  component={({ match }) => <AdminSerie match={match} />}
                />
                <Route path="/staff/serie" component={() => <AdminSerie />} />
                
                <Route
                  path="/staff/episode/:action/:id"
                  component={({ match }) => <AdminEpisode match={match} />}
                />
                <Route
                  path="/staff/episode/:action"
                  component={({ match }) => <AdminEpisode match={match} />}
                />
                <Route path="/staff/episode" component={() => <AdminEpisode />} />
                  */}

                <Route
                  path="/staff/news/:action/:id"
                  component={({ match }) => <AdminNews match={match} />}
                />
                <Route
                  path="/staff/news/:action"
                  component={({ match }) => <AdminNews match={match} />}
                />

                <Route
                  path="/staff/utm/:action/:id"
                  component={({ match }) => <AdminUtm match={match} />}
                />
                <Route
                  path="/staff/utm/:action"
                  component={({ match }) => <AdminUtm match={match} />}
                />
                <Route path="/staff/utm" component={() => <AdminUtm />} />

                <Route
                  path="/staff/faq/:action/:id"
                  component={({ match }) => <AdminFaq match={match} />}
                />
                <Route
                  path="/staff/faq/:action"
                  component={({ match }) => <AdminFaq match={match} />}
                />

                <Route
                  path="/staff/theme/:action/:id"
                  component={({ match }) => <AdminTheme match={match} />}
                />
                <Route
                  path="/staff/theme/:action"
                  component={({ match }) => <AdminTheme match={match} />}
                />

                <Route
                  path="/staff/cms/:action/:id"
                  component={({ match }) => <AdminCMS match={match} />}
                />
                <Route
                  path="/staff/cms/:action"
                  component={({ match }) => <AdminCMS match={match} />}
                />

                <Route
                  path="/staff/message/:action/:id"
                  component={({ match }) => <AdminMessage match={match} />}
                />
                <Route
                  path="/staff/message/:action"
                  component={({ match }) => <AdminMessage match={match} />}
                />
                <Route
                  exact
                  path={"/registration"}
                  component={({ match }) => <Registration />}
                />

                <Route
                  path="/documents"
                  component={({ match }) => <Document match={match} />}
                />

                <Route
                  path="/podcasts"
                  component={({ match }) => <PodcastIndex match={match} />}
                />
                <Route
                  path="/podcast/theme/:themeId"
                  component={({ match }) => <PodcastTheme match={match} />}
                />
                {/*
                <Route
                  path="/podcast/show/:podcastId"
                  component={({ match }) => <PodcastShow match={match} />}
                />
                  */}
                <Route
                  path="/staff/podcast/theme/:action/:id"
                  component={({ match }) => <AdminPodcastTheme match={match} />}
                />
                <Route
                  path="/staff/podcast/theme/:action"
                  component={({ match }) => <AdminPodcastTheme match={match} />}
                />

                <Route
                  path="/staff/podcast/show/:action/:id"
                  component={({ match }) => <AdminPodcastShow match={match} />}
                />
                <Route
                  path="/staff/podcast/show/:action"
                  component={({ match }) => <AdminPodcastShow match={match} />}
                />

                <Route
                  path="/staff/feedback/:eventId/:action/:id"
                  component={({ match }) => <AdminFeedback match={match} />}
                />
                <Route
                  path="/staff/feedback/:eventId/:action"
                  component={({ match }) => <AdminFeedback match={match} />}
                />
                <Route
                  path="/feedback/:eventId"
                  component={({ match }) => <Feedback match={match} />}
                />

                <Route
                  exact
                  path={"/staff/quiz/:eventId/:action/:id"}
                  component={({ match }) => <AdminQuiz match={match} />}
                />
                <Route
                  exact
                  path={"/staff/quiz/:eventId/:action"}
                  component={({ match }) => <AdminQuiz match={match} />}
                />

                <Route
                  exact
                  path={"/staff/orchestrator/:eventId/:action"}
                  component={({ match }) => <AdminOrchestrator match={match} />}
                />
                <Route
                  exact
                  path={"/staff/orchestrator/list"}
                  component={({ match }) => (
                    <AdminOrchestratorIndex match={match} />
                  )}
                />

                <Route path="/account" component={() => <Account />} />
                <Route path="/files" component={() => <Files />} />

                <Route path="/GPTPubMed" component={() => <GPTPubMed />} />


                {/*<Route component={() => <NoMatch />} />*/}
                <Route render={() => <Dummy didMount={this.dummyDidMount} willUnmount={this.dummyWillUnmount} />}/>
              </Switch>
              <If condition={noMatch}>
                <SeriesRoute last={true}/>
              </If>
            </Router>
          </If>
        </ThemeProvider>
      </div>
      </StylesProvider>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    authStateKnown: authStateKnown(state),
    // loggedIn: isLoggedIn(state),
    userRole: getCurrentUserRole(state),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      const uri = queryString.parse(window.location.search);
      return dispatch(
        initialeLogin({
          referer: uri.r,
        }),
      );
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(App));
