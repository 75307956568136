import React from "react";
// import Prettyfier from 'components/UI/Prettyfier';
import Paragrapher from "components/UI/Paragrapher/Paragrapher";

let _LOCALE = null;
console.log("IMPORT I18N", `../i18n/${process.env.REACT_APP_SITE}_fr.js`);
import(`../i18n/${process.env.REACT_APP_SITE}_fr.js`).then((module) => {
  _LOCALE = module.default;
  console.log("i18n _LOCALE SET", _LOCALE);
});
console.log("IMPORT I18N _LOCALE", _LOCALE);


const get = (string, options) => {
  if (!_LOCALE){
      console.log("i18n get error no _LOCALE", string);
     return "";
  }else
    console.log("i18n ok", string);
  let r, s;
  r = _LOCALE[string] !== undefined ? _LOCALE[string] : string;
  if (
    string !== false &&
    ((options !== undefined && options.pretty === true) ||
      options === undefined ||
      options.pretty !== false)
  )
    s = (
      <Paragrapher
        prettyfy={true}
        substitute={
          options !== undefined && options.substitute
            ? options.substitute
            : undefined
        }
        text={r}
        element={
          options !== undefined && options.element ? options.element : "span"
        }
        elementStyle={{
          // display: "inline-block",
          // verticalAlign: "middle",
          // minHeight: "16px",
          // marginBottom: 10,
          display: "block",
          // verticalAlign: "middle",
          minHeight: "16px",
          // marginBottom: 10,
        }}
      />
    );
  else s = r;
  return s;
};
const has = (string) => {
  if (!_LOCALE) return "";
  return _LOCALE[string] !== undefined && _LOCALE[string] !== false;
};

console.log("I18N loginAction", get('loginAction'));


export default {
  get,
  has,
};
