import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { primaryColor } from 'helpers/colors';

const styles = (theme) => {
  return {
    h2: {
      // color: '#000000',
      color: primaryColor,
      fontWeight: 'bold',
      fontSize: '30px',
      letterSpacing: '1.32px',
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    },
    white: {
      color: '#fff',
    },
  };
};

class BlockTitle extends Component {
  render() {
    const { title, variant, color, classes, className, bar, style } = this.props;

    const barStyles = {
      default: {
        boxSizing: 'border-box',
        height: '3',
        borderRadius: '30px',
        width: '100px',
        border: '1px solid ' + primaryColor,
        display: 'block',
        margin: '15px auto 0',
      },
      white: {
        width: '50px',
        border: '1px solid #fff',
      },
    };

    // let typologyClasses = {};

    return (
      <Typography
        classes={{
          root: classes.h2 + (color !== undefined ? ' ' + classes[color] : ''),
        }}
        className={className}
        variant={variant !== undefined ? variant : 'h2'}
        gutterBottom={true}
        style={style}
      >
        {title}
        <If condition={bar !== false}>
          <div
            style={{
              ...barStyles.default,
              ...(color !== undefined && barStyles[color] !== undefined ? barStyles[color] : {}),
            }}
          ></div>
        </If>
      </Typography>
    );
  }
}

export default withStyles(styles)(BlockTitle);
