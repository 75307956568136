import React from 'react';
import Form from './AdminForm';
import List from './AdminList';
import Template from 'components/UI/Template/Template';
import Box from '@material-ui/core/Box';
import config from 'config/api';

export default ({ match }) => {
  return (
    <Template
      maxWidth="auto"
      current="admin"
      headerPosition={'relative'}
      headerImage={config.template && config.template.admin && config.template.admin.background ? config.template.admin.background : undefined}
    >
      <Box width="100%">
        <If condition={match.params.action === 'list'}>
          <List />
        </If>
        <If condition={match.params.action === 'edit'}>
          <Form id={match.params.id} />
        </If>
      </Box>
    </Template>
  );
};
