import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { formatDatez } from "helpers/stringUtils";
import config from "config/api";
import PodcastShowDetailsCard from "./PodcastShowDetailsCard";
import { connect } from "react-redux";
import {
  fetchPodcastShowDetails,
  getPodcastShowDetails,
  clearPodcastShowDetails,
} from "./ducks/podcastShow";

const colors = {
  live: '#0075A8',
  pending: '#009EE3'
}
const legends = {
  live: 'En ligne',
  pending: 'A venir'
}

const styles = (theme) => {
  return {
    root: {

    },
    title: {

    },
    subTitle: {

    },
    detailsWrapper: {

    },
    podcastState: {
      color: '#fff',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      letterSpacing: 'normal',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,

      // fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
      marginBottom: theme.spacing(2),
    },

  };
};

class PodcastShowDetails extends Component {
  componentDidMount() {
    const {podcastId} = this.props;
    this.props.onMount({podcastId});
  }
  componentDidUpdate(oldProps) {
    if ((oldProps.podcastId === undefined && this.props.podcastId !== undefined ) || (oldProps.podcastId !== undefined && oldProps.podcastId !== this.props.podcastId)) {
      this.props.onMount({podcastId: this.props.podcastId});
    }
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }

  render() {
    const {
      $$data,
      $$themesData,
      classes,
      $$podcastShowDetails,
    } = this.props;


    return (
      <If condition={$$podcastShowDetails !== undefined}>
      {/*<Box px={4} className={classes.root}>
      <Grid
      container={true}
      spacing={4}
      alignItems="flex-start"
      justifyContent="space-around"
      className={classes.detailsWrapper}
    >*/}

      <PodcastShowDetailsCard
      $$cardData={$$podcastShowDetails}
      $$themesData={$$themesData}
      tIdx={0}
      gridSize={1}
      />
{/*
      </Grid>
      </Box>
  */}
      </If>
    );
  }
}
function mapStateToProps(state) {
  const props = {
    $$podcastShowDetails: getPodcastShowDetails(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: ({podcastId}) => {
      dispatch(fetchPodcastShowDetails({podcastId}));
    },
    onUnmount: () => {
      dispatch(clearPodcastShowDetails());
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PodcastShowDetails))
);
