import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18n from "helpers/i18n";
// import BlockRatio from "components/BlockRatio";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { formatDate } from "helpers/stringUtils";
import config from "config/api";
import getCardImageFromArray from "helpers/getCardImageFromArray";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import Dialog from "components/UI/Dialog/Dialog";
import Paper from "@material-ui/core/Paper";
import getCardImagesFromArray from 'helpers/getCardImagesFromArray';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Prettyfier from 'components/UI/Prettyfier';
import crop from 'mout/string/crop'
import {
  fetchPodcastShowFlipbook,
  clearPodcastShowFlipbook,
  getPodcastShowFlipbook,
  fetchPodcastShowAudio,
  clearPodcastShowAudio,
  getPodcastShowAudio,
} from "./ducks/podcastShow";
import Share from 'components/UI/Share';
import NPS from 'components/UI/NPS';

import apiFetch from "components/UI/helpers/fetch";
import {serieUrl, categoryLabel} from 'components/UI/Serie/helper';
import {
  primaryColor,
} from 'helpers/colors';

const colors = {
  live: primaryColor,
  pending: '#009EE3'
}
const legends = {
  live: 'En ligne',
  pending: 'A venir'
}
const styles = (theme) => {
  return {
    root: {
      // transition: [
      //   theme.transitions.create("box-shadow", {
      //     easing: theme.transitions.easing.easeOut,
      //     duration: theme.transitions.duration.enteringScreen,
      //   }),
      // ],
      // boxShadow: theme.shadows[1],

      // "&:hover": {
      //   boxShadow: theme.shadows[9],
      //   "& .MuiButton-root": {
      //     backgroundColor: "#fff !important",
      //     color: "inherit",
      //     border: "1px #5A5A5A solid",
      //   },
      // },
      // "& .MuiButton-root": {
      //   "&:hover": {
      //     backgroundColor: "#fff !important",
      //     color: "inherit",
      //   },
      // },
      // '& .MuiInputLabel-formControl': {
      //   color: 'inherit'
      // },
      '& .MuiTypography-caption': {
        textAlign: 'left'
      }
    },

    paperWrapper: {
      backgroundColor:theme.palette.serie.pageBG,
      paddingTop: theme.spacing(3),
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.replays && theme.palette.replays.backgroundColor ? theme.palette.replays.backgroundColor : theme.palette.primary.light,
        zIndex: -1,
      },
    },
    paper: {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& a': {
        color: 'inherit',
      },
    },    
    /*
    breadCrumbs: {
      paddingLeft: theme.spacing(4),
      color: '#000',
      fontSize: '.7rem',
      [theme.breakpoints.down("xs")]: {
        display: 'none',
      },
      '& a': {
        color: '#000',
        textDecoration: 'underline',
      },
      '& a:hover': {
        textDecoration: 'undeline'
      }

    },*/

    breadCrumbsWrapper:{
      backgroundColor:theme.palette.serie.breadCrumbBG,
      borderTop:theme.palette.serie.breadCrumbBorder+' 1px solid',
      //borderBottom:theme.palette.serie.breadCrumbBorder+' 1px solid',
    },
    breadCrumbs: {
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: '0.8125rem',
      color: theme.palette.serie.breadCrumbText,

      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),

      '& a': {
        color: theme.palette.serie.breadCrumbText,
        textDecoration: 'none',
        display: 'inline-block',
        verticalAlign: 'middle',
      },
      '& *': {
        color: theme.palette.serie.breadCrumbText,
      },
    },


    cardWrapper: {
      backgroundColor: '#fff',
      // boxShadow: theme.shadows[2],
      boxShadow: 'rgba(0,0,0,0.1) 0 0 10px',
    },
    clickable: {
      cursor: "pointer",
    },
    // inline: {
    //   display: "inline-block",
    // },
    cardImage: {
      width: '100%',
      aspectRatio: 2.62,
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      marginBottom: theme.spacing(4),
      // display: "inline-block",
      // verticalAlign: "bottom",
      // marginRight: 20,
    },
    title: {
      /*
      '& .MuiTypography-h1':{
        fontSize: '1.4rem',
        textAlign: 'left',
        color: primaryColor,
        fontWeight: 'normal',
        marginBottom: theme.spacing(4),
      }
      */
    },
    cardTitle: {
      fontSize: '1.4rem',
      textAlign: 'left',
      color: primaryColor,
      fontWeight: 'normal',
      marginBottom: theme.spacing(4),
      //   [theme.breakpoints.down("md")]: {
      //     fontSize: "1.1rem",
      //   },
      //   [theme.breakpoints.down("sm")]: {
      //     fontSize: "1.0rem",
      //   },
      position: 'relative',
      '& span': {
        position: 'relative',
      },
      '& span:after': {
        content: "' '",
        position: 'absolute',
        bottom: 0,
        left: 0,
        top: theme.spacing(2),
        height: '100%',
        width: '30%',
        borderBottom: '3px '+primaryColor+' solid',
      }

    },
    cardGuests: {
      color: '#000',
      fontStyle: 'italic',
      marginBottom: theme.spacing(2),
      fontSize: '.9rem',
      '& strong': {
        fontStyle: 'normal'
      }
    },
    cardDescription: {
      color: '#5A5A5A',
      // textAlign: "center",
      // minHeight: 230,
      fontSize: "1rem",
      lineHeight: '1rem',
      marginBottom: theme.spacing(2),
      // [theme.breakpoints.up("lg")]: {
      //   // minHeight: 260,
      //   // minHeight: 150,
      // },
      // [theme.breakpoints.down("md")]: {
      //   // minHeight: 140,
      //   fontSize: "1.1rem",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   // minHeight: 140,
      //   fontSize: "1.0rem",
      // },
      // [theme.breakpoints.down("xs")]: {
      //   // minHeight: "auto",
      //   fontSize: "1.0rem",
      // },
    },
    dynamicColor: {
      color: "inherit",
      borderColor: "inherit",
    },
    cardDate: {
      fontSize: '.8rem',
      textTransform: 'uppercase',
      color: primaryColor
    },
    cardLinkThumb: {
      width: '100%',
      cursor: 'pointer',
    },
    columnRight: {
      justify: 'flex-end',
      [theme.breakpoints.down("sm")]: {
        justify: 'flex-start',
      },
    },
    columnRightContent: {
      maxWidth: 300,
      [theme.breakpoints.down("sm")]: {
        maxWidth: '60%',
      },
    },
    cardPlayButton: {
      // width: '100%',
      marginTop: theme.spacing(2),
      backgroundColor: primaryColor,
      border:'none',
      color:'white',
      fontSize: '1.3rem',
      fontWeight: 'bold',
      boxShadow: 'none',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        width: '100%',
        fontSize: '1rem',
      }
    },
    cardDownloadButton: {
      width: '100%',
      marginTop: theme.spacing(2),
      color: primaryColor,
      backgroundColor: '#fff',
      border: '2px '+primaryColor+' solid',
      fontSize: '.7rem',
      fontWeight: 'bold',
      boxShadow: 'none',
      marginBottom: theme.spacing(2),
      '&:hover': {
        color: '#fff',
        backgroundColor: primaryColor,
        border: '2px #fff solid',
      }
    },
    cardAudioPlayer: {
      width: '90%',
      marginTop: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        width: '100%',
        // marginLeft: '5%',
        marginBottom: theme.spacing(2),
      }
    },
    disabled: {
      opacity: .4
    },
    navWrapper: {
      marginTop: theme.spacing(4),
      position: 'relative',
      height: 60,
    },
    navButton: {
      position: 'absolute',
      bottom: 0,
      backgroundColor:theme.palette.serie.buttonBG,
      color:theme.palette.serie.buttonFG,
      '&:hover':{
        backgroundColor:theme.palette.serie.buttonFG,
        color:theme.palette.serie.buttonBG,
      }
    },
    navPrevious: {
      left: 0,
    },
    navNext: {
      right: 0,
    },
    share:{
      textAlign:'center',
      width:'100%',
      marginTop:'2em',
    },

  };
};
class PodcastShowDetailsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popinFlipbookState: false,
      popinFlipbookHeight: 500,
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.setPopinFlipbookHeight, true);
    this.setPopinFlipbookHeight();
    
  }
  componentDidUpdate(prevProps) {
    const {$$cardData}=this.props;
    //console.log('row', row);
    const id=$$cardData ? $$cardData.get('id') : null;
    let audio = document.getElementById("audio");
    if(audio && id){
      audio.onloadstart = () => {
        console.log('podcast onloadstart');
        apiFetch('/podcastShow/onPlay/'+id, {})
          .then((data)=>{
            console.log("data", data);
          })
          .catch((error)=>{
            console.log("error", error);
          })
          const time=this.readTimeFromHash();
          if(time)
            this.goto(time);
      };
      audio.ontimeupdate = ()=>{
        if(Math.random()>=0.2)
          return;
        apiFetch('/podcastShow/onUpdate/'+id, {time:audio.currentTime})
        .then((data)=>{
          console.log("data", data);
        })
        .catch((error)=>{
          console.log("error", error);
        })
        }
    }
  }  
  componentWillUnmount() {
    window.removeEventListener('resize', this.setPopinFlipbookHeight);
    this.props.onUnmount();
  }


  goto=(time)=>{
    let audio = document.getElementById("audio");
    if(audio){
      audio.currentTime = time;
      audio.play();
    }
  }

   readTimeFromHash=()=> {
    if(window.location.hash===undefined)
      return null;
    const hash=window.location.hash.substring(1);
    const res=parseInt(hash, 10);
    if (isNaN(res))
      return null;
    return res;
  }

  setPopinFlipbookHeight = () => {
    this.setState({popinFlipbookHeight: Math.round(window.innerHeight * 60 / 100 )})
  }
  openLinkUrl = () => {
    const {$$cardData} = this.props;
    this.setState({popinFlipbookState: true}, () => {
      this.props.fetchPodcastShowFlipbook({podcastId: $$cardData.get('id')});
    })
  }
  closeLinkUrl = () => {
    this.setState({popinFlipbookState: false}, () => {
      this.props.clearPodcastShowFlipbook();
    })
  }
  /*
  gotoPodcast = (direction) => {
  const {$$cardData, $$themesData} = this.props;
  const $$themeData = $$themesData.find($$p => $$p.get('id') == $$cardData.get('podcastTheme'))

  const $$podcasts = $$themeData.get('items');
  const index = $$podcasts.findIndex($$r => $$r.get('id') === $$cardData.get('id'))
  let newPodcast = false;
  const debug = false;
  if (direction === 'next' && index < ($$podcasts.size-1)) {
    if (debug) console.log('next 1');
    newPodcast = $$podcasts.get(index+1)
  } else if (direction === 'next' && index >= ($$podcasts.size -1)) {
    if (debug) console.log('next 2');
    newPodcast = $$podcasts.get(0);
  } else if (direction === 'previous' && index > 0) {
    if (debug) console.log('previous 1');
    newPodcast = $$podcasts.get(index-1)
  } else if (direction === 'previous' && index === 0) {
    if (debug) console.log('previous 2');
    newPodcast = $$podcasts.get($$podcasts.size -1)
  }

  // if (!newReplay) return;
  // console.log(newReplay.toJS())

  this.props.history.push('/podcast/show/'+newPodcast.get('id'));
}*/
 navigate=(row)=>{
  this.props.history.push(serieUrl('podcast', row.podcastCategory, 'podcast', row.id));
}

  render() {
    const {
      $$cardData,
      tIdx,
      classes,
      gridSize,
      podcastId,
      $$themesData,
      $$podcastShowFlipbook,
      $$podcastShowAudio,
    } = this.props;
    const {
      popinFlipbookState,
      popinFlipbookHeight
    } = this.state;

    const row=$$cardData!==undefined ? $$cardData.toJS() : {};

    // console.log($$cardData.toJS())
    const cardThumb = getCardImageFromArray($$cardData, {needleField: 'podcastThumb', needleId: 'podcastId', needleUrl: config.podcastshowthumbpreview});
    const podcastLinkThumb = getCardImageFromArray($$cardData, {needleField: 'podcastLinkThumb', needleId: 'podcastId', needleUrl: config.podcastshowlinkthumbpreview});

    // const podcastFiles = JSON.parse($)
    // console.log($$cardData.get('podcastFile'));
    // let podcastFiles = []
    // try {
    //   podcastFiles = JSON.parse($$cardData.get('podcastFile'));
    // }catch(e) {
    //   podcastFiles = [];
    // }
    // console.log({podcastFiles})
    const $$guests = getCardImagesFromArray($$cardData, {needleField: 'podcastGuests', needleId: 'podcastId', needleUrl: config.podcastshowthumbpreview});
    // console.log($$guests)

    //const $$themeData = $$themesData.find($$p => $$p.get('id') == $$cardData.get('podcastTheme'))
    //const $$podcasts = $$themeData.get('items');
    //const themeColor = undefined;

    return (
      <Box className={classes.paperWrapper} elevation={3}>
        <Paper square elevation={0} className={clsx(classes.root, classes.paper)}>
      <Grid
        key={"show_" + tIdx}
        item={true}
        xs={12}
        className={clsx(classes.mainGrid)}
        >
          
        {/*<Box className={classes.breadCrumbs}>
          <Link to={'/podcasts'}>Accueil podcast</Link>&nbsp;&gt;&nbsp;<Link to={'/podcast/theme/'+$$themeData.get('id')}>{$$themeData.get('themeSubTitle')}</Link>&nbsp;&gt;&nbsp;{crop($$cardData.get('podcastTitle'), 10, '[...]')}
          </Box>*/}
          <Box mb={0}>
            <Typography variant={'h3'} gutterBottom className={classes.title}>
              {$$cardData.get('podcastTitle')}
            </Typography>
          </Box>
    


          <Box className={classes.cardWrapper}>
            <Box px={4} py={4}>
              <Grid container>
                <Grid item xs={12} sm={8}>

                <If condition={$$cardData.get('podcastState') !== 'live'}>
                        <Button variant="outlined" disabled={$$cardData.get('podcastState') !== 'live'} className={clsx(classes.cardPlayButton, classes.disabled)}>Bientôt disponible</Button>
                      </If>
                      <If condition={$$cardData.get('podcastState') === 'live' && $$podcastShowAudio === undefined}>
                        <Button startIcon={<PlayArrowIcon/>} variant="outlined" disabled={$$cardData.get('podcastState') !== 'live'} onClick={(e) => {
                            e.preventDefault();
                          }} className={classes.cardPlayButton} onClick={(e) => {
                            this.props.fetchPodcastShowAudio({podcastId: $$cardData.get('id')})
                          }}>Ecouter</Button>
                        </If>
                        <If condition={$$cardData.get('podcastState') === 'live' && $$podcastShowAudio !== undefined}>
                          <audio id='audio' controls className={classes.cardAudioPlayer} controlsList="nodownload">
                            <source src={$$podcastShowAudio.get('url')} type="audio/mpeg"/>
                            Your browser does not support the audio element.
                          </audio>
                          {/*<iframe allowtransparency="true" height={150} width="100%" scrolling="no" data-name="pb-iframe-player" src={$$podcastShowAudio.get('url')}></iframe>*/}

                        </If>

                  <If condition={$$cardData.get("podcastDuration") !== undefined && $$cardData.get("podcastDuration") !== false && $$cardData.get("podcastDuration") !== null && $$cardData.get("podcastDuration") !== '' && $$cardData.get("podcastDuration") !== '0'}>
                    <Typography
                      variant={"body1"}
                      gutterBottom={true}
                      className={clsx(classes.cardDuration)}
                      component={"div"}
                      >
                      Durée : {$$cardData.get("podcastDuration")}min
                    </Typography>
                  </If>
                  <Typography
                    variant={"body1"}
                    gutterBottom={true}
                    className={clsx(classes.cardDescription)}
                    component={"div"}
                    >
                    <Paragrapher
                      element={"div"}
                      text={$$cardData.get("podcastDescription")}
                      prettyfy={true}
                      />
                  </Typography>

                  <If condition={$$guests !== false && $$guests.size > 0}>
                    <Box className={classes.cardGuests}>
                      {/*  <Typography variant={'caption'} gutterBottom style={{marginBottom: 8, display: 'block'}}>{i18n.get('eventAnimatedBy', {pretty: false})}</Typography>*/}
                      {$$guests.map(($$g, aIdx) => {
                        return (
                          <Box w={'100%'} key={'av_'+aIdx} style={{clear: 'both'}}>
                            <Typography variant={'body1'}><strong>{$$g.getIn(['attributes','name'])}</strong><br />  {$$g.getIn(['attributes','description'])}</Typography>
                          </Box>)
                        }).toArray()}
                      </Box>
                    </If>

                  
                    <If condition={$$cardData.get('podcastState') === 'live' && $$cardData.get('podcastDate') !== undefined && $$cardData.get('podcastDate') !== null && $$cardData.get('podcastDate') !== false && $$cardData.get('podcastHideDate') !== 'on'}>
                      <Typography
                        variant={"body1"}
                        gutterBottom={true}
                        className={clsx(classes.cardDate)}
                        component={"div"}
                        >
                        <CalendarTodayIcon size="small" style={{width: '.85rem', height: '.85rem', marginTop:'-2px', marginRight: 2, display: 'inline-block', verticalAlign: 'middle'}}/> {formatDate($$cardData.get('podcastDate'), 'dd mmm yyyy')}
                        </Typography>
                      </If>
                      
                      </Grid>
                      <Grid item xs={12} sm={4} container className={classes.columnRight}>
                        <Box className={classes.columRightContent}>
                          <If condition={podcastLinkThumb !== false}>
                            <img src={podcastLinkThumb} alt="flip book" className={classes.cardLinkThumb} onClick={(e) => {
                                e.preventDefault();
                                this.openLinkUrl()
                              }}/>
                            </If>
                            <If condition={$$cardData.get('podcastFiles') !== undefined && $$cardData.get('podcastFiles') !== false && $$cardData.get('podcastFiles') !==null}>
                              <Button variant="outlined" className={classes.cardDownloadButton} endIcon={<GetAppIcon/>} onClick={(e) => {
                                  e.preventDefault();
                                  window.location.replace(config.podcastShow+'/download/'+$$cardData.get('id'))
                                }}>Télécharger</Button>
                              </If>
                            </Box>
                            <If condition={$$cardData.get('podcastState') === 'live'} >
                            <Box className={classes.share}>
                                <NPS module="podcastShow" id={$$cardData.get('id')} data={row.nps} align="center"/>
                              
                                <Share module="podcastShow" id={$$cardData.get('id')} title={$$cardData.get('podcastTitle')}/>
                            </Box>
                            </If>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Dialog open={popinFlipbookState} title={$$cardData.get('podcastTitle')} onClose={this.closeLinkUrl} showOnClose={true}>
                      <Box height={popinFlipbookHeight}>
                        <If condition={$$podcastShowFlipbook !== undefined}>
                          <iframe width="100%" height="100%" src={$$podcastShowFlipbook.get('url')} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </If>
                      </Box>
                    </Dialog>

                    {/*
                    <If condition={$$podcasts !== undefined && $$podcasts.size > 1}>
                      <Grid item xs={12} className={classes.navWrapper}>
                        <Button startIcon={<ArrowBackIcon/>} variant="contained" color="primary" className={clsx(classes.navButton, classes.navPrevious)} onClick={e => this.gotoPodcast('previous')}>{i18n.get('podcastPrevious',{pretty: false})}</Button>
                        <Button endIcon={<ArrowForwardIcon/>} variant="contained" color="primary" className={clsx(classes.navButton, classes.navNext)} onClick={e => this.gotoPodcast('next')}>{i18n.get('podcastNext',{pretty: false})}</Button>
                      </Grid>
                    </If>
                    */}
                  
                  <Grid item xs={12} className={classes.navWrapper}>
                  <If condition={row.previous !== undefined}>
                    <Button variant="contained" className={clsx(classes.navButton, classes.  navPrevious)} onClick={(e) => this.navigate(row.previous)}>
                      Podcast précédent
                    </Button>
                  </If>
                  <If condition={row.next !== undefined}>
                    <Button variant="contained" className={clsx(classes.navButton, classes.navNext)} onClick={(e) => this.navigate(row.next)}>
                    Podcast suivant
                    </Button>
                  </If>
                  </Grid>
                  </Grid>

                  </Paper>
                  {/*
                  <Paper square elevation={0} className={classes.breadCrumbsWrapper}>
        <Grid item xs={12} className={classes.breadCrumbs}>
              <Typography variant="subtitle1">
                <Link to="/">{i18n.get('home', { pretty: false })}</Link>
                &nbsp;&nbsp;&gt;&nbsp;&nbsp;
                <Link to={serieUrl('podcast', row.podcastCategory)}>{categoryLabel('podcast', row.podcastCategory)}</Link>
                &nbsp;&nbsp;&gt;&nbsp;&nbsp;
                <b><Prettyfier text={row.podcastTitle} /></b>
              </Typography>
          </Grid>
        </Paper>
                  */}
                  

                  </Box>
                );
              }
            }

            function mapStateToProps(state) {
              const props = {
                $$podcastShowFlipbook: getPodcastShowFlipbook(state),
                $$podcastShowAudio: getPodcastShowAudio(state),
              };
              return props;
            }
            function mapDispatchToProps(dispatch) {
              return {
                fetchPodcastShowFlipbook: ({podcastId}) => {
                  dispatch(fetchPodcastShowFlipbook({podcastId}));
                },
                clearPodcastShowFlipbook: () => {
                  dispatch(clearPodcastShowFlipbook());
                },
                fetchPodcastShowAudio: ({podcastId}) => {
                  dispatch(fetchPodcastShowAudio({podcastId}));
                },
                clearPodcastShowAudio: () => {
                  dispatch(clearPodcastShowAudio());
                },
                onUnmount: () => {
                  dispatch(clearPodcastShowFlipbook());
                  dispatch(clearPodcastShowAudio());
                }
              };
            }

            export default withRouter(
              connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PodcastShowDetailsCard))
            );
