import dateFormat from "dateformat";
dateFormat.i18n = {
  dayNames: [
    "Dim",
    "Lun",
    "Mar",
    "Mer",
    "Jeu",
    "Ven",
    "Sam",
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ],
  monthNames: [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Jui",
    "Jul",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
};
// import areIntlLocalesSupported from 'intl-locales-supported';
// let DateTimeFormat;
//
// if (areIntlLocalesSupported(['fr'])) {
//   DateTimeFormat = global.Intl.DateTimeFormat;
// } else {
//   const IntlPolyfill = require('react-intl');
//   DateTimeFormat = IntlPolyfill.DateTimeFormat;
//   require('react-intl/locale-data/fr');
// }
//
// export function formatDate(date, format) {
//   let t;
//   if (format === undefined || format === 'full')
//   t = new Intl.DateTimeFormat('fr', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }).format(date*1000)
//   return t;
// }
export function formatDate(date, format) {
  return dateFormat(date * 1000, format);
}
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const defaultUtils = {
  capitalize,
  formatDate,
};
