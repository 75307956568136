import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import IconAdd from "@material-ui/icons/AddCircleOutline";
import IconDelete from "@material-ui/icons/HighlightOff";
import IconCheck from "@material-ui/icons/Check";
// import BlockButton from 'components/UI/BlockButton';
import Button from "components/UI/BlockButton";

import { primaryColor } from "helpers/colors";

const styles = (theme) => ({
  addButton: {
    borderRadius: 4,
    minHeight: 24,
    backgroundColor: "#fff",
    border: "1px " + primaryColor + " solid",
    color: primaryColor,
    marginTop: "-4px",
    padding: "5px 15px",
    fontSize: "0.8rem",
    boxShadow: "0 0 0 rgba(0,0,0,0.21)",
    textTransform: "uppercase",
    "& span > .subLabel": {
      fontSize: "200%",
      marginTop: "-6px",
      color: primaryColor,
      marginLeft: 5,
    },
    "&:hover": {
      boxShadow: "0 5px 20px rgba(0,0,0,0.21)",
      color: "#fff",
      backgroundColor: primaryColor,
      "& span > .subLabel": {
        color: "#fff",
      },
    },
  },
  checkButton: {
    borderRadius: 25,
    minHeight: 24,
    // backgroundColor: '#fff',
    // color: '#47B42A',
    border: "3px #47B42A solid",
    color: "#fff",
    backgroundColor: "#47B42A",
    "& svg": {
      color: "#fff",
    },
    marginTop: "-4px",
    padding: "5px 15px",
    fontSize: "0.8rem",
    boxShadow: "0 0 0 rgba(0,0,0,0.21)",
    textTransform: "uppercase",
    "&:hover": {
      boxShadow: "0 5px 20px rgba(0,0,0,0.21)",
      backgroundColor: "#fff",
      color: "#47B42A",
      "& svg": {
        color: "#47B42A",
      },
      // color: '#fff',
      // backgroundColor: '#47B42A',
      // '& svg': {
      //   color: '#fff'
      // }
    },
  },
  addIcon: {
    width: 30,
    height: 30,
    color: primaryColor,
    verticalAlign: "middle",
    margin: "-5px",
  },
  checkIcon: {
    width: 20,
    height: 25,
    color: "#47B42A",
    verticalAlign: "middle",
    marginTop: "-4px",
    marginLeft: 3,
  },
  label: {
    marginRight: 10,
  },
});

class PopinWrapper extends Component {
  render() {
    const {
      onClick,
      className,
      classes,
      icon,
      button,
      label,
      subLabel,
      style,
      endIcon,
      startIcon,
      size,
      disabled,
    } = this.props;
    if (button !== true) {
      let iconComponent;
      if (icon === "delete")
        iconComponent = <IconDelete classes={{ root: classes.addIcon }} />;
      else if (icon === "check")
        iconComponent = <IconCheck classes={{ root: classes.checkIcon }} />;
      else if (icon !== false)
        iconComponent = <IconAdd classes={{ root: classes.addIcon }} />;
      else iconComponent = <span></span>;
      return (
        <IconButton
          onClick={onClick}
          classes={{
            root: icon === "check" ? classes.checkButton : classes.addButton,
          }}
          className={className}
          disabled={disabled}
        >
          <span>{label}</span>
          <If condition={subLabel !== undefined}>
            <span className="subLabel">{subLabel}</span>
          </If>
          {iconComponent}
        </IconButton>
      );
    } else {
      return (
        <Button
          size={size}
          label={label}
          onClick={onClick}
          style={style}
          startIcon={startIcon}
          endIcon={endIcon}
          className={className}
          disabled={disabled}
        />
      );
    }
  }
}
export default withStyles(styles)(PopinWrapper);
