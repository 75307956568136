import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
// import config from 'config/api'
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
// import Bubble from 'components/UI/Bubble';
import Header from "components/Header/Header";
import HeaderContent from "components/Header/HeaderContent";
import HeaderSub from "components/Header/HeaderSub";
import Footer from "components/Footer";
import ContactForm from "./ContactForm";
import BlockTitle from "components/UI/BlockTitle";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import i18n from "helpers/i18n";
import { getCurrentUserRole } from "ducks/authentication";
const styles = (theme) => {
  return {
    root: {
      height: "100%",
      [theme.breakpoints.up("md")]: {
        height: "auto",
        minHeight: "1000px",
      },
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
    },
    content: {
      maxWidth: "1280px",
      margin: "0 auto",
      // height: '100%',
      minHeight: "calc(100% - 60px)",
      // background: 'linear-gradient(180deg, rgba(241,243,244,0.42) 0%, rgba(223,227,229,0.42) 100%)',
      borderBottom: "3px rgba(49,142,184,0.18) solid",
    },
    contactFormWrapper: {
      maxWidth: 1080,
      margin: "0 auto",
      // marginTop: '-300px',
      position: "relative",
      // zIndex: 9,
      // [theme.breakpoints.down('sm')]: {
      // marginTop: '-270px'
      // }
    },
    // contactFormDecorator: {
    //   padding: 20,
    // },
    title: {
      marginTop: 20,
    },
    // headerBg: {
    //   minHeight: 210,
    //   backgroundImage: `url(${require("assets/images/" +
    //     process.env.REACT_APP_SITE +
    //     "/header.jpg")})`,
    //   backgroundPosition: "0 0",
    //   [theme.breakpoints.down("sm")]: {
    //     backgroundImage: "none",
    //   },
    // },
    // titleSmall: {
    //   fontSize: "2rem",
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: "1.2rem",
    //     textAlign: "center",
    //   },
    // },
    headerImage: {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        textAlign: "center",
      },
    },
  };
};
class Contact extends Component {
  render() {
    const { onPanelToggle, classes, userRole } = this.props;
    // console.log('userRole', userRole)
    return (
      <div className={classes.root}>
        <ScrollToTopOnMount />
        <div className={classes.content}>
          <Header
            onPanelToggle={onPanelToggle}
            userRole={userRole}
            current={"contact"}
            headerContent={
              process.env.REACT_APP_SITE === "neonat" ||
              process.env.REACT_APP_SITE === "transplant" ? (
                <HeaderContent />
              ) : undefined
            }
          />

          {/*process.env.REACT_APP_SITE === "pneumo" ||
          process.env.REACT_APP_SITE === "pneumo_medevent" ? (
            <HeaderSub size={"small"} />
          ) : null*/}

          <Box>
            <Box align="middle" mb={3}>
              <Typography variant="h2" className={classes.title}>
                Nous contacter
              </Typography>
            </Box>
            <ContactForm userRole={userRole} />
          </Box>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}
// export default ;
export default connect(mapStateToProps)(withStyles(styles)(Contact));
