import React, { Component } from "react";
// import BlockButton from 'components/UI/BlockButton';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import { formatDate } from "helpers/stringUtils";
// import i18n from 'helpers/i18n'
import { withStyles } from "@material-ui/core/styles";
import getCardImage from "helpers/getCardImage";
import getThemeImage from "helpers/getThemeImage";
// import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  list: {
    // '& .MuiList-root': {
    //   display: 'flex',
    // },
    //     '& .MuiListItem-root': {
    //       flexGrow: 0,
    //       maxWidth: '50%',
    //       flexBasis: '50%',
    // width: '50%',
    //     },
    "& .MuiTypography-colorTextSecondary": {
      color: theme.palette.text.primary,
    },
    "& .MuiTypography-body1": {
      color: theme.palette.primary.main,
    },
  },
  h2: {
    color: theme.palette.primary.main,
    textAlign: "center",
    padding: "0 20px 20px",
  },
  h5: {
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  h6: {
    fontWeight: "normal",
    color: theme.palette.primary.main,
  },
  caption: {
    display: "block",
    textAlign: "center",
    marginBottom: 5,
  },
  body1: {
    textAlign: "center",
    marginBottom: 10,
  },
});
class EventSubscriptionRecap extends Component {
  render() {
    const {
      classes,
      $$events,
      // onClose,
      // userRole,
    } = this.props;
    let image;
    const cellSize = $$events.size > 1 ? 6 : 12;
    return (
      <Grid
        container={true}
        spacing={2}
        alignItems="center"
        justify="flex-start"
        className={classes.list}
      >
        {$$events
          .map(($e) => {
            image = getThemeImage($e);
            return (
              <Grid key={"ev_" + $e.get("id")} item={true} xs={cellSize}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <If condition={image !== false}>
                        <img
                          src={image}
                          alt={$e.get("themeTitle")}
                          width="100%"
                        />
                      </If>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={$e.get("eventTitle")}
                    secondary={formatDate(
                      $e.get("eventDate"),
                      "dddd dd mmmm yyyy",
                    )}
                  />
                </ListItem>
              </Grid>
            );
          })
          .toArray()}
      </Grid>
    );
  }
}

// export default ;
export default withStyles(styles)(EventSubscriptionRecap);
