//NOTE B : recupéré depuis webconf-sobi-front-v1
import config from "config/api";

function postAndRedirect(path, params, method) {
  method = method || "post";

  var form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", path);

  for (var key in params) {
    if (params.hasOwnProperty(key)) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export default ({$$event, link, callback}) => {
  let url = config.events + "/redir/" + $$event.get("id");
  if (link !== undefined) url = url+='?l='+link
  // window.location = url;
  var formData = new FormData();
  formData.append("rd", Math.round(new Date() / 1000));
  // const url = config.events+'/redir/'+$$event.get('id');
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
      "X-Requested-With": "XMLHttpRequest", // needed by php api
    },
    body: formData,
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.live && response.live === "complete") callback(true);
      else if (
        response.live &&
        response.live === "redir" &&
        response.to !== undefined
      ) {
        console.log("calling ", response.to);
        if (response.toMethod === "GET") window.location = response.to;
        else {
          console.log("GOT A POST !");
          console.log("resonseto", response.toData);
          postAndRedirect(response.to, response.toData, "post");
        }
      } else callback(false);
    });
  // this._goto(url)
};
