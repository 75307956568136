/* eslint-disable import/newline-after-import */
/* Combine all available reducers to a single root reducer.
 *
 * CAUTION: When using the generators, this file is modified in some places.
 *          This is done via AST traversal - Some of your formatting may be lost
 *          in the process - no functionality should be broken though.
 *          This modifications only run once when the generator is invoked - if
 *          you edit them, they are not updated again.
 */
/* Populated by react-webpack-redux:reducer */
import { combineReducers } from "redux";
// import { reducer as formReducer } from 'redux-form'
import authentication from "components/UI/Account/ducks/authentication";
import registration from "../ducks/registration";
import contact from "../ducks/contact";
import globalDuckReducer from "../ducks/global";
import asyncFetcher from "../ducks/asyncFetcher";
import dynamicForm from "components/UI/DynamicForm/ducks/dynamicForm";
// import dynamicList from "ducks/dynamicList";
import dynamicList from "components/UI/DynamicList/ducks/dynamicList";

import live from "components/UI/Live/ducks/live";
import files from "ducks/files";

import adminMessages from "components/UI/Message/ducks/adminMessages";
import quiz from "components/UI/Quiz/ducks/quiz";
import relay from "components/UI/Socket/ducks/relay";
import feedback from "components/UI/Feedback/ducks/feedback";
import moderation from "components/UI/Orchestrator/ducks/moderation";
import documents from "components/UI/Document/ducks/document";
import podcastTheme from "components/UI/PodcastTheme/ducks/podcastTheme";
import podcastShow from "components/UI/PodcastShow/ducks/podcastShow";

import wevent from 'components/UI/Event/ducks/event';
import replay from 'components/UI/Replays/ducks/replay';

import media from 'components/UI/Media/ducks/media';
import news from 'components/UI/News/ducks/news';

import announce from "components/UI/Announce/ducks/announce";
import account from "components/UI/Account/ducks/account";
import dashboard from "components/UI/Dashboard/ducks/dashboard";

const reducers = {
  authentication,
  registration,
  dynamicForm,
  asyncFetcher,
  contact,
  dynamicList,
  live,
  files,
  adminMessages,
  quiz,
  relay,
  feedback,
  moderation,
  documents,
  podcastTheme,
  podcastShow,

  wevent,
  replay,
  media,
  news,
  announce,
  account,
  dashboard,

};
const combined = combineReducers(reducers);

const globalReducer = (state = {}, action) => {
  state = combined(state, action);
  state = globalDuckReducer(state, action);
  return state;
};

// module.exports = globalReducer;
export default globalReducer;
