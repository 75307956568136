import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import config from "config/api";
import DynamicForm from "components/UI/DynamicForm";
import JobForm from "./JobForm";
import i18n from "helpers/i18n";
import Immutable from "immutable";
import LoginForm from "./LoginForm";
import ResetPasswordRequestForm from "components/UI/ResetPassword/ResetPasswordRequestForm";
import { connect } from "react-redux";
import { setCurrentUser } from "ducks/authentication";
import RegistrationMessage from "components/UI/Registration/RegistrationMessage";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

const styles = (theme) => {
  return {
    root: {
      padding: "0 20px 10px 20px",
    },
    column: {
      "&:nth-of-type(1)": {
        borderRight: "1px #ccc solid",
        [theme.breakpoints.down("sm")]: {
          borderRight: "none",
          borderBottom: "1px #ccc solid",
          borderTop: "1px #ccc solid",
        },
      },
    },
    padder: {
      padding: "20px 40px",
      margin: "0 30px 20px",
    },
    h2: {
      color: theme.palette.primary.main,
      textAlign: "center",
      padding: "0 20px 20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    h5: {
      textAlign: "center",
    },
    h6: {
      fontWeight: "normal",
    },
    body1: {
      textAlign: "center",
    },
    instructions: {
      textAlign: "center",
    },
    recap: {
      "& a": {
        color: theme.palette.primary.main,
      },
    },
    formTitle: {
      textAlign: "center",
      color: theme.palette.primary.main,
      marginBottom: 30,
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  };
};
class RegistrationTwoStepsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isFormComplete: true,
      showRecap: false,
      // logMessage: false,
      step: 1,
      // showRecap: true,
      // logMessage: 'st',
      // step: 2
    };
    // this._onSubscriptionFormComplete = this._onSubscriptionFormComplete.bind(this);
    this._onJobFormComplete = this._onJobFormComplete.bind(this);
    this._onRegistrationComplete = this._onRegistrationComplete.bind(this);
    // this._onRegistrationComplete = this._onFormComplete.bind(this);
    this._onRegistrationCancel = this._onRegistrationCancel.bind(this);
    this._onClose = this._onFormComplete.bind(this);
    this._onLoginFormComplete = this._onFormComplete.bind(this);
    this._onLoginPasswordReset = this._onLoginPasswordReset.bind(this);
    // this._onClose = this._onClose.bind(this);
  }
  //   componentWillUnmount() {
  // }
  // _onSubscriptionFormComplete(responseData) {
  // }
  _onRegistrationCancel() {
    this.setState({
      accountJob: undefined,
      step: 1,
      // isNewMember: false,
    });
  }
  _onRegistrationComplete(r) {
    //     const {setCurrentUser} = this.props;
    //console.log('COMPLETE', r)
    //     setCurrentUser(r.user);
    const { showRecap } = this.props;
    //console.log({showRecap});
    if (showRecap === true)
      this.setState({
        showRecap: true,
        registrationResult: r, //AJOUT B
        step: 5, //AJOUT B
      });
    else this._onFormComplete(r);
    //     // this.setState({step: 3, newUserRole: r.accountJob});
  }
  _onJobFormComplete(o) {
    this.setState({
      accountJob: o.values.accountJob,
      step: 2,
      isNewMember: true,
    });
  }
  _onLoginPasswordReset() {
    this.setState({
      accountJob: undefined,
      step: 3,
    });
  }
  _onFormComplete() {
    const { onClose, onFormComplete, closeOnComplete } = this.props;
    // console.log('BUBBLED !', onFormComplete)
    const { isNewMember, logMessage, accountJob } = this.state;
    // console.log({
    //   logMessage,
    //   accountJob
    // })
    //console.log({onFormComplete});
    if (onFormComplete === undefined) {
      //console.log("a");
      onClose();
    } else {
      onFormComplete({
        isNewMember: isNewMember,
        accountJob: accountJob,
      });
      console.log("b");
      if (closeOnComplete === true) {
        //console.log('c')
        onClose();
      }
    }
  }
  render() {
    const {
      onClose,
      classes,
      $$event,
      $$events,
      // title,
      showRecap,
      // extraFields,
      registrationResult,
    } = this.props;
    //
    const {
      // isFormComplete,
      isNewMember,
      // logMessage,
      // showRecap,
      accountJob,
      step,
      // logMessage,
    } = this.state;
    const moduleConfig = {
      module: "account",
      fetchUrl: config.registration + "/edit",
      postUrl: config.registration + "/edit",
      redirectAfterEdit: false,
      afterEdit: "onFormComplete",
      afterEditLog: "",
      afterEditHideForm: true,
      btCancel: true,
      onFormCancel: this._onRegistrationCancel,
      btCancelLabel: "Retour",
      btSubmitLabel: "Envoyer",
    };
    const hiddenFields = ["accountJob"];
    // const hiddenFields = [];
    // const jobCategories = {
    //   md: "isMd",
    //   sf: "isSf",
    //   lb: "isLb",
    //   sf: "isSf",
    //   in: "isIn",
    // };
    const jobCategories = config.getJobsCategories();
    // console.log({ jobCategories });
    const jobCategory =
      accountJob === undefined ? [] : jobCategories[accountJob];
    let ef = {};
    if ($$event !== undefined && $$event.get("id") !== undefined)
      ef = {
        fromEvent: $$event.get("id"),
      };
    else if ($$events !== undefined && $$events.get(0) !== undefined)
      ef = {
        fromEvents: $$events.map((e) => e.get("id")).toArray(),
      };
    ef.accountJob = accountJob;
    console.log("accountJob", accountJob);
    //console.log({showRecap})
    const extraFields = Immutable.fromJS(ef);
    //console.log({step})
    return (
      <div id="mainform" className={clsx(classes.root, "job_" + accountJob)}>
        <If condition={step === 1}>
          <Typography
            variant="h5"
            gutterBottom={true}
            color="primary"
            className={classes.instructions}
          >
            Pour participer à une réunion, merci de vous identifier, ou de créer
            un nouveau compte
          </Typography>

          <Typography variant={"h2"} className={classes.h2} gutterBottom>
            {" "}
            {i18n.get("subscriptionFormVisitorTitle")}
          </Typography>
          <Grid
            container
            spacing={4}
            alignItems="flex-start"
            justify="space-around"
          >
            <Grid item={true} xs={12} md={6} className={classes.column}>
              <Typography
                variant={"h5"}
                className={classes.formTitle}
                gutterBottom={true}
              >
                {i18n.get("loginFormTitle")}
              </Typography>
              <LoginForm
                onFormComplete={this._onLoginFormComplete}
                onPasswordResetRequest={this._onLoginPasswordReset}
                extraFields={extraFields}
                footerAlign={"left"}
              />
            </Grid>
            <Grid item={true} xs={12} md={6} className={classes.column}>
              <Typography
                variant={"h5"}
                className={classes.formTitle}
                gutterBottom={true}
              >
                {i18n.get("registrationFormTitle")}
              </Typography>
              <JobForm
                userRole={undefined}
                onFormComplete={this._onJobFormComplete}
                showRequiredLegend={false}
              />
            </Grid>
          </Grid>
        </If>
        <If condition={step === 2}>
          {/*<If condition={showRecap !== true}>
          <If condition={i18n.has('registrationFormTitle') === true}>
          <Typography variant={'h4'} className={classes.formTitle} gutterBottom>
          {i18n.get('registrationFormTitle')}
          </Typography>
          </If>
          <If condition={i18n.has('registrationFormInstructions') === true}>
          <Typography variant={'h6'} className={classes.h6} gutterBottom>
          {i18n.get('registrationFormInstructions')}
          </Typography>
          </If>
          <Box align="left">
          <DynamicForm moduleConfig={moduleConfig} onFormComplete={this._onRegistrationComplete} formFooter={<If condition={i18n.has('registrationFormFooter')} ><div>{i18n.get('registrationFormFooter')}</div></If>} extraFields={extraFields} forceHiddenFields={hiddenFields}  onFormCancel={this._onRegistrationCancel} initialCategories={jobCategory}/>

          <br />
          <Typography variant={'body1'} classes={{root: classes.legend}} gutterBottom>{i18n.get('registrationLogLegend1', {prettyfy: true})}</Typography>
          <hr />
          <Typography variant={'body1'} classes={{root: classes.legend}} gutterBottom>{i18n.get('registrationLogLegend2', {prettyfy: true})}</Typography>
          </Box>
          </If>
          <If condition={showRecap === true}>
          <Box maxWidth={1024} mx={'auto'} className={classes.recap}>
          <If condition={i18n.has('registrationSuccessTitle')}>
          <Typography variant={'h2'} classes={{root: classes.h2}} gutterBottom>{i18n.get('registrationSuccessTitle', {pretty: true, element: 'div'})}</Typography>
          </If>
          <If condition={i18n.has('registrationSuccessText')}>
          <Typography variant={'h6'} classes={{root: classes.h6}} gutterBottom>{i18n.get('registrationSuccessText', {pretty: true})}</Typography>
          </If>
          <If condition={logMessage === 'x1'}>
          <Typography variant={'body1'} classes={{root: classes.body1}} gutterBottom>{i18n.get('registrationLogStudents', {pretty: true})} </Typography>
          </If>
          <If condition={logMessage !== 'x1'}>
          <Typography variant={'body1'} classes={{root: classes.body1}} gutterBottom>{i18n.get('registrationLogSuccess', {pretty: true})}</Typography>
          </If>


          <BlockButton label="Terminer" onClick={this._onClose} style={{margin: '20px auto 10px', display: 'block'}}/>
          </Box>
          </If>*/}

          <If condition={i18n.has("registrationFormTitle") === true}>
            <Typography
              variant={"h4"}
              className={classes.formTitle}
              gutterBottom
            >
              {i18n.get("registrationFormTitle")}
            </Typography>
          </If>
          <If condition={i18n.has("registrationFormInstructions") === true}>
            <Typography variant={"h6"} className={classes.h6} gutterBottom>
              {i18n.get("registrationFormInstructions")}
            </Typography>
          </If>
          <Box align="left">
            <DynamicForm
              moduleConfig={moduleConfig}
              onFormComplete={this._onRegistrationComplete}
              formFooter={
                <If condition={i18n.has("registrationFormFooter")}>
                  <Typography variant="body1" component="div">
                    {i18n.get("registrationFormFooter", {
                      prettyfy: true,
                      element: "div",
                    })}
                  </Typography>
                </If>
              }
              extraFields={extraFields}
              forceHiddenFields={hiddenFields}
              onFormCancel={this._onRegistrationCancel}
              initialCategories={jobCategory}
            />

            <br />
            <Typography
              variant={"body1"}
              classes={{ root: classes.legend }}
              gutterBottom
            >
              {i18n.get("registrationLogLegend1", { prettyfy: true })}
            </Typography>
            <hr />
            <Typography
              variant={"body1"}
              classes={{ root: classes.legend }}
              gutterBottom
            >
              {i18n.get("registrationLogLegend2", { prettyfy: true })}
            </Typography>
          </Box>
        </If>
        <If condition={step === 3}>
          <ResetPasswordRequestForm onFormCancel={this._onRegistrationCancel} />
        </If>
        <If condition={step === 4}></If>
        <If condition={step === 5}>
          {/* AJOUT B */}
          <Grid item xs={12} className={classes.formWrapper}>
            <Box align="center" my={4}>
              <RegistrationMessage registrationResult={registrationResult} />
              <Box mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    onClose();
                  }}
                >
                  Fermer
                </Button>
              </Box>
            </Box>
          </Grid>
        </If>
      </div>
    );
  }
}
const mapStateToProps = ($$state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (user) => {
      dispatch(setCurrentUser(user));
    },
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(RegistrationTwoStepsForm),
);
