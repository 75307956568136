import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';//"react-markdown": "4.3.1",

import ToolCalls from './ToolCalls';

const styles = makeStyles((theme) => {
  return {
    root: {
      '& p':{
        margin:".6em 0",
      }
    },
    message:{
      marginBottom: '10px',
      padding: '.6em',
      border: '1px solid #ccc',
      borderRadius: '3px',
      backgroundColor: '#fff',
    },
    text:{
      //whiteSpace:'pre-wrap',
      fontSize: '1.1em',
      color: '#333',
    },
    user:{
      borderColor: '#007BFF',
      backgroundColor: '#EDF3F9',
      },
    assistant:{
      borderColor: '#28A745',
      backgroundColor: '#EFF7F2',
    },
  };
});


export default function Message({ id, role, content, metadata }) {
  const classes = styles();
  let empty=true;
  content.forEach(row => {
    if(row.text.value!='')
      empty=false;
  });
  if(empty)
    return null;
  return (
    <div className={classes.root}>
      <Box className={clsx(classes.message, classes[role])}>
        <Box className={classes.toolCalls}>
          <ToolCalls metadata={metadata}/>
        </Box>
     
        {content.map((row, key)=>(
          <Box key={key}>
            <Choose>
            <When condition={row.type === 'text'}>
              <Box className={clsx(classes.text)}>
                <ReactMarkdown>{row.text.value}</ReactMarkdown>
                {/*row.text.value*/}
              </Box>
            </When>
            <Otherwise>
              Type ? {row.type}
            </Otherwise>
            </Choose>
          </Box>
        ))}
      </Box>
    </div>
  );
}
