import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import SerieCard from './SerieCard';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
const styles = makeStyles((theme) => {
  return {
    root: {
      marginLeft: '0',
      marginRight: '0',
      marginBottom: theme.spacing(2),
      '& .MuiTypography-h2': {
        paddingRight: 4,
        paddingLeft: 4,
        marginBottom: theme.spacing(2),
        textAlign: 'left',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.1rem',
      },
      '& .MuiMobileStepper-dot': {
        marginLeft: '4px',
        marginRight: '4px',
      },
    },
    badge: {
      top: '50%',
      right: '-16px',
    },
    title: {
      color: theme.palette.serie.title,
      marginBottom: theme.spacing(1),
    },
  };
});

export default function SeriesSection({ type, title, rows, category }) {
  if (rows === undefined) return '';
  const classes = styles();

  const gridSize = { sm: 4, md: 3 };

  return (
    <Grid container alignItems="center" justify="flex-start" className={classes.root}>
      <If condition={title !== undefined}>
        <Grid item xs={12}>
          {/*<If condition={showCount}>
            <Typography variant="h3" className={classes.title}>
              <StyledBadge badgeContent={rows.length}>
                {title}
              </StyledBadge>
            </Typography>
          </If>*/}
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        </Grid>
      </If>
      <Grid item xs={12} container align="flex-start">
        {rows.map((row, idx) => {
          return (
            <Grid key={'i_' + idx} item xs={12} sm={gridSize.sm} md={gridSize.md}>
              <SerieCard type={type} row={row} category={category} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
