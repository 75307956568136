import React, { Component } from "react";
import { connect } from "react-redux";
import config from "config/api";
import Spinner from "components/UI/Spinner";
import i18n from "helpers/i18n";
import Immutable from "immutable";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
// import Prettyfier from 'components/UI/Prettyfier';
// import HomeMember from './HomeMember';
// import HomeSpeaker from './HomeSpeaker';
import Header from "../Header/Header";
import HeaderContent from "../Header/HeaderContent";
import HeaderSub from "../Header/HeaderSub";
import Footer from "../Footer";
import HomeHelp from "components/Home/HomeHelp";
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import PodcastThemesIndex from "components/UI/PodcastTheme/PodcastThemesIndex";
// import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  fetchData,
  getDataHome,
  getLoadingError,
} from "../../ducks/asyncFetcher";
import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
  isStateKnown,
} from "ducks/authentication";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { primaryColor } from "../../helpers/colors";

const styles = (theme) => {
  return {
    root: {
      minHeight: "100%",
      // height: '100%',
      maxWidth: "1280px",
      margin: "0 auto",
      "& .MuiTypography-h1": {
        color: "#0075A8",
        fontWeight: 700,
        textAlign: "center",
        //   color: '#000000',
        textTransform: "none",
        fontSize: "2.5rem",
      },
      // '& .MuiTypography-body1': {
      //   textAlign: 'center',
      //   color: '#000000',
      //   marginBottom: theme.spacing(2)
      // },
      // '& .MuiTypography-caption': {
      //   textAlign: 'center',
      //   display: 'block',
      //   color: '#000000',
      //   fontWeight: 'bold'
      // }
    },
    wrapper: {
      // maxWidth: '1280px',
      // margin: '0 auto',
      // height: '100%',
      height: "calc(100% - 118px)",
      // background: 'linear-gradient(180deg, rgba(241,243,244,0.42) 0%, rgba(223,227,229,0.42) 100%)',
      borderBottom: "3px #daebf3 solid",
    },
    contentWrapper: {
      // backgroundColor: '#faebf3',
      // background: 'linear-gradient(180deg, rgba(250,235,243,1) 0%, rgba(255,255,255,1) 100%)',
      position: "relative",
    },
    content: {
      position: "relative",
      zIndex: 9,
      // '&:before': {
      //   zIndex: -1,
      //   position: 'absolute',
      //   content: '" "',
      //   backgroundColor: '#fff',
      //   backgroundPosition: '50% 100%',
      //   paddingTop: theme.spacing(64),
      //   width: '100%',
      //   height: '100%',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundSize: 'contain',
      //   backgroundImage: 'url('+require('assets/images/'+process.env.REACT_APP_SITE+'/halfcircle_pink.jpg')+')',
      // }
    },
    subTitle: {
      color: "#000",
      fontSize: "1.2rem",
    },
    loginNav: {
      "& .MuiButton-textSecondary": {
        backgroundColor: "#318EB8",
        color: "#fff",
        fontSize: "1rem",
        margin: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          fontSize: ".8rem",
        },

        // fontWeight: 'bold'
      },
    },
  };
};

const AF_KEY = "home";

const PodcastThemesIndexStyled = withStyles((theme) => {
  return {
    //   root: {
    //     maxWidth: 1200,
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    //     '& .MuiTypography-h4': {
    //       textAlign: 'center',
    //       color: '#000000',
    //       fontSize: '1.8rem',
    //       lineHeight: '2.4rem'
    //     },
    //     '& .MuiPaper-root': {
    //       backgroundColor: '#fff'
    //     }
    //   },
    //   item: {
    //     textDecoration: 'none',
    //     borderRadius: 5,
    //     padding: theme.spacing(1),
    //     display: 'flex',
    //     alignItems: 'flex-start'
    //   },
    //   itemBullet: {
    //     display: 'flex',
    //     width: '70px',
    //     fontWeight: 'normal',
    //   },
    //   itemContent: {
    //     maxWidth: 'calc(100% - 120px)',
    //     display: 'flex',
    //     fontWeight: 'bold',
    //   },
    //   itemEven: {
    //     color: '#b90066',
    //     '&:hover': {
    //       backgroundColor: '#fbf0f6',
    //       fontWeight: 'bold'
    //     }
    //   },
    //   itemArrow: {
    //     display: 'flex',
    //     verticalAlign: 'middle',
    //     // marginTop: 4,
    //     marginLeft: 8,
    //     // float: 'right'
    //   },
    //
    //   itemOdd: {
    //     color: '#a5a5a5',
    //     '&:hover': {
    //       backgroundColor: '#eee',
    //       fontWeight: 'bold'
    //     }
    //   }
  };
})(PodcastThemesIndex);

// const DocumentHelpStyled = withStyles((theme) => {
//   return {
//     root: {
//       backgroundColor: '#b90066',
//       color: '#fff',
//       marginBottom: theme.spacing(8),
//       '& .MuiTypography-h3': {
//         fontSize: '1.3rem'
//       }
//
//     },
//     darker: {
//     },
//     button: {
//       backgroundColor: '#fff',
//       color: '#000',
//       fontSize: '.95rem',
//     }
//
//   }
// })(DocumentHelp)

class Podcast extends Component {
  componentDidMount() {
    this.props.onMount();
  }

  componentDidUpdate(oldProps) {}

  render() {
    const { $$data, userRole, onPanelToggle, classes, history } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Header
            onPanelToggle={onPanelToggle}
            current="home"
            headerTitle={undefined}
            headerContent={
              process.env.REACT_APP_SITE !== "pneumo" &&
              process.env.REACT_APP_SITE !== "pneumo_medevent" ? (
                <HeaderContent />
              ) : undefined
            }
          />
          <ScrollToTopOnMount />
          <Box className={classes.contentWrapper}>
            <Box className={classes.content}>
              <Choose>
                <When condition={$$data !== undefined}>
                  <Box mt={10} mb={10}>
                    <Typography variant={"h1"} gutterBottom>
                      <img
                        src={require("assets/images/podcast.png")}
                        title="logo"
                        style={{ marginRight: 16 }}
                      />
                      Les podcasts
                    </Typography>
                    <Typography
                      variant={"body1"}
                      align="center"
                      className={classes.subTitle}
                    >
                      Retrouver des sujets d'actualité traités par les experts.
                      <br />
                      Des remis, téléchargeables, seront disponibles pour chaque
                      sujet abordé.
                    </Typography>
                  </Box>
                  <Choose>
                    <When
                      condition={
                        $$data !== undefined &&
                        userRole !== undefined &&
                        userRole !== "visitor"
                      }
                    >
                      <Box mb={16}>
                        <PodcastThemesIndexStyled
                          $$podcastThemes={$$data.get("podcastThemes")}
                        />
                      </Box>
                    </When>
                    <Otherwise>
                      <Box my={6} align="center">
                        <Typography
                          variant="h5"
                          gutterBottom={true}
                          className={clsx(classes.legend, classes.h6extends)}
                        >
                          Merci de vous connecter, ou de créer un compte pour
                          accéder à ce contenu
                        </Typography>
                        <Box className={classes.loginNav}>
                          <Button
                            size={"large"}
                            color="secondary"
                            onClick={(e) => {
                              history.push("/loginSimple");
                            }}
                          >
                            {i18n.get("loginFormTitle")}
                          </Button>
                          <Button
                            size={"large"}
                            color="secondary"
                            onClick={(e) => {
                              history.push("/registration");
                            }}
                          >
                            {i18n.get("subscribeButtonTitle")}
                          </Button>
                        </Box>
                      </Box>
                    </Otherwise>
                  </Choose>
                </When>
                <Otherwise>
                  <div className={"Loading"}>
                    <Spinner style={{ marginTop: 200 }}>Chargement...</Spinner>
                  </div>
                </Otherwise>
              </Choose>
            </Box>
          </Box>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    error: getLoadingError(state),
    userRole: getCurrentUserRole(state),
    // authStateKnown: isStateKnown(state),
    // $$user: getCurrentUser(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Podcast)),
);
