import React, { Component } from "react";
import BlockButton from "components/UI/BlockButton";
import RegistrationTwoStepsForm from "components/Registration/RegistrationTwoStepsForm";
//import RegistrationTwoStepsForm from 'components/UI/Registration/RegistrationTwoStepsForm';
// import LoginForm from 'components/Forms/LoginForm';
import Immutable from "immutable";
// import SubscribeToEventForm from 'components/Forms/SubscribeToEventForm';
// import EventSubscription from 'components/Event/EventSubscription';
import EventSubscriptionRecap from "components/Event/EventSubscriptionRecap";
// import EventSendInvite from 'components/Event/EventSendInvite';
// import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import BlockTitle from "components/UI/BlockTitle";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import Box from '@material-ui/core/Box';
// import { formatDate } from 'helpers/stringUtils'
// import colors from 'config/colors'
import i18n from "helpers/i18n";
const styles = (theme) => ({
  addButton: {
    backgroundColor: "transparent",
    padding: 0,
    boxShadow: "0 5px 20px rgba(0,0,0,0.21)",
    "&:hover": {
      boxShadow: "0 5px 5px rgba(0,0,0,0.21)",
      backgroundColor: "transparent",
    },
  },
  addIcon: {
    width: 50,
    height: 50,
    color: "#EB9250",
    verticalAlign: "middle",
    margin: "-5px",
  },
  color: {
    color: theme.palette.primary.main,
  },
  // colorTitle: {
  //   color: theme.palette.primary.main
  // },
  inline: {
    display: "inline-block",
  },
  h2: {
    color: theme.palette.primary.main,
    textAlign: "center",
    padding: "0 20px 20px",
  },
  h5: {
    textAlign: "center",
    // color: theme.palette.primary.main
  },
  h6: {
    fontWeight: "normal",
    // color: theme.palette.primary.main
  },
  caption: {
    display: "block",
    textAlign: "center",
    marginBottom: 5,
  },
  body1: {
    textAlign: "center",
    marginBottom: 10,
  },
});
class EventSubscriptionVisitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleMode: false,
      // visibleMode: 'invitationComplete',
      // visibleMode: 'subscriptionComplete',
      userEmail: false,
      log: undefined,
      // userEmail: 'rdsqdsqd.dsqdsqdsqrr@new-net.dsqdsqdsq.net'
    };
    // this._onClick = this._onClick.bind(this);
    // this._onFormComplete = this._onFormComplete.bind(this);
    // this._onSubscribeToEvent = this._onSubscribeToEvent.bind(this);
    this._onSubscriptionError = this._onSubscriptionError.bind(this);
    this._onSubscriptionComplete = this._onSubscriptionComplete.bind(this);
    this._onRegistrationShow = this._onRegistrationShow.bind(this);
    this._onRegistrationComplete = this._onRegistrationComplete.bind(this);
    this._onSendInvitationComplete = this._onSendInvitationComplete.bind(this);
    this._onSendInvitationError = this._onSendInvitationError.bind(this);
    // this._onFormComplete = this._onFormComplete.bind(this);
    this._onRestart = this._onRestart.bind(this);
  }
  // _onClick(e, formType) {
  //   // console.log('clicked', formType)
  //   this.setState({visibleMode: formType});
  // }
  _onSubscriptionError(kind, user) {
    this.setState({
      visibleMode: "unknown_subsriber",
      userEmail: user.email,
    });
  }
  _onSendInvitationComplete() {
    this.setState({
      visibleMode: "invitationComplete",
    });
  }
  _onSendInvitationError(kind, log) {
    this.setState({
      visibleMode: kind,
      log,
    });
  }
  // _onSubscriptionComplete(r) {
  //   // console.log('_onSubscriptionComplete', r)
  //   this.setState({visibleMode: 'subscriptionComplete'});
  // }
  _onRegistrationComplete(r) {
    // this.setState({visibleMode: 'subscriptionComplete'});
    this._onSubscriptionComplete(r);
  }
  _onRegistrationShow() {
    this.setState({
      visibleMode: "register",
    });
  }
  _onRestart() {
    this.setState({
      visibleMode: false,
    });
  }
  _onSubscriptionComplete(r) {
    const { onClose, onSubscriptionComplete } = this.props;
    // console.log('_onFormComplete', onSubscriptionComplete)
    if (onSubscriptionComplete !== undefined) onSubscriptionComplete(r);
    else onClose();
  }
  render() {
    const {
      $$event,
      $$events,
      onClose,
      classes,
      // userRole,
    } = this.props;
    const {
      visibleMode,
      // userEmail,
    } = this.state;
    let ef;
    if ($$event !== undefined && $$event.get("id") !== undefined)
      ef = {
        fromEvent: $$event.get("id"),
      };
    else if ($$events !== undefined && $$events.get(0) !== undefined)
      ef = {
        fromEvents: JSON.stringify($$events.map((e) => e.get("id")).toArray()),
      };
    const extraFields = Immutable.fromJS(ef);
    return (
      <div>
        {/*<Choose>
        <When condition={visibleMode === 'subscriptionComplete'}>
          <Typography variant={'h2'} classes={{root: classes.h2}} gutterBottom>{i18n.get('subscriptionSelfSuccessTitle', {prettyfy: true})}</Typography>
          <EventSubscriptionRecap  $$events={$$events} />

          <If condition={i18n.has('subscriptionSelfSuccessText')}>
            <Typography variant={'h5'} classes={{root: classes.h5}} gutterBottom>{i18n.get('subscriptionSelfSuccessText')}</Typography>
          </If>
          <BlockTitle />
          <BlockButton label="Fermer" onClick={this._onSubscriptionComplete} style={{margin: '20px auto 10px', display: 'block'}}/>

        </When>

        <Otherwise>
          <RegistrationTwoStepsForm extraFields={extraFields} onClose={onClose} onFormComplete={this._onRegistrationComplete} $$event={$$event} $$events={$$events} title={i18n.get('subscriptionFormVisitorTitle')}/>
        </Otherwise>
      </Choose>*/}

        <RegistrationTwoStepsForm
          extraFields={extraFields}
          onClose={onClose}
          onFormComplete={this._onRegistrationComplete}
          $$event={$$event}
          $$events={$$events}
          title={i18n.get("subscriptionFormVisitorTitle")}
        />
      </div>
    );
  }
}
// export default ;
export default withStyles(styles)(EventSubscriptionVisitor);
