import React from "react";
// import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import Immutable from "immutable";
import clsx from "clsx";
// import {
//   Redirect,
// } from 'react-router-dom'
import Typography from "@material-ui/core/Typography";

import Form from "components/UI/Form/Form";
import {
  // send as formSubmitAction,
  hasSentFailed as isFormErrored,
  getLog as getFormLog,
  getError as getFormError,
  isSending as isFormRunning,
  isSent as isFormComplete,
} from "ducks/contact";

import { getCurrentUser } from "ducks/authentication";

import config from "config/api";

const FIELDS_FULL = [
  {
    id: "accountJob",
    name: "accountJob",
    label: "Profession",
    validation: ["notEmpty"],
    type: "select",
    // options: [
    //   {'label': 'Médecin', value: 'md'},
    //   // {'label': 'Sage-femme', value: 'sf'},
    //   {'label': 'Chiesi', value: 'lb'},
    // ],
    options: config.getJobs(),
    sizes: { xs: 12 },
  },
];

// const CONFIG = {
// btCancel: true,
// btCancelLabel: 'Retour'
// }
class JobForm extends Form {
  constructor(props) {
    super(props);
    this.state = {};
    // let $$FIELDS;
    this.$$FIELDS = Immutable.fromJS(FIELDS_FULL);
    // this.$$CONFIG = Immutable.fromJS(CONFIG);

    this._onSubmit = this._onSubmit.bind(this);
    // this.$$FIELDS = $$FIELDS;
    // this._onFormSubmit = this._onFormSubmit.bind(this);
    // this._onFieldValueChange = this._onFieldValueChange.bind(this);
  }

  componentDidMount() {
    this._setupState();
    this._mapRawValues(this.props.$$formValues);
  }
  _mapRawValues($$values) {
    if ($$values === undefined || !$$values.get) return;
    var o = {};
    // return Immutable.fromJS(o);
    // return $$values;
    // var tmp = {}
    // $$values.map((val, k) => {
    Immutable.fromJS(o).map((val, k) => {
      this._onFieldValueChange(null, { id: k, value: val });
      return true;
    });
    // return $$values;
    return Immutable.fromJS(o);
  }
  _onSubmit(formData) {
    this.props.onFormComplete({ values: formData });
  }
  _getI18n() {
    this.i18n = {
      send: "S'inscrire",
      sending: "Envois...",
    };
  }
  render() {
    const {
      // error,
      // loading,
      className,
      log,
      isFormComplete,
      // userRole,
    } = this.props;
    //
    // if (isFormComplete) {
    //   const url ='/contact'
    //   return <Redirect to={{
    //       pathname: url,
    //       state: { from: this.props.location }
    //     }}/>
    //   }

    return (
      <div
        className={clsx("Form", className)}
        style={{ maxWidth: 420, margin: "0 auto 20px" }}
      >
        <If condition={!isFormComplete}>{this._renderForm()}</If>
        <If condition={isFormComplete}>
          <Typography variant="h5">{log}</Typography>
        </If>
      </div>
    );
  }
}

const mapStateToProps = ($$state) => {
  return {
    isFormErrored: isFormErrored($$state),
    log: getFormLog($$state),
    error: getFormError($$state),
    loading: isFormRunning($$state),
    isFormComplete: isFormComplete($$state),
    $$formValues: getCurrentUser($$state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onSubmit: (formData) => {
    //   dispatch(formSubmitAction(formData));
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobForm);
