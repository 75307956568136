import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';
import getFetchOptions from 'helpers/getFetchOptions';

const actionTypes = mirrorCreator(['SET_PODCAST_SHOW_FLIPBOOK_DATA', 'CLEAR_PODCAST_SHOW_FLIPBOOK_DATA', 'SET_PODCAST_SHOW_AUDIO_DATA', 'CLEAR_PODCAST_SHOW_AUDIO_DATA', 'SET_PODCAST_SHOW_DATA', 'SET_PODCAST_SHOW_ERROR', 'CLEAR_PODCAST_SHOW'], { prefix: 'podcast/' });

const mutators = {
  setPodcastShowFlipbook: (flipbook) => ($$state) => $$state.set('flipbook', flipbook),
  setPodcastShowAudio: (audio) => ($$state) => $$state.set('audio', audio),
  setPodcastShowDetails: (details) => ($$state) => $$state.set('details', details),
  setPodcastShowError: (error) => ($$state) => $$state.set('error', error),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_PODCAST_SHOW_FLIPBOOK_DATA:
      acts.push(mutators.setPodcastShowFlipbook(action.flipbook));
      return pipe(acts, $$state);
    case actionTypes.CLEAR_PODCAST_SHOW_FLIPBOOK_DATA:
      acts.push(mutators.setPodcastShowFlipbook(undefined));
      return pipe(acts, $$state);

    case actionTypes.SET_PODCAST_SHOW_AUDIO_DATA:
      acts.push(mutators.setPodcastShowAudio(action.audio));
      return pipe(acts, $$state);
    case actionTypes.CLEAR_PODCAST_SHOW_AUDIO_DATA:
      acts.push(mutators.setPodcastShowAudio(undefined));
      return pipe(acts, $$state);

    case actionTypes.SET_PODCAST_SHOW_DATA:
      acts.push(mutators.setPodcastShowDetails(action.podcast));
      return pipe(acts, $$state);

    case actionTypes.SET_PODCAST_SHOW_ERROR:
      return pipe([mutators.setPodcastShowError(action.error)], $$state);

    case actionTypes.CLEAR_PODCAST_SHOW:
      return pipe([mutators.setPodcastShowError(undefined), mutators.setPodcastShowDetails(undefined)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.podcastShow || Immutable.Map();
export const getPodcastShowDetails = createSelector([getRoot], ($$state) => $$state.get('details'));
export function fetchPodcastShowDetails({ podcastId, params }) {
  // console.log({login, password})
  return (dispatch) => {
    let url;
      url = config.podcastShow + '/get/'+ podcastId;

    if (params !== undefined && params.k !== undefined && params.r === undefined) url = url+'?k='+params.k;
    if (params !== undefined && params.r !== undefined && params.k === undefined) url = url+'?r='+params.r;
    if (params !== undefined && params.r !== undefined && params.k !== undefined) url = url+'?k='+params.k+'&r='+params.r;

    dispatch(clearPodcastShowDetails());

    fetch(url, getFetchOptions())
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.podcastShow && response.podcastShow === 'complete') {
          dispatch(setPodcastShowDetails(Immutable.fromJS(response.data)));
        } else {
          dispatch(setPodcastShowError(response.log));
        }
      })
      .catch((error) => dispatch(setPodcastShowError(error.message)));
  };
}

export function setPodcastFlipbookData(flipbook) {
  return {
    type: actionTypes.SET_PODCAST_SHOW_FLIPBOOK_DATA,
    flipbook,
  };
}
export function setPodcastAudioData(audio) {
  return {
    type: actionTypes.SET_PODCAST_SHOW_AUDIO_DATA,
    audio,
  };
}
export function setPodcastShowsDetails(kind, podcasts) {
  return {
    type: actionTypes.SET_PODCAST_SHOWS_DATA,
    kind, podcasts,
  };
}
export function setPodcastShowDetails(podcast) {
  return {
    type: actionTypes.SET_PODCAST_SHOW_DATA,
    podcast,
  };
}
export function setPodcastShowError(error) {
  return {
    type: actionTypes.SET_PODCAST_SHOW_ERROR,
    error,
  };
}
export function clearPodcastShowDetails() {
  return {
    type: actionTypes.CLEAR_PODCAST_SHOW,
  };
}
export const getPodcastShowFlipbook = createSelector([getRoot], ($$state) => $$state.get('flipbook'));
export function fetchPodcastShowFlipbook({ podcastId }) {
  // console.log({login, password})
  return (dispatch) => {
    let url;
      url = config.podcastShow + '/flipbook/'+ podcastId;
      dispatch(clearPodcastShowFlipbook());

    fetch(url, getFetchOptions())
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.flipbook && response.flipbook === 'complete') {
          dispatch(setPodcastFlipbookData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setPodcastShowError(response.log));
        }
      })
      .catch((error) => dispatch(setPodcastShowError(error.message)));
  };
}
export function clearPodcastShowFlipbook() {
  return {
    type: actionTypes.CLEAR_PODCAST_SHOW_FLIPBOOK_DATA,
  };
}
export const getPodcastShowAudio = createSelector([getRoot], ($$state) => $$state.get('audio'));
export function fetchPodcastShowAudio({ podcastId }) {
  return (dispatch) => {
    let url;
      url = config.podcastShow + '/audio/'+ podcastId;
      dispatch(clearPodcastShowAudio());

    fetch(url, getFetchOptions())
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.audio && response.audio === 'complete') {
          // console.log('set audio data', response.data)
          dispatch(setPodcastAudioData(Immutable.fromJS(response.data)));
        } else {
          dispatch(setPodcastShowError(response.log));
        }
      })
      .catch((error) => dispatch(setPodcastShowError(error.message)));
  };
}
export function clearPodcastShowAudio() {
  return {
    type: actionTypes.CLEAR_PODCAST_SHOW_AUDIO_DATA,
  };
}
