import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import ToolCall from './ToolCall';

const styles = makeStyles((theme) => {
  return {
    root: {
    },
  };
});


export default function ToolCalls({ metadata, ignore_ids }) {
  const classes = styles();

  const tool_calls_ids=metadata && metadata.tool_calls_ids!==undefined ? JSON.parse(metadata.tool_calls_ids) : [];
  const tool_calls_status=metadata && metadata.tool_calls_status!==undefined ? JSON.parse(metadata.tool_calls_status) : [];

  return (
    <Box className={classes.root}>
    {tool_calls_ids.map((id)=>{
      if(ignore_ids!==undefined && ignore_ids.includes(id))
        return;
      return <ToolCall key={id} rawCall={metadata[id]} status={tool_calls_status[id]!==undefined ? tool_calls_status[id] : null}/>
    }
    )}
  </Box>
  );
}
