import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';

const actionTypes = mirrorCreator(['SET_DOCUMENTS_INDEX', 'SET_DOCUMENTS_ERROR', 'CLEAR_DOCUMENTS_INDEX'], { prefix: 'documents/' });

const mutators = {
  setDocumentsIndex: (data) => ($$state) => $$state.set('documents', data),
  setDocumentsError: (error) => ($$state) => $$state.set('error', error),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    case actionTypes.SET_DOCUMENTS_INDEX:
      acts.push(mutators.setDocumentsIndex(action.data));
      return pipe(acts, $$state);

    case actionTypes.SET_DOCUMENTS_ERROR:
      acts.push(mutators.setDocumentsError(action.error));
      return pipe(acts, $$state);

    case actionTypes.CLEAR_DOCUMENTS_INDEX:
      return pipe([mutators.setDocumentsError(undefined), mutators.setDocumentsIndex(undefined)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.documents || Immutable.Map();
export const getDocumentsIndex = createSelector([getRoot], ($$state) => $$state.get('documents'));
export const getDocumentsError = createSelector([getRoot], ($$state) => $$state.get('error'));

export function fetchDocumentsIndex() {
  // console.log({login, password})
  return (dispatch) => {
    const url = config.documents + '/list'

    let formData = {};

    dispatch(clearDocumentsIndex());

    fetch(url, {
      credentials: 'include',
      method: config.urlMethod || 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.documents && response.documents === 'complete') {
          dispatch(setDocumentsIndex(Immutable.fromJS(response.data)));
        } else {
          dispatch(setDocumentsError(response.log));
        }
      })
      .catch((error) => dispatch(setDocumentsError(error.message)));
  };
}

export function setDocumentsIndex(data) {
  return {
    type: actionTypes.SET_DOCUMENTS_INDEX,
    data,
  };
}
export function setDocumentsError(error) {
  return {
    type: actionTypes.SET_DOCUMENTS_ERROR,
    error,
  };
}
export function clearDocumentsIndex() {
  return {
    type: actionTypes.CLEAR_DOCUMENTS_INDEX,
  };
}
