import React, { useContext } from 'react';
import apiFetch from './api';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";

import { Context } from './Context';

const styles = makeStyles((theme) => {
  return {
    root: {
        margin: '5px 0',
        padding: '5px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        backgroundColor: '#f0f0f0',
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '600px',
        },
    },
    button:{
    },
  };
});

export default function UserInput({run}) {
  const classes = styles();
  const {currentThread, beta} = useContext(Context);

  const [message, setMessage] = React.useState('');
  const [instructions, setInstructions] = React.useState(run ? run.instructions : '');

  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const onInstructionsChange = (event) => {
    setInstructions(event.target.value);
  };
  const onSubmit = (event) => {
    apiFetch("add_message", {thread:currentThread, message:message, instructions:instructions})
      .then((data) => {
        //console.log('fetchData ok', data);
        setMessage('');
      })
      .catch(({ response, error }) => {
        console.log('fetchData error', error, response);
      });
  };


  return (
    <div className={classes.root}>
      <TextField
          id="standard-multiline-flexible"
          label="Message..."
          multiline
          maxRows={10}
          value={message}
          onChange={onMessageChange}
        />
      {beta!==null &&
        <TextField
            id="standard-multiline-flexible"
            label="Instructions..."
            multiline
            maxRows={10}
            value={instructions}
            onChange={onInstructionsChange}
          />
      }
        <Button color="primary" variant="contained" className={classes.button} onClick={(event) => onSubmit(event)}>Envoyer</Button>
    </div>
  );
}
