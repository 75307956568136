import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import i18n from 'helpers/i18n';

import { withStyles } from '@material-ui/core/styles';
import AccountOptinsForm from 'components/UI/Account/AccountOptinsForm';
import Dialog from 'components/UI/Dialog/Dialog';
import { isLoggedIn, getCurrentUser, setUserUpdatedOptins } from 'components/UI/Account/ducks/authentication';
import { getDataHome } from 'ducks/asyncFetcher';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => {
  return {
    root: {
      minHeight: '100%',
      width: '100%',
    },
    subtitle:{
      textAlign:'center',
    },
    pageTitle: {
      paddingTop: 16,
    },
    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.primary.light,
        zIndex: -1
      }
    },
    paper: {
      padding: theme.spacing(4),
      paddingTop: 0,
      maxWidth: 1280,
      marginLeft: 'auto',
      marginRight: 'auto',
      // backgroundColor: 'transparent',
    },
    legend:{
      color:'#777',
      fontSize: '75%',
      '& a': {
        color: theme.palette.primary.main,
      },
    },
  };
};

class AccountForceChangeOptins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // tabSelected: 0
      showPasswordForm: false,
      optinsComplete: false,
    };
  }

  _onFormComplete = () => {
    this.setState({optinsComplete: true})
    this.props.setUserUpdatedOptins(1);
  }

  //MODIF B : force accountOptin1 par défaut si le compte doit faire la màj
  _onFormLoaded = ($$data) => {
    const values=$$data.values;
    if(values!==undefined){
      const accountHasUpdatedOptins=values.get('accountHasUpdatedOptins');
      if(accountHasUpdatedOptins=='0' || accountHasUpdatedOptins===null){
        return {accountOptin1:'on'};
      }
    }
  }

  _onClose = () => {
    window.location.reload(true)
  }
  render() {
    const { classes, isLoggedIn, $$data, $$user } = this.props;
    const { optinsComplete } = this.state;
    const mustChangeOptins = $$user !== undefined && ($$user.get('hasUpdatedOptins') == '0' || $$user.get('hasUpdatedOptins') === null);
    if (!mustChangeOptins)
      return <span></span>
    else
      return (
        <Dialog className={classes.root} open={!optinsComplete} title={i18n.get('accountMustChangeOptins')}>
          <If condition={i18n.has('accountMustChangeOptinsText')}>
            <Typography variant='body' component='div' className={classes.subtitle}>{i18n.get('accountMustChangeOptinsText')}</Typography>
          </If>
          <AccountOptinsForm onFormComplete={this._onFormComplete} onFormLoaded={this._onFormLoaded} showCancel={false} btCancel={false}/>
          <If condition={i18n.has('registrationLogLegend2')}>
              <hr />
              <Typography variant={'body1'} classes={{ root: classes.legend }} gutterBottom>
                {i18n.get('registrationLogLegend2', { prettyfy: true })}
              </Typography>
            </If>
        </Dialog>
      );
  }
}
function mapStateToProps(state) {
  const props = {
    $$user: getCurrentUser(state),
    $$data: getDataHome(state),
    isLoggedIn: isLoggedIn(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    /*onMount: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },*/
    setUserUpdatedOptins: (value) => {
      dispatch(setUserUpdatedOptins(value));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountForceChangeOptins)));
