import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
  light,
  dark,
  disabledColor,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  titleColor,
  textColor,
} from 'helpers/colors';

export default {
      palette: {
        type: 'light',
        template: {
          color: textColor,
        },    
        primary: {
          main: primaryColor,
          light: primaryColor,
        },
        secondary: {
          main: secondaryColor,
          contrastText: '#fff',
        },
        tertiary: {
          main: tertiaryColor,
        },
        text: {
          primary: textColor,
          secondary: textColor,
          light,
          dark,
        },
        action: {
          disabled: disabledColor,
        },

        background: {
          default: '#f5f5f5',
        },

        logo:{
          //width: 200,
          //height: '100%',
          //backgroundImage: `url(${require('assets/images/' + process.env.REACT_APP_SITE + '/logo.svg')})`,
        },

        header:{
          backgroundColor:'#fff',
        },

        newsWrapper:{
          backgroundColor: 'transparent',
          backgroundImage:'none',
        },
        news: {
          backgroundColor: '#F4F4F4',
          color: '#212121',
        },
        replays: {
          breadCrumbColor: '#fff',
          backgroundColor: '#4CA1A6',
        },
        live: {
          breadCrumbColor: '#fff',
          backgroundColor: '#4CA1A6',
        },
        footer:{
          color:'#fff',
          background:'#5a2d80',
        },
        faq:{
          backgroundColor:'red',
          itemColor:'#333',
          bulletColor:primaryColor,
          mainColor:'#333',
          answerColor:'#777',
          itemBackgroundColor:'white',
          itemShadow:'none',
        },
        rgpd: {
          backgroundColor: '#3F7275',
          color: '#fff',
        },
        serie:{
          title:primaryColor,
          buttonBG:primaryColor,
          buttonFG:'#fff',
          pageBG:'#F4F5F5',
          breadCrumbText:'#7D868C',
          breadCrumbBorder:'#D8DCDC',
          breadCrumbBG:'#F4F5F5',
        },
        share:{
          buttonBG:primaryColor,
          buttonFG:'#fff',
          pageBG:'#F4F5F5',
          title:primaryColor,
        },
      },
      typography: {
        fontFamily: ['Montserrat, Helvetica, Arial, sans-serif'].join(','),
        useNextVariants: true,
        h1: {
          color: titleColor || light,
          fontWeight: 'bold',
          fontSize: '2.6rem',
          marginBottom: 30,
          letterSpacing: 2,

          paddingTop: 16,
          paddingBottom: 16,
          color: primaryColor,
          textAlign: 'center',
          fontSize: '1.6rem',
          fontWeight: 'normal',
          width: '100%',

        },
        h2: {
          textAlign: 'center',
          fontSize: '2.1rem',
          lineHeight: '2.4rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          display: 'block',
          width: '90%',
          marginLeft: 'auto',
          marginRight: 'auto',

        },
        h3: {
          color: primaryColor,
          fontSize: '1.2rem',
          fontWeight: 400,
          marginBottom: 30,
          paddingBottom: 10,
          paddingTop: 10,
          textAlign: 'left',
        },
        h4: {
          color: 'inherit',
          fontSize: '1.4rem',
          fontWeight: 'normal',
          textTransform: 'uppercase',
          marginBottom: 24,
        },
        h5: {
          color: 'inherit',
          fontSize: '1.2rem',
          marginTop: '24px',
          position: 'relative',
        },
        h6: {
          color: primaryColor,
          fontSize: '1.1rem',
          textAlign: 'left',
        },
        body1: {
          fontSize: '1rem',
          color: 'inherit',
        },
        body2: {
          fontSize: '1rem',
          color: primaryColor,
        },
        subtitle1: {
          color: primaryColor,
          fontSize: '1rem',
        },
        subtitle2: { color: secondaryColor, fontSize: '1rem' },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      overrides: {
        MuiSvgIcon: {
          root: {
            display: 'inline-block',
            verticalAlign: 'middle',
            marginTop: -3,
            marginRight: 4
          }
        },
        MuiButton: {
          
          root: {
          },
          
          containedPrimary: {
            backgroundColor: primaryColor,
            color: secondaryColor,
            fontWeight: 'bold',
            textTransform: 'none',
            opacity: 0.8,
            '&:hover': {
              color: primaryColor,
              backgroundColor: secondaryColor,
            },
          },
          containedSecondary: {
            backgroundColor: secondaryColor,
            color: primaryColor,
            fontWeight: 'bold',
            textTransform: 'none',
            opacity: 0.8,
            '&:hover': {
              color: secondaryColor,
              backgroundColor: primaryColor,
            },
          },
          outlinedPrimary: {
            backgroundColor: primaryColor,
            color: secondaryColor,
            border:primaryColor+' 1px solid',
            fontWeight: 'bold',
            '&:hover': {
              color: primaryColor,
              backgroundColor: secondaryColor,
            },
            '&.Mui-disabled': {
              backgroundColor: '#ccc',
              opacity: 0.7,
            },
          },
          outlinedSecondary: {
            backgroundColor: secondaryColor,
            fontWeight: 'bold',
            color: primaryColor,
            border:tertiaryColor+' 1px solid',
            '&:hover': {
              color: secondaryColor,
              backgroundColor: primaryColor,
              border:primaryColor+' 1px solid',
            },
            '&.Mui-disabled': {
              backgroundColor: '#ccc',
              opacity: 0.7,
            },
          },
          textSecondary: {
            boxShadow: 'none',
            color: primaryColor,
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: "#b80e800f",//magenta clair
            },
            '&.Mui-disabled': {
              backgroundColor: '#ccc',
              opacity: 0.7,
            },
          },
        },
        MuiFormLabel: {
          root: {
            '&.Mui-focused': {
              color: primaryColor,
            },
          },
        },
        MuiFormHelperText: {
          root: {
            color: primaryColor,
          },
        },
        'MuiStepper-root': {
          padding: 0,
        },
        MuiStep: {
          root: {
            '& > .MuiStepConnector-root': {
              display: 'none',
            },
          },
        },
        MuiMenuItem: {
          root: {
          },
        },
        MuiAlert: {
          standardSuccess: {
            fontWeight: 'bold'
          }
        },
        MuiAppBar: {
          colorPrimary: {
          }
        },
        MuiTab:{
          root:{
            textTransform: 'none',
          },
        },
      },
    };