import React, { Component } from "react";
import { Link } from "react-router-dom";
// import BlockRatio from "components/BlockRatio";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { formatDate } from "helpers/stringUtils";
import config from "config/api";
import getCardImageFromArray from "helpers/getCardImageFromArray";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import getCardImagesFromArray from 'helpers/getCardImagesFromArray';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
const colors = {
  live: '#0075A8',
  pending: '#009EE3'
}
const legends = {
  live: 'En ligne',
  pending: 'A venir'
}
const styles = (theme) => {
  return {
    root: {
      // transition: [
      //   theme.transitions.create("box-shadow", {
      //     easing: theme.transitions.easing.easeOut,
      //     duration: theme.transitions.duration.enteringScreen,
      //   }),
      // ],
      // boxShadow: theme.shadows[1],

      // "&:hover": {
      //   boxShadow: theme.shadows[9],
      //   "& .MuiButton-root": {
      //     backgroundColor: "#fff !important",
      //     color: "inherit",
      //     border: "1px #5A5A5A solid",
      //   },
      // },
      // "& .MuiButton-root": {
      //   "&:hover": {
      //     backgroundColor: "#fff !important",
      //     color: "inherit",
      //   },
      // },
      // '& .MuiInputLabel-formControl': {
      //   color: 'inherit'
      // },
      '& .MuiTypography-caption': {
        textAlign: 'left'
      }
    },
    cardWrapper: {
      backgroundColor: '#fff',
      // boxShadow: theme.shadows[2],
      boxShadow: 'rgba(0,0,0,0.1) 0 0 10px',
    },
    clickable: {
      cursor: "pointer",
    },
    // inline: {
    //   display: "inline-block",
    // },
    cardImage: {
      width: '100%',
      aspectRatio: 2.62,
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      marginBottom: theme.spacing(4),
      // display: "inline-block",
      // verticalAlign: "bottom",
      // marginRight: 20,
    },
    cardTitle: {
      fontSize: '1.4rem',
      textAlign: 'left',
      color: '#0075A8',
      fontWeight: 'normal',
      //   [theme.breakpoints.down("md")]: {
      //     fontSize: "1.1rem",
      //   },
      //   [theme.breakpoints.down("sm")]: {
      //     fontSize: "1.0rem",
      //   },
    },
    /*cardDescription: {
      lineHeight: '1.4rem'
    },*/
    cardGuests: {
      color: '#000',
      fontStyle: 'italic',
      marginBottom: theme.spacing(2),
      fontSize: '.9rem',
      '& strong': {
        fontStyle: 'normal'
      }
    },
    cardDescription: {
      color: '#5A5A5A',
      // textAlign: "center",
      // minHeight: 230,
      fontSize: "1rem",
      lineHeight: '1.4rem',
      marginBottom: theme.spacing(2),
      // [theme.breakpoints.up("lg")]: {
      //   // minHeight: 260,
      //   // minHeight: 150,
      // },
      // [theme.breakpoints.down("md")]: {
      //   // minHeight: 140,
      //   fontSize: "1.1rem",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   // minHeight: 140,
      //   fontSize: "1.0rem",
      // },
      // [theme.breakpoints.down("xs")]: {
      //   // minHeight: "auto",
      //   fontSize: "1.0rem",
      // },
    },
    dynamicColor: {
      color: "inherit",
      borderColor: "inherit",
    },
    cardDate: {
      fontSize: '.8rem',
      textTransform: 'uppercase',
      color: '#0075A8'
    },
    cardButton: {
      width: '100%',
      marginTop: theme.spacing(2),
      backgroundColor: '#318EB8',
      fontSize: '.7rem',
      fontWeight: 'bold',
      boxShadow: 'none',
      marginBottom: theme.spacing(2),
    },
    disabled: {
      opacity: .4
    }
  };
};
class PodcastThemeDetailsCard extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     // showLiveButton: false,
  //   };
  // }
  // componentDidMount() {
  // }
  // componentDidUpdate(oldProps) {
  // }
  // componentWillUnmount() {
  // }
  render() {
    const {
      $$cardData,
      tIdx,
      $$subscriptions,
      userRole,
      $$user,
      onSubscriptionComplete,
      classes,
      gridSize,
    } = this.props;
    // console.log($$cardData.toJS())
    const cardThumb = getCardImageFromArray($$cardData, {needleField: 'podcastThumb', needleId: 'podcastId', needleUrl: config.podcastshowthumbpreview});
    // console.log(cardThumb)

    const $$guests = getCardImagesFromArray($$cardData, {needleField: 'podcastGuests', needleId: 'podcastId', needleUrl: config.podcastshowthumbpreview});
    const themeColor = $$cardData.get("themeColor");

    // const eventsCount = $$cardData.size;

    const gridSizes = [
      {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
      },
      {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
      },
      {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
      },
    ];
    const gs = gridSize <= 2 ? gridSize : 2;


    // console.log('delayed evens are', delayedEvent)
    return (
      <Grid
        key={"thm_" + tIdx}
        item={true}
        xs={gridSizes[gs].xs}
        sm={gridSizes[gs].sm}
        md={gridSizes[gs].md}
        lg={gridSizes[gs].lg}
        className={clsx(classes.root)}
        >
        <Box
          className={classes.cardWrapper}
          color={themeColor}
          style={{
            borderColor: themeColor,
          }}
          >
          {/*<img
            src={cardThumb !== false ? cardThumb : undefined}
            className={classes.cardImage}
            />*/}
            <div className={classes.cardImage} style={{backgroundImage: cardThumb !== false ? 'url('+cardThumb+')' : undefined, backgroundColor: colors[$$cardData.get('podcastState')]}}></div>

            <Box px={4}>
              <Typography
                variant={"h2"}
                gutterBottom={true}
                className={clsx(classes.cardTitle)}
                component={'div'}
                >
                <Paragrapher
                  element={"div"}
                  text={$$cardData.get("podcastTitle")}
                  prettyfy={true}
                  />

              </Typography>

              <If condition={$$guests !== false && $$guests.size > 0}>
                <Box className={classes.cardGuests}>
                  {/*  <Typography variant={'caption'} gutterBottom style={{marginBottom: 8, display: 'block'}}>{i18n.get('eventAnimatedBy', {pretty: false})}</Typography>*/}
                  {$$guests.map(($$g, aIdx) => {
                    return (
                      <Box w={'100%'} key={'av_'+aIdx} style={{clear: 'both'}}>
                        <Typography variant={'body1'}><strong>{$$g.getIn(['attributes','name'])}</strong>,  {$$g.getIn(['attributes','description'])}</Typography>
                      </Box>)
                    }).toArray()}
                  </Box>
                </If>

                <Typography
                  variant={"body1"}
                  gutterBottom={true}
                  className={clsx(classes.cardDescription)}
                  component={"div"}
                  >
                  <Paragrapher
                    element={"div"}
                    text={$$cardData.get("podcastDescription")}
                    prettyfy={true}
                    />
                </Typography>
                <If condition={$$cardData.get('podcastDate') !== undefined && $$cardData.get('podcastDate') !== null && $$cardData.get('podcastDate') !== false}>
                <Typography
                  variant={"body1"}
                  gutterBottom={true}
                  className={clsx(classes.cardDate)}
                  component={"div"}
                  >
                  <CalendarTodayIcon size="small" style={{width: '.85rem', height: '.85rem', marginTop:'-2px', marginRight: 2, display: 'inline-block', verticalAlign: 'middle'}}/> {formatDate($$cardData.get('podcastDate'), 'dd mmm yyyy')}
                </Typography>
                </If>
                <If condition={$$cardData.get('podcastState') !== 'live'}>
                <Button variant="outlined" disabled={$$cardData.get('podcastState') !== 'live'} className={clsx(classes.cardButton, classes.disabled)}>Bientôt disponible</Button>
                  </If>
                <If condition={$$cardData.get('podcastState') === 'live'}>
                <Button variant="outlined" disabled={$$cardData.get('podcastState') !== 'live'} onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/podcast/show/'+$$cardData.get('id'))
                  }} className={clsx(classes.cardButton)}>Accéder</Button>
                  </If>
                </Box>
              </Box>
            </Grid>
          );
        }
      }
      export default withRouter(withStyles(styles)(PodcastThemeDetailsCard));
