import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import base from "components/UI/Theme";
import {
  light,
  dark,
  disabledColor,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  titleColor,
  textColor,
} from 'helpers/colors';

/*
Merge de la locale avec la commune plutôt que d'utiliser des nested theme (prob unpredictable suffix (e.g. MuiFormLabel-root-371) https://stackoverflow.com/questions/60721323/how-reliable-are-mui-global-class-names-in-jss
utiliser selecteur [class*="MuiFormLabel-root"] n'est plus nécessaire
*/

const local={
  ...base,
  palette: {
    ...base.palette,

    live:{
      ...base.palette.live,
      backgroundColor:primaryColor,
    },

    background: {
      default: 'white',
    },

    logo:{
      width: 170,
      height: '100%',
      backgroundImage: `url(${require('assets/images/' + process.env.REACT_APP_SITE + '/logo.svg')})`,
    },

    newsWrapper:{
      backgroundColor: '#b90066',
      backgroundImage:'url('+(require('assets/images/bg_purple.jpeg'))+')',
    },

    rgpd: {
      backgroundColor: '#b90066',
      color: '#fff',
    },

  },
  typography: {
    ...base.typography,
    h1: {
      fontFamily: 'Montserrat',
      fontSize: '52px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '140%',
      textAlign: 'center',
      letterSpacing: 2,
      paddingTop: 4,
      paddingBottom: 4,
      color: primaryColor,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: '35px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '130%',
      textAlign: 'left',
      paddingTop: 4,
      paddingBottom: 4,
      color: primaryColor,
    },
    h3: {
      fontFamily: 'Montserrat',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '120%',
      textAlign: 'left',
      paddingTop: 4,
      paddingBottom: 4,
      color: primaryColor,
    },
  },
  breakpoints: {
    ...base.breakpoints,
  },
  overrides: {
    ...base.overrides,
    MuiButton: {
      ...base.overrides.MuiButton,
      root: {
        ...base.overrides.MuiButton.root,
        textTransform: 'none',
      },
      outlinedPrimary: {
        ...base.overrides.MuiButton.outlinedPrimary,
        //backgroundColor: '#ff0000',
      },
    },
  },
};



export default class Theme extends React.Component {
  render() {
    const { children } = this.props;
    const theme = createMuiTheme(local);

    return (
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    );
  }
}



/*
import React from "react";
// import PropTypes from 'prop-types'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import baseTheme from "components/UI/Theme";
import {
  primaryColor,
  secondaryColor,
  tercaryColor,
  bodyColor,
  buttonBgColor,
  bodyAltColor,
  light,
  dark,
  disabledColor,
  tertiaryColor,
  titleColor,
  textColor,  
} from "helpers/colors";

export default class Theme extends React.Component {
  render() {
    const { children } = this.props;
    const theme = createMuiTheme({
      palette: {
        type: 'light',
        template: {
          color: textColor,
        },    
        primary: {
          main: primaryColor,
          light: primaryColor,
        },
        secondary: {
          main: secondaryColor,
          contrastText: '#fff',
        },
        tertiary: {
          main: tertiaryColor,
        },
        text: {
          primary: textColor,
          secondary: textColor,
          light,
          dark,
        },
        action: {
          disabled: disabledColor,
        },

        background: {
          default: '#f5f5f5',
        },

        logo:{
          //width: 200,
          //height: '100%',
          //backgroundImage: `url(${require('assets/images/' + process.env.REACT_APP_SITE + '/logo.svg')})`,
        },

        header:{
          backgroundColor:'#fff',
        },

        newsWrapper:{
          backgroundColor: 'transparent',
          backgroundImage:'none',
        },
        news: {
          backgroundColor: '#F4F4F4',
          color: '#212121',
        },
        replays: {
          breadCrumbColor: '#fff',
          backgroundColor: '#4CA1A6',
        },
        live: {
          breadCrumbColor: '#fff',
          backgroundColor: '#4CA1A6',
        },
        footer:{
          color:'#fff',
          background:'#5a2d80',
        },
        faq:{
          backgroundColor:'red',
          itemColor:'#333',
          bulletColor:primaryColor,
          mainColor:'#333',
          answerColor:'#777',
          itemBackgroundColor:'white',
          itemShadow:'none',
        },
        rgpd: {
          backgroundColor: '#3F7275',
          color: '#fff',
        },
        serie:{
          title:'#5489A3',
          buttonBG:'#5489A3',
          buttonFG:'#fff',
          pageBG:'#F4F5F5',
          breadCrumbText:'#7D868C',
          breadCrumbBorder:'#D8DCDC',
          breadCrumbBG:'#F4F5F5',
        },
      },
      typography: {
        fontFamily: ['Montserrat, Helvetica, Arial, sans-serif'].join(','),
        useNextVariants: true,
        h1: {
          color: titleColor || light,
          fontWeight: 'bold',
          fontSize: '2.6rem',
          marginBottom: 30,
          letterSpacing: 2,

          paddingTop: 16,
          paddingBottom: 16,
          color: primaryColor,
          textAlign: 'center',
          fontSize: '1.6rem',
          fontWeight: 'normal',
          width: '100%',

        },
        h2: {
          textAlign: 'center',
          fontSize: '2.1rem',
          lineHeight: '2.4rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          display: 'block',
          width: '90%',
          marginLeft: 'auto',
          marginRight: 'auto',

        },
        h3: {
          color: primaryColor,
          fontSize: '1.2rem',
          fontWeight: 400,
          marginBottom: 30,
          paddingBottom: 10,
          paddingTop: 10,
          textAlign: 'left',
        },
        h4: {
          color: 'inherit',
          fontSize: '1.4rem',
          fontWeight: 'normal',
          textTransform: 'uppercase',
          marginBottom: 24,
        },
        h5: {
          color: 'inherit',
          fontSize: '1.2rem',
          marginTop: '24px',
          position: 'relative',
        },
        h6: {
          color: primaryColor,
          fontSize: '1.1rem',
          textAlign: 'left',
        },
        body1: {
          fontSize: '1rem',
          color: 'inherit',
        },
        body2: {
          fontSize: '1rem',
          color: primaryColor,
        },
        subtitle1: {
          color: primaryColor,
          fontSize: '1rem',
        },
        subtitle2: { color: secondaryColor, fontSize: '1rem' },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      overrides: {
        MuiSvgIcon: {
          root: {
            display: 'inline-block',
            verticalAlign: 'middle',
            marginTop: -3,
            marginRight: 4
          }
        },
        MuiButton: {
          
          root: {
          },
          
          containedPrimary: {
            backgroundColor: primaryColor,
            color: secondaryColor,
            fontWeight: 'bold',
            textTransform: 'none',
            opacity: 0.8,
            '&:hover': {
              color: primaryColor,
              backgroundColor: secondaryColor,
            },
          },
          containedSecondary: {
            backgroundColor: secondaryColor,
            color: primaryColor,
            fontWeight: 'bold',
            textTransform: 'none',
            opacity: 0.8,
            '&:hover': {
              color: secondaryColor,
              backgroundColor: primaryColor,
            },
          },
          outlinedPrimary: {
            backgroundColor: primaryColor,
            color: secondaryColor,
            border:primaryColor+' 1px solid',
            fontWeight: 'bold',
            '&:hover': {
              color: primaryColor,
              backgroundColor: secondaryColor,
            },
            '&.Mui-disabled': {
              backgroundColor: '#ccc',
              opacity: 0.7,
            },
          },
          outlinedSecondary: {
            backgroundColor: secondaryColor,
            fontWeight: 'bold',
            color: primaryColor,
            border:tertiaryColor+' 1px solid',
            '&:hover': {
              color: secondaryColor,
              backgroundColor: primaryColor,
              border:primaryColor+' 1px solid',
            },
            '&.Mui-disabled': {
              backgroundColor: '#ccc',
              opacity: 0.7,
            },
          },
          textSecondary: {
            boxShadow: 'none',
            color: primaryColor,
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: "#b80e800f",//magenta clair
            },
            '&.Mui-disabled': {
              backgroundColor: '#ccc',
              opacity: 0.7,
            },
          },
        },
        MuiFormLabel: {
          root: {
            '&.Mui-focused': {
              color: primaryColor,
            },
          },
        },
        MuiFormHelperText: {
          root: {
            color: primaryColor,
          },
        },
        'MuiStepper-root': {
          padding: 0,
        },
        MuiStep: {
          root: {
            '& > .MuiStepConnector-root': {
              display: 'none',
            },
          },
        },
        MuiMenuItem: {
          root: {
          },
        },
        MuiAlert: {
          standardSuccess: {
            fontWeight: 'bold'
          }
        },
        MuiAppBar: {
          colorPrimary: {
          }
        },
        MuiTab:{
          root:{
            textTransform: 'none',
          },
        },
      },
    });
    // console.log('generated theme', theme)
    // console.log('theme debug', getMuiTheme(getCustomPalette(currentUserGroup)))
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
  }
}

*/

/*
export default class Theme extends React.Component {
  render() {
    const { children } = this.props;
    const theme = createMuiTheme({
      palette: {
        primary: {
          main: primaryColor,
          secondary: "#ff0000",
          dark: primaryColor,
          contrastText: "#fff",
        },
        secondary: {
          // main: primaryColor,
          main: primaryColor,
          light: "#fff",
          dark: "#fff",
          contrastText: "#fff",
        },
        tertiary: {//AJOUT B copy secondary 
          // main: primaryColor,
          main: primaryColor,
          light: "#fff",
          dark: "#fff",
          contrastText: "#fff",
        },
        text: {
          primary: bodyColor,
          // disabled: bodyColor,
          secondary: "#fff",
        },
        action: {
          disabled: "#ccc",
        },
        background: {
          default: "#fff",
        },
        // action: {
        //   active: '#ff0000',
        //   selected: '#ff0000',
        //   hover: '#ff0000',
        // }
      },
      typography: {
        fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
        fontSize: 12,
        useNextVariants: true,
        h1: {
          padding: "10px 30px",
          fontSize: "20px",
          color: "#fff",
          fontWeight: "normal",
          backgroundColor: "transparent",
          color: "#004b7f",
          fontSize: "3rem",
          textTransform: "uppercase",
        },
        h2: {
          color: primaryColor,
          letterSpacing: "0.39px",
          fontSize: "26px",
        },
        h3: {
          color: secondaryColor,
          fontSize: "30px",
          fontWeight: "bold",
          // lineHeight: "47px",
          lineHeight: "32px",
        },
        h4: {
          color: primaryColor,
          fontSize: "24px",
          // lineHeight: "40px",
          lineHeight: "28px",
          textAlign: "center",
        },
        h5: {
          fontSize: "1.6rem",
          lineHeight: "1.43",
          color: bodyColor,
        },
        h6: {
          color: "#666",
          textAlign: "center",
        },
        body1: {
          '& a': {
            color: 'inherit',
          }
        },
        body2: {
          color: bodyAltColor,
          fontSize: "14px",
          fontWeight: 500,
          letterSpacing: "0.5px",
          lineHeight: "29px",
        },
        subtitle1: {
          letterSpacing: "2px",
          fontSize: "14px",
        },
        caption: {
          fontSize: "12px",
          letterSpacing: "0.43px",
          fontStyle: "italic",
        },
        subtitle2: {
          textTransform: "uppercase",
          color: "#009EE3",
          letterSpacing: "1.17px",
          fontSize: "14px",
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      overrides: {
        MuiButton: {
          outlinedPrimary: {
            color: "#fff",
          },
          outlinedSecondary: {
            color: "#fff",
          },
          root: {
            // color: primaryColor, // Some CSS
            // backgroundColor: '#d2d2d2'
            color: "#fff", // Some CSS
            backgroundColor: buttonBgColor,
            border: "1px transparent solid",
            padding: "8px 20px",
            boxShadow:
              "0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)",
            "& .MuiButton-label": {
              padding: "3px 15px",
            },
            // '&.outlinedPrimary': {
            //   color: '#fff',
            // },
            // '&.outlinedSecondary': {
            //   color: '#fff',
            // },
            "&:hover": {
              backgroundColor: "#fff",
              border: "1px " + bodyColor + " solid",
              color: bodyColor,
            },
            "&.MuiButton-sizeLarge": {
              // padding: "8px 20px",
              padding: "8px 20px",
              // paddingLeft: 50,
              // paddingRight: 50
            },
            // '&.MuiButton-testSizeLarge': {
            //   paddingLeft: 50,
            //   paddingRight: 50
            // },
            "&.MuiButton-textSecondary": {
              backgroundColor: "#fff",
              color: primaryColor,
              "&:hover": {
                backgroundColor: tercaryColor,
                color: "#fff",
              },
            },
          },
        },
        "MuiButton-textSecondary": {},
        MuiFormHelperText: {
          root: {
            color: "#666",
          },
        },
        "MuiStepper-root": {
          padding: 0,
        },
        MuiStep: {
          root: {
            "& > .MuiStepConnector-root": {
              display: "none",
            },
          },
        },
        MuiInputLabel: {
          outlined: {
            color: 'inherit'
          }
        }
        //             fontSize: '24px',
        //             width: 34,
        //             height: 34,
        //             marginTop: '-7px',
        //             color: 'green',
        //             border: '2px '+secondaryColor+' solid',
        //             opacity: .3,
        //             '& .MuiStepIcon-text': {
        //               color: 'blue'
        //             },
        //             '& > circle': {
        //               fill: '#fff'
        //             },
        //             '& > text': {
        //               fill: primaryColor
        //             },
        //             '&.MuiStepIcon-active': {
        //               marginTop: '-13px',
        // backgroundColor: '#fff',
        //               width: 50,
        //               height: 50,
        //               opacity: 1
        //             }
        //           }
        //         }
        // // MuiInput: {
        // //   root: {
        // //     color: primaryColor
        // //   },
        // //   outlined: {
        // //     borderRadius: '30px'
        // //   }
        // // },
        // MuiOutlinedInput: {
        //   input: {
        //     paddingLeft: 15,
        //     paddingRight: 15
        //     // textAlign: 'center'
        //   }
        // },
        // MuiPrivateNotchedOutline: {
        //   root: {
        //     borderRadius: '30px',
        //     overflow: 'hidden',
        //     textAlign: 'center'
        //   }
        // },
        // MuiPaper: {
        //   rounded: {
        //     borderRadius: '16px'
        //   }
        // }
      },
    });
    // console.log('generated theme', theme)
    // console.log('theme debug', getMuiTheme(getCustomPalette(currentUserGroup)))
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
  }
}
*/
