import React, { Component } from "react";
// import BlockButton from 'components/UI/BlockButton';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import clsx from "clsx";
import { formatDate } from "helpers/stringUtils";
import i18n from "helpers/i18n";
import { withStyles } from "@material-ui/core/styles";
import getCardImage from "helpers/getCardImage";
import getThemeImage from "helpers/getThemeImage";
// import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import BlockRatio from "components/BlockRatio";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import { primaryColor, secondaryColor } from "../../helpers/colors";

const styles = (theme) => ({
  root: {},
  paperStyle: {
    padding: "0 30px 20px",
  },
  list: {
    // '& .MuiList-root': {
    //   display: 'flex',
    // },
    //     '& .MuiListItem-root': {
    //       flexGrow: 0,
    //       maxWidth: '50%',
    //       flexBasis: '50%',
    // width: '50%',
    //     },
    "& .MuiTypography-colorTextSecondary": {
      color: theme.palette.text.primary,
    },
    "& .MuiTypography-body1": {
      color: theme.palette.primary.main,
    },
  },
  bigTitle: {
    color: "inherit",
    textAlign: "center",
    padding: "0 20px 20px",
    fontSize: "2rem",
    textAlign: "left",
    fontWeight: "bold",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
      marginLeft: "5px",
    },
  },
  dynamicColor: {
    color: "inherit",
  },
  // h5: {
  //   textAlign: 'center',
  //   color: 'inherit'
  // },
  // h6: {
  //   fontWeight: 'normal',
  //   color: 'inherit'
  // },
  // caption: {
  //   display: 'block',
  //   textAlign: 'center',
  //   marginBottom: 5
  // },
  // body1: {
  //   textAlign: 'center',
  //   marginBottom: 10
  // },
  avatar: {
    width: "100%",
    height: "100%",
  },
  smallSubTitle: {
    marginTop: 10,
    textTransform: "uppercase",
    marginBottom: 5,
  },
  inline: {
    display: "inline-block",
  },
  emphasis: {
    // color: theme.palette.secondary.main
  },
  legend: {
    marginTop: 0,
    display: "inline",
    verticalAlign: "middle",
    fontSize: "20px",
  },
  legendButton: {
    display: "inline",
    verticalAlign: "middle",
    marginLeft: 20,
    backgroundColor: "transparent",
    backgroundImage: "none",
    boxShadow: "none",
    padding: 0,
    textTransform: "none",
    textDecoration: "underline",
    color: theme.palette.primary.main,
  },
  subTitle: {
    fontSize: "1.4rem",
    marginBottom: theme.spacing(2),
    // fontWeight: 'normal',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      lineHeight: "24px",
    },
  },
  description: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  programTitle: {
    fontWeight: "bold",
    position: "relative",
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 0,
    },
    "&:after": {
      content: '" "',
      position: "absolute",
      bottom: 0,
      width: 20,
      left: "calc(50% - (20px/2))",
      height: 2,
      borderBottom: "2px " + primaryColor + " solid",
    },
  },
  bgSpacerBottom: {
    height: 35,
    marginTop: theme.spacing(8),
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundImage: `url(${require("assets/images/" +
      process.env.REACT_APP_SITE +
      "/halfcircle.jpg")})`,
    [theme.breakpoints.down("xs")]: {
      height: 10,
    },
  },
  eventSelectorWrapper: {
    backgroundColor: primaryColor,
    color: "#fff",
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
    "& .MuiButton-root": {
      backgroundColor: secondaryColor,
    },
  },
});
class EventSubscriptionRecap extends Component {
  render() {
    const {
      classes,
      $$events,
      onSelectionReset,
      showDates,
      // onClose,
      // userRole,
    } = this.props;
    const $$theme = $$events !== undefined ? $$events.get(0) : undefined;
    const themeThumb =
      $$events !== undefined && $$events.size > 0
        ? getThemeImage($$events.get(0))
        : undefined;

    const themeColor =
      $$theme !== undefined ? $$theme.get("themeColor") : undefined;
    // if ($$theme !== undefined)
    // console.log({themeColor, color: $$theme.toJS()})
    const cellSize = $$events.size > 1 ? 6 : 12;
    const isNeoNat = process.env.REACT_APP_SITE === "neonat";

    return (
      <Box className={classes.root} mb={4} color={themeColor}>
        <If condition={$$theme !== undefined}>
          <Grid
            container={true}
            spacing={0}
            alignItems="center"
            justify="flex-start"
            className={classes.list}
          >
            <Grid item={true} xs={1} lg={1}>
              <Box
                width="100%"
                height="100%"
                my={2}
                className={classes.headerContent}
              >
                <BlockRatio ratio="1_1">
                  <Avatar className={classes.avatar} src={themeThumb}></Avatar>
                </BlockRatio>
              </Box>
            </Grid>
            <Grid item={true} xs={11} lg={11}>
              <Typography
                variant={"h2"}
                className={classes.bigTitle}
                gutterBottom={true}
                component="div"
                style={{
                  color: themeColor,
                  borderBottom: "2px " + themeColor + " solid",
                  marginBottom: 20,
                }}
              >
                <Paragrapher
                  element={"div"}
                  text={$$theme.get("themeName")}
                  prettyfy={true}
                />
              </Typography>
            </Grid>

            <Grid item={true} xs={12}>
              <Box className={classes.headerContent}>
                <Typography
                  variant={"h3"}
                  className={clsx(classes.dynamicColor, classes.subTitle)}
                  gutterBottom={true}
                  style={{ color: themeColor }}
                  component={"div"}
                >
                  <Paragrapher
                    element={"div"}
                    text={$$theme.get("themeSubTitle")}
                    prettyfy={true}
                  />
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant={"body1"}
                  className={clsx(classes.description, classes.dynamicColor)}
                  gutterBottom={true}
                  style={{ color: themeColor }}
                  component={"div"}
                >
                  <Paragrapher
                    element={"div"}
                    text={$$theme.get("themeDescription")}
                    prettyfy={true}
                  />
                </Typography>
              </Box>

              <If
                condition={
                  $$theme.get("themeProgram") !== "" &&
                  $$theme.get("themeProgram") !== null
                }
              >
                <Box mt={0}>
                  <Typography
                    gutterBottom={true}
                    align={isNeoNat ? "center" : "left"}
                    className={clsx(classes.description, classes.dynamicColor, {
                      [classes.programTitle]: isNeoNat,
                    })}
                    component={"div"}
                  >
                    {i18n.get("eventSubsProgramTitle", { prettyfy: false })}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    gutterBottom={true}
                    className={clsx(classes.description, classes.dynamicColor)}
                    component={"div"}
                  >
                    <Paragrapher
                      element={"div"}
                      text={$$theme.get("themeProgram")}
                      prettyfy={true}
                    />
                  </Typography>
                </Box>
              </If>
            </Grid>

            <If condition={showDates !== false}>
              <Grid item={true} xs={12}>
                <hr />
                {isNeoNat ? (
                  <Box className={classes.bgSpacerBottom}></Box>
                ) : null}
                <Box
                  className={clsx({
                    [classes.eventSelectorWrapper]: isNeoNat,
                  })}
                >
                  <Box className={classes.headerContent} align="center">
                    <Typography
                      variant="h5"
                      gutterBottom={true}
                      style={{ color: isNeoNat ? "#fff" : "inherit" }}
                    >
                      {i18n.get("eventSubsRecapRegistered", {
                        prettyfy: false,
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {$$events
                .map(($e) => {
                  return (
                    <Grid
                      key={"ev_" + $e.get("id")}
                      item={true}
                      xs={cellSize}
                      align="center"
                    >
                      <Box
                        className={clsx({
                          [classes.eventSelectorWrapper]: isNeoNat,
                        })}
                      >
                        <Typography
                          variant="h6"
                          className={clsx(classes.legend, classes.emphasis)}
                          style={{ color: isNeoNat ? "#fff" : themeColor }}
                        >
                          {formatDate($e.get("eventDate"), "dddd dd mmmm yyyy")}{" "}
                          -{" "}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.legend}
                          style={{ color: isNeoNat ? "#fff" : themeColor }}
                        >
                          {formatDate($e.get("eventDate"), "HH'h'MM")}
                        </Typography>
                        <Button
                          variant={"text"}
                          className={classes.legendButton}
                          onClick={onSelectionReset}
                          style={{ color: isNeoNat ? "#fff" : primaryColor }}
                        >
                          Modifier la date
                        </Button>
                      </Box>
                    </Grid>
                  );
                })
                .toArray()}
            </If>
          </Grid>
        </If>
      </Box>
    );
  }
}

// export default ;
export default withStyles(styles)(EventSubscriptionRecap);
