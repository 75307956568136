import React, { Component } from "react";
import config from "config/api";
import Immutable from "immutable";
import BlockButton from "components/UI/BlockButton";
import Input from "components/UI/Form/Input";
import RemainingTime from "components/UI/RemainingTime";
import BlockTitle from "components/UI/BlockTitle";
import { connect } from "react-redux";
// import { formatDate } from 'helpers/stringUtils'
import getRemainingTime from "helpers/getRemainingTime";
// import colors from 'config/colors'
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import i18n from "helpers/i18n";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import clsx from "clsx";
// import getCardImage from 'helpers/getCardImage'
// import Grid from '@material-ui/core/Grid';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
import EventSubscriptionRecapSingle from "components/Event/EventSubscriptionRecapSingle";

import IconWarning from "@material-ui/icons/Warning";
// import IconCheck from '@material-ui/icons/Check';
// import {
//   getCurrentUserReferer,
//   // isLoggedIn,
// } from 'ducks/authentication';

const styles = (theme) => ({
  root: {
    // maxWidth: "1280px",
    // margin: "0 auto",
    "& .MuiButton-root": {
      "&:hover": {
        backgroundColor: "#fff !important",
        color: "inherit",
      },
    },
    "& .MuiInputLabel-formControl": {
      color: "inherit",
    },
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: 'inherit'
    // },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        borderColor: "inherit",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "inherit",
          borderSize: "1px",
        },
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 14px",
    },
  },
  buttonLarge: {
    "&:hover": {
      color: "#000",
    },
  },
});

class EventCanceledNotifier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      log: false,
      email: false,
      complete: false,
      step: 1,
    };
    this._send = this._send.bind(this);
    this._onEmailChange = this._onEmailChange.bind(this);
    // this.onEventSelectionReset =
    // this._onSubscriptionComplete = this._onSubscriptionComplete.bind(this);
  }
  _onEmailChange(kind, target) {
    this.setState({ email: target.value, error: false, log: undefined });
  }
  _validateEmail($$field, value) {
    let check = true;
    if (value !== undefined)
      check = !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
    const log =
      check === true
        ? undefined
        : "Le champs " + $$field.get("label") + " est invalide";
    return log;
  }
  _send(e) {
    const {
      $$event,
      // referer
    } = this.props;
    const { sending, email } = this.state;

    if (sending === true) return;

    let url;
    url = config.events + "/notify/" + $$event.get("id");

    this.setState({ sending: true, log: false });

    var formData = new FormData();

    // if ($$event !== undefined)
    // formData.append('rid', $$event.get('id'));
    // else if ($$events !== undefined)
    // formData.append('rids', JSON.stringify($$events.map(e => e.get('id')).toArray()));

    // if (themeId !== undefined)
    // formData.append('themeId', themeId);

    // if (referer !== undefined)
    // formData.append('referer', referer);

    // if (registerFor === true) {
    const check = this._validateEmail(
      Immutable.fromJS({ label: "Email" }),
      this.state.email,
    );
    if (check !== undefined) {
      // console.log('NOT OK', check)
      this.setState({ log: check, sending: false, error: true });
      return;
    } else {
      // console.log('check ok')
      formData.append("remail", this.state.email);
    }
    // console.log('ok')
    // } else {
    // formData.append('remail', $$user.get('id'));
    // }

    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        // console.log(response)
        if (response.notify && response.notify === "complete") {
          this.setState({ step: "complete", error: false, log: undefined });
        } else if (response.log) {
          this.setState({ error: true, log: response.log });
        }
      });
  }
  render() {
    const { themeColor, classes } = this.props;
    const {
      // visibleMode,
      sending,
      log,
      error,
      complete,
      step,
    } = this.state;

    return (
      <Box mt={2} className={classes.root}>
        <Choose>
          <When condition={step === "complete"}>
            <Typography variant="h4" gutterBottom style={{ color: "#ff0000" }}>
              Vous serez averti(e) en priorité dès que la nouvelle date sera
              connue.
              <br />
              Merci.
            </Typography>
          </When>
          <Otherwise>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ color: "#ff0000", fontWeight: "bold" }}
            >
              Je souhaite être averti(e) de la nouvelle date de cet événement
            </Typography>
            <Input
              type="text"
              id="email"
              name="email"
              variant="outlined"
              label="Email"
              style={{
                maxWidth: 280,
                color: themeColor,
                borderColor: themeColor,
              }}
              size="small"
              onChange={this._onEmailChange}
              error={error === true}
              errorMessage={log}
            />
            <Button
              size={"large"}
              onClick={(e) => {
                e.preventDefault();
                this._send();
              }}
              className={clsx(classes.buttonLarge)}
              style={{ backgroundColor: themeColor }}
            >
              Envoyer
            </Button>
            <If
              condition={
                log !== undefined && error !== true && complete === true
              }
            >
              <Box align="left" mt={1}>
                <Typography variant="body1" color="error">
                  {log}
                </Typography>
              </Box>
            </If>
          </Otherwise>
        </Choose>
      </Box>
    );
  }
}

// export default ;
export default withStyles(styles)(EventCanceledNotifier);
