import React, { Component } from "react";
import EventIntro from "../Event/EventIntro";
import EventIntroCondensed from "../Event/EventIntroCondensed";
// import config from 'config/api';
// import LiveHelp from '../Lives/LiveHelp';
// import AvailableEvents from 'components/Event/AvailableEvents';
// import EventSelector from "components/Event/EventSelector";
import HomeHelp from "components/Home/HomeHelp";
import BlockRatio from "components/BlockRatio";
import BlockButton from "components/UI/BlockButton";
import Immutable from "immutable";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { formatDate } from "helpers/stringUtils";
import RemainingTime from "components/UI/RemainingTime";
import getRemainingTime from "helpers/getRemainingTime";
import WarningIcon from "@material-ui/icons/Warning";
import config from "config/api";
import gotoLiveRoom from "helpers/gotoLiveRoom";
import getThemeImage from "helpers/getThemeImage";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import Input from "components/UI/Form/Input";
import EventCanceledNotifier from "components/Event/EventCanceledNotifier";

const styles = (theme) => {
  return {
    root: {
      // maxWidth: "1280px",
      // margin: "0 auto",
      transition: [
        theme.transitions.create("box-shadow", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      ],
      // boxShadow: theme.shadows[1],

      "&:hover": {
        boxShadow: theme.shadows[9],
        "& .MuiButton-root": {
          backgroundColor: "#fff !important",
          color: "inherit",
          border: "1px #5A5A5A solid",
        },
      },
      "& .MuiButton-root": {
        "&:hover": {
          backgroundColor: "#fff !important",
          color: "inherit",
        },
      },
      "& .MuiInputLabel-formControl": {
        color: "inherit",
      },
      // '& .MuiInput-underline:after': {
      //   borderBottomColor: 'inherit'
      // },
      // '& .MuiOutlinedInput-root': {
      //   '&.Mui-focused': {
      //     borderColor: 'inherit',
      //     '& .MuiOutlinedInput-notchedOutline': {
      //       borderColor: 'inherit',
      //       borderSize: '1px'
      //     }
      //   }
      // },
      // '& .MuiOutlinedInput-input': {
      //   padding: '15px 14px'
      // }
    },
    clickable: {
      cursor: "pointer",
    },
    inline: {
      display: "inline-block",
    },
    emphasis: {
      color: theme.palette.secondary.main,
    },
    titleWrapper: {
      borderBottom: "2px " + theme.palette.primary.main + " solid",
      marginBottom: 20,
      minHeight: 80,
    },
    titleImage: {
      width: 60,
      height: 60,
      display: "inline-block",
      verticalAlign: "bottom",
      marginRight: 20,
    },
    titleLabel: {
      width: "calc(100% - 80px)",
      display: "inline-block",
      verticalAlign: "top",
      textAlign: "left",
      fontSize: "1.2rem",
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.0rem",
      },
    },
    cardDescription: {
      textAlign: "left",
      minHeight: 230,
      fontSize: "1.2rem",
      [theme.breakpoints.up("lg")]: {
        // minHeight: 260,
        minHeight: 150,
      },
      [theme.breakpoints.down("md")]: {
        minHeight: 140,
        fontSize: "1.1rem",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: 140,
        fontSize: "1.0rem",
      },
      [theme.breakpoints.down("xs")]: {
        minHeight: "auto",
        fontSize: "1.0rem",
      },
    },
    dynamicColor: {
      color: "inherit",
      borderColor: "inherit",
    },
    mainColor: {
      color: theme.palette.primary.main,
      borderColor: "inherit",
    },
    subTitle: {
      // textAlign: 'left',
      fontSize: "80%",
    },
    legend: {
      display: "inline-block",
    },
    negativeTop: {
      marginTop: "-65px",
      height: "60px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0",
        marginTop: "-85px",
        height: "80px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "0",
        height: "auto",
      },
      buttonLarge: {
        "&:hover": {
          color: "#000",
        },
      },
    },
  };
};
class ThemeCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLiveButton: false,
    };
    this._runRemainingTimer = this._runRemainingTimer.bind(this);
    this._gotoLiveRoom = this._gotoLiveRoom.bind(this);
  }
  componentDidMount() {
    this._startTimer();
  }
  componentDidUpdate(oldProps) {
    if (
      (oldProps.$$subscriptions === undefined &&
        this.props.$$subscriptions !== undefined) ||
      oldProps.$$subscriptions !== this.props.$$subscriptions
    )
      this._startTimer();
  }
  componentWillUnmount() {
    this._stopTimer();
  }
  // componentDidUpdate(oldProps) {
  //    console.log(oldProps.$$data.toJS(), oldProps.userRole, this.props.userRole)
  //   if ((oldProps.userRole === undefined && this.props.userRole !== undefined) || this.props.userRole !== undefined && oldProps.$$data !== this.props.$$data) {
  //      console.log('GOGOGO')
  //     this._startTimer();
  //   }
  // }
  _gotoLiveRoom = (e) => {
    const { $$user } = this.props;
    e.preventDefault();
    const $liveEvent = this._getSubscribedEvent();
    // console.log($liveEvent.toJS())
    // return;
    // gotoLiveRoom($liveEvent, (res) => {
    // if (res === true) this._goto("/live/" + $liveEvent.get("id"));
    this._goto("/live/" + $liveEvent.get("id"));
    // });
  };
  _stopTimer() {
    // console.log('timer stop')
    if (this._remainingTimer) {
      clearInterval(this._remainingTimer);
    }
  }
  _startTimer() {
    // console.log('timer start')
    if (this._remainingTimer) return;
    const $$subscribedEvent = this._getSubscribedEvent();
    // console.log('$$subscribedEvent', $$subscribedEvent)
    // console.log('trying to start')
    if ($$subscribedEvent === undefined) return this._stopTimer();
    // console.log('started')
    // console.log('starting timer')
    // this._cardTimer = $$card.get('eventDate');
    this._remainingTimer = setInterval(this._runRemainingTimer, 1000);
    this._runRemainingTimer();
  }
  _runRemainingTimer() {
    // console.log('run1')
    const $$subscribedEvent = this._getSubscribedEvent();
    if ($$subscribedEvent === undefined) {
      return this._stopTimer();
    }
    // console.log('run3')
    // console.log($e)
    // console.log($e.get('eventState'))
    // console.log('ddsqdqs', $$subscribedEvent.toJS())
    // const timeRemaining = $$subscribedEvent !== undefined && $$subscribedEvent.get('eventState') != 'live'
    const timeRemaining =
      $$subscribedEvent !== undefined &&
      $$subscribedEvent.get("eventState") !== "live"
        ? getRemainingTime($$subscribedEvent.get("eventDate"))
        : {
            days: 0,
            hours: 0,
            minutes: 0,
          };
    // console.log(timeRemaining)
    if (
      timeRemaining.days <= 0 &&
      timeRemaining.hours <= 0 &&
      timeRemaining.minutes <= 15
    ) {
      this.setState(
        {
          showLiveButton: true,
        },
        () => {
          // console.log('STOPPPING')
          this._stopTimer();
        },
      );
    } else {
      // console.log('RUN')
      this.setState({
        timeRemaining,
      });
    }
  }
  // _getMyLiveEvent() {
  //   const {$$data, $$subscriptions} = this.props;
  //   if ($$data !== undefined) {
  //     // console.log($$subscriptions)
  //     // console.log($$data.get('myfutureevents'))
  //   }
  //   // const $$subscriptions = this._getMySubscriptions();
  //   // return $$subscriptions !== undefined && $$subscriptions.size > 0 && $$subscriptions.get(0) !== undefined
  //   // ? $$data.get('myfutureevents').get(0)
  //   // : undefined;
  //   return undefined;
  // }
  // _getMyPastEvent() {
  //   const {$$data} = this.props;
  //   if ($$data !== undefined) {
  //     // console.log($$data.toJS())
  //     // console.log($$subscriptions)
  //     // console.log($$data.get('myfutureevents'))
  //     // console.log($$data.get('mypastevents').filter($e => $e.get('hasAttended') !== undefined && $e.get('hasAttended') !== '' && $e.get('hasAttended') !== null).toJS())
  //   }
  //   return $$data !== undefined && $$data.get('mypastevents') !== undefined && $$data.get('mypastevents').size > 0
  //   ? $$data.get('mypastevents').filter($e => $e.get('hasAttended') !== undefined && $e.get('hasAttended') !== '' && $e.get('hasAttended') !== null).get(0)
  //   : undefined;
  // }
  _goto(url) {
    const { history } = this.props;
    history.push(url);
  }
  _getSubscribedEvent() {
    const { $$themeData, $$subscriptions } = this.props;
    // console.log($$subscriptions, $$themeData)
    let subscribedEvent = [];
    $$themeData.map(($$event, i) => {
      if ($$subscriptions.contains($$event.get("id"))) {
        // console.log('matches ',i)
        subscribedEvent.push($$event);
      }
    });
    const $$subscribedEvent = Immutable.fromJS(subscribedEvent);
    // console.log($$subscribedEvent.toJS(), $$subscriptions.toJS());
    // console.log($$subscribedEvent.sortBy($e => $e.get('eventDate')).get(0).toJS());
    return $$subscribedEvent.sortBy(($e) => $e.get("eventDate")).get(0);
  }
  render() {
    const {
      $$themeData,
      tIdx,
      $$subscriptions,
      userRole,
      $$user,
      onSubscriptionComplete,
      classes,
      gridSize,
    } = this.props;
    // console.log({$liveEvent, $pastEvent})
    // if ($e !== undefined) console.log($e.toJS())
    const { showLiveButton } = this.state;
    const themeId = $$themeData.get(0).get("themeId");
    const themeThumb = getThemeImage($$themeData.get(0), "themThumb");
    const themeColor = $$themeData.get(0).get("themeColor");

    const delayedEvent = $$themeData.filter(
      ($f) => $f.get("eventState") === "delayed",
    );
    const eventsCount = $$themeData.size;
    // const $liveEvent = this._getMyLiveEvent();
    const $$subscribedEvent = this._getSubscribedEvent();
    // const timeRemaining = $$subscribedEvent !== undefined
    // ? getRemainingTime($$subscribedEvent.get('eventDate'))
    // : {};
    const gridSizes = [
      {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
      },
      {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
      },
    ];
    const gs = gridSize <= 2 ? gridSize : 2;
    const showModifySession =
      process.env.REACT_APP_SITE !== "pneumo" &&
      process.env.REACT_APP_SITE !== "medevent_pneumo";
    // console.log('delayed evens are', delayedEvent)
    return (
      <Grid
        key={"thm_" + tIdx}
        item={true}
        xs={gridSizes[gs].xs}
        sm={gridSizes[gs].sm}
        md={gridSizes[gs].md}
        lg={gridSizes[gs].lg}
        className={clsx(classes.root, {
          [classes.clickable]: delayedEvent.size < 1,
        })}
        onClick={(e) => {
          e.preventDefault();
          if (delayedEvent.size > 0) return;
          if (showLiveButton === true) return;
          this._goto("/subscribe/" + themeId);
        }}
      >
        <Box
          color={themeColor}
          style={{
            borderColor: themeColor,
          }}
        >
          <Typography
            variant={"h2"}
            gutterBottom={true}
            className={clsx(classes.titleWrapper, classes.dynamicColor)}
            component={"div"}
          >
            <Avatar
              src={themeThumb !== false ? themeThumb : undefined}
              className={classes.titleImage}
            />
            <span className={classes.titleLabel}>
              <Paragrapher
                element={"div"}
                text={$$themeData.get(0).get("themeName")}
                prettyfy={true}
              />
            </span>
          </Typography>
          <Typography
            variant={"body1"}
            gutterBottom={true}
            className={clsx(classes.cardDescription, {
              [classes.dynamicColor]:
                process.env.REACT_APP_SITE !== "transplant",
              [classes.mainColor]: process.env.REACT_APP_SITE === "transplant",
            })}
            component={"div"}
          >
            <Paragrapher
              element={"div"}
              text={$$themeData.get(0).get("themeSubTitle")}
              prettyfy={true}
            />
          </Typography>
          <If condition={$$subscribedEvent !== undefined}>
            <Box className={classes.negativeTopDisabled}>
              <Typography
                variant="subtitle2"
                gutterBottom={true}
                className={clsx(classes.dynamicColor, classes.subTitle)}
              >
                Vous êtes inscrit(e) à la session suivante:
              </Typography>
              <Typography
                variant="h6"
                className={clsx(classes.legend, classes.dynamicColor)}
                gutterBottom={true}
              >
                {formatDate(
                  $$subscribedEvent.get("eventDate"),
                  "dddd dd mmmm yyyy",
                )}{" "}
                à {formatDate($$subscribedEvent.get("eventDate"), "HH'h'MM")}
              </Typography>
            </Box>
            <If
              condition={showLiveButton !== true && showModifySession !== false}
            >
              <Box mt={2}>
                <Button
                  size={"large"}
                  onClick={(e) => {
                    e.preventDefault();
                    this._goto("/subscribe/" + themeId);
                  }}
                  className={clsx(classes.buttonLarge)}
                  style={{ backgroundColor: themeColor }}
                >
                  Modifier la session
                </Button>
              </Box>
            </If>
            <If condition={showLiveButton === true}>
              <Box my={2}>
                <Button
                  size={"large"}
                  onClick={this._gotoLiveRoom}
                  className={clsx(classes.buttonLarge)}
                  style={{ backgroundColor: themeColor }}
                >
                  Accéder au live
                </Button>
              </Box>
              <Typography variant="body1" gutterBottom={true}>
                La conférence a déjà débuté !
              </Typography>
            </If>
          </If>

          <If
            condition={$$subscribedEvent === undefined && delayedEvent.size < 1}
          >
            <Box mt={2}>
              <Button
                size={"large"}
                onClick={(e) => {
                  e.preventDefault();
                  this._goto("/subscribe/" + $$themeData.get(0).get("themeId"));
                }}
                className={clsx(classes.buttonLarge)}
                style={{ backgroundColor: themeColor }}
              >
                Inscrivez-vous en 3 étapes
              </Button>
            </Box>
          </If>
          <If
            condition={$$subscribedEvent === undefined && delayedEvent.size > 0}
          >
            <EventCanceledNotifier
              $$event={delayedEvent.get(0)}
              themeColor={themeColor}
            />
          </If>
        </Box>
      </Grid>
    );
  }
}
export default withRouter(withStyles(styles)(ThemeCard));
