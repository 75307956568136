import React, { PureComponent } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SendIcon from "@material-ui/icons/Send";
import config from "config/api";
import { getToken } from "components/UI/Account/ducks/authentication";
// import {
//   // getRoomMessages,
//   sendRoomMessage,
//   getRoomMessageState,
//   // sendRoomMessageApprobation
// } from '../../ducks/roomMessage';
// import {sendMessage} from 'ducks/relay';
import BlockButton from "components/UI/BlockButton";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
// import RoomMessageDelete from 'components/RoomMessage/RoomMessageDelete';

const bottomBar = 100;
const titleHeight = 40;

const styles = (theme) => {
  return {
    icon: {
      verticalAlign: "middle",
    },
    fullWidth: {
      width: "100%",
    },
    fullHeight: {
      height: "100%",
      alignItems: "flex-start",
    },
    input: {
      // color: '#fff'
    },
    noMargin: {
      margin: 0,
    },
    mainWrapper: {
      height: "calc(100% - " + titleHeight + "px - " + bottomBar + "px)",
    },
    mainTitle: {
      height: titleHeight,
    },
    mainContent: {
      // height: 'calc(100% - ' + titleHeight + 'px)'
      height: "100%",
    },
    listRoot: {
      // maxHeight: '50%',
      // overflow: 'hidden'
      height: "calc(100% - " + titleHeight + "px)",
      overflowX: "hidden",
      overflowY: "auto",
    },
    // tabToggler: {
    //   minWidth: 60
    // },
    // tabPanel: {
    //
    // }
    // inline: {
    //   paddingRight: 82
    // },
    textFieldMultiline: {
      // height: 'calc(100%  - ' + titleHeight + 'px)',
      // height: 'calc(100%  - 4px)',
      height: "100%",
      width: "100%",
      marginTop: 0,
    },
    button: {
      width: "100%",
      marginLeft: '5px',
      height: "53px",
    },
  };
};

class LiveMessage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      // activeTab: 0,
    };
    this._onMessageChange = this._onMessageChange.bind(this);
    this._onSend = this._onSend.bind(this);
    // this._onMessageDeleteComplete = this._onMessageDeleteComplete.bind(this);
    // this._onMessageApprobationChange = this._onMessageApprobationChange.bind(this);
  }

  componentDidUpdate(oldProps) {
    // if (oldProps.roomMessageState === 'locked' && this.props.roomMessageState !== 'locked')
    // this.setState({message: ''})
  }

  _onMessageChange(e, v) {
    this.setState({ message: e.target.value });
  }

  _onSend = async (e, v) => {
    const { $$event, onMessageSent } = this.props;
    const { message, sending } = this.state;
    // const disabled = sending === true
    // if (disabled)
    // return;
    if (message === false || message === "") return;

    this.setState({ sending: true });

    const url = config.message + "/send";
    var formData = new FormData();

    if ($$event !== undefined) formData.append("eid", $$event.get("id"));
    formData.append("message", message);
    formData.append("fsubmit", "message_form");
    const token = await getToken();
    formData.append("csrf", token);

    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.form && response.form === "saved") {
          // if (this.props.onClose) this.props.onClose();
          this.setState({ complete: true, sending: false, message: "" }, () => {
            onMessageSent();
          });
        } else {
          this.setState({ sending: false });
        }
      });
  };
  render() {
    const {
      // requestConnection,
      classes,
      // $$roomData,
      // $$roomMessages,
      multiline,
      roomMessageState,
      // activeTab
    } = this.props;
    const { message } = this.state;

    // const onSend = this._onSend;
    const disabled = roomMessageState === "locked";
    // const pointerEnabled = $$roomOptions !== undefined && $$roomOptions.get('pointerEnabled') === true;
    return (
      <React.Fragment>
        <Grid
          className={classes.fullHeight}
          container={true}
          spacing={0}
          alignItems="center"
          justify="space-around"
        >
          <Grid item={true} xs={8} className={classes.fullHeight}>
            <TextField
              id="message"
              multiline={multiline !== false}
              label="Votre question"
              onChange={this._onMessageChange}
              className={clsx([
                classes.textFieldMultiline,
                classes.fullHeight,
                classes.input,
              ])}
              value={message}
              margin={"none"}
              InputProps={{
                spellCheck: true,
                classes: {
                  input: clsx(classes.fullHeight, classes.input),
                  // formControl: clsx(classes.input),
                  multiline: clsx([
                    classes.fullHeight,
                    classes.noMargin,
                    classes.input,
                  ]),
                },
              }}
              fullWidth={true}
              variant="outlined"
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  // Do code here
                  ev.preventDefault();
                  this._onSend();
                }
              }}
            />
          </Grid>
          <Grid item={true} xs={4} className={classes.fullHeight}>
            <Button
              endIcon={
                <SendIcon
                  style={{ fontSize: "small", verticalAlign: "middle" }}
                />
              }
              onClick={this._onSend}
              className={classes.button}
              color="primary"
              variant="contained"
            >
              {disabled ? "Envois en cours..." : "Envoyer"}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  // const props = {
  //   roomMessageState: getRoomMessageState(state)
  // };
  // return props;
}
function mapDispatchToProps(dispatch) {
  return {
    // onSendRoomMessage: (roomHash, recipient, message) => {
    //   dispatch(sendRoomMessage(roomHash, recipient, message));
    // },
    // onMessage: message => dispatch(sendMessage(message))
  };
}

export default withStyles(styles, { withTheme: true })(LiveMessage);
