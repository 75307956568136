import React, { Component } from "react";
import EventIntro from "../Event/EventIntro";
import EventIntroCondensed from "../Event/EventIntroCondensed";
// import config from 'config/api';
// import LiveHelp from '../Lives/LiveHelp';
// import AvailableEvents from 'components/Event/AvailableEvents';
import EventSelector from "components/Event/EventSelector";
import HomeHelp from "components/Home/HomeHelp";
import BlockRatio from "components/BlockRatio";
// import BlockButton from 'components/UI/BlockButton';
import Immutable from "immutable";
import { withStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import Avatar from '@material-ui/core/Avatar';
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { formatDate } from "helpers/stringUtils";
import RemainingTime from "components/UI/RemainingTime";
import getRemainingTime from "helpers/getRemainingTime";
import WarningIcon from "@material-ui/icons/Warning";
import config from "config/api";
import gotoLiveRoom from "helpers/gotoLiveRoom";
import getThemeImage from "helpers/getThemeImage";
import ThemeCard from "./ThemeCard";
const styles = (theme) => {
  return {
    root: {
      maxWidth: "1280px",
      margin: "0 auto",
    },
    inline: {
      display: "inline-block",
    },
    emphasis: {
      color: theme.palette.secondary.main,
    },
    titleWrapper: {
      borderBottom: "2px " + theme.palette.primary.main + " solid",
      marginBottom: 20,
      minHeight: 80,
    },
    titleImage: {
      width: 60,
      height: 60,
      display: "inline-block",
      verticalAlign: "bottom",
      marginRight: 20,
    },
    titleLabel: {
      width: "calc(100% - 80px)",
      display: "inline-block",
      verticalAlign: "top",
      textAlign: "left",
      fontSize: "1.8rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
    },
    cardDescription: {
      fontSize: "1.6rem",
      textAlign: "left",
      minHeight: 200,
      [theme.breakpoints.down("md")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    dynamicColor: {
      color: "inherit",
      borderColor: "inherit",
    },
  };
};
class ThemeSelector extends Component {
  constructor(props) {
    super(props);
  }
  _goto(url) {
    const { history } = this.props;
    history.push(url);
  }
  render() {
    const {
      $$data,
      $$subscriptions,
      userRole,
      $$user,
      onSubscriptionComplete,
      classes,
    } = this.props;
    // console.log({$liveEvent, $pastEvent})
    // if ($e !== undefined) console.log($e.toJS())
    const $$themes =
      $$data.get("availableEvents") !== undefined
        ? $$data
            .get("availableEvents")
            .groupBy(($$event, eIdx) => $$event.get("themeName"))
        : undefined;
    // const $$themes = $$data.get('themes') !== undefined ? $$data.get('themes');
    const gridSize = $$themes !== undefined ? $$themes.size - 1 : 0;
    return (
      <If condition={$$themes !== undefined}>
        <Box px={4}>
          <Grid
            container={true}
            spacing={8}
            alignItems="flex-start"
            justify="space-around"
          >
            {$$themes
              .map(($$themeData, tIdx) => {
                return (
                  <ThemeCard
                    key={"thm_" + tIdx}
                    $$themeData={$$themeData}
                    $$subscriptions={$$subscriptions}
                    tIdx={tIdx}
                    gridSize={gridSize}
                  />
                );
              })
              .toArray()}
          </Grid>
        </Box>
      </If>
    );
  }
}
export default withRouter(withStyles(styles)(ThemeSelector));
