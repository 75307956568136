import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import RichEdit from "./RichEdit";
import Tinymce from "./Tinymce";

const styles = (theme) => {
  return {
    root: {
      margin: "50px 20px 20px 20px",
      textAlign: "left",
      minHeight: "300px",
    },
    page: {
      maxWidth: "800px",
      border: "#ccc 1px solid",
      padding: "10px",
      textAlign: "left",
    },
  };
};

class RichEditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root} justify="center">
        {/*<a href="mailto:test@test.com?subject=Sujet&body=Body%0A%0ATest%0A%0Ahttps://www.gooogle.com">Test lien partage</a>*/}
        <Grid item xs={12} align="center">
          <h1>Test éditeur</h1>
        </Grid>
        <Grid item xs={12} className={classes.page} align="center">
          {/*<RichEdit/>*/}
          <Tinymce />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(RichEditPage);
