import mirrorCreator from "mirror-creator";
import Immutable from "immutable";
import { createSelector } from "reselect";
import pipe from "helpers/redux-state-mutators.js";
import queryString from "query-string";
import config from "config/api";

const actionTypes = mirrorCreator(
  ["SET_REGISTRATION_STATE", "SET_ERROR", "SET_REGISTERING", "SET_STATE_KNOWN"],
  { prefix: "registration/" },
);

const mutators = {
  setRegistrationState: (state) => ($$state) => $$state.set("status", state),
  setResetPassword: (step, result, log) => ($$state) =>
    $$state.set("resetPassword", Immutable.fromJS({ step, result, log })),
  clearErrors: ($$state) => $$state.delete("error"),
  setError: (error) => ($$state) => $$state.set("error", error),
  setRegistering: (registering) => ($$state) =>
    $$state.set("registering", registering),
  setStateKnown:
    (stateKnown = true) =>
    ($$state) =>
      $$state.set("stateKnown", stateKnown),
};

export default function reducer($$state = Immutable.Map(), action) {
  switch (action.registrationState) {
    case actionTypes.SET_REGISTRATION_STATE:
      return pipe(
        [
          mutators.setRegistrationState(action.state),
          mutators.clearErrors,
          mutators.setStateKnown(),
        ],
        $$state,
      );

    case actionTypes.SET_ERROR:
      return pipe([mutators.setError(action.error)], $$state);

    case actionTypes.SET_REGISTERING:
      return pipe([mutators.setRegistering(action.status)], $$state);

    case actionTypes.SET_STATE_KNOWN:
      return pipe([mutators.setStateKnown(action.stateKnown)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.authentication || Immutable.Map();
export const isStateKnown = createSelector([getRoot], ($$state) =>
  $$state.get("stateKnown"),
);

export const isRegistering = createSelector([getRoot], ($$state) =>
  $$state.get("registering", false),
);
export const isRegistered = createSelector(
  [getRoot],
  ($$state) => $$state.get("registering") === true,
);
export const hasRegistrationFailed = createSelector(
  [getRoot],
  ($$state) => $$state.get("error") !== undefined,
);
export const getError = createSelector([getRoot], ($$state) =>
  $$state.get("error"),
);

export function register(formData) {
  // console.log({login, password})
  return (dispatch) => {
    let url = config.registration;
    dispatch(setRegistering(true));

    formData.registrationChallenge = true;

    fetch(url, {
      credentials: "include",
      method: config.urlMethod || "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: queryString.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.registration && response.registration === "ok") {
          return dispatch(setRegistrationState(response.user));
        } else {
          return dispatch(setError(response.log));
        }
      })
      .catch((error) => dispatch(setError(error.message)))
      .finally(() => dispatch(setRegistering(false)));
  };
}

export function setRegistrationState(state) {
  return {
    type: actionTypes.SET_REGISTRATION_STATE,
    state,
  };
}
export function setError(error) {
  return {
    type: actionTypes.SET_ERROR,
    error,
  };
}

export function setRegistering(registering) {
  return {
    type: actionTypes.SET_REGISTERING,
    status: registering,
  };
}

export function setStateKnown(stateKnown = true) {
  return {
    type: actionTypes.SET_STATE_KNOWN,
    stateKnown,
  };
}
