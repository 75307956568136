import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import BlockTitle from "components/UI/BlockTitle";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const styles = (theme) => {
  return {
    root: {},
    grid: {
      position: "relative",
      height: "192px",
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
    },
    paper: {
      padding: "20px 0",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    cell: {
      height: "100%",
    },
    avatar: {
      width: 70,
      height: 70,
      margin: "0 10px 0 20px",
      display: "inline-block",
      verticalAlign: "middle",
    },
    content: {
      width: "calc(100% - 130px)",
      margin: "0 20px 0 10px",
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "30px",
      letterSpacing: "0.5px",
    },
  };
};
class EventIntro extends Component {
  render() {
    const { $$data, altStyle, size, classes } = this.props;
    // const cellWidth = size === 'small' ? 3 : 4;
    const cellWidth = $$data.get("cellSize") || 3;
    return (
      <Box className={classes.root}>
        <Grid
          container={true}
          spacing={4}
          alignItems="center"
          justify="space-around"
          className={classes.grid}
        >
          {$$data
            .get("items")
            .map((item, idx) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={cellWidth}
                  key={"itm_" + idx}
                  className={classes.cell}
                >
                  <Paper className={classes.paper} elevation={2}>
                    <Avatar
                      className={classes.avatar}
                      src={require("assets/images/" +
                        process.env.REACT_APP_SITE +
                        "/icons/" +
                        item.get("icon") +
                        ".svg")}
                    ></Avatar>
                    <Paragrapher
                      text={item.get("title")}
                      elementClassName={classes.content}
                      cleanNewLines={true}
                    />
                  </Paper>
                </Grid>
              );
            })
            .toArray()}
        </Grid>
      </Box>
    );
  }
}
export default withStyles(styles)(EventIntro);
