import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import EventCard from 'components/UI/Event/EventCard';

const EventCardStyled = withStyles((theme) => ({
  root: {
  },
  liveNavigation: {
    width:'100%',
    '& [class*="MuiButton-root"][class*="MuiButton-containedPrimary"]': {
      width:'100%',
      maxWidth: '350px',
    },
    /*'& [class*="MuiButton-root"][class*="MuiButton-containedPrimary"].liveButton': {
      width:'200px',
    },*/
  },
  eventCategory: {
    display:'none',
  },
}))(EventCard);

class EventCardWrapper extends Component {
  render() {
    const { $$item, itemIndex, classes, kind, variant, isSubscribed } = this.props;
        
    return <EventCardStyled 
      $$item={$$item}
      itemIndex={itemIndex}
      kind={kind}
      variant={variant}
      isSubscribed={isSubscribed}
    />;
  }
}

export default EventCardWrapper;
