import React, { Component } from "react";
import config from "config/api";
import {
  connect
} from "react-redux";
import clsx from 'clsx';
// import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
// import ModuleConfigProvider from "components/UI/ModuleConfigProvider";
// import BlockButton from 'components/UI/BlockButton';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// import FaqItemList from "./FaqItemList";
// import FaqItemBox from "./FaqItemBox";
// import DialogPopinButton from 'components/UI/Dialog/DialogPopinButton';
// import FaqForm from 'components/Forms/FaqForm';
// import Dialog from 'components/UI/Dialog/Dialog';
// import {
//   withRouter
// } from "react-router-dom";
// import { Link } from "react-router-dom";


import {
  getDocumentsError,
  getDocumentsIndex,
  clearDocumentsIndex,
  fetchDocumentsIndex,
} from "./ducks/document";


const styles = (theme) => {
  return {
    root: {
      '& .MuiPaper-root': {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      }
    },
    collection: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(4)
    },
    item: {
      display: 'block',
      [theme.breakpoints.down("lg")]: {
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      '& .MuiSvgIcon-root': {
        display: 'none'
      },
      '&:hover .MuiSvgIcon-root': {
        display: 'inline-block'
      }
    },
    itemOdd: {
    },
    itemEven: {
    },
    itemBullet: {
    },
    itemContent: {
    },
    itemArrow: {
    },
    logo: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 300,
      marginBottom: theme.spacing(2)
    }
  }
}
class DocumentIndex extends Component{
  // constructor(props) {
  //   super(props);
  //   this.state = {};
  //   this._onClick = this._onClick.bind(this);
  // }
  componentDidMount() {
    this.props.onMount();
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }
  render() {
    const {
      $$data,
      $$documents,
      classes,
      openInNewWindow,
    } = this.props;
    // const dense = window.innerWidth >= 1024;
    if ($$documents !== undefined)
    console.log('documents', $$documents.toJS())
    return (
      <Grid
        className={classes.root}
        container={true}
        spacing={0}
        justifyContent={'flex-start'}
        item
        xs={12}
      >
        <If condition={$$documents !== undefined}>
          {$$documents
            .map(($$collection, cIdx) => {
              return (<Grid
                  item
                  xs={12}
                  lg={$$documents.size > 1 ? 6 : 12}
                  key={'c_'+cIdx}
                  className={classes.collection}
                >
                <Paper elevation={2} >
                <Typography variant="h4" gutterBottom>
                {$$collection.get('title')}
                </Typography>
                <If condition={$$collection.get('subTitle')}>
                <Typography variant="body1" gutterBottom>
                {$$collection.get('subTitle')}
                </Typography>
                </If>
                <If condition={$$collection.get('logo') !== undefined}>
                <img className={classes.logo} src={require('assets/images/'+process.env.REACT_APP_SITE+'/documents/'+$$collection.get('logo'))} alt={$$collection.get('title')}/>
                </If>

                {$$collection.get('items').map(($$item, iIdx) => {
                  return (<a key={'i_'+cIdx+'_'+iIdx} href={config.documents+'/get/'+$$item.get('id')} target={openInNewWindow ? '_blank' : undefined} className={clsx(classes.item, classes['item'+((iIdx%2==1) ? 'Odd' : 'Even')])}>
                  <If condition={$$item.get('prefix') !== undefined}>
                    <span className={classes.itemBullet}>{$$item.get('prefix')}</span>
                  </If>
                  <span className={classes.itemContent}>
                  {$$item.get('title').replace(/ \?/, ' ?')}</span>
                  <span className={classes.itemArrow}>
                  <ArrowRightAltIcon />
                  </span>

                </a>)
                }).toArray()}
              </Paper>
            </Grid>);
            }).toArray()}
        </If>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$documents: getDocumentsIndex(state),
    error: getDocumentsError(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchDocumentsIndex());
    },
    onUnmount: () => {
      dispatch(clearDocumentsIndex());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentIndex));
