import React, { Component } from "react";
import Immutable from "immutable";
// import Immutable from 'immutable';
import { withStyles } from "@material-ui/core/styles";
// import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
// import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
import config from "config/api";
import clsx from "clsx";
import i18n from "helpers/i18n";
import Template from "components/Template/Template";
import BlockTitle from "components/UI/BlockTitle";

// import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
// import withWidth from '@material-ui/core/withWidth';
import isArray from "mout/lang/isArray";
// import IconPlay from '@material-ui/icons/PlayCircleFilled';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import AddIcon from '@material-ui/icons/Add';
// import EventIntro from 'components/Event/EventIntro';
// import BlockTitle from 'components/UI/BlockTitle';
// import BlockButton  from 'components/UI/BlockButton';
// import MyEventscontainer={true} from 'components/Event/MyEventsContainer';
// import RemainingTime from 'components/UI/RemainingTime';
import Box from "@material-ui/core/Box";
import Spinner from "components/UI/Spinner";
import Header from "../Header/Header";
import Footer from "../Footer";
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
// import Bubble from 'components/UI/Bubble';
// import HelpSpeedTest from 'components/Help/HelpSpeedTest';
// import PopinWrapper from 'components/PopinWrapper';
// import EventSubscription from 'components/Event/EventSubscription';
// import EventSubscriptionFor from 'components/Event/EventSubscriptionFor';
import EventCardEmbed from "components/Event/EventCardEmbed";
// import DialogPopinButton from 'components/UI/Dialog/DialogPopinButton';
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import {
  fetchData,
  getDataHome,
  getLoadingError,
} from "../../ducks/asyncFetcher";

import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
} from "ducks/authentication";

import { primaryColor } from "helpers/colors";

const AF_KEY = "home";

const styles = (theme) => {
  // console.log(theme)
  return {
    root: {
      minHeight: "100%",
      // display: "flex",
      // flexDirection: "column",
      // "& > *": {
      //   flex: 1,
      // },
      "& .MuiTypography-h3": {
        color: "inherit",
      },
      "& .MuiTypography-h5": {
        marginTop: 0,
      },
    },
    pageTitle: {
      // paddingTop: 16,
      // paddingBottom: 16,
      // color: theme.palette.primary.main,
      // textAlign: 'center',
      // fontSize: '2.2rem',
      // backgroundColor: '#fff',
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(2),
      // boxShadow: theme.shadows[3],
      // [theme.breakpoints.down('md')]: {
      //   fontSize: '1.8rem',
      // },
      // [theme.breakpoints.down('sm')]: {
      //   fontSize: '1.5rem',
      // },
      // [theme.breakpoints.down('xs')]: {
      //   fontSize: '1.3rem',
      // },
      textAlign: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },

    whiteBg: {
      position: "relative",
      marginBottom: theme.spacing(4),
      "&:before": {
        position: "absolute",
        left: 0,
        top: 0,
        content: "' '",
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.paper,
        opacity: 0.9,
      },
    },

    paperWrapper: {
      display: "block",
      width: "100%",
    },
    paper: {
      // padding: theme.spacing(4),
      marginBottom: theme.spacing(4),
      position: "relative",
      backgroundColor: "transparent",
    },

    mainWrapper: {
      // marginTop: "-300px",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      position: "relative",
      zIndex: 9,
      color: "#fff",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        // marginTop: "-270px",
      },
    },
    mainTitle: {
      marginTop: 20,
      textAlign: "center",
      marginBottom: 40,
    },
    livePaper: {
      // color: '#fff',
      margin: "0 auto",
      padding: "40px 20px 50px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 20px",
      },
      // padding: '60px'
    },
    legend: {
      marginBottom: 30,
    },
    loginNav: {
      "& .MuiButton-textSecondary": {
        backgroundColor: "#318EB8",
        color: "#fff",
        fontSize: "1rem",
        margin: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          fontSize: ".8rem",
        },

        // fontWeight: 'bold'
      },
    },

    // cardRoot: {
    //   padding: "50px 40px 30px",
    //   textAlign: "left",
    //   margin: "0 auto 30px",
    //   background: "linear-gradient(278.43deg, #FFCCE7 0%, #30003B 100%)",
    //   position: "relative",
    //   [theme.breakpoints.down("sm")]: {
    //     textAlign: "center",
    //   },
    // },
    // cardImage: {
    //   position: "absolute",
    //   top: 30,
    //   right: 30,
    //   borderRadius: 90,
    //   width: 130,
    //   height: 130,
    //   backgroundRepeat: "no-repeat",
    //   backgroundPosition: "50% 50%",
    //   border: "1px #ccc solid",
    //   [theme.breakpoints.down("sm")]: {
    //     position: "relative",
    //     top: "initial",
    //     right: "initial",
    //     margin: "0 auto 40px",
    //   },
    // },
    // cartTitle: {
    //   fontSize: "33px",
    // },
    // cardContent: {
    //   width: "60%",
    //   margin: "40px 0",
    //   [theme.breakpoints.down("sm")]: {
    //     width: "100%",
    //   },
    // },
    // cardTimeRemaininTitle: {
    //   color: primaryColor,
    //   fontSize: "26px",
    //   marginBottom: 20,
    // },
    // cardButton: {
    //   fontSize: "11px",
    //   padding: "15px 40px",
    //   backgroundColor: "#fff",
    //   margin: "0 auto 20px",
    //   color: primaryColor,
    //   fontWeight: "bold",
    //   letterSpacing: "0.92px",
    //   backgroundImage: "none",
    //   "&:hover": {
    //     color: "#fff",
    //     backgroundColor: primaryColor,
    //     backgroundImage: "none",
    //   },
    // },
    // cardTimeRemainin: {
    //   textAlign: "center",
    //   margin: "0 auto 40px",
    //   [theme.breakpoints.down("sm")]: {
    //     marginBottom: 20,
    //   },
    // },
    // mainContent: {
    //   height: "calc(100% - 103px)",
    // },
  };
};
class Replays extends Component {
  constructor(props) {
    super(props);
    this._gotoLiveRoom = this._gotoLiveRoom.bind(this);
  }
  componentDidMount() {
    this.props.onMount();
    // this._startTimer();
  }
  _gotoLiveRoom() {
    const $$card = this._getCard();
    // console.log('click', $$card.toJS())
    if (
      $$card !== undefined &&
      $$card.get("eventLinkExternal") !== undefined &&
      $$card.get("eventLinkExternal") !== null &&
      $$card.get("eventLinkExternal") !== false
    ) {
      // window.open($$card.get('eventLinkExternal'))
      const url = config.events + "/redir/" + $$card.get("id");
      console.log("redirecting to", url);
      window.open(url);
    }
  }
  _getCard() {
    const { $$data } = this.props;
    return $$data !== undefined &&
      $$data.get("live") !== undefined &&
      $$data.get("live").size > 0
      ? $$data.get("live").get(0)
      : undefined;
  }

  _getCardImage($$card) {
    let cardImage = false;
    // let cardPresentation = false;
    let decodedValues;
    if (
      $$card !== undefined &&
      $$card.get("eventThumb") !== undefined &&
      $$card.get("eventThumb") !== null &&
      $$card.get("eventThumb") !== false
    ) {
      try {
        decodedValues = JSON.parse($$card.get("eventThumb"));
      } catch (e) {}
      // console.log('decoded is', decodedValues)
      if (
        decodedValues !== undefined &&
        isArray(decodedValues) &&
        decodedValues[0] &&
        decodedValues[0].dest
      ) {
        cardImage =
          config.eventthumbpreview.replace("{eventId}", $$card.get("id")) +
          "/" +
          decodedValues[0].dest;
      }
    }
    return cardImage;
  }

  render() {
    const { $$data, userRole, onPanelToggle, classes, $$user, history } =
      this.props;

    const itemsGrid =
      $$data !== undefined &&
      $$data.get("replays") !== undefined &&
      $$data
        // .get('replays').size > 1 ? {xs:12, sm:12, md: 6} : {xs:12, sm:12, md: 12}
        .get("replays").size > 1
        ? { xs: 12, sm: 12, md: 12 }
        : { xs: 12, sm: 12, md: 12 };

    return (
      <Template current="replays">
        <Fade in timeout={1000} style={{ width: "100%" }}>
          <div className={classes.root}>
            <BlockTitle
              title={i18n.get("replaysTitle")}
              className={classes.mainTitle}
            />

            <Box className={classes.paperWrapper}>
              <Paper square elevation={0} className={classes.paper}>
                <Choose>
                  <When
                    condition={$$data !== undefined && userRole === undefined}
                  >
                    <Box my={6} align="center">
                      <Typography
                        variant="h5"
                        gutterBottom={true}
                        className={clsx(classes.legend, classes.h6extends)}
                      >
                        Merci de vous connecter, ou de créer un compte pour
                        accéder à ce contenu
                      </Typography>
                      <Box className={classes.loginNav}>
                        <Button
                          size={"large"}
                          color="secondary"
                          onClick={(e) => {
                            history.push("/loginSimple");
                          }}
                        >
                          {i18n.get("loginFormTitle")}
                        </Button>
                        <Button
                          size={"large"}
                          color="secondary"
                          onClick={(e) => {
                            history.push("/registration");
                          }}
                        >
                          {i18n.get("subscribeButtonTitle")}
                        </Button>
                      </Box>
                    </Box>
                  </When>
                  <When
                    condition={
                      $$data !== undefined &&
                      (userRole === "member" ||
                        userRole === "admin" ||
                        userRole === "staff")
                    }
                  >
                    <div className={classes.mainWrapper}>
                      <If
                        condition={
                          $$data !== undefined &&
                          $$data.get("replays") !== undefined &&
                          $$data.get("replays").size > 0
                        }
                      >
                        <Grid container>
                          {$$data
                            .get("replays")
                            .map(($$card, cIdx) => {
                              return (
                                <EventCardEmbed
                                  key={"c_" + cIdx}
                                  grid={itemsGrid}
                                  $$card={$$card}
                                  showReplay={true}
                                  onReplaySelected={(e) => {
                                    e.preventDefault();
                                    this.props.history.push(
                                      "/replays/" + $$card.get("id"),
                                    );
                                  }}
                                />
                              );
                            })
                            .toArray()}
                        </Grid>
                      </If>
                      <If
                        condition={
                          $$data === undefined ||
                          ($$data !== undefined &&
                            $$data.get("replays") === undefined) ||
                          ($$data !== undefined &&
                            $$data.get("replays") !== undefined &&
                            $$data.get("replays").size === 0)
                        }
                      >
                        <Typography align="center" variant={"h5"}>
                          Pas de replay pour l'instant
                        </Typography>
                      </If>
                    </div>
                  </When>
                  <When
                    condition={$$data !== undefined && userRole === "speaker"}
                  >
                    <p>No place for speaker</p>
                  </When>
                  <Otherwise>
                    <div className={"Loading"}>
                      <Spinner style={{ marginTop: 200 }}>
                        Chargement...
                      </Spinner>
                    </div>
                  </Otherwise>
                </Choose>
              </Paper>
            </Box>
          </div>
        </Fade>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    error: getLoadingError(state),
    isLoggedIn: isLoggedIn(state),
    userRole: getCurrentUserRole(state),
    $$user: getCurrentUser(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },
  };
}

// export default ;
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Replays)),
);
