import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from 'config/api';
import Spinner from 'components/UI/Spinner';
import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import StyledBadge from 'components/UI/StyledBadge/StyledBadge';
import { getAvailableEvents, getReplayEvents } from '../../ducks/asyncFetcher';
//import HomeIntro from 'components/Home/HomeIntro';
import HomeHelp from 'components/Home/HomeHelp';
import News from 'components/UI/News/News';
import Template from 'components/UI/Template/Template';
import EventList from 'components/UI/Event/EventList';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';// import queryString from 'query-string';

import { fetchData, getDataHome, getLoadingError, getSubscriptions } from '../../ducks/asyncFetcher';
import { isLoggedIn, getCurrentUserRole, getCurrentUser, isStateKnown } from 'components/UI/Account/ducks/authentication';
import i18n from 'helpers/i18n';

import { bannerBackgroundColor } from 'helpers/colors';
import HomeReplayCategs from "components/UI/Serie/HomeReplayCategs";
import HomePodcastCategs from "components/UI/PodcastShow/HomePodcastCategs";
import Slide from 'react-reveal/Slide';

const styles = (theme) => {
  return {
    root: {
      minWidth: '100%',
      minHeight: '920px',
    },

    mainWrapper:{
      //margin:'0 auto',
      //maxWidth:'1350px',
      //padding:"0 3% 10px 3%"
    },
    innerWrapper:{
      margin:'0 auto',
      maxWidth:'1350px',
      padding:"0 20px 10px 20px",
    },
    headerBanner: {
      backgroundColor: '#b90066',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontWeight: 600,
      color: '#fff',
      textAlign: 'center'
    },

    itemTitle: {
      color: theme.palette.secondary.main,
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    itemDate: {
      color: '#6d676d',
      width: '100%',
      fontSize: '0.95rem',
      marginBottom: theme.spacing(2),
    },
    itemThumbnail: {
      backgroundColor: '#f1ddfb'
    },
    badge: {
      transform: 'none',
      top: 'auto',
      right: 'auto',
      position: 'relative',
      color: theme.palette.secondary.dark,
      fontWeight: 'bold',
      // top: theme.spacing(-1.5),
      // right: theme.spacing(-1),
      // right: 0,
      // width: 25,
      // height: 25,
      // fontSize: '1rem',
      alignSelf: 'center',
      marginLeft: 16,
      // borderRadius: '180px',
      [theme.breakpoints.down('sm')]: {
        //   top: theme.spacing(-1),
        width: 20,
        height: 20,
        fontSize: '.8rem'
      }
    },
    tabs: {
      width: '100%',
      maxWidth: 1280,
      //marginLeft: 'auto',
      marginRight: 'auto',
      //paddingLeft: theme.spacing(2),
      //paddingRight: theme.spacing(2),
      overflow: 'visible',
      marginBottom: theme.spacing(3),
      // flexGrow: 1,
      // borderBottom: '1px '+theme.palette.text.light+' solid',
      '& [class*="MuiTabs-indicator"]': {
        backgroundColor: theme.palette.primary.main,
        height: 3,
      },
    },
    tabTitle:{
      marginLeft:'7px',
    },
    tab: {
      '&[class*="Mui-selected"]': {
        color: theme.palette.secondary.dark
      },
      color: '#858B8E',
      // [theme.breakpoints.down('sm')]: {
      //   marginBottom: theme.spacing(0),
      // },
      '& [class*="MuiTypography-h3"]': {
        color:'#333',
        marginBottom: 0,
        fontWeight: 600,
        fontSize:'18pt',
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.1rem',
          // backgroundColor: 'red',
          // marginBottom: theme.spacing(0.5)
          // textAlign: 'center'
        }
      },
      '& [class*="MuiBadge-badge"]':{
        //backgroundColor:theme.palette.primary.main,
        color:'#fff',
      },
      textTransform: 'none',
      position: 'relative',
      maxWidth: 'none',
      overflow: 'visible',
      padding: 0,
      marginRight: theme.spacing(5),
      // paddingTop: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        // marginRight: 'auto',
        // marginLeft: 'auto',
        // paddingLeft: 22,
        // marginRight: theme.spacing(0),
        // marginBottom: theme.spacing(2),
        // flexGrow: 1,
        // borderBottom: '2px '+theme.palette.secondary.main+' solid'
      },
      [theme.breakpoints.down('xs')]: {
        // marginRight: 'auto',
        // marginLeft: 'auto',
        //paddingLeft: 22,
        //marginRight: theme.spacing(0),
        //marginBottom: theme.spacing(2),
        // flexGrow: 1,
        // borderBottom: '2px '+theme.palette.secondary.main+' solid'
      },

    },
    tabPanel: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
    singleTab: {
      borderBottom: 'none',
      '& [class*="MuiTabs-indicator"]': {
        display: 'none'
      }
    },

    /*newsBgDefault: {
      backgroundColor: bannerBackgroundColor,
      backgroundImage:'url('+(require('assets/images/banner_bg.svg'))+')',
      backgroundSize: 'cover',
    },*/
    eventsMainDiv:{
      overflow:'hidden',
      //width: '100%',
      //padding:'0 5%',
      //margin:'0 auto',
      //maxWidth:'1350px',
      //padding:"0 3% 10px 3%",

      [theme.breakpoints.down('sm')]: {
        padding:'0 4%',
      },
      [theme.breakpoints.down('xs')]: {
        padding:'0 2%',
      },
    },
    eventsSubDiv:{
      width: '100%',
    },
    textTitle:{
      textAlign:'left',
      marginLeft:0,
      padding:'1em 0 1em 0',
      textTransform: 'none',
    },
    textDescription:{
      fontWeight:'bold',
      textAlign:'left',
      //padding:'0 5%',
      paddingBottom:'2em',
      marginBottom:'2em',
      marginTop:'2em',
      borderBottom:'#aaa 1px solid',
      /*'& [class*="MuiTypography-body2"]':{
        color:'red !important',
      },*/
    },
    categsWrapper:{
      //margin:'0 auto',
      //maxWidth:'1350px',
      //padding:"0 3% 50px 3%",
      marginBottom:'50px',
    }
  };
};

const AF_KEY = 'home';

const NewsStyled = withStyles((theme) => {
  return {
    root: {
      //maxWidth: 1280,//maj fullwidth
      marginBottom: '10px',
      '& [class*="MuiCardContent-root"]': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& [class*="SwipeableTextMobileStepper-card"]': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& [class*="MuiMobileStepper-dotActive"]': {
        backgroundColor: '#fff',
      },
      '& [class*="MuiPaper-root"]': {
        paddingTop: 0,
      },
      '& [class*="MuiCardContent-root"]': {
        padding: 0,
      },
    },
    wrapper: {
      // backgroundColor: theme.palette.secondary.main,
      // backgroundImage: 'url('+require('assets/images/header_news.png')+')',
      // backgroundSize: 'cover',
      // backgroundPosition: '50%',
        padding:'0',
    },
    buttonNav: {
      backgroundColor: '#fff',
      color: theme.palette.secondary.dark,
    },
    background: {
      backgroundColor: 'transparent',
    },
    newsWrapper: {
      color: '#fff',
      paddingLeft: theme.spacing(6),
      backgroundColor: 'transparent',
      //minHeight:'435px',
      gap: 0,
      //alignItems: 'stretch',
      //alignContent: 'stretch',
      /*
      '& [class*="MuiTypography-h2"]': {
        color: '#fff',
        textTransform:'none',
        textAlign:'left',
        fontFamily: 'Montserrat',
        fontSize: '36px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '140%',
      },*/
    },
    newsWrapperPadded: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(0),
    },
    newsHeader: {
      textTransform: 'none',
      fontSize:'110%',
      marginBottom: 0,
      paddingTop:0,
      marginTop:'-5px',
      '& [class*="MuiTypography-caption"]': {
        color: '#fff',
        textTransform: 'none',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        borderRadius: 35,
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(1),
      },
    },
    newsSeparator: {
      verticalAlign:'sub',
      fontSize:'150%',
      border: 'none',
      width: 'auto',
      height: 'auto',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
    mediaWrapper: {
      marginTop: theme.spacing(0),
      //minHeight:config.newsHeight+'px',
    },
    media: {
      //clipPath: 'circle(73.6% at 80% 49%)',
      //minHeight:config.newsHeight+'px',
      //maxHeight:'200px',
      //objectFit: 'cover',
      //width: '50vw',
    },
    /*
    buttonLeft: {
      left: '5%',
    },
    buttonRight: {
      right: '5%',
    },
    wrapper:{
      padding:'0 6%',
    },*/
  };
})(News);

/*
const HomeIntroStyled = withStyles(theme => ({
}))(HomeIntro)
*/
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      >
      {value === index && (
        <React.Fragment>{children}</React.Fragment>
      )}
    </div>
  );
}
// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

class HomeClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePanel: 0,
      counterAvailableEvents: 0,
    }
  }
  componentDidMount() {
    // const { classes } = this.props;
    this.props.onMount();
    // document.body.classList.add(classes.withBg);
    // this._onWindowResize();
    // window.addEventListener('resize', this._onWindowResize);
    if (this.props.section) this.scrollToSection(this.props.section)
  }

  componentDidUpdate(oldProps) {
    // if (oldProps.$$data !== this.props.$$data) {
    //   this._onWindowResize();
    // }
    // if (oldProps.section !== this.props.section || oldProps.$$data === undefined && this.props.$$data !== undefined)
    // this.scrollToSection(this.props.section)
  }

  componentWillUnmount() {
    // console.log('unmount')
    // window.removeEventListener('resize', this._onWindowResize);
  }
  scrollToSection = (section) => {
    const elem = document.getElementById(section);
    if (!elem) return
    let rect = elem.getBoundingClientRect();

    window.scroll({
      top: rect.y,
      left: 0,
      behavior: 'smooth',
    });

  }
  // _onWindowResize = () => {
  //   const { classes } = this.props;
  //   const el = document.querySelector('#root');
  //   // console.log('resize', el.offsetHeight, window.innerHeight);
  //   el.style.height = 'auto';
  //   if (el.offsetHeight <= window.innerHeight) {
  //     // console.log('fixing');
  //     el.style.height = window.innerHeight + 'px';
  //   } else {
  //     // console.log('releasing');
  //     el.style.height = 'auto';
  //   }
  // };
  onPanelChange = (event, newValue) => {
    this.setState({activePanel: newValue})

  }
  onCounterChange = (kind, value) => {
    // console.log('counter changed!', kind, value);
    this.setState({counterAvailableEvents: value})
  }
  onReplaysLoaded = () => {
    if (this.props.section === 'replays') {
      // this.scrollToSection('replays')
      }
  }
  render() {
    const { $$data, classes, $$subscriptions, $$availableEvents, $$replayEvents } = this.props;
    const { activePanel, counterAvailableEvents, counterMyEvents } = this.state;
    const viewEventReplays=false;

    let newsCover;
    //let newsBgClass;
    //newsBgClass = classes.newsBgDefault;
    /*
    newsCover = {
      title: 'Des rendez-vous réguliers\n& interactifs avec les \n[b]experts de votre spécialité[/b]',
      thumb: require('assets/images/header_news_intro.png'),
    };*/

    return (
      <Template current="home" kind={'fullWidth'} maxWidth={'auto'} headerBanner={<Grid item xs={12} className={classes.headerBanner}>{/*<LocalHospitalIcon /> */}{i18n.get('homeBanner', {pretty: false})}</Grid>}>
      <Grid sm={12}>
      {
        /*<Template current="home" kind={'fullWidth'} maxWidth={'auto'} headerBanner={<Grid item xs={12} className={classes.headerBanner}><LocalHospitalIcon /> {i18n.get('homeBanner', {pretty: false})}</Grid>}>*/
    }
        <Choose>
          <When condition={$$data !== undefined}>
            <ScrollToTopOnMount />
            <NewsStyled
                  $$data={$$data}
                  newsSeparator={'•'}
                  newsCover={newsCover}
                  universe={undefined}
                  
                />{/*classes={{ wrapper: newsBgClass }}*/}



        
          <Box className={classes.mainWrapper}>
          {/*<Typography variant="h2" component="div" gutterBottom className={classes.textTitle}>A propos de CanalPneumo</Typography>*/}
          {/*
          <Box className={classes.innerWrapper} style={{height: 'auto', overflow: 'hidden'}}>
          <Slide bottom delay={200}>
          <Typography variant="body1" className={classes.textDescription}>
          Bienvenue sur Canal Pneumo, votre plateforme pour une information scientifique de qualité autour de 4 grandes thématiques : la BPCO, l’Asthme, l’Innovation en Pneumologie et le Parcours de soins du patient.<br/><br/>
          Vous souhaitez profiter de contenus scientifiques de qualité et disponibles quand vous vous voulez ?<br/>
          Webinaires en live, vidéos et mini-séries en replay, émissions de podcasts...<br/><br/>
          Inscrivez-vous et rejoignez les 1500 professionnels de santé membres de la communauté Canal Pneumo.
          </Typography>
          </Slide>
          </Box>
          */}
          <Box className={classes.innerWrapper}>
            <Box className={classes.eventsMainDiv} style={{height: 'auto', overflow: 'hidden'}}>
            <Hidden xsDown>
              <div className={classes.eventsSubDiv}>
              <If condition={($$availableEvents !== undefined && $$availableEvents.size > 0) || ($$subscriptions !== undefined && $$subscriptions.size > 0 && counterAvailableEvents >0)}>
                <Tabs value={activePanel} onChange={this.onPanelChange} aria-label="Tabs" className={clsx(classes.tabs, {[classes.singleTab]: $$subscriptions === undefined || $$subscriptions.size === 0})}>
                  <If condition={$$availableEvents !== undefined && $$availableEvents.size > 0}>
                    <Tab label={
                        <Typography variant="h2" component="div" className={classes.tabTitle}><StyledBadge classes={{badge: classes.badge}} badgeContent={counterAvailableEvents} color="primary">{i18n.get('homeAvailableEvents', {pretty: false})}</StyledBadge></Typography>
                      } overlap="rectangular" className={classes.tab}/>
                    </If>
                    <If condition={$$subscriptions !== undefined && $$subscriptions.size > 0 && counterAvailableEvents >0}>
                      <Tab label={
                          <Typography variant="h2" component="div" className={classes.tabTitle}><StyledBadge classes={{badge: classes.badge}} badgeContent={$$subscriptions !== undefined ? $$subscriptions.size : 0} color="primary">{i18n.get('homeYourEvents', {pretty: false})}</StyledBadge></Typography>
                        } className={classes.tab}/>
                      </If>
                    </Tabs>
                  </If>
                  <TabPanel value={activePanel} index={0} className={classes.tabPanel}>
                    <EventList showCount={true} kind="events" eventCardClasses={{itemTitle: classes.itemTitle, itemDate: classes.itemDate, itemThumbnail: classes.itemThumbnail}} onCounterChange={this.onCounterChange} />
                  </TabPanel>
                  <TabPanel value={activePanel} index={1} className={classes.tabPanel}>
                    <EventList showCount={true} kind="events" itemFilter={($$item) => $$subscriptions.contains($$item.get('id'))} eventCardClasses={{itemTitle: classes.itemTitle, itemDate: classes.itemDate, itemThumbnail: classes.itemThumbnail}} />
                  </TabPanel>
                  </div>
                </Hidden>
                <Hidden smUp>
                  <If condition={$$availableEvents !== undefined && $$availableEvents.size > 0}>
                    <div className={classes.tab}>
                      <Typography variant="h3" component="div"><StyledBadge classes={{badge: classes.badge}} badgeContent={counterAvailableEvents} color="primary">{i18n.get('homeAvailableEvents', {pretty: false})}</StyledBadge></Typography>
                    </div>
                  </If>

                  <div className={classes.eventsSubDiv}>
                    <TabPanel value={99} index={99} className={classes.tabPanel}>
                    <EventList showCount={true} kind="events" eventCardClasses={{itemTitle: classes.itemTitle, itemDate: classes.itemDate, itemThumbnail: classes.itemThumbnail}} onCounterChange={this.onCounterChange} />
                  </TabPanel>
                  </div>

                  <If condition={$$replayEvents !== undefined && $$replayEvents.size > 0}>
                    <div className={classes.tab}>
                      <Typography variant="h3" component="div"><StyledBadge classes={{badge: classes.badge}} badgeContent={counterMyEvents} color="primary">{i18n.get('homeYourEvents', {pretty: false})}</StyledBadge></Typography>
                    </div>
                  </If>

                  <TabPanel value={99} index={99} className={classes.tabPanel}>
                    <EventList showCount={true} kind="events" itemFilter={($$item) => $$subscriptions.contains($$item.get('id'))} eventCardClasses={{itemTitle: classes.itemTitle, itemDate: classes.itemDate, itemThumbnail: classes.itemThumbnail}}  />
                  </TabPanel>
                </Hidden>
                <If condition={viewEventReplays}>
                  <EventList title={i18n.get('homeReplays', {pretty: false})} showCount={true} kind="replays" onCounterChange={this.onReplaysLoaded} eventCardClasses={{itemTitle: classes.itemTitle, itemDate: classes.itemDate, itemThumbnail: classes.itemThumbnail}}/>
                </If>
                {/*<Box mb={4} width={'100%'}>
                  <HomeHelp/>
                </Box>*/}
                </Box>
                </Box>

                <Box className={classes.categsWrapper}>
                  <HomeReplayCategs/>
                  <HomePodcastCategs/>
                </Box>

                {/*<HomeIntroStyled $$intro={$$data.get('intro').get('items')}/>*/}
                </Box>
              </When>
              <Otherwise>
                <Spinner>{i18n.get('loading')}</Spinner>
              </Otherwise>
            </Choose>
            </Grid>
          </Template>
        );
      }
    }

    function mapStateToProps(state) {
      const props = {
        $$data: getDataHome(state),
        error: getLoadingError(state),
        isLoggedIn: isLoggedIn(state),
        userRole: getCurrentUserRole(state),
        authStateKnown: isStateKnown(state),
        $$user: getCurrentUser(state),
        $$subscriptions: getSubscriptions(state),
        $$availableEvents: getAvailableEvents(state),
        $$replayEvents: getReplayEvents(state),

      };
      return props;
    }
    function mapDispatchToProps(dispatch) {
      return {
        onMount: () => {
          dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
        },
      };
    }

    const HomeWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HomeClass)));

    export default HomeWrapper;

