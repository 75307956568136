import React, { Component } from "react";
import Immutable from "immutable";
// import Hidden from '@material-ui/core/Hidden';
// import Immutable from 'immutable';
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import { connect } from 'react-redux';
// import config from 'config/api'
import Grid from "@material-ui/core/Grid";
import { formatDate } from "helpers/stringUtils";
import EventSubscriptionRecapSingle from "components/Event/EventSubscriptionRecapSingle";
// import withWidth from '@material-ui/core/withWidth';
// import isArray from 'mout/lang/isArray';
import combine from "mout/array/combine";
import filter from "mout/array/filter";
// import IconPlay from '@material-ui/icons/PlayCircleFilled';
// import Paper from '@material-ui/core/Paper';
// import AddIcon from '@material-ui/icons/Add';
// import EventIntro from 'components/Event/EventIntro';
import BlockTitle from "components/UI/BlockTitle";
// import BlockButton  from 'components/UI/BlockButton';
// import MyEventsContainer from 'components/Event/MyEventsContainer';
// import EventCardSimple from "components/Event/EventCardSimple";
// import RemainingTime from 'components/UI/RemainingTime';
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import EventSubscription from "components/Event/EventSubscription";
// import EventSubscriptionFor from 'components/Event/EventSubscriptionFor';
// import DialogPopinButton from 'components/UI/Dialog/DialogPopinButton';
// import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
// import Spinner from 'components/UI/Spinner';
// import Header from '../Header/Header';
// import Footer from '../Footer';
// import LiveHelp from './LiveHelp';
// import ScrollToTopOnMount from 'components/UI/ScrollToTopOnMount';
// import Bubble from 'components/UI/Bubble';
// import HelpSpeedTest from 'components/Help/HelpSpeedTest';
import PopinWrapper from "components/PopinWrapper";
import EventSubscriptionVisitor from "components/Event/EventSubscriptionVisitor";
import EventSubscriptionFor from "components/Event/EventSubscriptionFor";
import DialogPopinButton from "components/UI/Dialog/DialogPopinButton";
// import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";
// import {primaryColor} from 'helpers/colors';
import i18n from "helpers/i18n";
import { primaryColor, secondaryColor } from "../../helpers/colors";
const SINGLE_EVENT_SUBSCRIPTION = false;
const styles = (theme) => {
  // console.log(theme)
  return {
    root: {
      maxWidth: 1280,
      margin: "0 auto 30px",
    },
    subTitle: {
      margin: "60px 0 50px",
      "& > h2": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          width: "80%",
          margin: "0 auto",
        },
      },
    },
    selectorRoot: {
      padding: "20px 40px 30px",
      textAlign: "left",
      margin: "0 auto",
      // background: 'linear-gradient(278.43deg, #FFCCE7 0%, #30003B 100%)',
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    dateFull: {
      // backgroundImage:
      // 'url(' + require('@assets/images/' + process.env.REACT_APP_SITE + '/bubble_disabled.png') + ')',
    },

    // titleEmphasis: {
    //   fontSize: '120%',
    //   fontWeight: 'bold'
    // },
    // selectorTitle: {
    //   color: theme.palette.text.secondary,
    //    color: theme.palette.text.primary,
    //   display: 'block',
    // },
    dateFullLegend: {
      // position: 'absolute',
      // top: 20,
      // padding: '5px 10px',
      // right: '-30px',
      // transform: 'rotate(35deg)',
      // fontSize: '1.5rem',
      // backgroundColor: theme.palette.secondary.main,
      // color: theme.palette.secondary.contrastText
      color: theme.palette.primary.main,
      fontWeight: "bold",
      width: "100%",
      fontSize: "1.2rem",
    },

    dateItem: {
      cursor: "pointer",
    },
    dateWrapper: {
      position: "relative",
      // border: "1px #fff solid",
      border: "2px " + secondaryColor + " solid",
      // color: '#fff',
      backgroundColor: "#f7f8fa",
      color: theme.palette.primary.main,
      // color: theme.palette.primary.main,
      // border: '1px '+theme.palette.primary.main+' solid',
      padding: theme.spacing(1),
      // minHeight: 140,
      display: "flex",
      flexDirection: "column",
      transition: [
        theme.transitions.create("background-color", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        theme.transitions.create("color", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      ],
      "& .MuiTypography-body1": {
        // fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "1.17px",
        fontStyle: "normal",
        // color: theme.palette.common.black,
        // fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
          lineHeight: "16px",
        },
      },
      "& .MuiTypography-subtitle1": {
        fontSize: "24px",
        lineHeight: "29px",
        fontWeight: "bold",
        minHeight: 70,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          lineHeight: "16px",
        },
      },
    },
    eventHour: {
      position: "relative",
      paddingBottom: 16,
      fontWeight: 700,
      fontSize: "1rem",
      "&:after": {
        content: '" "',
        position: "absolute",
        bottom: 0,
        width: 20,
        left: "calc(50% - (20px/2))",
        height: 2,
        borderBottom: "2px " + primaryColor + " solid",
      },
    },
    dateWrapperSelected: {
      // backgroundColor: '#fff',
      // color: theme.palette.primary.main
      // backgroundColor: theme.palette.secondary.main,
      color: "#fff",
      boxShadow: theme.shadows[9],
      "& .MuiTypography-body1": {
        color: "#fff",
        "&:after": {
          borderBottom: "2px #fff solid",
        },
      },
      "& .MuiBox-root": {
        // backgroundColor: "#fff",
      },
    },
    dateWrapperActions: {
      padding: "0",
    },
    dateWrapperActionsButton: {
      transition: [
        theme.transitions.create("color", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        theme.transitions.create("opacity", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      ],
      fontWeight: "bold",
      borderRadius: "0 20px 20px 0",
      fontSize: "16px",
      letterSpacing: "1px",
      padding: "10px 30px",
      display: "flex",
      margin: "0 auto",
    },
    subscriptionState: {
      position: "absolute",
      right: 5,
      top: 5,
    },
    inline: {
      display: "inline-block",
    },
    disabled: {
      opacity: 0.5,
    },
  };
};
class EventSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCard: 0,
      selected: [],
    };
    this._redirTo = this._redirTo.bind(this);
    this._onToggleCardSelect = this._onToggleCardSelect.bind(this);
    // this._onSubscriptionComplete = this._onSubscriptionComplete.bind(this);
    // this._setIsLogginIn = this._setIsLogginIn.bind(this);
    this._onSelectionComplete = this._onSelectionComplete.bind(this);
  }
  componentDidMount() {
    this._setSubscriptions();
  }
  componentDidUpdate(oldProps, oldState) {
    if (
      oldState.selected.length === 0 &&
      oldProps.$$subscriptions.size !== this.props.$$subscriptions.size
    ) {
      this._setSubscriptions();
    }
  }
  _onSelectionComplete(step) {
    const { onSelectionComplete } = this.props;
    const $$selectedCards = this._getSelectedCards();
    onSelectionComplete($$selectedCards);
  }
  _setSubscriptions() {
    const { $$subscriptions } = this.props;
    if ($$subscriptions.size > 0) {
      let arr = [];
      $$subscriptions.map(($s) => arr.push($s));
      this.setState({
        selected: arr,
      });
    }
  }
  _redirTo(url) {
    const { history } = this.props;
    history.push(url);
  }
  _getCard() {
    const { $$data } = this.props;
    const { currentCard } = this.state;
    if ($$data === undefined) return false;
    else {
      const c = $$data.filter((d) => d.get("id") === currentCard).get(0);
      if (c !== undefined) {
        return c;
      } else if ($$data.get(0) !== undefined) {
        return $$data.get(0);
      } else return false;
    }
  }
  _onToggleCardSelect(cardId) {
    const { selected } = this.state;
    let newSelected = combine([], selected);
    const k = newSelected.indexOf(cardId);
    // console.log('found?',k, cardId, newSelected)
    // console.log('l',selected.length)
    if (SINGLE_EVENT_SUBSCRIPTION === true) newSelected = [];
    if (k >= 0) newSelected = filter(newSelected, (s) => s !== cardId);
    else newSelected.push(cardId);
    // console.log('l',newSelected.length)
    this.setState({
      selected: newSelected,
      currentCard: cardId,
    });
  }
  // _setIsLogginIn() {
  //   this.setState({ isLoggingIn: true });
  // }
  // _onSubscriptionComplete(r) {
  //   const { onSubscriptionComplete } = this.props;
  //   this.setState(
  //     {
  //       isLoggingIn: false,
  //     },
  //     () => {
  //       onSubscriptionComplete(r);
  //     }
  //   );
  // }
  _getSelectedCards($) {
    const { $$data } = this.props;
    const { selected } = this.state;
    const $$selectedCards =
      $$data !== undefined
        ? $$data.filter((d) => selected.indexOf(d.get("id")) >= 0)
        : Immutable.fromJS([]);
    return $$selectedCards;
  }
  render() {
    const { $$data, classes, $$subscriptions, $$user, $$pastEvents } =
      this.props;
    const { selected, isLoggingIn } = this.state;
    const $$card = this._getCard();
    const $$selectedCards = this._getSelectedCards();
    const userRole = $$user !== undefined ? $$user.get("role") : undefined;
    const userSubRole =
      $$user !== undefined ? $$user.get("subRole") : undefined;
    // console.log($$selectedCards.toJS())
    // if ($$subscriptions !== undefined)
    // let isSubscribed;
    // console.log(isLoggingIn)
    let isSelected;
    let subscribed;
    const $pastEvent = undefined;
    // const $pastEvent = $liveEvent === undefined ? this._getMyPastEvent() : undefined;
    // if ($$pastEvents !== undefined) console.log($$pastEvents.toJS())
    const themeColor = $$card.get("themeColor");
    return (
      <React.Fragment>
        <div className={classes.root}>
          <Box className={classes.selectorRoot}>
            <If condition={$$data !== undefined && $pastEvent !== undefined}>
              <Box align="middle" mt={-3} mb={4} bgcolor="common.white">
                <Typography variant="subtitle2" gutterBottom={true}>
                  Vous etiez inscrit(e) à la session suivante:
                </Typography>
                <Typography
                  variant="h6"
                  className={clsx(classes.legend, classes.emphasis)}
                  gutterBottom={true}
                >
                  {formatDate($pastEvent.get("eventDate"), "dddd dd mmmm yyyy")}{" "}
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.legend}
                  gutterBottom={true}
                >
                  {formatDate($pastEvent.get("eventDate"), "HH'h'MM")}
                </Typography>

                <Choose>
                  <When
                    condition={
                      $pastEvent.get("hasAttended") === undefined &&
                      $pastEvent.get("hasAttended") === "" &&
                      $pastEvent.get("hasAttended") === null
                    }
                  >
                    <Typography variant="h6" gutterBottom={true}>
                      <span className={classes.inline}>
                        La conférence est terminée, mais vous n'y avez pas
                        assisté.
                      </span>
                      <span className={classes.inline}>
                        Vous pouvez donc vous inscrire à une nouvelle
                        conférence&nbsp;!
                      </span>
                    </Typography>
                    <Button
                      size={"large"}
                      onClick={(e) => {
                        e.preventDefault();
                        this._goto("/subscribe");
                      }}
                      className={classes.buttonLarge}
                    >
                      Modifier ma session
                    </Button>
                  </When>
                  <Otherwise>
                    <Typography variant="h6" gutterBottom={true}>
                      La conférence est terminée !
                    </Typography>
                  </Otherwise>
                </Choose>
              </Box>
            </If>

            <If condition={$$data !== undefined && $pastEvent === undefined}>
              <Grid
                container={true}
                spacing={2}
                alignItems="center"
                justify="center"
              >
                {$$data
                  .map(($$card, cardIdx) => {
                    // isSubscribed = $$subscriptions.contains($$card.get('id'));
                    isSelected = selected.indexOf($$card.get("id")) >= 0;
                    subscribed = $$subscriptions.contains($$card.get("id"));
                    return (
                      <Grid
                        key={"k_" + cardIdx}
                        item={true}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        className={classes.dateItem}
                      >
                        <Box
                          align="center"
                          className={clsx(classes.dateWrapper, {
                            [classes.dateWrapperSelected]: isSelected,
                            [classes.dateFull]: $$card.get("isFull") === 1,
                          })}
                          style={{
                            backgroundColor: isSelected
                              ? secondaryColor
                              : undefined,
                            color: isSelected ? undefined : secondaryColor,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              $$card.get("isFull") === 1 &&
                              subscribed !== true
                            )
                              return;
                            else if ($$card.get("eventState") === "delayed")
                              return;
                            else this._onToggleCardSelect($$card.get("id"));
                          }}
                        >
                          <If condition={isSelected === true}>
                            <Typography
                              variant={"caption"}
                              gutterBottom={true}
                              className={classes.subscriptionState}
                            >
                              <CheckBoxIcon />
                            </Typography>
                          </If>
                          <If condition={isSelected !== true}>
                            <Typography
                              variant={"caption"}
                              gutterBottom={true}
                              className={classes.subscriptionState}
                            >
                              <CheckBoxOutlineBlankIcon />
                            </Typography>
                          </If>

                          <Typography
                            variant={"body1"}
                            className={classes.eventHour}
                          >
                            <span className={classes.inline}>
                              {formatDate($$card.get("eventDate"), "HH'h'MM")}
                            </span>
                          </Typography>
                          <Typography
                            variant={"subtitle1"}
                            style={{
                              fontWeight: 700,
                              fontSize: "1rem",
                            }}
                          >
                            {formatDate(
                              $$card.get("eventDate"),
                              "dddd dd mmmm yyyy",
                            )}
                          </Typography>

                          <If condition={$$card.get("isFull") === 1}>
                            <Box className={classes.dateFullLegend}>
                              Complet !
                            </Box>
                          </If>
                          <If
                            condition={$$card.get("eventState") === "delayed"}
                          >
                            <Box className={classes.dateFullLegend}>
                              Emission reportée !
                            </Box>
                          </If>
                        </Box>
                      </Grid>
                    );
                  })
                  .toArray()}
                <Grid
                  key={"k_action"}
                  item={true}
                  xs={12}
                  className={classes.dateItem}
                >
                  <Box className={clsx(classes.dateWrapperActions)}>
                    <If
                      condition={
                        userRole === undefined ||
                        userRole === "visitor" ||
                        isLoggingIn
                      }
                    >
                      <Button
                        disabled={selected.length === 0}
                        className={clsx(classes.dateWrapperActionsButton, {
                          [classes.disabled]: selected.length === 0,
                        })}
                        endIcon={<ArrowRightAltIcon />}
                        onClick={this._onSelectionComplete}
                        size="large"
                      >
                        Etape suivante
                      </Button>
                    </If>
                    <If
                      condition={
                        (userRole === "member" || userRole === "staff") &&
                        !isLoggingIn
                      }
                    >
                      <Button
                        disabled={selected.length === 0}
                        className={clsx(classes.dateWrapperActionsButton, {
                          [classes.disabled]: selected.length === 0,
                        })}
                        endIcon={<ArrowRightAltIcon />}
                        onClick={this._onSelectionComplete}
                        size="large"
                      >
                        {i18n.get("subscriptionSelfButton", { pretty: false })}
                      </Button>
                    </If>
                    <If condition={userRole === "admin" && !isLoggingIn}>
                      <Button
                        disabled={selected.length === 0}
                        className={clsx(classes.dateWrapperActionsButton, {
                          [classes.disabled]: selected.length === 0,
                        })}
                        endIcon={<ArrowRightAltIcon />}
                        onClick={this._onSelectionComplete}
                        size="large"
                      >
                        Inscrire un participant
                      </Button>
                    </If>
                  </Box>
                </Grid>
              </Grid>
            </If>
          </Box>
        </div>
      </React.Fragment>
    );
  }
}
// export default ;
export default withRouter(withStyles(styles)(EventSelector));
