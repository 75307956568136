import React, { Component } from "react";
import i18n from "helpers/i18n";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const AF_KEY = "home";
const styles = (theme) => {
  return {
    titleSmall: {
      fontSize: "2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
        textAlign: "center",
      },
    },
    headerImage: {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        textAlign: "center",
      },
    },
  };
};

class HeaderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLogginIn: false,
    };
  }

  render() {
    const { classes } = this.props;
    if (process.env.REACT_APP_SITE === "neonat")
      return (
        <Grid container spacing={0} alignItems="center" justify="space-around">
          <Grid item xs={12} sm={4}>
            <Box className={classes.headerImage}>
              <img
                width="180"
                src={require("assets/images/" +
                  process.env.REACT_APP_SITE +
                  "/header.png")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box align="left">
              <Typography
                variant={"h1"}
                color="primary"
                className={classes.titleSmall}
              >
                <div>{i18n.get("homeTitle1", { pretty: false })}</div>
                <div>{i18n.get("homeTitle2", { pretty: false })}</div>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    else if (process.env.REACT_APP_SITE === "transplant")
      return (
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="space-around"
          style={{ marginTop: 40, paddingBottom: 20 }}
        >
          <Grid item xs={12} sm={5}>
            <Box className={classes.headerImage}>
              <img
                width="180"
                src={require("assets/images/" +
                  process.env.REACT_APP_SITE +
                  "/header.png")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box align="left">
              <Typography
                variant={"h1"}
                color="primary"
                className={classes.titleSmall}
              >
                <div>{i18n.get("homeTitle1", { pretty: false })}</div>
                <div>{i18n.get("homeTitle2", { pretty: false })}</div>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    else return <React.Fragment></React.Fragment>;
  }
}

export default withStyles(styles)(HeaderContent);
