import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Loading from './Loading';

const styles = makeStyles((theme) => {
  return {
    root: {
    },
    status: {
      backgroundColor:'#f9f7a5',
      border:'#f9df10 1px solid',
      padding:'3px 6px',
      margin:'3px 0',
      color:'#333',
      borderRadius: '3px',
    },
  };
});

const labels={
  queued:"En file d'attente...",
  in_progress:"Requête GPT en cours...",
  requires_action:"Attente requête API...",
  completed:"",
  expired:"",
  failed:"Échec API OpenAI",
  cancelling:'En annulation',
  cancelled:'Annulé',
};

export default function RunStatus({ status, metadata, last_error }) {
  const classes = styles();
  if(status===null)
    return null;
  let text=labels[status]!==undefined ? labels[status] : "Inconnu ("+status+")";

  let loading=status=="queued" || status=="in_progress" || status=="requires_action";
  if(status=="requires_action" && metadata.tool_status!==undefined){
    if(metadata.tool_status=="pending"){
      text="Requête API en cours...";
    }else if(metadata.tool_status=="finished"){
      text="Requête API terminée";
    }
  }
  if(text=='')
    return null;

  const icon= loading ? <Loading size={20} text={false} inline={true}/> : null;
  const error=status=="failed" ? <Box><Box>Code : {last_error.code}</Box><Box>Message : {last_error.message}</Box></Box> : null;
  return (
    <Box className={classes.root}>
    <Box className={clsx(classes.status, classes[status])}>
        <Box>
          {icon}
          {text}
        </Box>
        {error}
      </Box>
    </Box>
  );
}
