import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Context } from './Context';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import apiFetch from './api';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = makeStyles((theme) => {
  return {
    root: {
      textAlign:'left',
    },
    item:{
      cursor:'pointer',
    },
    selected:{
      fontWeight:'bold',
    },
    deleting:{
      color:'grey',
      cursor:'wait',
    },
    deleteIcon:{
      color:'#777',
      '&:hover':{
        color:'#ffa700',
      },
    }
  };
});



export default function ThreadItem({ id, name }) {
  const classes = styles();
  const {currentThread, setCurrentThread} = useContext(Context);
  const [style, setStyle] = React.useState({display: 'none'});
  const [deleting, setDeleting] = React.useState(false);

  const deleteThread = (event) => {
    console.log(event);
    setDeleting(true);
    apiFetch("delete_thread", {id:id})
      .then((data) => {
        //console.log('fetchData ok', data);
        if(currentThread==id)
          setCurrentThread(null);
      })
      .catch(({ response, error }) => {
        console.log('fetchData error', error, response);
        setDeleting(false);
      });
  };

  const label=deleting ? "Suppression..." : (name!=id ? name : "Nouvelle discussion...");

  return (
    <div className={classes.root}>
      <Box my={1} align="left" className={clsx(classes.item, id===currentThread ? classes.selected : null, deleting ? classes.deleting : null)}  onMouseEnter={e => {setStyle({display: 'block'});}} onMouseLeave={e => {setStyle({display: 'none'})}}>
      <Grid container spacing={0} alignItems="center" justifyContent="flex-start">
        <Grid item xs={10}  className="" onClick={(e)=>setCurrentThread(id)}>
          {label}
          </Grid>
        <Grid item xs={2} className="">
          <DeleteIcon title="Supprimer la discussion" className={classes.deleteIcon} style={style} onClick={(e)=>deleteThread(e)}/>
        </Grid>
        </Grid>

      </Box>
    </div>
  );
}
