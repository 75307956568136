import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import BlockRatio from 'components/BlockRatio';
import Error from 'components/UI/Error';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Loading from 'components/UI/Loading';
import Share from 'components/UI/Share';

import getThumb from 'components/UI/helpers/thumb';
import EpisodeList from 'components/UI/Episode/List';
import apiFetch from 'components/UI/helpers/fetch';
import { serieUrl } from './helper';
import imageDefault from 'assets/images/event.png';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      '& div': {
        borderRadius: 14,
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      color: 'white',
      backgroundColor: '#0000000f',
      zIndex: 1,
      '& svg': {
        fontSize: '2rem',
      },
    },
    itemWrapper: {
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
      borderRadius: 14,
      backgroundColor: '#fff',
      height: '100%',
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      '& > div': {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: 0,
      },
    },
    itemContentWrapper: {
      marginTop: '-120px',
      paddingTop: theme.spacing(0),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    itemThumbnailWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    itemThumbnail: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundSize: 'cover',
      maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))',
    },
    itemTitle: {
      minWidth: '40vw',
      fontSize: '200%',
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
      textAlign: 'left',
      marginBottom: '40px',
    },
    liveNavigation: {
      marginTop: theme.spacing(1),
      '& .MuiTypography-body1': {
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        fontWeight: 700,
      },
    },
    itemDescription: {
      textAlign: 'left',
    },
    itemGuests: {
      textAlign: 'left',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      fontWeight: 'bold',
      '& .MuiTypography-caption': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiTypography-body1': {
        color: theme.palette.secondary.dark,
        fontWeight: 700,
      },
    },
    action: {
      display: 'inline-block',
      color: 'white',
      padding: '6px 12px',
      borderRadius: 16,
      fontSize: '90%',
    },
    share: {
      textAlign: 'right',
    },
  };
});

export default function SerieDetails({ id, category, onCloseSerie, initRow = null }) {
  const classes = useStyles();

  const [opened, setOpened] = React.useState(true);
  const [error, setError] = React.useState(null);

  const [row, setrow] = React.useState(initRow);
  useEffect(() => {
    if (id) {
      if (initRow === null) fetchData();
    } else {
      console.error('SerieDetails no serieId');
      setrow(false);
    }
    return () => {
      console.log('cleaned up');
    };
  }, [id]);

  const onClose = (event) => {
    window.history.replaceState(null, null, serieUrl('replay', category !== undefined ? category : row.serieCategory));
    setOpened(false);
    if (onCloseSerie !== undefined) onCloseSerie();
  };

  const fetchData = () => {
    apiFetch('/serie/get/' + id, {})
      .then((data) => {
        console.log('fetchData ok', data);
        setrow(data);
        setError(null);
      })
      .catch(({ response, error }) => {
        console.log('fetchData error', error, response);
        setrow(false);
        setError(error);
      });
  };

  let thumb = row ? getThumb('serie', row.id, row.serieThumb, 'url(' + imageDefault + ')') : '';

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={opened} maxWidth="md" className={clsx(classes.root, 'SerieDetails', row ? 'categ_' + row.serieCategory : '')}>
      {/*<MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h2">{row.serieTitle}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>*/}
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Grid item xs={12} container justify="flex-start" alignContent="flex-start">
        <If condition={row}>
          <Paper className={classes.itemWrapper} elevation={0} element="div">
            <Grid container style={{ height: '100%' }} alignContent="flex-start">
              <Grid item xs={12} container alignContent="center" style={{ position: 'relative' }}>
                <Box className={classes.itemThumbnailWrapper}>
                  <BlockRatio ratio="3_1">
                    <div className={classes.itemThumbnail} style={{ backgroundImage: thumb }}></div>
                  </BlockRatio>
                </Box>
              </Grid>
              <Grid item xs={12} container alignContent="flex-start" className={classes.itemContentWrapper}>
                <Typography variant="h5" component="div" gutterBottom className={classes.itemTitle}>
                  <Paragrapher text={row.serieTitle} prettyfy={true} element={'div'} />
                </Typography>
                <Grid item xs={12} container>
                  <Box className={classes.itemDescription} width={'100%'}>
                    <Paragrapher text={row.serieDescription} prettyfy={true} element={'div'} />
                  </Box>
                </Grid>
                <If condition={row.serieGuests != ''}>
                  <Grid item xs={12} container>
                    <Box className={classes.itemGuests} width={'100%'}>
                      <Typography variant="body1">
                        <Paragrapher text={row.serieGuests} prettyfy={true} element={'div'} />
                      </Typography>
                    </Box>
                  </Grid>
                </If>
                <Grid item xs={12} container className={classes.liveNavigation} mb={2}>
                  <Grid item xs={6}>
                    <Box className={clsx(classes.action, 'SerieAction')} mb={2}>
                      {row.episode_count} épisode{row.episode_count >= 2 ? 's' : ''}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.share}>
                      <Share module="serie" id={row.id} title={row.serieTitle} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <EpisodeList serieId={row.id} />
            </Grid>
          </Paper>
          <Else />
          <If condition={row === null}>
            <Box m={10} align="center">
              <Loading />
            </Box>
            <Else />
            <Error result={error} />
          </If>
        </If>
      </Grid>
    </Dialog>
  );
}
