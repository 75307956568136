import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Loading from './Loading';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor:'#def0ed',
      border:'#93c4bd 1px solid',
      padding:'3px 6px',
      margin:'3px 0',
      borderRadius: '3px',
    },
    arg:{
      color:'#40005e',
    }
  };
});

const functions={
  mesh_query:"Recherche MeSH",
  pubmed_query:"Recherche PubMed",
};

export default function ToolCall({ rawCall, status }) {
  const classes = styles();

  const call=rawCall!==undefined && rawCall!='' ? JSON.parse(rawCall) : {};
  const args=call.function!==undefined ?JSON.parse(call.function.arguments) : {};
  const argsStr=Object.keys(args).map((name)=>{
    //return name+'="'+args[name]+'"';
    if(typeof args[name]=='object')
      return name+'="'+JSON.stringify(args[name])+'"';
    return name+'="'+args[name]+'"';
  }).join(', ');

  const funcName=call.function!==undefined && functions[call.function.name]!==undefined ? functions[call.function.name] : 'Fonction inconnue';
  //const funcStr= funcName+" ("+argsStr+")";

  const icon=status=="pending" ? <Loading size={15} text={false} inline={true}/> : <ChevronRightIcon/>;

  return (
    <Box className={classes.root}>
      {icon}
      {funcName}
      {" : "}
      <Box component="span" className={classes.arg}>{argsStr}</Box>
    </Box>
  );
}
