import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import config from 'config/api';

import SeriesHome from 'components/UI/Serie/SeriesHome';
import AdminSerie from 'components/UI/Serie/AdminSerie';
import AdminEpisode from 'components/UI/Episode/Admin';
import { categoryFromPath } from './helper';

import NoMatch from 'components/NoMatch';

export default function route({ last }) {
  return (
    <Switch>
      <Route
        path={'/MesReplays/:category/:subtype/:id'}
        component={({ match }) => <SeriesHome type="replay" category={categoryFromPath('replay', match.params.category)} subtype={match.params.subtype} id={match.params.id} />}
      />

      <Route path={'/MesReplays/:category'} component={({ match }) => <SeriesHome type="replay" category={categoryFromPath('replay', match.params.category)} />} />

      <Route
        path={'/MesPodcasts/:category/:subtype/:id'}
        component={({ match }) => <SeriesHome type="podcast" category={categoryFromPath('podcast', match.params.category)} subtype={match.params.subtype} id={match.params.id} />}
      />
      <Route path={'/MesPodcasts/:category'} component={({ match }) => <SeriesHome type="podcast" category={categoryFromPath('podcast', match.params.category)} />} />

      <Route path="/staff/serie/:action/:id" component={({ match }) => <AdminSerie match={match} />} />
      <Route path="/staff/serie/:action" component={({ match }) => <AdminSerie match={match} />} />
      <Route path="/staff/serie" component={() => <AdminSerie />} />

      <Route path="/staff/episode/:action/:id" component={({ match }) => <AdminEpisode match={match} />} />
      <Route path="/staff/episode/:action" component={({ match }) => <AdminEpisode match={match} />} />
      <Route path="/staff/episode" component={() => <AdminEpisode />} />
      <If condition={last}>
        <Route component={() => <NoMatch />} />
      </If>
    </Switch>
  );
}
