import React, { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import config from 'config/api';
import Box from '@material-ui/core/Box';
import Template from 'components/UI/Template/Template';
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";

import { makeStyles } from '@material-ui/core/styles';
const styles = makeStyles((theme) => {
  return {
    root: {
      margin:0,
    },
    HomeBlock:{
      margin:"3em",
      textAlign:'center',
      width:'100%',
      minHeight:'50vh',
    },
  };
});

export default function NoMatch({ embed }) {
  let history = useHistory();
  const classes=styles();
  if (embed) {
    return (
      <div className={classes.HomeBlock}>
        <div className="content">
          <h1>Erreur 404</h1>
          <p>Page introuvable...</p>
        </div>
      </div>
    );
  } else {
    return (
      <Template current="home" kind={'fullWidth'} maxWidth={'auto'}>
        <ScrollToTopOnMount />
        <div className={classes.HomeBlock} id="Error404">
          <div className="wrapper">
            <div className="content">
              <h1>Erreur 404</h1>
              <p>Page introuvable... </p>
            </div>
          </div>
        </div>
      </Template>
    );
  }
}

