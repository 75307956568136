import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import BlockButton from "components/UI/BlockButton";
import DialogPopinButton from "components/UI/Dialog/DialogPopinButton";
import SpeedTest from "components/UI/SpeedTest";
import IconWifi from "@material-ui/icons/Wifi";
// import ListItem from '@material-ui/core/ListItem';
import i18n from "helpers/i18n";
import { primaryColor } from "helpers/colors";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
const styles = (theme) => {
  return {
    paper: {
      // margin: '0 20px 20px',
      // padding: '20px 0 0',
    },
    root: {
      // padding: '20px 0',
      backgroundColor: primaryColor,
    },
    buttonSpacer: {
      display: "block",
      textAlign: "center",
      height: 20,
      lineHeigt: "30px",
    },
    button: {
      padding: "0 50px",
      border: "1px transparent solid",
      [theme.breakpoints.down("sm")]: {
        padding: "5px 10px",
      },
      "&:hover": {
        color: "#fff",
        backgroundColor: "transparent",
        border: "1px #fff solid",
      },
    },
    buttonContent: {
      display: "block",
      fontWeight: "bold",
      fontSize: "18px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
    },
  };
};

class SpeedTestWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClose: false,
    };
    this._onTestComplete = this._onTestComplete.bind(this);
  }

  _onTestComplete() {
    this.setState({ showClose: true });
  }

  render() {
    const { classes, onClose } = this.props;
    const { showClose } = this.state;
    return (
      <Paper classes={{ root: classes.paper }}>
        <SpeedTest
          duration={40}
          onTestComplete={this._onTestComplete}
          restart={false}
        />
        <If condition={showClose === true}>
          <BlockButton
            label="Fermer"
            onClick={onClose}
            style={{ margin: "0 auto 20px", display: "block" }}
          />
        </If>
      </Paper>
    );
  }
}
SpeedTestWrapper = withStyles(styles)(SpeedTestWrapper);

class HelpSpeedTest extends Component {
  render() {
    const { style, classes, className } = this.props;

    return (
      <div className={clsx(classes.root, className)} style={style}>
        <DialogPopinButton
          trigger={BlockButton}
          triggerProps={{
            className: classes.button,
            label: (
              <React.Fragment>
                <span className={classes.buttonSpacer}></span>
                <span className={classes.buttonContent}>
                  <Box align="center">
                    <Hidden xsDown>
                      <IconWifi
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          marginTop: "-4px",
                          marginRight: 10,
                        }}
                      />
                    </Hidden>
                    Testez votre connexion internet
                  </Box>
                </span>
                <span className={classes.buttonSpacer}>
                  <ArrowRightAltIcon />
                </span>
              </React.Fragment>
            ),
            size: "big",
            style: { boxShadow: "none" },
          }}
          popinTitle={i18n.get("speedTestButtonLabel")}
          popinContent={SpeedTestWrapper}
          popinContentProps={{}}
          openOnMount={false}
        />
      </div>
    );
  }
}

export default withStyles(styles)(HelpSpeedTest);
