import {config} from './config'

export default function apiFetch (action, post={}) {

  return new Promise((resolve, reject)=>{ 
  const user = new URLSearchParams(window.location.search).get("user");
  if(user!==null && user!=='')
    post['user']=user;

  const params = new URLSearchParams(post);

  fetch(config.apiUrl+"/api.php?action="+action, {
    credentials: 'include',
    method: 'post',
    headers: {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: params.toString(),
  })
    .then((response) => response.json())
    .then((response) => {
      if(response.result!==undefined && !response.result){
        console.log('apiFetch result error');
        reject({
          response:response,
          error:'error' in response ? response.error : null,
          //msg:'msg' in response ? response.msg : null,
        });
      }else if(response.result){
        console.log('apiFetch success', response);
        resolve(response);
      }
    })
    .catch((response) => {
      console.log('apiFetch error', response);
      reject({
        response:response,
        error:'error' in response ? response.error : null,
        //msg:'msg' in response ? response.msg : null,
      });
  });
  });
}