import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import config from 'config/api';
import queryString from 'query-string';
import Loading from 'components/UI/Loading';

import Card from './Card';

const styles = makeStyles((theme) => {
  return {
    root: {
      marginLeft: '0',
      marginRight: '0',
      marginBottom: theme.spacing(4),
      '& .MuiTypography-h3': {
        paddingRight: 4,
        paddingLeft: 22,
        marginBottom: theme.spacing(2),
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.1rem',
      },
      '& .MuiMobileStepper-dot': {
        marginLeft: '4px',
        marginRight: '4px',
      },
    },
    badge: {
      top: '50%',
      right: '-16px',
    },
  };
});

export default function List({ serieId, variant = 'horizontal' }) {
  const classes = styles();
  let showCount = false;
  const gridSize = variant == 'horizontal' ? { sm: 12, md: 12 } : { sm: 4, md: 3 };

  const verbose = true;
  const apiUrl = config.baseUrl + '/api/episode/front_list';
  const [list, setList] = React.useState(null);
  useEffect(() => {
    if (serieId) fetchList({ serie: serieId });
    else {
      console.error('Episode/List no serieId');
      setList(false);
    }
  }, []);

  console.log('list', list);

  const fetchList = (data) => {
    fetch(apiUrl, {
      credentials: 'include',
      method: 'post',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest', // needed by php api
      },
      body: queryString.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (verbose) console.log('fetchList response', response);
        setList(response.data);
      })
      .catch((error) => {
        setList(false);
        console.log('fetchList ERROR', error);
      });
  };

  return (
    <Grid container alignItems="center" justify="flex-start" className={classes.root}>
      <Grid item xs={12} container align="flex-start">
        <If condition={list}>
          {list.map((row, idx) => {
            return (
              <Grid key={'i_' + idx} item xs={12} sm={gridSize.sm} md={gridSize.md}>
                <Card variant={variant} row={row} />
              </Grid>
            );
          })}
          <Else />
          <If condition={list === false}>
            Erreur
            <Else />
            <Box m={10} align="center">
              <Loading />
            </Box>
          </If>
        </If>
      </Grid>
    </Grid>
  );
}
