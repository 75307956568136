import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { formatDatez } from "helpers/stringUtils";
import config from "config/api";
import PodcastThemeDetailsCard from "./PodcastThemeDetailsCard";
import { connect } from "react-redux";
import {
  fetchPodcastThemeDetails,
  getPodcastThemeDetails,
  clearPodcastThemeDetails,
} from "./ducks/podcastTheme";

const colors = {
  live: '#0075A8',
  pending: '#009EE3'
}
const legends = {
  live: 'En ligne',
  pending: 'A venir'
}

const styles = (theme) => {
  return {
    root: {

    },
    title: {

    },
    subTitle: {

    },
    breadCrumbs: {
      color: '#000',
      fontSize: '.7rem',
      paddingLeft: theme.spacing(4),
      '& a': {
        color: '#000',
        textDecoration: 'undeline',
      },
      '& a:hover': {
        textDecoration: 'undeline'
      }

    },
    detailsWrapper: {

    },
    podcastState: {
      color: '#fff',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      letterSpacing: 'normal',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,

      // fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
      marginBottom: theme.spacing(2),
    },

  };
};

class PodcastThemeDetails extends Component {
  componentDidMount() {
    const {themeId} = this.props;
    this.props.onMount({themeId});
  }
  componentWillUnmount() {
    this.props.onUnmount();
  }

  render() {
    const {
      $$data,
      $$themeData,
      classes,
      $$podcastThemeDetails,
    } = this.props;


    return (
      <If condition={$$podcastThemeDetails !== undefined}>
      <Box mt={4} mb={4} className={classes.breadCrumbs}>
      <Link to={'/podcasts'}>Accueil podcast</Link>&nbsp;&gt;&nbsp;{$$themeData.get('themeSubTitle')}
      </Box>
      <Box mb={10}>
      <Typography variant={'h1'} gutterBottom className={classes.title}>
      {$$themeData.get('themeName')}
      </Typography>
      <Typography variant={'body1'} align="center" className={classes.subTitle}>
      {$$themeData.get('themeDescription')}
      </Typography>
      </Box>
      <Box px={4} className={classes.root}>
      <Grid
      container={true}
      spacing={4}
      alignItems="flex-start"
      justifyContent="space-around"
      className={classes.detailsWrapper}
      >
      {$$podcastThemeDetails.groupBy(($p) => $p.get('podcastState')).sortBy(($p, pIdx) => pIdx).map(($$g, gIdx) => {
        const gridSize = $$g !== undefined ? $$g.size - 1 : 0;
        return (<Grid item xs={12} key={'g_'+gIdx}><Box mb={4} >
        <Box>
        <Typography variant="subtitle1" className={classes.podcastState} style={{backgroundColor: colors[gIdx]}}>
        {legends[gIdx]}
        </Typography>
        </Box>
        <Grid container spacing={4}>
        {$$g.map(($$cardData, tIdx) => {
          return (
            <PodcastThemeDetailsCard
            key={"thm_" + tIdx}
            $$cardData={$$cardData}
            tIdx={tIdx}
            gridSize={gridSize}
            />
          );
        }).toArray()}
        </Grid>
        </Box>
        </Grid>)
      }).toArray()}
      </Grid>
      </Box>
      </If>
    );
  }
}
function mapStateToProps(state) {
  const props = {
    $$podcastThemeDetails: getPodcastThemeDetails(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: ({themeId}) => {
      dispatch(fetchPodcastThemeDetails({themeId}));
    },
    onUnmount: () => {
      dispatch(clearPodcastThemeDetails());
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PodcastThemeDetails))
);
