import React, { Component } from "react";
import Immutable from "immutable";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import { formatDate } from "helpers/stringUtils";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import { connect } from "react-redux";
import config from "config/api";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import BlockTitle from "components/UI/BlockTitle";
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Header from "components/Header/Header";
import Footer from "components/Footer";
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import { withRouter } from "react-router-dom";

import {
  fetchFiles,
  getFilesData,
} from "./ducks/files";

import {
  fetchData,
  getDataHome,
  getLoadingError,
} from "ducks/asyncFetcher";

import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
} from "components/UI/Account/ducks/authentication";

const AF_KEY = "home";

const styles = (theme) => {
  // console.log(theme)
  return {
    root: {
      // maxWidth: "1280px",
      margin: "0 auto",
      minHeight: '600px',
    },
    fullWidth: {
      width: '100%'
    },
    headerBg: {
      // minHeight: 210,
      // backgroundImage: "url(" + require("assets/images/header.jpg") + ")",
      backgroundPosition: "0 0",
      [theme.breakpoints.down("sm")]: {
        backgroundImage: "none",
      },
    },

    mainWrapper: {
      fontSize: "14px",
    },
    mainTitle: {
      marginBottom: 40,
      textAlign: "center",
    },
    livePaper: {
      color: theme.palette.common.black,
      maxWidth: 1280,
      margin: "30px auto",
      padding: "40px 20px 50px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 20px",
      },
    },
    inline: {
      display: "inline-block",
    },
    header: {
      // height: 150,
      // [theme.breakpoints.down("sm")]: {
      //   height: 200
      // }
    },
    content: {
      // height: 'calc(100% - 150px - 120px)',
      // [theme.breakpoints.down("sm")]: {
      // height: 'auto'
      // }
    },
    regularHeight: {
      height: 'auto',
    },
    footer: {
      width: '100%',
      // height: 120,
      // [theme.breakpoints.down("sm")]: {
      //   height: 'auto'
      // }
    }

  };
};
class Files extends Component {
  constructor(props) {
    super(props);
    this.state = { logMessage: false };
    // this._gotoLiveRoom = this._gotoLiveRoom.bind(this);
    // this._onMessageSent = this._onMessageSent.bind(this);
    // this._handleSnackbarClose = this._handleSnackbarClose.bind(this);
    // this._runRemainingTimer = this._runRemainingTimer.bind(this);
  }
  componentDidMount() {
    // const liveId = this.props.match.params.id;
    this.props.onMount();
    // this._startTimer();
  }

  render() {
    const { userRole, classes, $$filesData } = this.props;
    return (
      <Fade in timeout={1000} style={{height: '100%', maxWidth: '100%'}}>
        <div className={classes.root}>
          <Container fixed disableGutters style={{ height: '100%', maxWidth: '100%' }}>
            <ScrollToTopOnMount />
            <Grid container style={{ height: '100%' }}>
              <Grid item xs={12} className={classes.header}>
                <Header userRole={userRole} current={'lives'} headerContent={undefined} headerClass={classes.headerBg}/>
              </Grid>
              <Grid
                item
                xs={12}
                container
                className={classes.content}
                >
                <Box className={classes.fullWidth} align="left">
                  <Paper square elevation={0} className={classes.paper}>
                    <div className={classes.mainWrapper}>
                      <Paper className={classes.livePaper} elevation={3}>
                        <Box align="center">
                          <BlockTitle
                            title={"Mes fichiers"}
                            className={classes.mainTitle}
                            />
                        </Box>
                        <If condition={$$filesData !== undefined}>
                        <Grid
                          container
                          spacing={0}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          style={{ height: "100%" }}
                          >

                        {$$filesData.get('files').map(($$event, eIdx) => {
                            let $$files;
                            $$files = $$event.get('eventPostFiles') !== undefined && $$event.get('eventPostFiles') !== false && $$event.get('eventPostFiles') !== '' ? Immutable.fromJS(JSON.parse($$event.get('eventPostFiles'))) : Immutable.fromJS([]);

                        return (<Grid  key={'e_'+eIdx} item={true} xs={6}>
                          <List omponent="div" aria-labelledby="nested-list-subheader" subheader={<ListSubheader component="div" id="nested-list-subheader">

                        <Typography variant={'subtitle1'} gutterBottom={true}>{formatDate($$event.get('eventDate'), "dd/mm/yyyy")}</Typography>
                        <Typography variant={'h6'} align="left" gutterBottom={true}>{$$event.get('eventTitle')}</Typography>

                        </ListSubheader>} className={classes.root}>
                        {$$files.map(($f, fIdx) => {
                          console.log($f.toJS())
                          return (<ListItem key={'f_'+fIdx} button>
                            <ListItemIcon>
                            <Avatar><FileIcon /></Avatar>
                            </ListItemIcon>
                            <ListItemText primary={$f.get('name')+' ('+(Math.round($f.get('size')/1000/1000)*10)/10+'Mo)'}/>
                          </ListItem>);

                        })}
                        </List></Grid>)
                        }).toArray()}
                        </Grid>
                        </If>
                        </Paper>
                      </div>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item={true} xs={12}>
                  <Footer />
                </Grid>
              </Grid>
            </Container>
          </div>
        </Fade>
      );
    }
  }

  function mapStateToProps(state) {
    const props = {
      $$data: getDataHome(state),
      error: getLoadingError(state),
      isLoggedIn: isLoggedIn(state),
      userRole: getCurrentUserRole(state),
      $$user: getCurrentUser(state),
      $$filesData: getFilesData(state),
    };
    return props;
  }
  function mapDispatchToProps(dispatch) {
    return {
      onMount: () => {
        dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
        dispatch(fetchFiles());
      },
    };
  }

  // export default ;
  export default withStyles(styles)(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(Files))
  );
