import React, { Component } from "react";
import Immutable from "immutable";
import Fade from "@material-ui/core/Fade";
// import Immutable from 'immutable';
import { withStyles } from "@material-ui/core/styles";
import RemainingTime from "components/UI/RemainingTime";
// import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
import config from "config/api";
import clsx from "clsx";
// import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Container from "@material-ui/core/Container";
// import withWidth from '@material-ui/core/withWidth';
import isArray from "mout/lang/isArray";
// import IconPlay from '@material-ui/icons/PlayCircleFilled';
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import AddIcon from '@material-ui/icons/Add';
// import EventIntro from 'components/Event/EventIntro';
import BlockTitle from "components/UI/BlockTitle";
import BlockRatio from "components/BlockRatio";
import getRemainingTime from "helpers/getRemainingTime";
import gotoLiveRoom from "helpers/gotoLiveRoom";

// import BlockButton  from 'components/UI/BlockButton';
// import MyEventsContainer from 'components/Event/MyEventsContainer';
// import RemainingTime from 'components/UI/RemainingTime';

import Spinner from "components/UI/Spinner";
import Header from "../Header/Header";
import Footer from "../Footer";
// import LiveHelp from "./LiveHelp";
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
// import Bubble from 'components/UI/Bubble';
// import HelpSpeedTest from 'components/Help/HelpSpeedTest';
// import PopinWrapper from 'components/PopinWrapper';
// import EventSubscription from 'components/Event/EventSubscription';
// import EventSubscriptionFor from 'components/Event/EventSubscriptionFor';
import EventCardEmbed from "components/Event/EventCardEmbed";
import Template from "components/Template/Template";
// import LiveMessage from "components/Lives/LiveMessage";
// import DialogPopinButton from 'components/UI/Dialog/DialogPopinButton';
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";

// import {
//   fetchLive,
//   getLiveData,
//   getLiveError,
// } from "../../ducks/live";

import { fetchReplay, getReplayData, getReplayError } from "../../ducks/replay";

import {
  fetchData,
  getDataHome,
  getLoadingError,
} from "../../ducks/asyncFetcher";

import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
} from "ducks/authentication";
import MuiAlert from "@material-ui/lab/Alert";
import i18n from "helpers/i18n";
import { primaryColor } from "helpers/colors";

const AF_KEY = "home";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => {
  // console.log(theme)
  return {
    root: {
      // color: '#fff',
      color: "inherit",
      marginBottom: theme.spacing(4),
      width: "100%",
      // maxWidth: "1280px",
      "& .MuiTypography-h2": {
        color: "inherit",
      },
      "& .MuiTypography-caption": {
        color: "inherit",
      },
      "& .MuiTypography-h6": {
        color: "inherit",
      },
    },
    fullWidth: {
      width: "100%",
    },
    headerBg: {
      // minHeight: 210,
      // backgroundImage: "url(" + require("assets/images/header.jpg") + ")",
      backgroundPosition: "0 0",
      [theme.breakpoints.down("sm")]: {
        backgroundImage: "none",
      },
    },

    mainWrapper: {
      fontSize: "14px",
    },
    mainTitle: {
      marginTop: theme.spacing(2),
      marginBottom: 0,
      textAlign: "center",
    },
    livePaper: {
      maxWidth: 1280,
      // color: '#fff',
      margin: "0 auto",
      padding: "40px 20px 50px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 20px",
      },
    },
    inline: {
      display: "inline-block",
    },
    header: {
      // height: 150,
      // [theme.breakpoints.down("sm")]: {
      //   height: 200
      // }
    },
    content: {
      // height: 'calc(100% - 150px - 120px)',
      // [theme.breakpoints.down("sm")]: {
      // height: 'auto'
      // }
    },
    regularHeight: {
      height: "auto",
    },
    backButton: {
      [theme.breakpoints.down("sm")]: {
        float: "none",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    footer: {
      width: "100%",
      // height: 120,
      // [theme.breakpoints.down("sm")]: {
      //   height: 'auto'
      // }
    },
  };
};
class Lives extends Component {
  constructor(props) {
    super(props);
    this.state = { logMessage: false };
    // this._gotoLiveRoom = this._gotoLiveRoom.bind(this);
    // this._onMessageSent = this._onMessageSent.bind(this);
    // this._handleSnackbarClose = this._handleSnackbarClose.bind(this);
    // this._runRemainingTimer = this._runRemainingTimer.bind(this);
  }
  componentDidMount() {
    const replayId = this.props.match.params.id;
    this.props.onMount({ replayId });
    // this._startTimer();
  }
  componentDidUpdate(oldProps) {
    // if (oldProps.$$replayData !== this.props.$$replayData) {
    //   this._startTimer();
    // }
  }
  componentWillUnmount() {
    // this._stopTimer();
  }

  // _stopTimer() {
  //   // console.log('timer stop')
  //   if (this._remainingTimer) {
  //     clearInterval(this._remainingTimer);
  //   }
  // }
  // _startTimer() {
  //   // console.log('timer start')
  //   // const $$card = this._getCard();
  //   // console.log('test1')
  //   if (this._remainingTimer) return;
  //   const $$liveEvent = this._getCard();
  //   // console.log('test2', $e)
  //   if ($$liveEvent === undefined) return;
  //   // console.log('starting timer')
  //   // this._cardTimer = $$card.get('eventDate');
  //   this._remainingTimer = setInterval(this._runRemainingTimer, 1000);
  //   this._runRemainingTimer();
  // }
  // _runRemainingTimer() {
  //   // console.log('run1')
  //   // const { $$card } = this.props;
  //   // const $$liveEvent = $$card;
  //   const $$liveEvent = this._getCard();
  //   // console.log('run2', $e)
  //   if ($$liveEvent === undefined) {
  //     return this._stopTimer();
  //   }
  //   // console.log('run3')
  //   // console.log($e)
  //   // console.log($e.get('eventState'))
  //   const timeRemaining =
  //   $$liveEvent !== undefined && $$liveEvent.get("eventState") != "live"
  //   ? getRemainingTime($$liveEvent.get("eventDate"))
  //   : { days: 0, hours: 0, minutes: 0 };
  //   // console.log(timeRemaining)
  //   if (
  //     timeRemaining.days <= 0 &&
  //     timeRemaining.hours <= 0 &&
  //     timeRemaining.minutes <= 15
  //   ) {
  //     this.setState({ showLiveButton: true }, () => {
  //       // console.log('STOPPPING')
  //       this._stopTimer();
  //     });
  //   } else {
  //     // console.log('RUN')
  //     this.setState({ timeRemaining });
  //   }
  // }

  // _gotoLiveRoom(e) {
  //   // const { $$card } = this.props;
  //   const $$card = this._getFakeCard();
  //   if (e !== undefined && e.preventDefault !== undefined)
  //   e.preventDefault();
  //   gotoLiveRoom($$card, () => {
  //     this._goto('/live/'+$$card.get('id'))
  //   })
  // }
  _getCard() {
    const { $$data, match, $$replayData } = this.props;
    const { id } = match.params;
    let found = undefined;
    if ($$replayData !== undefined) found = $$replayData;
    return found;
  }

  _redirectToLogin = (e) => {
    let pn = window.location.pathname;
    console.log(pn[pn.length - 1]);
    if (pn[pn.length - 1] === "/") {
      pn = pn.substring(0, pn.length - 1);
      // console.log('mathced!')
    }

    let returnUrl = pn + window.location.search;
    // console.log(returnUrl)
    returnUrl = encodeURIComponent(returnUrl);
    // console.log(returnUrl)
    this.props.history.push("/login?_pn=" + returnUrl);
  };

  render() {
    const { $$data, userRole, onPanelToggle, classes, $$user } = this.props;
    const { logMessage, showLiveButton } = this.state;
    // const showLiveButton = true;
    const $$subscriptions =
      userRole !== undefined &&
      $$data !== undefined &&
      $$data !== undefined &&
      $$data.get("myfutureevents") !== undefined
        ? $$data.get("myfutureevents").map((e) => e.get("id"))
        : Immutable.Map();

    // const $$card = this._getCard();
    const $$card = this._getCard();
    // if ($$card !== undefined)
    // console.log('card is', $$card.toJS())
    // else
    // console.log('CARD IS NOT FOUND !')

    // const timeRemaining =
    // ($$card !== undefined)
    // ? getRemainingTime($$card.get('eventDate'))
    // : {};
    // if ($$card !== undefined)
    // console.log($$card.toJS())
    // else console.log('no card !!')

    return (
      <Template current="replays" resize={false}>
        <Fade in timeout={1000} style={{ height: "100%", maxWidth: "100%" }}>
          <div className={classes.root}>
            <Box align="center">
              <Typography variant="h2" className={classes.mainTitle}>
                {/*<Button
                    color="primary"
                    variant="outlined"
                    className={classes.backButton}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push('/replays');
                    }}
                    >
                    Retour
                  </Button>*/}
                Replay
              </Typography>
            </Box>

            <Choose>
              <When condition={$$data !== undefined && userRole === undefined}>
                <Box my={6} align="center">
                  <Typography
                    variant="h5"
                    gutterBottom={true}
                    className={clsx(classes.legend, classes.h6extends)}
                  >
                    Merci de vous connecter pour accéder à ce contenu
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this._redirectToLogin()}
                  >
                    {" "}
                    Connexion
                  </Button>
                </Box>
              </When>
              <When
                condition={
                  $$data !== undefined &&
                  (userRole === "member" ||
                    userRole === "admin" ||
                    userRole === "staff")
                }
              >
                <If condition={$$card !== undefined}>
                  <Box mt={0} mb={1} px={0} align="center">
                    <EventCardEmbed $$card={$$card} showDescription={false} />
                  </Box>

                  <Paper className={classes.livePaper} elevation={3}>
                    <Box mt={0} mb={1} px={4} align="center">
                      <Typography variant="caption">
                        <span className={classes.inline}>
                          {i18n.get("liveInfo")}
                        </span>
                      </Typography>
                    </Box>

                    <BlockRatio ratio={"16_9"}>
                      <iframe
                        title="replay"
                        src={$$card.get("eventLinkReplay")}
                        className={"embed"}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                      />
                    </BlockRatio>
                  </Paper>
                </If>
                <If condition={$$card === undefined}>
                  <Box my={8} align="center">
                    <Typography
                      variant="h5"
                      gutterBottom={true}
                      className={clsx(classes.legend, classes.h6extends)}
                    >
                      Il n'y a pas de Replay pour cette webconférence :(
                    </Typography>
                    <Box mt={3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          window.location.replace("/");
                        }}
                      >
                        Cliquez pour retourner à l'accueil
                      </Button>
                    </Box>
                  </Box>
                </If>
              </When>
              <When condition={$$data !== undefined && userRole === "speaker"}>
                <p>No place for speaker</p>
              </When>
              <Otherwise>
                <div className={"Loading"}>
                  <Spinner style={{ marginTop: 200 }}>Chargement...</Spinner>
                </div>
              </Otherwise>
            </Choose>
          </div>
        </Fade>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    error: getLoadingError(state),
    isLoggedIn: isLoggedIn(state),
    userRole: getCurrentUserRole(state),
    $$user: getCurrentUser(state),
    $$replayData: getReplayData(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: ({ replayId }) => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
      dispatch(fetchReplay({ replayId }));
    },
  };
}

// export default ;
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Lives)),
);
