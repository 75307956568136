import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import BlockTitle from "components/UI/BlockTitle";
import { withStyles } from "@material-ui/core/styles";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MobileStepper from "@material-ui/core/MobileStepper";
// import Button from '@material-ui/core/Button';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme) => ({
  // root: {
  //   flexGrow: 1,
  // },
  // header: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   height: 50,
  //   paddingLeft: theme.spacing.unit * 4,
  //   backgroundColor: theme.palette.background.default,
  // },
  // img: {
  //   height: 255,
  //   display: 'block',
  //   maxWidth: 400,
  //   overflow: 'hidden',
  //   width: '100%',
  // },
  // card: {
  //   maxWidth: 345,
  // },
  // media: {
  //   height: 140,
  // },
  mobileStepper: {
    backgroundColor: "transparent",
  },
  dots: {
    margin: "0 auto",
  },
});

class EventIntro extends Component {
  constructor() {
    super();
    this.state = {
      currentIndex: 0,
    };
  }
  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.currentIndex + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.currentIndex - 1,
    }));
  };

  handleStepChange = (currentIndex) => {
    this.setState({ currentIndex });
  };

  render() {
    const { $$data, altStyle, size, classes } = this.props;
    const { currentIndex } = this.state;
    const maxSteps = 3;
    let cellWidth = 4;
    return (
      <div
        className={
          "HomeIntro HomeBlock condensed" + (altStyle === true ? " alt" : "")
        }
      >
        <BlockTitle title={$$data.get("title")} />

        <div className="condensedWrapper">
          <Grid
            className="grid"
            container
            spacing={4}
            alignItems="flex-start"
            justify="space-around"
            style={{ height: "100%" }}
          >
            <If condition={$$data.get("items") !== undefined}>
              <AutoPlaySwipeableViews
                steps={maxSteps}
                axis={"x"}
                index={currentIndex}
                onChangeIndex={this.handleStepChange}
                enableMouseEvents
              >
                {$$data
                  .get("items")
                  .map((item, idx) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={cellWidth}
                        key={"itm_" + idx}
                        className={"cell" + (size === "small" ? " small" : "")}
                      >
                        <div className="padder">
                          <span className="iconWrapper">
                            <span
                              className="icon"
                              style={{
                                backgroundImage:
                                  "url(" +
                                  require("assets/images/" +
                                    process.env.REACT_APP_SITE +
                                    "/icons/" +
                                    item.get("icon") +
                                    ".svg") +
                                  ")",
                              }}
                            ></span>
                          </span>
                          <Paragrapher
                            text={item.get("title")}
                            className="content"
                            cleanNewLines={true}
                          />
                        </div>
                      </Grid>
                    );
                  })
                  .toArray()}
              </AutoPlaySwipeableViews>
            </If>
          </Grid>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={currentIndex}
            classes={{ root: classes.mobileStepper, dots: classes.dots }}
          />
        </div>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(EventIntro);
