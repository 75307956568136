import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import config from 'config/api';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import 'react-sticky-header/styles.css';
//import { fetchData } from '../../ducks/asyncFetcher';
import HeaderMenu from 'components/UI/Header/HeaderMenu';
import HeaderLogin from 'components/UI/Header/HeaderLogin';
import RGPDBanner from 'components/UI/RGPDBanner/RGPDBanner'
import AccountForceChangeOptins from 'components/UI/Account/AccountForceChangeOptins'

const AF_KEY = 'home';

const styles = (theme) => {
  return {
    root: {
      backgroundColor: theme.palette.header.backgroundColor,
      '& .MuiButton-root.right': {
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: 'transparent',
      },
    },
    header: {
      minHeight: 95,
      transition: [
        theme.transitions.create('background-position', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        theme.transitions.create('background-size', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      ],
      [theme.breakpoints.down('lg')]: {
        backgroundPosition: '50% 0',
      },
      [theme.breakpoints.down('md')]: {
        backgroundPosition: '40% 0',
      },
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: '100% 0',
        backgroundSize: '30%',
      },
      [theme.breakpoints.down('xs')]: {
        backgroundPosition: '100% 0',
        backgroundSize: '20%',
        minHeight: 200,
      },
    },
    logo: {
      backgroundImage: theme.palette.logo && theme.palette.logo.backgroundImage ? theme.palette.logo.backgroundImage : `url(${require('assets/images/' + process.env.REACT_APP_SITE + '/logo.png')})`,
      width: theme.palette.logo && theme.palette.logo.width ? theme.palette.logo.width : 200,
      height: theme.palette.logo && theme.palette.logo.height ? theme.palette.logo.height : '100%',
      cursor: 'pointer',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      display: 'block',
      margin: '0 10px 0 30px',
      backgroundPosition: 'bottom',
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: 'center',
        margin: '10px auto',
      },
    },
    headerMenu: {
      textAlign: 'left',
      paddingLeft: '5px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        padding: 0,
        marginTop: 0,
        border: 'none',
      },
    },
    headerLogin: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      }
    },
    headerLogo: {
      height: 80,
    },
    headerContent: {
      textAlign: 'center',
      maxWidth: '100%',
      padding: 10,
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '90%',
      },
      '& img': {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'normal',
        margin: '-40px auto -30px',
        width: 214,
        height: 134
      },
      }
    },
  };
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //open: false,
      //isLogginIn: false,
    };
    //this.handleClose = this.handleClose.bind(this);
    //this.handleToggle = this.handleToggle.bind(this);
    this._gotoHome = this._gotoHome.bind(this);
    //this._setIsLogginIn = this._setIsLogginIn.bind(this);
    //this._onFormComplete = this._onFormComplete.bind(this);
  }

  _gotoHome() {
    this.props.history.push('/');
  }
/*
  handleToggle = (e, url) => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleClose = (event, url) => {
    this.setState({ isLogginIn: false });

    if (this.anchorEl.contains(event.target)) {
      return;
    }

    const { history } = this.props;
    this.setState({ open: false }, function () {
      history.push(url);
    });
  };
  _setIsLogginIn = () => {
    this.setState({ isLogginIn: true });
  };
  _onFormComplete = () => {
    const { onLoggedIn } = this.props;
    this.setState({ isLogginIn: false }, () => {
      onLoggedIn();
    });
  };
  */
  render() {
    const {
      current,
      headerImage,
      headerTitle,
      headerContent,
      //isLoggedIn,
      //$$user,
      classes,
      headerStyle,
      headerClass,
      forceChangeOptins,
    } = this.props;

    const menuSize = ['sm', 'xs'].indexOf(this.props.width) < 0 ? 40 : 28;
    const headerType = headerImage || void 0;
    let finalHeaderStyle = {};
    if (headerType) {
      finalHeaderStyle.backgroundPosition = '0 25%';
      finalHeaderStyle.backgroundSize = 'cover';
      finalHeaderStyle.backgroundRepeat = 'no-repeat';
    }

    //const $$menu = config.getMenu($$user, { asImmutable: true });
    //const userRole = $$user !== undefined ? $$user.get('role') : undefined;
    return (
      <Grid item xs={12} className={classes.root} style={finalHeaderStyle}>
        <header id="header" className={clsx(classes.header, headerClass)} style={headerStyle}>
          <Grid container spacing={0} alignItems="center" justify="space-around">
            <Grid item xs={12} md={2} className={classes.headerLogo}>
              <div className={clsx(classes.logo, "headerLogoLeft")} onClick={this._gotoHome}></div>
            </Grid>
            <Grid item xs={12} md={5} lg={7} className={classes.headerMenu}>
              <HeaderMenu
                menuSize={menuSize}
                style={{ whiteSpace: 'nowrap' }}
                current={current}
              />
            </Grid>
            <Grid item xs={12} md={5} lg={3} className={clsx(classes.headerLogin)}>
              <HeaderLogin/>
            </Grid>
          </Grid>
          <If condition={headerTitle !== undefined}>
            <Grid item xs={12}>
              <Typography variant="h1">{headerTitle}</Typography>
            </Grid>
          </If>
          <If condition={headerContent !== undefined}>
            <Box className={classes.headerContent}>{headerContent}</Box>
          </If>
        </header>
        <If condition={forceChangeOptins}>
          <AccountForceChangeOptins />
        </If>
        <RGPDBanner key="rpgd" />
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    /*
    isLoggedIn: isLoggedIn(state),
    $$user: getCurrentUser(state),
    */
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    /*onLoggedIn: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },*/
  };
}

export default withStyles(styles)(
  withRouter(withWidth()(connect(mapStateToProps, mapDispatchToProps)(Header))),
);
