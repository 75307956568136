import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Main from './Main';

const styles = makeStyles((theme) => {
  return {
    root: {
      color:'#333',
      padding:'1em 2em',
      backgroundColor:'#f4f5f5',
      width:'100%',
      textAlign:'left',
    },
  };
});

export default function Page({ }) {
  const classes = styles();

  return (
    <div className={classes.root}>
      <Box>
      <Typography variant="h1" gutterBottom={true} className="">
        GPTPubMed <small style={{color:'grey'}}>beta</small>
      </Typography>
      <Main/>
      </Box>
    </div>
  );
}
