import React, { Component } from "react";
import { connect } from "react-redux";
import config from "config/api";
import Spinner from "components/UI/Spinner";
import i18n from "helpers/i18n";
import Immutable from "immutable";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
// import Prettyfier from 'components/UI/Prettyfier';
// import HomeMember from './HomeMember';
// import HomeSpeaker from './HomeSpeaker';
import Header from "../Header/Header";
import HeaderContent from "../Header/HeaderContent";
import HeaderSub from "../Header/HeaderSub";
import Footer from "../Footer";
import HomeHelp from "components/Home/HomeHelp";
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import PodcastThemeDetails from "components/UI/PodcastTheme/PodcastThemeDetails";
// import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  fetchData,
  getDataHome,
  getLoadingError,
} from "../../ducks/asyncFetcher";
import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
  isStateKnown,
} from "ducks/authentication";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { primaryColor } from "../../helpers/colors";

const styles = (theme) => {
  return {
    root: {
      minHeight: "100%",
      maxWidth: "1280px",
      margin: "0 auto",
      "& .MuiTypography-h1": {
        color: "#0075A8",
        fontWeight: 700,
        textAlign: "center",
        textTransform: "none",
        fontSize: "2.5rem",
      },
    },
    wrapper: {
      height: "calc(100% - 118px)",
      borderBottom: "3px #daebf3 solid",
    },
    contentWrapper: {
      position: "relative",
    },
    content: {
      position: "relative",
      zIndex: 9,
    },
    loginNav: {
      "& .MuiButton-textSecondary": {
        backgroundColor: "#318EB8",
        color: "#fff",
        fontSize: "1rem",
        margin: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          fontSize: ".8rem",
        },

        // fontWeight: 'bold'
      },
    },
  };
};

const AF_KEY = "home";

const PodcastThemeDetailsStyled = withStyles((theme) => {
  return {
    root: {
      backgroundColor: "#eaf4fb",
      background:
        "linear-gradient(180deg, #eaf4fb 0%, rgba(255,255,255,1) 100%)",
    },
    subTitle: {
      color: "#000",
      fontSize: "1.2rem",
    },
    detailsWrapper: {
      backgroundImage:
        "url(" +
        require("assets/images/" +
          process.env.REACT_APP_SITE +
          "/halfcircle_blue.jpg") +
        ")",
      backgroundSize: "contain",
      backgroundPosition: "50% 0",
      backgroundRepeat: "no-repeat",
    },
  };
})(PodcastThemeDetails);

class PodcastTheme extends Component {
  componentDidMount() {
    const { match } = this.props;
    this.props.onMount();
  }

  componentDidUpdate(oldProps) {}
  render() {
    const {
      $$data,
      userRole,
      onPanelToggle,
      classes,
      history,
      $$podcastTheme,
      match,
    } = this.props;
    const themeId = match.params.themeId;
    // console.log({themeId})
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Header
            onPanelToggle={onPanelToggle}
            current="home"
            headerTitle={undefined}
            headerContent={
              process.env.REACT_APP_SITE !== "pneumo" &&
              process.env.REACT_APP_SITE !== "pneumo_medevent" ? (
                <HeaderContent />
              ) : undefined
            }
          />
          <ScrollToTopOnMount />
          <Box className={classes.contentWrapper}>
            <Box className={classes.content}>
              <Choose>
                <When condition={$$data !== undefined}>
                  <Choose>
                    <When
                      condition={
                        $$data !== undefined &&
                        userRole !== undefined &&
                        userRole !== "visitor"
                      }
                    >
                      <Box mb={16}>
                        <PodcastThemeDetailsStyled
                          themeId={themeId}
                          $$themeData={$$data
                            .get("podcastThemes")
                            .find(($$p) => $$p.get("id") == themeId)}
                        />
                      </Box>
                    </When>
                    <Otherwise>
                      <Box my={6} align="center">
                        <Typography
                          variant="h5"
                          gutterBottom={true}
                          className={clsx(classes.legend, classes.h6extends)}
                        >
                          Merci de vous connecter, ou de créer un compte pour
                          accéder à ce contenu
                        </Typography>
                        <Box className={classes.loginNav}>
                          <Button
                            size={"large"}
                            color="secondary"
                            onClick={(e) => {
                              history.push("/loginSimple");
                            }}
                          >
                            {i18n.get("loginFormTitle")}
                          </Button>
                          <Button
                            size={"large"}
                            color="secondary"
                            onClick={(e) => {
                              history.push("/registration");
                            }}
                          >
                            {i18n.get("subscribeButtonTitle")}
                          </Button>
                        </Box>
                      </Box>
                    </Otherwise>
                  </Choose>
                </When>
                <Otherwise>
                  <div className={"Loading"}>
                    <Spinner style={{ marginTop: 200 }}>Chargement...</Spinner>
                  </div>
                </Otherwise>
              </Choose>
            </Box>
          </Box>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    error: getLoadingError(state),
    userRole: getCurrentUserRole(state),
    // authStateKnown: isStateKnown(state),
    // $$user: getCurrentUser(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(PodcastTheme)),
);
