import React, { useEffect, useContext } from 'react';
import Loading from './Loading';
import Error from './Error';
import apiFetch from './api';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ThreadItem from './ThreadItem'
import { Context } from './Context';

const styles = makeStyles((theme) => {
  return {
    root: {
      margin:'1em 0',
      maxHeight:'75vh',
      overflow:'auto',
    },
  };
});

export default function ThreadList({}) {
  const classes = styles();

  const {currentThread, setCurrentThread} = useContext(Context);

  const [list, setList] = React.useState(null);
  useEffect(() => {
    fetchData("list_thread");
    let timer=setInterval(()=>fetchData("list_thread"), 5000)
    return ()=>{clearInterval(timer)};
  }, [currentThread]);

  const [error, setError] = React.useState(null);

  const fetchData = (action) => {
    apiFetch(action)
      .then((data) => {
        //console.log('fetchData ok', data);
        setList(data['list']);
        setError(null);
      })
      .catch(({ response, error }) => {
        console.log('fetchData error', error, response);
        setList(false);
        setError(error);
      });
  };

  let count = list ? list.length : 0;

  return (
    <div className={classes.root}>
      <Choose>
        <When condition={list === null}>
          <Box my={6} align="center">
            <Loading />
          </Box>
        </When>
        <When condition={!error && count === 0}>
          <Box my={6}>
            <Typography variant="h5" gutterBottom={true} className="">
              Aucune discussion
            </Typography>
          </Box>
        </When>
        <When condition={list}>
          {Object.keys(list).map((threadID) => (
            <ThreadItem key={threadID} id={threadID} name={list[threadID]}/>
          ))}
        </When>
        <Otherwise>
          <Error>{error}</Error>
        </Otherwise>
      </Choose>
    </div>
  );
}
