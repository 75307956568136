import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const styles = makeStyles((theme) => {
  return {
    root: {
      margin:".5em",
      padding:".5em",
      backgroundColor:'#ffdea3',
      border:'#ff7e7e 1px solid',
      borderRadius:'3px',
      textAlign:'left',
    },
  };
});


export default function Error({children}) {
  const classes = styles();

  return (
    <Box className={classes.root}>
      Erreur : {children}
  </Box>
  );
}
