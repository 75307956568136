import React from "react";
import { connect } from "react-redux";
import config from "config/api";
import Form from "components/UI/Form/Form";
import DynamicForm from "components/UI/DynamicForm";
import { getCurrentUserId } from "ducks/authentication";

class AccountSettingsForm extends Form {
  componentDidMount() {
    this._setupState();
  }

  _getI18n() {
    this.i18n = {
      send: "Enregistrer",
      sending: "Enregistrement...",
    };
  }

  render() {
    const { currentUserId } = this.props;
    const moduleConfig = {
      module: "account",
      fetchUrl: config.account + "/edit",
      postUrl: config.account + "/edit",
      formTitle: false,
      redirectAfterEdit: false,
      afterEdit: "log",
      afterEditLog: "Vos modifications ont bien été enregistrées",
    };
    return (
      <DynamicForm
        moduleConfig={moduleConfig}
        id={currentUserId}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ($$state) => {
  return {
    currentUserId: getCurrentUserId($$state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettingsForm);
