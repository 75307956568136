import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { menuColor } from 'helpers/colors';
import HeaderMenuItem from 'components/UI/Header/HeaderMenuItem'
import { getCurrentUser } from 'components/UI/Account/ducks/authentication';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import config from 'config/api';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { getAvailableEvents, getReplayEvents } from '../../../ducks/asyncFetcher';

const styles = (theme) => {
  return {
    headerMenu:{

    },
    button: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      fontWeight: 'normal',
      borderRadius: 'initial',
      fontSize: '1rem',
      color: menuColor || theme.palette.text.light,
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: {},
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.selected': {
        //borderBottom: '1px '+theme.palette.primary.main+' solid',
        fontWeight:'bold',
      },
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      '& [class*="MuiButton-label"]': {
        padding: 0,
      },
      '&:after': {
        content: '" "',
        position: 'absolute',
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        width: 0,
        height: 1,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    },
  };
};

class HeaderMenu extends Component {
  contructor(props) {
    this.super(props);
  }

  render() {
    const { current, classes, $$user, $$availableEvents} = this.props;
    const $$menu = config.getMenu($$user, { asImmutable: true }, $$availableEvents);
    return (
      <Box className={clsx(classes.headerMenu, "headerMenu")}>
        {$$menu
          .map((menu, i) => {
            return (<HeaderMenuItem 
                key={menu}
                item={menu.toJS()}
                selected={current === menu.get('icon')}
                classes={{ button: classes.button }}
              />)
            /*
            if (menu.get('target') === '_blank')
              return (
                <Button
                  classes={{ root: classes.button }}
                  className={'headerButton' + (current === menu.get('icon') ? ' selected' : '')}
                  key={'lnk_' + i}
                  aria-label={menu.get('title')}
                  onClick={() => {
                    console.log('clicked!');
                    window.open(menu.get('url'));
                  }}
                  disableRipple
                >
                  {menu.get('title')}{' '}
                </Button>
              );
            else
              return (
                <Button
                  classes={{ root: classes.button }}
                  className={current === menu.get('icon') ? ' selected' : ''}
                  key={'lnk_' + i}
                  aria-label={menu.get('title')}
                  component={Link}
                  to={menu.get('url')}
                >
                  {menu.get('title')}{' '}
                </Button>
              );
            */
          })
          .toArray()}
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    $$user: getCurrentUser(state),
    $$availableEvents: getAvailableEvents(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
  };
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderMenu)),
);
