import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import apiFetch from './api';
import { Context } from './Context';

import { makeStyles } from '@material-ui/core/styles';
const styles = makeStyles((theme) => {
  return {
    root: {
    },
  };
});

export default function Menu({ }) {
  const classes = styles();
  const {setCurrentThread} = useContext(Context);

  const onClick = (event) => {
    console.log(event);
    apiFetch("create_thread")
      .then((data) => {
        //console.log('fetchData ok', data);
        if(data.thread!==undefined && data.thread.id)
          setCurrentThread(data.thread.id);
        else
          setCurrentThread(null);
      })
      .catch(({ response, error }) => {
        console.log('fetchData error', error, response);
        setCurrentThread(null);
      });
  };


  return (
    <Box className={classes.root}>
      <Box className={classes.items}>
          <Button color="primary" variant="contained" className={classes.button} onClick={(event) => onClick(event)}>Nouvelle discussion</Button>
      </Box>
    </Box>
  );
}
