import React, { Component } from "react";
import EventIntro from "../Event/EventIntro";
import EventIntroCondensed from "../Event/EventIntroCondensed";
// import config from 'config/api';
// import LiveHelp from '../Lives/LiveHelp';
// import AvailableEvents from 'components/Event/AvailableEvents';
import EventSelector from "components/Event/EventSelector";
import HomeHelp from "components/Home/HomeHelp";
import BlockRatio from "components/BlockRatio";
import BlockButton from "components/UI/BlockButton";
import Immutable from "immutable";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { formatDate } from "helpers/stringUtils";
import RemainingTime from "components/UI/RemainingTime";
import ThemeSelector from "components/Theme/ThemeSelector";
import getRemainingTime from "helpers/getRemainingTime";
import WarningIcon from "@material-ui/icons/Warning";
import config from "config/api";
import gotoLiveRoom from "helpers/gotoLiveRoom";
import i18n from "helpers/i18n";

const styles = (theme) => {
  return {
    root: {
      maxWidth: "1280px",
      margin: "0 auto",
    },
    headerImage: {
      width: "100%",
      height: "100%",
      // minHeight: 100,
      maxHeight: 400,
      maxWidth: 200,
    },
    headerImageWrapper: {
      padding: theme.spacing(0),
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(4) * -1,
        padding: 0,
      },
    },
    headerContent: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: "center",
      },
    },
    bgSpacerTop: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% 50%",
      backgroundSize: "cover",
      height: 138,
      backgroundImage:
        "url(" +
        require("assets/images/" +
          process.env.REACT_APP_SITE +
          "/halfcircle_top.png") +
        ")",
      marginBottom: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        height: 70,
        marginBottom: theme.spacing(3),
      },
    },
    bgSpacerBottom: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% 50%",
      backgroundSize: "cover",
      height: 138,
      backgroundImage:
        "url(" +
        require("assets/images/" +
          process.env.REACT_APP_SITE +
          "/halfcircle.jpg") +
        ")",
      // marginBottom: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        height: 70,
        // marginBottom: theme.spacing(3)
      },
    },
    avatar: {
      width: "100%",
      height: "100%",
    },
    inline: {
      display: "inline-block",
      marginRight: 10,
    },
    emphasis: {
      color: theme.palette.secondary.main,
    },
    legend: {
      marginTop: 0,
      display: "inline",
      verticalAlign: "middle",
      fontSize: "24px",
    },
    legendButton: {
      display: "inline",
      verticalAlign: "middle",
      marginLeft: 20,
      backgroundColor: "transparent",
      backgroundImage: "none",
      boxShadow: "none",
      padding: 0,
      textTransform: "none",
      textDecoration: "underline",
      color: theme.palette.primary.main,
    },
    titleBig: {
      textAlign: "left",
      // [theme.breakpoints.down("md")]: {
      // fontSize: "2rem",
      // },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.4rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "2rem",
        marginBottom: 5,
        paddingLeft: 0,
        paddingRight: 0,
        // fontSize: "1.5rem",
        textAlign: "center",
      },
    },
    titleSmall: {
      fontSize: "1.4rem",
    },
    bg: {
      backgroundColor: "#f7f8fa",
    },
    wrapper: {
      // marginTop: -190,
      marginTop: -140,
      [theme.breakpoints.down("md")]: {
        marginTop: -60,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: -100,
      },
    },
    wrapperSmall: {
      marginTop: -160,
      [theme.breakpoints.down("sm")]: {
        marginTop: -60,
      },
    },
  };
};
class HomeVisitor extends Component {
  render() {
    const { classes, size } = this.props;

    const maxWidth = size == "small" ? 720 : 1024;
    return (
      <Box>
        <Box className={classes.bgSpacerTop}></Box>
        <Box
          pb={4}
          maxWidth={maxWidth}
          mx={"auto"}
          className={clsx(classes.wrapper, {
            [classes.wrapperSmall]: size === "small",
          })}
        >
          <Grid
            container={true}
            spacing={0}
            alignItems="center"
            justify="space-around"
          >
            <Grid item={true} xs={12} sm={2}>
              <Box align="left" className={classes.headerImageWrapper}>
                <img
                  src={require("assets/images/" +
                    process.env.REACT_APP_SITE +
                    "/header.png")}
                  alt="header"
                  className={classes.headerImage}
                />
              </Box>
            </Grid>
            <Grid item={true} xs={12} sm={10} container={true}>
              <Grid item={true} xs={12}>
                <Box align={"center"} mb={1} className={classes.headerContent}>
                  <Typography
                    variant={"h1"}
                    className={clsx(classes.titleBig, {
                      [classes.titleSmall]: size === "small",
                    })}
                  >
                    <span className={classes.inline}>
                      {i18n.get("homeTitle1", { prettyfy: false })}
                    </span>
                    <span className={classes.inline}>
                      {i18n.get("homeTitle2", { prettyfy: false })}
                    </span>
                    <span className={classes.inline}>
                      {i18n.get("homeTitle3", { prettyfy: false })}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

export default withRouter(withStyles(styles)(HomeVisitor));
