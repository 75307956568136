import React, { Component } from "react";
import config from "config/api";
import Immutable from "immutable";
import BlockButton from "components/UI/BlockButton";
// import Input from 'components/UI/Form/Input';
// import { formatDate } from 'helpers/stringUtils'
import colors from "config/colors";
import { withStyles } from "@material-ui/core/styles";
// import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  addButton: {
    backgroundColor: "transparent",
    padding: 0,
    boxShadow: "0 5px 20px rgba(0,0,0,0.21)",
    "&:hover": {
      boxShadow: "0 5px 5px rgba(0,0,0,0.21)",
      backgroundColor: "transparent",
    },
  },
  addIcon: {
    width: 50,
    height: 50,
    color: "#EB9250",
    verticalAlign: "middle",
    margin: "-5px",
  },
  color: {
    color: "#585854",
  },
  colorTitle: {
    color: colors.primaryColor,
  },
});

class EventSendInvite extends Component {
  constructor(props) {
    super(props);
    this.state = { sending: false, log: false, email: false };
    this._send = this._send.bind(this);
  }
  _validateEmail($$field, value) {
    let check = true;
    if (value !== undefined)
      check = !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
    const log =
      check === true
        ? undefined
        : "Le champs " + $$field.get("label") + " est invalide";
    return log;
  }
  _send(e) {
    const {
      $$event,
      $$events,
      // onClose,
      // registerFor,
    } = this.props;
    const { sending } = this.state;

    if (sending === true) return;

    const url = config.eventSendInvite;

    this.setState({ sending: true, log: false });
    var formData = new FormData();
    if ($$event !== undefined) formData.append("rid", $$event.get("id"));
    else if ($$events !== undefined)
      formData.append(
        "rids",
        JSON.stringify($$events.map((e) => e.get("id")).toArray()),
      );
    const check = this._validateEmail(
      Immutable.fromJS({ label: "Email" }),
      this.props.email,
    );
    if (check !== undefined) {
      // console.log('NOT OK', check)
      this.setState({ log: check, sending: false });
      return;
    } else {
      // console.log('check ok')
      formData.append("semail", this.props.email);
    }

    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.invitation && response.invitation === "complete") {
          if (this.props.onClose) this.props.onClose();
          if (this.props.onSendInvitationComplete)
            this.props.onSendInvitationComplete();
        } else if (
          response.invitation &&
          response.invitation === "unknown_subsriber"
        ) {
          if (this.props.onSendInvitationError)
            this.props.onSendInvitationError("unknown_subsriber", {
              email: this.props.email,
            });
        } else if (
          response.invitation &&
          response.invitation === "already_sent"
        ) {
          if (this.props.onSendInvitationError)
            this.props.onSendInvitationError("invitation_already_sent", {
              log: response.log,
            });
        } else {
          this.setState({ sending: false });
        }
      });
  }
  render() {
    const {
      email,
      // classes,
      // style,
    } = this.props;
    const {
      // visibleMode,
      sending,
      // log,
    } = this.state;

    // console.log('DEBUG', $$event)
    return (
      <BlockButton
        label={"Envoyer une invitation à " + email}
        style={{ display: "inline-block", margin: "0 5px" }}
        onClick={this._send}
        disabled={sending}
      ></BlockButton>
    );
  }
}

// export default ;
export default withStyles(styles)(EventSendInvite);
