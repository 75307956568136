import React, { useEffect, useReducer } from 'react';
import config from 'config/api';
import Template from 'components/Template/Template';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

import './Serie.css';
import SeriesFilters from 'components/UI/Serie/SeriesFilters';
import SeriesList from 'components/UI/Serie/SeriesList';
import EpisodePlayer from 'components/UI/Episode/Player';
import PodcastShow from 'components/Podcast/PodcastShow';
import SerieDetails from './SerieDetails';
import NoMatch from 'components/NoMatch';
import NotConnected from 'components/UI/NotConnected';
import { isLoggedIn, getCurrentUserRole } from 'components/UI/Account/ducks/authentication';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  return {
    root: {},
    main: {
      width: '100%',
    },
    header: {
      padding: '20px',
      color: '#fff',
      backGroundColor: '#63666a',
    },
    title: {
      color: '#fff',
    },
    description: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
  };
});
const serieConfig = {
  replay: {
    base: '/MesReplays',
    title: 'Mes replays',
    menus: {
      bpco: { label: 'BPCO', path: 'BPCO', img: require('assets/images/test1.png') },
      asthme: { label: 'Asthme', path: 'Asthme', img: require('assets/images/test2.png') },
      parcours: { label: 'Parcours de Soins', path: 'ParcoursSoins', img: require('assets/images/test3.png') },
      tech: { label: 'Pneumo Innovations', path: 'PneumoInnovations', img: require('assets/images/test4.png') },
      tout: { label: 'Voir tout', path: 'Tout' },
    },
  },
  podcast: {
    base: '/MesPodcasts',
    title: 'Mes podcasts',
    menus: {
      bpco: { label: 'BPCO', path: 'BPCO', img: require('assets/images/test1.png') },
      asthme: { label: 'Asthme', path: 'Asthme', img: require('assets/images/test2.png') },
      tout: { label: 'Voir tout', path: 'Tout' },
    },
  },
};

export default function SeriesHome({ type, category, subtype, id }) {
  const classes = styles();
  console.log({ category });
  const title = config.serie[type].title;
  const description = category !== undefined && config.serie[type].menus[category].description !== undefined ? config.serie[type].menus[category].description : '';

  //const isLoggedIn = useSelector(isLoggedIn);
  //console.log({isLoggedIn});

  return (
    <Template current="series" className={classes.root}>
      <Box className={classes.main}>
        <If condition={category !== undefined}>
          <Box className={clsx(classes.header, 'SeriesHeader', 'categ_' + category)}>
            <Typography variant="h1" component="div" gutterBottom className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {description}
            </Typography>
            <SeriesFilters type={type} category={category} />
          </Box>
          <Choose>
            <When condition={subtype == 'episode'}>
              <EpisodePlayer id={id} />
            </When>
            <When condition={subtype == 'podcast'}>
              <PodcastShow id={id} embed={true} />
            </When>
            <When condition={subtype == 'serie'}>
              <SerieDetails id={id} category={category} />
              <SeriesList type={type} category={category} />
            </When>
            <Otherwise>
              <SeriesList type={type} category={category} />
            </Otherwise>
          </Choose>
          <Else />
          <NoMatch embed={true} />
        </If>
      </Box>
    </Template>
  );
}
