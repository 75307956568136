import React from "react";
// import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import Immutable from "immutable";
import clsx from "clsx";
// import {
//   Redirect,
// } from 'react-router-dom'
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Form from "components/UI/Form/Form";
import {
  send as formSubmitAction,
  hasSentFailed as isFormErrored,
  getLog as getFormLog,
  getError as getFormError,
  isSending as isFormRunning,
  isSent as isFormComplete,
} from "ducks/contact";
import { getCurrentUser } from "ducks/authentication";

class ContactForm extends Form {
  constructor(props) {
    super(props);
    this.state = {};

    let FIELDS_FULL = [
      {
        id: "email",
        name: "email",
        label: "Email",
        validation: ["notEmpty", "email"],
        maxLength: 64,
        sizes: {
          xs: 12,
        },
      },
      {
        id: "email2",
        name: "email2",
        label: "Email (confirmation)",
        validation: ["notEmpty", "email"],
        maxLength: 64,
        sizes: {
          xs: 12,
        },
      },
      {
        id: "firstname",
        name: "firstname",
        label: "Prénom",
        validation: ["notEmpty", "alphaNum"],
        maxLength: 64,
        sizes: {
          sm: 6,
        },
      },
      {
        id: "name",
        name: "name",
        label: "Nom",
        validation: ["notEmpty", "alphaNum"],
        maxLength: 64,
        sizes: {
          sm: 6,
        },
      },
      {
        id: "city",
        name: "city",
        label: "Ville",
        validation: ["notEmpty", "alphaNum"],
        maxLength: 64,
        sizes: {
          sm: 6,
        },
      },
      {
        id: "cell",
        name: "cell",
        label: "Téléphone (mobile)",
        validation: ["cell"],
        sizes: {
          sm: 6,
        },
      },
      {
        id: "subject",
        name: "subject",
        label: "Sujet",
        type: "select",
        categoryTrigger:{
          'technique':'technique',
          'labo':'labo',
          'registration':'registration',
          'connection':'registration',
        },
        options: [
          
          {
            value: "registration",
            label: "Problème d'inscription",
          },
          {
            value: "connection",
            label: "Problème de connexion",
          },
          /*
          {
            value: "fiches",
            label: "Question fiches patients",
          },
          */
          /*{'value': 'technique', 'label': 'Question technique'},*/
        ],
        validation: ["notEmpty", "alphaNum"],
        maxLength: 128,
        sizes: {
          xs: 12,
        },
      },
    ];
    const FIELDS_LIGHT = [
      {
        id: "email",
        name: "email",
        label: "Email",
        validation: ["notEmpty", "email"],
        maxLength: 64,
        sizes: {
          xs: 12,
        },
      },
      {
        id: "name",
        name: "name",
        label: "Nom",
        validation: ["notEmpty", "alphaNum"],
        maxLength: 64,
        sizes: {
          sm: 6,
        },
      },
      {
        id: "cell",
        name: "cell",
        label: "Téléphone (mobile)",
        validation: ["cell"],
        sizes: {
          sm: 6,
        },
      },
      {
        id: "subject",
        name: "subject",
        label: "Sujet",
        type: "select",
        options: [
          {
            value: "registration",
            label: "Problème d'inscription",
          },
          {
            value: "connection",
            label: "Problème de connexion",
          },
          // {'value': 'other', 'label': 'Autre'},
        ],
        validation: ["notEmpty", "alphaNum"],
        maxLength: 128,
        sizes: {
          xs: 12,
        },
      },
      // {
      //   id: 'message',
      //   name: 'message',
      //   label: 'Message',
      //   multiline: true,
      //   validation: ['notEmpty', 'alphaNum'],
      //   sizes: {xs: 12},
      //   maxLength: 2000,
      //   rows: 4
      // }
    ];

    console.log("userRole is", props.userRole);
    if(props.userRole!==undefined){
      FIELDS_FULL.forEach((field, pos)=>{
        if(field['id']=='subject')
          FIELDS_FULL[pos].options.push(
              {'value': 'labo', 'label': 'Question laboratoire'});
      });
      FIELDS_FULL.push({
        id: 'message',
        name: 'message',
        label: 'Message',
        multiline: true,
        validation: ['notEmpty'],
        sizes: {xs: 12},
        maxLength: 2000,
        rows: 4,
        category:'labo',
        helperText:"Si vous voulez signaler un effet indésirable lié à un médicament dont vous avez eu connaissance, vous pouvez le signaler sur le [link=https://signalement.social-sante.gouv.fr/#/accueil]Portail de signalement des événements sanitaires indésirables[/link] conformément aux exigences réglementaires en vigueur ou à Chiesi SAS en adressant un email à [link=mailto:pharmacovigilance-france@chiesi.com]pharmacovigilance-france@chiesi.com[/link]",
      }
   );
    }
    if (props.userRole === "staff" || props.userRole === "speaker")
      this.$$FIELDS = Immutable.fromJS(FIELDS_LIGHT);
    else this.$$FIELDS = Immutable.fromJS(FIELDS_FULL);
    // this.$$FIELDS = $$FIELDS;
    // this._onFormSubmit = this._onFormSubmit.bind(this);
    // this._onFieldValueChange = this._onFieldValueChange.bind(this);
  }
  componentDidMount() {
    this._setupState();
    this._mapRawValues(this.props.$$formValues);
  }
  _mapRawValues($$values) {
    if (
      $$values === undefined ||
      !$$values.get ||
      $$values.get("email") === undefined
    )
      return;
    var o = {
      // accountEmail: $$values.get('accountEmail'),
      // accountName: $$values.get('lastname'),
      // accountLastname: $$values.get('lastname'),
      // accountJob: $$values.get('job'),
      // accountCell: $$values.get('cell'),
      // accountCity: $$values.get('city'),
      // accountDpt: $$values.get('dpt'),
      // accountPlace: $$values.get('place'),
      // accountFaculty: $$values.get('faculty'),
      // accountCid: $$values.get('cid'),
      // accountRpps: $$values.get('rpps'),
      email: $$values.get("email"),
      // email2: $$values.get('email'),
      name: $$values.get("lastname"),
      lastname: $$values.get("lastname"),
      firstname: $$values.get("firstname"),
      cell: $$values.get("cell"),
      city: $$values.get("city"),
    };
    // return Immutable.fromJS(o);
    // return $$values;
    // var tmp = {}
    // $$values.map((val, k) => {
    Immutable.fromJS(o).map((val, k) => {
      this._onFieldValueChange(null, {
        id: k,
        value: val,
      });
      return true;
    });
    // return $$values;
    return Immutable.fromJS(o);
  }
  render() {
    const {
      // error,
      // loading,
      className,
      log,
      isFormComplete,
      // userRole,
    } = this.props;
    //
    // if (isFormComplete) {
    //   const url ='/contact'
    //   return <Redirect to={{
    //       pathname: url,
    //       state: { from: this.props.location }
    //     }}/>
    //   }
    return (
      <div className={clsx("Form", className)}>
        <If condition={!isFormComplete}>{this._renderForm()}</If>
        <If condition={isFormComplete}>
          <Box align="center">
            <Typography variant="h5" gutterBottom>
              {log}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Nous vous recontacterons dans les plus brefs délais.
            </Typography>
          </Box>
        </If>
      </div>
    );
  }
}
const mapStateToProps = ($$state) => {
  return {
    isFormErrored: isFormErrored($$state),
    log: getFormLog($$state),
    error: getFormError($$state),
    loading: isFormRunning($$state),
    isFormComplete: isFormComplete($$state),
    $$formValues: getCurrentUser($$state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (formData) => {
      dispatch(formSubmitAction(formData));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
