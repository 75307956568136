/*eslint-disable no-unused-vars*/

import mirrorCreator from "mirror-creator";
import Immutable from "immutable";
import pipe from "helpers/redux-state-mutators.js";
import { createSelector } from "reselect";
import isBlank from "helpers/isBlank";
import queryString from "query-string";
import getFetchOptions from "helpers/getFetchOptions";

const actionTypes = mirrorCreator(
  [
    "SET_DATA",
    "SET_ROW_FIELD_VALUE",
    "SET_ROW_APPARENCE",
    // 'SET_TEXT_FILTER',
    "ADD_FILTER",
    "REMOVE_FILTER",
    "SET_READ_ONLY",
    "DELETE_ROW",
    "REMOVE_LIST",
  ],
  { prefix: "dynamicList/" },
);

const mutators = {
  setData: (listId, data) => ($$state) =>
    $$state.setIn([listId, "data"], Immutable.fromJS(data)),
  setRowFieldValue: (listId, rowId, fieldId, value) => ($$state) =>
    $$state.setIn(
      [listId, "data", "rows", rowId, fieldId],
      Immutable.fromJS(value),
    ),
  setRowApparence: (listId, rowId, value) => ($$state) =>
    $$state.setIn(
      [listId, "data", "apparence", rowId],
      Immutable.fromJS(value),
    ),
  deleteRow: (listId, id) => ($$state) =>
    $$state.updateIn([listId, "data", "rows"], (rows) =>
      rows.filterNot((row) => row.get("id") === id),
    ),
  // setTextFilter: (listId, filter) => $$state => $$state.setIn([listId, 'textFilter'], filter),
  addFilter: (listId, filterId, filterValue) => ($$state) =>
    $$state.setIn(
      [listId, "data", "filters", filterId, "filter_value"],
      filterValue,
    ),
  // how to remove list ?
  removeFilter: (listId, filterId) => ($$state) =>
    $$state.setIn([listId, "data", "filters", filterId, "filter_value"], null),
  setReadOnly: (listId, value) => ($$state) =>
    $$state.setIn([listId, "readOnly"], value),
  remove: (listId) => ($$state) => $$state.delete(listId),
};

export default function reducer($$state = Immutable.Map(), action) {
  switch (action.type) {
    case actionTypes.SET_DATA:
      return pipe([mutators.setData(action.listId, action.data)], $$state);

    case actionTypes.SET_ROW_FIELD_VALUE:
      return pipe(
        [
          mutators.setRowFieldValue(
            action.listId,
            action.rowId,
            action.fieldId,
            action.value,
          ),
        ],
        $$state,
      );

    case actionTypes.SET_ROW_APPARENCE:
      return pipe(
        [mutators.setRowApparence(action.listId, action.rowId, action.value)],
        $$state,
      );

    case actionTypes.DELETE_ROW:
      return pipe([mutators.deleteRow(action.listId, action.id)], $$state);

    // case actionTypes.SET_TEXT_FILTER:
    //   return pipe([
    //     mutators.setTextFilter(action.listId, action.filter),
    //   ], $$state);

    case actionTypes.ADD_FILTER:
      return pipe(
        [
          mutators.addFilter(
            action.listId,
            action.filterId,
            action.filterValue,
          ),
        ],
        $$state,
      );

    case actionTypes.REMOVE_FILTER:
      return pipe(
        [mutators.removeFilter(action.listId, action.filterId)],
        $$state,
      );

    case actionTypes.SET_READ_ONLY:
      return pipe([mutators.setReadOnly(action.listId, action.value)], $$state);

    case actionTypes.REMOVE_LIST:
      return pipe([mutators.remove(action.listId)], $$state);

    default:
      return $$state;
  }
}

export const makeSelectors = (listId) => {
  const selectors = {};
  const getRoot = (selectors.getRoot = (state) =>
    state.dynamicList.get(listId, Immutable.Map()));

  const getIsDataLoaded = (selectors.getIsDataLoaded = createSelector(
    [getRoot],
    ($$state) => $$state.get("data") !== undefined,
  ));

  const getData = (selectors.getData = createSelector([getRoot], ($$state) =>
    $$state.get("data", Immutable.Map()),
  ));

  const getFields = (selectors.getFields = createSelector([getData], (data) =>
    data.get("fields"),
  ));

  const getRows = (selectors.getRows = createSelector([getData], (data) =>
    data.get("rows"),
  ));

  const getApparence = (selectors.getApparence = createSelector(
    [getData],
    (data) => data.get("apparence"),
  ));

  const getActions = (selectors.getActions = createSelector([getData], (data) =>
    data.get("actions"),
  ));

  const getFilters = (selectors.getFilters = createSelector([getData], (data) =>
    data.get("filters"),
  ));

  // const getToDelete = selectors.getToDelete = createSelector(
  //   [getData],
  //   data => data.get('toDelete')
  // );

  const getTextFilter = (selectors.getTextFilter = createSelector(
    [getRoot],
    (state) => state.get("textFilter"),
  ));
  //
  const textValue = (value) => {
    switch (typeof value) {
      case "string":
        return value;
      case "number":
        return value.toString();
      default:
        return undefined;
    }
  };

  const rowMatchTextFilter = ($$row, filter) => {
    return $$row.some((value) => {
      const text = textValue(value);
      return text && text.indexOf(filter) !== -1;
    });
  };

  const getFilteredRows = (selectors.getFilteredRows = createSelector(
    [getRows, getTextFilter],
    ($$rows, filter) => {
      if (isBlank(filter)) return $$rows;

      return $$rows.filter(($$row) => rowMatchTextFilter($$row, filter));
    },
  ));

  const isReadOnly = (selectors.isReadOnly = createSelector(
    [getRoot],
    ($$state) => $$state.get("readOnly"),
  ));

  const getLinkMap = (selectors.getLinkMap = createSelector([getData], (data) =>
    data.get("linkMap", Immutable.Map()),
  ));

  const getPagination = (selectors.getPagination = createSelector(
    [getData],
    (data) => data.get("pagination", Immutable.Map()),
  ));

  const getCurrentPage = (selectors.getCurrentPage = createSelector(
    [getPagination],
    (pagination) => (pagination ? pagination.get("currentPage") : undefined),
  ));

  const getPageCount = (selectors.getPageCount = createSelector(
    [getPagination],
    (pagination) => (pagination ? pagination.get("pageCount") : undefined),
  ));

  const getCurrentLimit = (selectors.getCurrentLimit = createSelector(
    [getPagination],
    (pagination) => (pagination ? pagination.get("pageLimit") : undefined),
  ));

  const getPageTotal = (selectors.getPageTotal = createSelector(
    [getPagination],
    (pagination) => (pagination ? pagination.get("total") : undefined),
  ));

  return selectors;
};

export function makeActionCreators(listId) {
  const addListId = (action) => ({ ...action, listId });
  const actionCreators = {};

  const initList = (actionCreators.initList = (moduleConfig) => {
    // console.log('initing with config', moduleConfig)
    return fetchData(moduleConfig);
  });
  const fetchData = (actionCreators.fetchData = (
    moduleConfig,
    options = {},
  ) => {
    // console.log('FILTER IS', moduleConfig.filter)
    let fetchUrl = moduleConfig.fetchUrl;

    let query = {};
    if (moduleConfig.filter) {
      query = {
        ...query,
        ...queryString.parse(moduleConfig.filter),
      };
    }
    if (options.page) query.pp = options.page;

    if (options.limit) query.pl = options.limit;
    // console.log(options)
    if (options.orderBy && options.orderWay) {
      query.sf = options.orderBy;
      query.sw = options.orderWay;
    }
    if (options.filterBy !== undefined && options.filterValue !== undefined) {
      query.ff = options.filterBy;
      query.fv = options.filterValue;
    }
    // console.log('TEST',fetchUrl, fetchUrl.match(pattern))
    var pattern = /\?/;
    fetchUrl =
      fetchUrl +
      (fetchUrl.match(pattern) ? "&" : "?") +
      queryString.stringify(query);
    // console.log('FINAL ', fetchUrl)
    return (dispatch) => {
      fetch(fetchUrl, getFetchOptions())
        .then((response) => response.json())
        .then((response) => {
          // console.log('setting data', response)

          // // TODO: supprimer ce code quand la pagination est les data
          // response.data = {
          //   ...response.data,
          //   pagination: {
          //     currentPage: 3,
          //     pageCount: 10,
          //   }
          // }

          dispatch(
            addListId({
              type: actionTypes.SET_DATA,
              data: response.data,
            }),
          );
        });
    };
  });

  // const setTextFilter = actionCreators.setTextFilter = (filter) => {
  //   return addListId({
  //     type: actionTypes.SET_TEXT_FILTER,
  //     filter,
  //   });
  // }
  const setRowFieldValue = (actionCreators.setRowFieldValue = (
    rowId,
    fieldId,
    value,
  ) => {
    return (dispatch, getState) => {
      // console.log(rowId, fieldId, value)
      dispatch(
        addListId({
          type: actionTypes.SET_ROW_FIELD_VALUE,
          rowId,
          fieldId,
          value,
        }),
      );
    };
  });

  const setRowApparence = (actionCreators.setRowApparence = (rowId, value) => {
    return (dispatch, getState) => {
      dispatch(
        addListId({
          type: actionTypes.SET_ROW_APPARENCE,
          rowId,
          value,
        }),
      );
    };
  });

  const deleteRow = (actionCreators.deleteRow = (moduleConfig, id) => {
    return (dispatch, getState) => {
      const state = getState();
      // const conf = confirm("Êtes vous sûr de vouloir supprimer cet élément?\n");
      // if (!conf)
      // return;

      dispatch(setReadOnly());
      let url = moduleConfig.deleteUrl;
      var data = { id: id };
      // console.log('foo', data)
      // const fetchOptions = {
      //   credentials: 'include',
      //   method: moduleConfig.submitMethod || 'POST',
      //   headers: {
      //   'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
      //   'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      //   },
      //   body: queryString.stringify(data, {arrayFormat: 'bracket'})
      // };
      fetch(url, getFetchOptions("POST", data))
        .then((response) => response.json())
        .then((response) => {
          // setTimeout(() => {
          // dispatch({
          //   type: actionTypes.SET_DATA,
          //   data: getData(state).deleteIn(['rows', index]).toJS(),
          // });

          // this.setState({openedPanels: this.state.openedPanels.delete($$row.get('id'))});
          if (response.deleted && response.deleted === "complete") {
            dispatch(
              addListId({
                type: actionTypes.DELETE_ROW,
                id,
              }),
            );
            //faire une action deleteRow
            dispatch(setReadOnly(false));
          }
        });
    };
  });

  const setReadOnly = (actionCreators.setReadOnly = (value = true) => {
    return addListId({
      type: actionTypes.SET_READ_ONLY,
      value,
    });
  });

  const removeList = (actionCreators.removeList = () =>
    addListId({
      type: actionTypes.REMOVE_LIST,
    }));

  const changePage = (actionCreators.changePage = (moduleConfig, page) =>
    fetchData(moduleConfig, { page }));

  const changeLimit = (actionCreators.changeLimit = (moduleConfig, limit) =>
    fetchData(moduleConfig, { limit }));

  const changeOrder = (actionCreators.changeOrder = (
    moduleConfig,
    orderBy,
    orderWay,
  ) => fetchData(moduleConfig, { orderBy, orderWay }));

  const changeFilter = (actionCreators.changeFilter = (
    moduleConfig,
    filterBy,
    filterValue,
  ) => fetchData(moduleConfig, { filterBy, filterValue }));
  // const changeFilter = actionCreators.changeFilter = (moduleConfig, filterId, filterValue) => addListId({
  //   type: actionTypes.ADD_FILTER,
  //   filterId, filterValue
  // });
  //  addFilter(filterBy, filterValue)

  return actionCreators;
}
