import mirrorCreator from 'mirror-creator';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import pipe from 'helpers/redux-state-mutators.js';
import queryString from 'query-string';
import config from 'config/api';
import getFetchOptions from 'helpers/getFetchOptions';
// import { getToken } from 'components/UI/Account/ducks/authentication';

const actionTypes = mirrorCreator(['SET_PODCAST_THEME_DATA', 'SET_PODCAST_THEME_ERROR', 'CLEAR_PODCAST_THEME'], { prefix: 'podcast/' });

const mutators = {
  setPodcastThemeDetails: (theme) => ($$state) => $$state.set('details', theme),
  setPodcastThemeError: (error) => ($$state) => $$state.set('error', error),
};

export default function reducer($$state = Immutable.Map(), action) {
  let acts = [];
  switch (action.type) {
    // case actionTypes.SET_PODCAST_THEMES_DATA:
    //   acts.push(mutators.setPodcastThemesDetails(action.kind, action.podcasts));
    //   // acts.push(mutators.setSubscriptionState(false))
    //   // if (action.state === true) acts.push(mutators.clearErrors);
    //   return pipe(acts, $$state);

    case actionTypes.SET_PODCAST_THEME_DATA:
      acts.push(mutators.setPodcastThemeDetails(action.podcast));
      // acts.push(mutators.setSubscriptionState(false))
      // if (action.state === true) acts.push(mutators.clearErrors);
      return pipe(acts, $$state);

    case actionTypes.SET_PODCAST_THEME_ERROR:
      return pipe([mutators.setPodcastThemeError(action.error)], $$state);

    case actionTypes.CLEAR_PODCAST_THEME:
      return pipe([mutators.setPodcastThemeError(undefined), mutators.setPodcastThemeDetails(undefined)], $$state);

    default:
      return $$state;
  }
}

export const getRoot = (state) => state.podcastTheme || Immutable.Map();
export const getPodcastThemeDetails = createSelector([getRoot], ($$state) => $$state.get('details'));
export function fetchPodcastThemeDetails({ themeId, params }) {
  // console.log({login, password})
  return (dispatch) => {
    let url;
      url = config.podcastTheme + '/get/'+ themeId;

    if (params !== undefined && params.k !== undefined && params.r === undefined) url = url+'?k='+params.k;
    if (params !== undefined && params.r !== undefined && params.k === undefined) url = url+'?r='+params.r;
    if (params !== undefined && params.r !== undefined && params.k !== undefined) url = url+'?k='+params.k+'&r='+params.r;
    let formDetails = {};
    formDetails.contact_form = true;

    dispatch(clearPodcastThemeDetails());

    fetch(url, getFetchOptions())
      .then((response) => response.json())
      .then((response) => {
        // console.log('RESPONSE IS', response)
        if (response.podcastTheme && response.podcastTheme === 'complete') {
          dispatch(setPodcastThemeDetails(Immutable.fromJS(response.data)));
        } else {
          dispatch(setPodcastThemeError(response.log));
        }
      })
      .catch((error) => dispatch(setPodcastThemeError(error.message)));
  };
}
// export function fetchPodcastThemes({ kind, params }) {
//   // console.log({login, password})
//   return (dispatch) => {
//     let url = config.podcasts + '/get/'+ kind || 'all';
//
//     let formDetails = {};
//
//     fetch(url, {
//       credentials: 'include',
//       method: config.urlMethod || 'post',
//       headers: {
//         Accept: 'application/json, application/xml, text/plain, text/html, *.*',
//         'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
//         'X-Requested-With': 'XMLHttpRequest', // needed by php api
//       },
//       body: queryString.stringify(formDetails),
//     })
//       .then((response) => response.json())
//       .then((response) => {
//         // console.log('RESPONSE IS', response)
//         if (response.podcasts && response.podcasts === 'complete') {
//           dispatch(setPodcastThemesDetails(kind, Immutable.fromJS(response.data)));
//         } else {
//           dispatch(setPodcastThemeError(response.log));
//         }
//       })
//       .catch((error) => dispatch(setPodcastThemeError(error.message)));
//   };
// }

// export function clearPodcastTheme() {
//   return (dispatch) => {
//     dispatch(clearPodcastTheme());
//     dispatch(clearSubscritionState());
//   }
// }
//
export function setPodcastThemesDetails(kind, podcasts) {
  return {
    type: actionTypes.SET_PODCAST_THEMES_DATA,
    kind, podcasts,
  };
}
export function setPodcastThemeDetails(podcast) {
  return {
    type: actionTypes.SET_PODCAST_THEME_DATA,
    podcast,
  };
}
export function setPodcastThemeError(error) {
  return {
    type: actionTypes.SET_PODCAST_THEME_ERROR,
    error,
  };
}
export function clearPodcastThemeDetails() {
  return {
    type: actionTypes.CLEAR_PODCAST_THEME,
  };
}
