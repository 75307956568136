import React from "react";
import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import { Link } from 'react-router-dom';
import config from "config/api";
import DynamicForm from "components/UI/DynamicForm";
// import BlockButton from 'components/UI/BlockButton'
import i18n from "helpers/i18n";
const styles = {
  padder: {
    padding: "20px 40px",
    margin: "0 30px 20px",
  },
  h5: {
    textAlign: "center",
  },
  h6: {
    fontWeight: "normal",
  },
  body1: {
    textAlign: "center",
  },
};
class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // formComplete: false,
      // logMessage: false
      formComplete: false,
      logMessage: false,
    };
    this._onFormComplete = this._onFormComplete.bind(this);
  }
  _onFormComplete(responseData) {
    // console.log('COMPLETe', this.props.onFormComplete)
    const logMessage = responseData.accountJob === "st" ? "st" : "md";
    if (this.props.onFormComplete) this.props.onFormComplete(responseData);
    else
      this.setState({
        formComplete: true,
        logMessage: logMessage,
      });
  }
  render() {
    const {
      // onClose,
      classes,
      extraFields,
    } = this.props;
    //
    const {
      formComplete,
      // logMessage,
    } = this.state;
    const moduleConfig = {
      module: "account",
      fetchUrl: config.registration + "/edit",
      postUrl: config.registration + "/edit",
      redirectAfterEdit: false,
      afterEdit: "onFormComplete",
      afterEditLog: "",
      afterEditHideForm: true,
      btCancel: false,
      btSubmitLabel: "Envoyer",
    };
    console.log(i18n.get("registrationFormFooter"));
    console.log(i18n.get("registrationFormFooter"));
    console.log(i18n.get("registrationFormFooter"));
    console.log(i18n.get("registrationFormFooter"));
    return (
      <div style={{ maxWidth: 420, margin: "0 auto 20px" }}>
        <div>
          <If condition={formComplete !== true}>
            <Typography variant={"h5"} className={classes.h5} gutterBottom>
              {i18n.get("registrationFormTitle")}
            </Typography>
            <If condition={i18n.has("registrationFormInstructions") === true}>
              <Typography variant={"h6"} gutterBottom>
                {i18n.get("registrationFormInstructions")}
              </Typography>
            </If>
            <Box align="left">
              <DynamicForm
                moduleConfig={moduleConfig}
                onFormComplete={this._onFormComplete}
                formFooter={
                  <If condition={i18n.has("registrationFormFooter")}>
                    <Typography variant="body1" component="div">
                      {i18n.get("registrationFormFooter", {
                        prettyfy: true,
                        element: "div",
                      })}
                    </Typography>
                  </If>
                }
                extraFields={extraFields}
              />
            </Box>
          </If>
          <If condition={formComplete === true && !this.props.onFormComplete}>
            Missing callback...
          </If>
          {/*<If condition={formComplete === true && !this.props.onFormComplete}>
            <Typography variant={'h5'} classes={{root: classes.h5}} gutterBottom>{i18n.get('registrationSuccessTitle', {pretty: true, element: 'span'})}</Typography>
            <If condition={i18n.has('registrationSuccessText')}>
              <Typography variant={'h6'} classes={{root: classes.h6}} gutterBottom>{i18n.get('registrationSuccessText')}</Typography>
            </If>

            <If condition={logMessage === 'x1'}>
              <Typography variant={'body1'} classes={{root: classes.body1}} gutterBottom>{i18n.get('registrationLogStudents')} </Typography>
            </If>
            <If condition={logMessage !== 'x1'}>
            <Typography variant={'body1'} classes={{root: classes.body1}} gutterBottom>{i18n.get('registrationLogSuccess')}</Typography>
            </If>

            <BlockButton label="Fermer" onClick={onClose} style={{margin: '20px auto 10px', display: 'block'}}/>
          </If>*/}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(RegistrationForm);
