import React, { Component } from "react";
import { Link } from "react-router-dom";
// import BlockRatio from "components/BlockRatio";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { formatDate } from "helpers/stringUtils";
import config from "config/api";
import getCardImageFromArray from "helpers/getCardImageFromArray";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";

const colors = {
  live: '#0075A8',
  pending: '#009EE3'
}
const legends = {
  live: 'En ligne',
  pending: 'A venir'
}
const styles = (theme) => {
  return {
    root: {
      // maxWidth: "1280px",
      // margin: "0 auto",
      // boxShadow: theme.shadows[2],
      boxShadow: 'rgba(0,0,0,0.1) 0 0 10px',

      transition: [
        theme.transitions.create("box-shadow", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      ],
      // boxShadow: theme.shadows[1],

      "&:hover": {
        boxShadow: theme.shadows[9],
        "& .MuiButton-root": {
          backgroundColor: "#fff !important",
          color: "inherit",
          border: "1px #5A5A5A solid",
        },
      },
      // "& .MuiButton-root": {
      //   "&:hover": {
      //     backgroundColor: "#fff !important",
      //     color: "inherit",
      //   },
      // },
      // '& .MuiInputLabel-formControl': {
      //   color: 'inherit'
      // },
      '& .MuiTypography-caption': {
        textAlign: 'left'
      }
    },
    clickable: {
      cursor: "pointer",
    },
    // inline: {
    //   display: "inline-block",
    // },
    cardTitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
      fontSize: '1.6rem',
      textAlign: 'center',
      color: '#0075A8',
      fontWeight: 'normal',
      [theme.breakpoints.down("md")]: {
        // fontSize: "1.4rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem",
      },
    },
    cardImage: {
      width: '100%',
      aspectRatio: 2.62,
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      // display: "inline-block",
      // verticalAlign: "bottom",
      // marginRight: 20,
    },
    cardDescription: {
      color: '#000',
      textAlign: "center",
      // minHeight: 230,
      fontSize: "1.1rem",
      lineHeight: '1.6rem',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        // minHeight: 260,
        // minHeight: 150,
      },
      [theme.breakpoints.down("md")]: {
        // minHeight: 140,
        fontSize: "1.1rem",
      },
      [theme.breakpoints.down("sm")]: {
        // minHeight: 140,
        fontSize: "1.0rem",
      },
      [theme.breakpoints.down("xs")]: {
        // minHeight: "auto",
        fontSize: "1.0rem",
      },
    },
    podCast: {
      fontSize: '1.2rem',
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: '1px #E4E4E4 solid',
      '&:last-of-type': {
        borderBottom: 'none'
      },
      // '& a': {
      //   display: 'block',
      //   textDecoration: 'none',
      //   paddingLeft: theme.spacing(2),
      //   paddingRight: theme.spacing(2),
      // },
      // '& a:hover': {
      //   backgroundColor: '#eee',
      //   // textDecoration: 'underline',
      // }
    },
    podcastState: {
      color: '#fff',
      fontSize: '.7rem',
      fontWeight: 'bold',
      letterSpacing: 'normal',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,

      // fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
      marginBottom: theme.spacing(2),
    },
    podcastTitle: {
      fontWeight: 500,
      fontSize: 'inherit',
      fontSize: '1.1rem',
    },
    podcastTag: {
      color: '#8A8A8A',
      fontSize: '1.1rem',
      fontStyle: 'normal'
    },
    dynamicColor: {
      color: "inherit",
      borderColor: "inherit",
    },

    dateLegend: {
    }
  };
};
class PodcastThemeIndexCard extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     // showLiveButton: false,
  //   };
  // }
  // componentDidMount() {
  // }
  // componentDidUpdate(oldProps) {
  // }
  // componentWillUnmount() {
  // }
  render() {
    const {
      $$cardData,
      tIdx,
      $$subscriptions,
      userRole,
      $$user,
      onSubscriptionComplete,
      classes,
      gridSize,
    } = this.props;
    const cardThumb = getCardImageFromArray($$cardData, {needleField: 'themeThumb', needleId: 'themeId', needleUrl: config.podcastthemethumbpreview});
    const themeColor = $$cardData.get("themeColor");

    // const eventsCount = $$cardData.size;

    const gridSizes = [
      {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
      },
      {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
      },
    ];
    const gs = gridSize <= 2 ? gridSize : 2;
    const now = Math.round(new Date()/1000)
    // console.log('delayed evens are', delayedEvent)
    return (
      <Grid
        key={"thm_" + tIdx}
        item={true}
        xs={gridSizes[gs].xs}
        sm={gridSizes[gs].sm}
        md={gridSizes[gs].md}
        lg={gridSizes[gs].lg}
        className={clsx(classes.root, classes.clickable)}
        onClick={(e) => {
          e.preventDefault();
          this.props.history.push('/podcast/theme/'+$$cardData.get('id'))

        }}
        >
        <Box
          color={themeColor}
          style={{
            borderColor: themeColor,
          }}
          >
          {/*<img
            src={cardThumb !== false ? cardThumb : undefined}
            className={classes.cardImage}
            />*/}
            <div className={classes.cardImage} style={{backgroundImage: cardThumb !== false ? 'url('+cardThumb+')' : undefined}}></div>

            <Typography
              variant={"h2"}
              gutterBottom={true}
              className={clsx(classes.cardTitle)}
              component={'div'}
              >
              <Paragrapher
                element={"div"}
                text={$$cardData.get("themeName")}
                prettyfy={true}
                />

            </Typography>
            <Typography
              variant={"body1"}
              gutterBottom={true}
              className={clsx(classes.cardDescription)}
              component={"div"}
              >
              <Paragrapher
                element={"div"}
                text={$$cardData.get("themeDescription")}
                prettyfy={true}
                />
            </Typography>

            <If condition={$$cardData.get('items') !== undefined && $$cardData.get('items').size > 0}>
              <Box px={0} py={0}>
                {$$cardData.get('items').groupBy(($p) => $p.get('podcastState')).sortBy(($p, pIdx) => pIdx).map(($$g, gIdx) => {
                  return (<Box mb={4} key={'g_'+gIdx}>
                  <Box>
                    <Typography variant="subtitle1" className={classes.podcastState} style={{backgroundColor: colors[gIdx]}}>
                      {legends[gIdx]}
                    </Typography>
                  </Box>
                  {$$g.map(($$p, pIdx) => {
                    return (<Box className={classes.podCast} key={'p_'+pIdx}>
                    <Typography variant="caption" gutterBottom className={classes.podcastTag}>{$$p.get('podcastTag')}</Typography>
                    <Typography variant="body1" gutterBottom className={classes.podcastTitle} style={{color: gIdx === 'live' ? colors[gIdx] : '#8A8A8A'}}>{$$p.get('podcastTitle')}</Typography>
                  </Box>)
                }).toArray()}
              </Box>)
            }).toArray()}
          </Box>
        </If>
      </Box>
    </Grid>
  );
}
}
export default withRouter(withStyles(styles)(PodcastThemeIndexCard));
