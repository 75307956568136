import config from "config/api";
import isArray from "mout/lang/isArray";

export default ($$attributes, props) => {
  const { needleField, needleUrl, needleId } = props;
  if (needleField === undefined) console.error("missing needleField");
  if (needleUrl === undefined) console.error("missing needleUrl");
  if (needleId === undefined) console.error("missing needleId");
  // console.log($$attributes.toJS(), props)
  let cardImage = false;
  // let cardPresentation = false;
  let decodedValues;
  let needleSubstituteId =
    needleSubstituteId !== undefined ? needleSubstituteId : "id";
  // const needleField = needleField ? needleField : 'themeThumb';
  if (
    $$attributes !== false &&
    $$attributes !== undefined &&
    $$attributes.get(needleField) !== undefined &&
    $$attributes.get(needleField) !== null &&
    $$attributes.get(needleField) !== false
  ) {
    try {
      decodedValues = JSON.parse($$attributes.get(needleField));
    } catch (e) {}
    // console.log('decoded is', decodedValues)
    if (
      decodedValues !== undefined &&
      isArray(decodedValues) &&
      decodedValues[0] &&
      decodedValues[0].dest
    ) {
      cardImage =
        needleUrl.replace(
          "{" + needleId + "}",
          $$attributes.get(needleSubstituteId),
        ) +
        "/" +
        decodedValues[0].dest;
    }
  }
  return cardImage;
};
