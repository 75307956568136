//global defaults for config/api.jsx
import Immutable from 'immutable';

const serieConfig={
  replay :{
    base : "/Exemple",
    title: "Exemple",
    menus :{
      categ1: { label: "Catégorie 1", path: "Categ1", description:"La cat1....", img:null/*require('assets/images/test1.png')*/ },
      tout: { label: "Voir tout", path: "Tout" },
    },
  },
};



const baseUrl = process.env.REACT_APP_BACKEND_URL;
export default {
  //newsHeight:285,//200 avant ?
  newsHeight:20,//vw
  baseUrl :baseUrl,
  home: baseUrl + '/api/home?api=3',
  profile: baseUrl + '/api/profil/get?api=3',
  legal: baseUrl + '/api/page/get/legal',
  confidentiality: baseUrl + '/api/page/get/confidentiality',
  cookies: baseUrl + '/api/page/get/cookies',
  ping: baseUrl + '/api/ping',
  login: baseUrl + '/api/auth/login',
  logout: baseUrl + '/api/auth/logout',
  registration: baseUrl + '/api/registration',
  faq: baseUrl + '/api/faq',
  replays: baseUrl + '/api/replay',
  cms: baseUrl + '/api/cms',
  account: baseUrl + '/api/account',
  message: baseUrl + '/api/message',
  orchestrator: baseUrl + "/api/orchestrator",
  quiz: baseUrl + "/api/orchestrator/quiz",
  feedback: baseUrl + "/api/feedback",
  utm: baseUrl + '/api/utm',
  news: baseUrl + '/api/news',
  newsthumbpreview: baseUrl + '/cache/news/{newsId}',
  speedtest: baseUrl + '/api/speedtest',
  stWorker: baseUrl + '/api/st',
  events: baseUrl + '/api/events',
  theme: baseUrl + '/api/theme',
  availableevents: baseUrl + '/api/availableevents',
  myevents: baseUrl + '/api/myevents',
  mypastevents: baseUrl + '/api/mypastevents',
  eventthumbpreview: baseUrl + '/cache/events/{eventId}',
  themethumbpreview: baseUrl + '/cache/themes/{eventId}',
  eventSendInvite: baseUrl + '/api/events/invite',
  eventSubscribe: baseUrl + '/api/events/subscribe',
  eventUnsubscribe: baseUrl + '/api/events/unsubscribe',
  contact: baseUrl + '/api/contact',
  rpwd: baseUrl + '/api/auth/rpwd',
  cpwd: baseUrl + '/api/auth/cpwd',
  dpwd: baseUrl + '/api/auth/dpwd',

  job: baseUrl + '/api/job',
  documentation: baseUrl + '/api/documentation',
  documentationPath: baseUrl + '/cache/documentation',
  
  serie: serieConfig,//configuration de la partie série
  //Activations NPS (note) et bouton partager
  NPS_replay:false,
  Share_replay:false,
  NPS_documentation:false,
  Share_documentation:false,
  
  fb: '',
  ld: '',
  recaptchaKey: '6Le1rTgiAAAAALkPdRx25_QlcfuqElwCEq58o0Gk',
  
  templateKind:'fullWidth',

  getJobs: (set) => {
    if (set === 1)
    return [
      { label: 'Médecin', value: 'md' },
      { label: 'Pharmacien', value: 'ph' },
      { label: 'Infirmier', value: 'in' },
      { label: 'Interne', value: 'int' },
      { label: 'Interne sans RPPS', value: 'x5' },
      // { label: 'Etudiant en pharmacie', value: 'x6' },
      { label: 'Préparateur', value: 'x7' },
    ];
    else
    return [
      { label: 'Médecin', value: 'x2' },
      { label: 'Pharmacien', value: 'x4' },
      { label: 'Infirmier', value: 'x3' },
      { label: 'Biocodex', value: 'lb' },
    ];
  },
  getJobsCategories: () => {
    return {
      md: 'isMd',
      ph: 'isPh',
      in: 'isIn',
      int: 'isInt',
      lb: 'isLb',
      au: 'isOther',
      x1: 'isX1',
      x2: 'isX2',
      x3: 'isX3',
      x4: 'isX4',
      x5: 'isX5',
      x6: 'isX6',
      x7: 'isX7',
    };
  },

  getMenu: ($$user, options) => {
    let userRole;
    if ($$user === undefined) userRole = 'visitor';
    else userRole = $$user.get('role');
    let menu = [];
    // if (userRole !== 'staff') {
      menu.push({ icon: 'home', title: 'Accueil', url: '/' });
      menu.push({ 
        icon: 'videos',
        title: 'Vidéos',
        url: '/',
        subMenu:{
            'Replays':'/videos#replays',
            'Autres vidéos':'/videos#other',
          },
        });
      menu.push({
         icon: 'documentations',
         title: 'Documentations',
         url: '/',
         subMenu:{
          'Newsletter':'/documentation#news',
          'Outils pour votre pratique':'/documentation#tools',
          'Documentation patients':'/documentation#doc',
          },
        });
      //menu.push({ icon: 'replays', title: 'Replays', url: '/replays' });
      //menu.push({ icon: '', title: 'Vidéos', url: '/videos' });
      //menu.push({ icon: '', title: 'Documentation', url: '/documentation' });
      // }
    // if (userRole === 'member' || userRole === 'speaker' || userRole === 'admin') {
    // menu.push({ icon: 'lives', title: 'Live', url: '/live'})
    // }
    // if (userRole !== 'speaker') {
    // menu.push({ icon: 'agenda', title: 'Agenda', url: '/agenda'})
    // }

    // if (userRole === 'staff') {
    //   menu.push({
    //     icon: 'event',
    //     title: 'Emissions',
    //     url: '/staff/event/list',
    //   });
    // }
    // if (
    //   process.env.REACT_APP_SITE === 'norgine' &&
    //   (userRole === 'member' || userRole === 'speaker' || userRole === 'admin' || userRole === 'staff')
    // ) {
    //   menu.push({ icon: 'replays', title: 'Replays', url: '/replays' });
    //   menu.push({ icon: 'medias', title: 'Medias', url: '/medias' });
    // }

    menu.push({ icon: 'help', title: 'Aide', url: '/help' });
    menu.push({ icon: 'contact', title: 'Contact', url: '/contact' });

    // if (userRole === 'visitor') menu.push({ icon: 'login', title: 'Mon compte', url: '/login' });

    return options !== undefined && options.asImmutable === true ? Immutable.fromJS(menu) : menu;
  },
  getProfileMenu: ($$user, options) => {
    let userRole='';
    let userSubRole='';
    if ($$user === undefined){
        userRole = 'visitor';
    }else {
        userRole = $$user.get('role');
        userSubRole = $$user.get('subRole');
    }
    const menus=[
      {
        title: 'navMyAccount',
        url: '/account',
        role:'all',
      },
      {
          icon: 'account',
          title: 'navAccounts',
          url: '/staff/account/list',
          subRole:['buadm'],
      },
      {
          icon: 'message',
          title: 'navModerator',
          url: '/staff/orchestrator/list',
          role:['speaker'],
          subRole:['mod', 'buadm'],
      },
      {
          icon: 'theme',
          title: 'navThemes',
          url: '/staff/theme/list',
      },
      {
          icon: 'event',
          title: 'navEvents',
          url: '/staff/event/list',
        },
      {
          icon: 'news',
          title: 'navNews',
          url: '/staff/news/list',
        },
        /*{
          icon: 'media',
          title: 'navAdminMedias',
          url: '/staff/media/list',
        },*/
        {
          icon: 'cms',
          title: 'adminCms',
          url: '/staff/cms/list',
        },
        {
          icon: 'documentation',
          title: 'adminDocumentation',
          url: '/staff/documentation/list',
        },
        {
          icon: 'utm',
          title: 'navUtms',
          url: '/staff/utm/list',
          subRole:['buadm'],
        },
        {
          icon: 'faq',
          title: 'navFaq',
          url: '/staff/faq/list',
        },
        {
          icon: 'email',
          title: 'navEmails',
          url: '/api/_cron/mail',
          target: '_blank',
        },
        {
          icon: 'logs',
          title: 'navLogs',
          url: '/api/_logs',
          target: '_blank',
        },
        {
          icon: 'export',
          title: 'navExports',
          url: '/api/_exports',
          target: '_blank',
          subRole:['buadm'],
        },
        {
          title: 'Se déconnecter',
          url: '/logout',
          role:'all',
        },
      ];

    let menu = [];
    menus.forEach(obj => {
      if(userRole=='admin' || obj.role=='all'
        || ('role' in obj && obj.role.includes(userRole))
        || ('subRole' in obj && obj.subRole.includes(userSubRole))
        )
          menu.push(obj);
    });

    return options !== undefined && options.asImmutable === true ? Immutable.fromJS(menu) : menu;
  },

  getFooterMenu: ($$user, options) => {
    let menu = [];
    menu.push({ icon: 'home', title: 'navHome', url: '/' });
    menu.push({ icon: 'videos', title: 'navVideos', url: '/videos#replays' });
    menu.push({ icon: 'help', title: 'navHelp', url: '/help' });
    menu.push({ icon: 'contact', title: 'navContact', url: '/contact' });
    menu.push({ icon: 'privacy', title: 'navConfidentiality', url: '/confidentiality', target:'_blank' });
    menu.push({ icon: 'legal', title: 'navLegal', url: '/legal', target:'_blank' });
    menu.push({ icon: 'cookies', title: 'navCookies', url: '/cookies', target:'_blank' });

    return options !== undefined && options.asImmutable === true ? Immutable.fromJS(menu) : menu;
  },

};
