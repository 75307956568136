import config from "config/api";
import isArray from "mout/lang/isArray";

export default ($$theme) => {
  let cardImage = false;
  // let cardPresentation = false;
  let decodedValues;
  if (
    $$theme !== false &&
    $$theme !== undefined &&
    $$theme.get("themeThumb") !== undefined &&
    $$theme.get("themeThumb") !== null &&
    $$theme.get("themeThumb") !== false
  ) {
    try {
      decodedValues = JSON.parse($$theme.get("themeThumb"));
    } catch (e) {}
    // console.log('decoded is', decodedValues)
    if (
      decodedValues !== undefined &&
      isArray(decodedValues) &&
      decodedValues[0] &&
      decodedValues[0].dest
    ) {
      cardImage =
        config.themethumbpreview.replace("{themeId}", $$theme.get("themeId")) +
        "/" +
        decodedValues[0].dest;
    }
  }
  return cardImage;
};
