import React, { Component } from "react";
import { connect } from "react-redux";
// import Immutable from 'immutable';
import config from "config/api";
import { Link } from "react-router-dom";
import { isLoggedIn, getCurrentUser } from "ducks/authentication";
import Grid from "@material-ui/core/Grid";
// import Typography from '@material-ui/core/Typography';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => {
  return {
    root: {
      backgroundColor: '#F4F5F5',
      color: "#000",
      fontSize: "11px",
      textAlign: "center",
      //minHeight: "118px",
      paddingTop:'10px',
      //marginTop:'50px',
      borderTop: '1px solid #D8DCDC',
    },
    footerWrapper: {
      //maxWidth: 1280,
      margin: "0 auto",
      padding: 20,
    },
    /*logo: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% 50%",
      backgroundSize: "contain",
      backgroundImage: `url(${require("assets/images/" +
        process.env.REACT_APP_SITE +
        "/logo.svg")})`,
      width: "170px",
      height: "100%",
      display: "block",
      margin: "0 auto",
    },*/
    inline: {
      display: "inline-block",
      width: "auto",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 5px",
      },
    },

    menuTop:{
      textAlign:'right',
      paddingRight:'10%',
      fontSize:'110%',
    },
    menuBottom:{
      textAlign:'right',
      paddingRight:'10%',
    },
    spacer:{
      borderBottom:'1px solid #D8DCDC',
      marginTop:'5px',
    },
    logoGrid:{
      textAlign:'left',
      paddingLeft:'3%',
    },
    copyright:{
      textAlign:'left',
      paddingLeft:'3%',
    },

    linkTop: {
      textDecoration: "none",
      color: "#000",
      fontSize:'1rem',
      "&:hover": {
        textDecoration: "underline",
      },
    },
    linkBottom: {
      textDecoration: "none",
      color: "#000",
      fontSize:'0.8rem',
      "&:hover": {
        textDecoration: "underline",
      },
    },

  };
};
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  _openLink(e, link) {
    e.preventDefault();
    const conf = window.confirm(
      "En cliquant sur ce lien, vous quittez le site.\n\nSi vous souhaitez poursuivre votre navigation sur ce site internet externe, cliquez sur Continuer.\nSobi n'est pas responsable des contenus présents sur ce site internet.",
    );
    if (conf) window.open(link);
  }
  render() {
    const { $$user, classes } = this.props;

    const $$menu = config.getMenu($$user, { asImmutable: true });

    return (
      <div className={classes.root}>
        <div className={classes.footerWrapper}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="space-around"
          >
            <Grid item xs={12} md={3} className={classes.logoGrid}>
              <div className={classes.logo}>
                <img width='150' src={require("assets/images/" +
        process.env.REACT_APP_SITE +
        "/logo_footer.svg")}/>
              </div>
            </Grid>
            <Grid item xs={12} md={9} className={classes.menuTop}>
              <List>
                {$$menu
                  .map((m, mIdx) => {
                    return (
                      <ListItem key={"fmn_" + mIdx} className={classes.inline}>
                        <ListItemText>
                          <Link className={classes.linkTop} to={m.get("url")}>
                            {m.get("title")}
                          </Link>
                        </ListItemText>
                      </ListItem>
                    );
                  })
                  .toArray()}
              </List>
              </Grid>
              <Grid item xs={12} className={classes.spacer}></Grid>
            <Grid item xs={12} md={3} className={classes.copyright}>
              {"© 2024 Canal" + process.env.REACT_APP_SITE+"."}
              <br />
              {"All rights reserved 2024/01-E-14088"}
            </Grid>
            <Grid item xs={12} md={9} className={classes.menuBottom}>
              <List>
              {config.footerMenus.map(item=>{
                return(
                <ListItem className={classes.inline}>
                <ListItemText>
                  <Link className={classes.linkBottom} to={item.url}>
                  {item.label}
                  </Link>
                </ListItemText>
                </ListItem>
                )})}
              </List>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

// export default Footer;
//
//
// export default withRouter(Header);
function mapStateToProps(state) {
  const props = {
    isLoggedIn: isLoggedIn(state),
    $$user: getCurrentUser(state),
  };
  return props;
}

export default withStyles(styles)(connect(mapStateToProps)(Footer));
