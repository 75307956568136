import React, { useEffect, useContext } from 'react';
import Loading from './Loading';
import apiFetch from './api';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import {config} from './config'
import {Context} from './Context'
import Error from './Error';
import Message from './Message'
import UserInput from './UserInput';
import ToolCalls from './ToolCalls';
import RunStatus from './RunStatus';

const styles = makeStyles((theme) => {
  return {
    root: {
  },
  contents: {
    maxWidth: '800px',
    margin: '0 20px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
  },
  debugLink:{
    textAlign:'right',
  },
};
});

export default function ThreadView({currentThread}) {
  const classes = styles();

  const {debug}=useContext(Context);

  const [thread, setThread] = React.useState(null);
  
  useEffect(() => {
    if(!currentThread)
      return;
    refresh();
    let timer = setInterval(() => refresh(), 3000);
    return () => {
      clearInterval(timer);
    };
  }, [currentThread]);

  const [error, setError] = React.useState(null);

  const refresh=()=>{
    if(currentThread)
      fetchData("view_thread", {id:currentThread});
    else
      setThread(null);
  }

  const fetchData = (action, post) => {
    apiFetch(action, post)
      .then((data) => {
        //console.log('fetchData ok', data);
        setError(null);
        setThread(data);
      })
      .catch(({ response, error }) => {
        console.log('fetchData error', error, response);
        setThread(null);
        setError(error);
      });
  };

  const debugUrl=()=>{
    const user = new URLSearchParams(window.location.search).get("user");
    let url=config.apiUrl+"/api.php?action=debug_thread&id="+currentThread;
    if(user!==null && user!=='')
      url+='&user='+user;
    return url;
  }
  
  const tool_calls_ids_in_messages=[];
  if(thread && thread.messages!==undefined){
    thread.messages.forEach((msg, k) => {
      if(msg.metadata!==undefined && msg.metadata.tool_calls_ids!==undefined){
        const ids=JSON.parse(msg.metadata.tool_calls_ids);
        tool_calls_ids_in_messages.push(...ids);
      }
    });
  }
  //console.log('thread', thread);
  //console.log('tool_calls_ids_in_messages', tool_calls_ids_in_messages);

  return (
    <Box className={classes.root}>
      <If condition={currentThread !== null}>
      <Box className={classes.contents}>
        {debug!==null && <Box className={classes.debugLink}>
        <a target='_blank' href={debugUrl()}>Debug infos</a>
        </Box>}
        {thread && thread.last_run ? "Modèle du dernier message : "+thread.last_run.model : ''}
      <Choose>
        <When condition={error !== null}>
            <Error>{error}</Error>
        </When>
        <When condition={thread === null}>
            <Loading />
        </When>
        <When condition={thread}>
          {thread.messages.map((msg) => (
            <Message key={msg.id} {...msg}/>
          )
          )}
          <If condition={thread.last_run}>
            <ToolCalls metadata={thread.last_run.metadata} ignore_ids={tool_calls_ids_in_messages}/>
            <RunStatus {...thread.last_run}/>
          </If>
          <UserInput run={thread.last_run}/>
        </When>
        <Otherwise>
          Erreur inconnue
        </Otherwise>
      </Choose>
      </Box>
      </If>
    </Box>
  );
}
