import config from 'config/api';

export function serieUrl(type, category, subtype, id, time) {
  if (config.serie === undefined) {
    console.error('Missing config.serie', config);
    return null;
  }
  const conf = config.serie;
  if (!(type in conf)) {
    console.error("Missing type '" + type + "' in config.serie", conf);
    return null;
  }
  if (!(category in conf[type].menus)) {
    console.error("Missing category '" + category + "' in config.serie", conf);
    return null;
  }
  let url = conf[type].base + '/' + conf[type].menus[category].path;
  if (id === undefined) return url;
  url += '/' + subtype + '/' + id + (time !== undefined ? '#' + time : '');
  return url;
}

export function categoryLabel(type, category) {
  if (config.serie === undefined) {
    console.error('Missing config.serie', config);
    return null;
  }
  const conf = config.serie;
  if (!(type in conf)) {
    console.error("Missing type '" + type + "' in config.serie", conf);
    return null;
  }
  if (!(category in conf[type].menus)) {
    console.error("Missing category '" + category + "' in config.serie", conf);
    return null;
  }
  return conf[type].menus[category].label;
}

export function categoryFromPath(type, path) {
  let res;
  Object.keys(config.serie[type].menus).forEach((category) => {
    if (config.serie[type].menus[category].path == path) {
      res = category;
      return false;
    }
  });
  return res;
}
