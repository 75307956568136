import React, { Component } from "react";
import BlockButton from "components/UI/BlockButton";
import BlockTitle from "components/UI/BlockTitle";
import RegistrationForm from "components/Registration/RegistrationForm";
// import LoginForm from 'components/Forms/LoginForm';
import Immutable from "immutable";
// import SubscribeToEventForm from 'components/Forms/SubscribeToEventForm';
import EventSubscription from "components/Event/EventSubscription";
import EventSendInvite from "components/Event/EventSendInvite";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { formatDate } from "helpers/stringUtils";
// import colors from 'config/colors'

const styles = (theme) => ({
  addButton: {
    backgroundColor: "transparent",
    padding: 0,
    boxShadow: "0 5px 20px rgba(0,0,0,0.21)",
    "&:hover": {
      boxShadow: "0 5px 5px rgba(0,0,0,0.21)",
      backgroundColor: "transparent",
    },
  },
  addIcon: {
    width: 50,
    height: 50,
    color: "#EB9250",
    verticalAlign: "middle",
    margin: "-5px",
  },
  color: {
    color: theme.palette.primary.main,
  },
  colorTitle: {
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  inline: {
    display: "inline-block",
  },
});

class EventSubscriptionFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleMode: false,
      // visibleMode: 'invitationComplete',
      userEmail: false,
      log: undefined,
      // userEmail: 'rdsqdsqd.dsqdsqdsqrr@new-net.dsqdsqdsq.net'
    };
    // this._onClick = this._onClick.bind(this);
    // this._onFormComplete = this._onFormComplete.bind(this);
    // this._onSubscribeToEvent = this._onSubscribeToEvent.bind(this);
    this._onSubscriptionError = this._onSubscriptionError.bind(this);
    this._onSubscriptionComplete = this._onSubscriptionComplete.bind(this);
    this._onRegistrationShow = this._onRegistrationShow.bind(this);
    this._onRegistrationComplete = this._onRegistrationComplete.bind(this);
    this._onSendInvitationComplete = this._onSendInvitationComplete.bind(this);
    this._onSendInvitationError = this._onSendInvitationError.bind(this);
    this._onRestart = this._onRestart.bind(this);
  }
  // _onClick(e, formType) {
  //   // console.log('clicked', formType)
  //   this.setState({visibleMode: formType});
  // }
  _onSubscriptionError(kind, user) {
    this.setState({ visibleMode: "unknown_subsriber", userEmail: user.email });
  }
  _onSendInvitationComplete() {
    this.setState({ visibleMode: "invitationComplete" });
  }
  _onSendInvitationError(kind, log) {
    this.setState({ visibleMode: kind, log });
  }
  _onSubscriptionComplete() {
    this.setState({ visibleMode: "subscriptionComplete" });
  }
  _onRegistrationComplete() {
    this.setState({ visibleMode: "subscriptionComplete" });
  }
  _onRegistrationShow() {
    this.setState({ visibleMode: "register" });
  }
  _onRestart() {
    this.setState({ visibleMode: false });
  }
  render() {
    const { $$event, $$events, onClose, classes, onSelectionReset, themeId } =
      this.props;
    const { visibleMode, userEmail } = this.state;

    let ef;
    if ($$event !== undefined && $$event.get("id") !== undefined)
      ef = { fromEvent: $$event.get("id") };
    else if ($$events !== undefined && $$events.get(0) !== undefined)
      ef = { fromEvents: $$events.map((e) => e.get("id")).toArray() };

    const extraFields = Immutable.fromJS(ef);
    return (
      <Box>
        <Choose>
          <When condition={visibleMode === "subscriptionComplete"}>
            <If condition={$$event !== undefined}>
              <Typography
                variant="h4"
                classes={{ root: classes.colorTitle }}
                gutterBottom
              >
                {$$event.get("eventTitle")}
              </Typography>
              <Typography
                variant="h6"
                classes={{ root: classes.color }}
                gutterBottom
                style={{ textTransform: "uppercase" }}
              >
                {formatDate(
                  $$event.get("eventDate"),
                  "dddd dd mmmm yyyy 'à' HH'h'MM",
                )}
              </Typography>
              <hr />
            </If>
            <Typography
              variant="h5"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              Inscription de {userEmail} confirmée !
            </Typography>
          </When>
          <When condition={visibleMode === "invitationComplete"}>
            <If condition={$$event !== undefined}>
              <Typography
                variant="h4"
                classes={{ root: classes.colorTitle }}
                gutterBottom
              >
                {$$event.get("eventTitle")}
              </Typography>
              <Typography
                variant="h6"
                classes={{ root: classes.color }}
                gutterBottom
                style={{ textTransform: "uppercase" }}
              >
                {formatDate(
                  $$event.get("eventDate"),
                  "dddd dd mmmm yyyy 'à' HH'h'MM",
                )}
              </Typography>
              <hr />
            </If>
            <Typography
              variant="h5"
              gutterBottom
              classes={{ root: classes.colorTitle }}
            >
              Une invitation a été envoyée{" "}
              <span className={classes.inline}>à l'adresse {userEmail} !</span>
            </Typography>
            <BlockTitle />
            <BlockButton
              label="Fermer"
              onClick={onClose}
              style={{ margin: "20px auto 10px", display: "block" }}
            />
          </When>
          <When condition={visibleMode === "invitation_already_sent"}>
            <If condition={$$event !== undefined}>
              <Typography
                variant="h4"
                classes={{ root: classes.colorTitle }}
                gutterBottom
              >
                {$$event.get("eventTitle")}
              </Typography>
              <Typography
                variant="h6"
                classes={{ root: classes.color }}
                gutterBottom
                style={{ textTransform: "uppercase" }}
              >
                {formatDate(
                  $$event.get("eventDate"),
                  "dddd dd mmmm yyyy 'à' HH'h'MM",
                )}
              </Typography>
              <hr />
            </If>
            <Typography
              variant="h5"
              gutterBottom
              style={{ textAlign: "center" }}
              className={classes.color}
            >
              Une invitation a déjà été envoyée{" "}
              <span className={classes.inline}>à l'adresse {userEmail}</span> !
            </Typography>
            <BlockTitle />
            <BlockButton
              label="Fermer"
              onClick={onClose}
              style={{ margin: "20px auto 10px", display: "block" }}
            />
          </When>
          <When condition={visibleMode === "unknown_subsriber"}>
            <div className="Form">
              <Typography
                variant="h5"
                classes={{ root: classes.colorTitle }}
                gutterBottom
              >
                Aucun compte ne correspond à cette adresse email...
              </Typography>
              <BlockTitle />
              {/*<Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>Vous pouvez créer un nouveau compte, ou relancer une autre recherche.</Typography>*/}
              <Box align="center" my={4}>
                <BlockButton
                  variant="white"
                  label="Autre recherche"
                  onClick={this._onRestart}
                  style={{ marginBottom: 5 }}
                />
                <EventSendInvite
                  $$event={$$event}
                  $$events={$$events}
                  email={this.state.userEmail}
                  onSendInvitationComplete={this._onSendInvitationComplete}
                  onSendInvitationError={this._onSendInvitationError}
                  style={{ marginBottom: 5 }}
                />
                {/*<BlockButton label="Créer un nouveau compte" onClick={this._onRegistrationShow} />*/}
              </Box>
            </div>
          </When>
          <When condition={visibleMode === "register"}>
            <RegistrationForm
              extraFields={extraFields}
              onClose={onClose}
              onFormComplete={this._onRegistrationComplete}
            />
          </When>
          <Otherwise>
            <EventSubscription
              $$event={$$event}
              $$events={$$events}
              registerFor={true}
              onSubscriptionError={this._onSubscriptionError}
              onSubscriptionComplete={this._onSubscriptionComplete}
              onSelectionReset={onSelectionReset}
              themeId={themeId}
            />
          </Otherwise>
        </Choose>
      </Box>
    );
  }
}

// export default ;
export default withStyles(styles)(EventSubscriptionFor);
