/*
let primaryColor = "#318EB8";
let secondaryColor = "#0075A8";
let tercaryColor = "#004D71";

let gradientColorPrimary = "#6C207E";
let gradientColorSecondary = "#6C207E";
let hoverBackgroundColor = "#fff";
let hoverColor = "#6C207E";
let bodyColor = "#5A5A5A";
let bodyAltColor = "#0075A8";
let footerBackgroundColor = "#fff";
let footerLinkColor = tercaryColor;
let footerColor = primaryColor;
let headerBgColor = "#F9F9F9";
let buttonBgColor = bodyColor;

switch (process.env.REACT_APP_SITE) {
  case "neonat":
    primaryColor = "#da6078";
    secondaryColor = "#682d37";
    bodyAltColor = secondaryColor;
    tercaryColor = "#682d37";
    headerBgColor = null;
    buttonBgColor = primaryColor;
    footerLinkColor = primaryColor;
    footerColor = primaryColor;
    break;
  case "transplant":
    // primaryColor = "#004a71";
    // secondaryColor = "#e77d3e";
    primaryColor = "#004a71";
    secondaryColor = "#e77d3e";
    bodyAltColor = primaryColor;
    tercaryColor = "#002f4b";
    headerBgColor = null;
    buttonBgColor = secondaryColor;
    footerLinkColor = primaryColor;
    footerColor = primaryColor;
    break;
  default:
}

export {
  primaryColor,
  secondaryColor,
  tercaryColor,
  gradientColorPrimary,
  gradientColorSecondary,
  hoverBackgroundColor,
  hoverColor,
  bodyColor,
  bodyAltColor,
  footerBackgroundColor,
  footerLinkColor,
  footerColor,
  headerBgColor,
  buttonBgColor,
};
*/

const light = "#fff";
const grey = "#d7dfe1";
const dark = "#000";
const disabledColor = "#ccc";
const backgroundColor = "#fff";

let primaryColor = "#b90066";//Chiesi Pink
let primaryColorLight = "#d7dfe1";
//let secondaryColor = '#d3da3d';//vert
let secondaryColor = "#fff";
//let tertiaryColor = '#b80e80';//magenta
let tertiaryColor = "#ccc";
let bodyColor = "#00394a";
let titleColor = primaryColor;
let menuColor = "#333";
let textColor = "#333";

//old
let bodyAltColor = "#0075A8";
let buttonBgColor = bodyColor;
let tercaryColor = "#004D71";

export {
  light,
  grey,
  dark,
  disabledColor,
  backgroundColor,
  primaryColor,
  primaryColorLight,
  secondaryColor,
  tertiaryColor,
  bodyColor,
  titleColor,
  menuColor,
  textColor,
  bodyAltColor,
  buttonBgColor,
  tercaryColor,
};
