import React, { Component } from "react";

// import Immutable from 'immutable';
// import { connect } from 'react-redux';
// import config from 'config/api'
import clsx from "clsx";
// import withWidth from '@material-ui/core/withWidth';
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import BlockTitle from "components/UI/BlockTitle";
import { withRouter } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import IconUp from '@material-ui/icons/KeyboardArrowUp';
// import IconDown from '@material-ui/icons/KeyboardArrowDown';

import { tercaryColor } from "helpers/colors";

// const AF_KEY = 'home';

const styles = (theme) => {
  return {
    root: {
    },
    darker: {
    },
    inline: {
      display: "inline-block",
    },
    title: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: 20,
      },
    },
    button: {
      marginLeft: 5,
      marginRight: 5,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 5,
      },
    },
  };
};

class DocumentHelp extends Component {
  _goRedir(url) {
    const { history } = this.props;
    history.push(url);
  }
  render() {
    const { classes, style } = this.props;

    return (
      <Box
        py={4}
        px={8}
        mb={8}
        style={style}
        align="center"
        bgcolor="primary.main"
        className={classes.root}
      >
        <Grid
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="space-around"
        >
          <Grid item={true} sm={12} md={8}>
            <Box align="middle" className={classes.title}>
              <Typography variant="h3" style={{ color: "#fff" }}>
                Vous avez une question sur l'une des thématiques ?
              </Typography>
            </Box>
          </Grid>
          <Grid
            item={true}
            sm={12}
            md={4}
            container={true}
            spacing={2}
            alignItems="center"
            justifyContent="space-around"
          >
            <span className={classes.inline}>
              <Button
                className={clsx(classes.button, classes.darker)}
                onClick={(e) => {
                  e.preventDefault();
                  this._goRedir("/contact");
                }}
              >
                Contactez-nous
              </Button>
            </span>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withRouter(withStyles(styles)(DocumentHelp));
