import React from "react";
import { connect } from "react-redux";
import Immutable from "immutable";
// import { Link } from 'react-router-dom';
import Form from "components/UI/Form/Form";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";

import {
  login as formSubmitAction,
  hasLoginFailed as isFormErrored,
  getError as getFormError,
  getCurrentUserRole,
  isLoggingIn as isFormRunning,
  isLoggedIn as isFormComplete,
} from "ducks/authentication";
import { clearData } from "ducks/asyncFetcher";

const FIELDS = [
  {
    id: "email",
    name: "email",
    label: "Email",
    validation: ["notEmpty", "email"],
    maxLength: 64,
    sizes: { xs: 12 },
    autoComplete: "email",
  },
  {
    id: "password",
    name: "password",
    label: "Mot de passe",
    type: "password",
    validation: ["notEmpty"],
    maxLength: 64,
    sizes: { xs: 12 },
    autoComplete: "current-password",
  },
];
const $$FIELDS = Immutable.fromJS(FIELDS);

const CONFIG = {
  showRequiredLegend: false,
  compact: true,
};
const $$CONFIG = Immutable.fromJS(CONFIG);

class LoginForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      erroredFields: {},
    };

    this._onFormSubmit = this._onFormSubmit.bind(this);
    this._onFieldValueChange = this._onFieldValueChange.bind(this);
    this._onPasswordResetRequest = this._onPasswordResetRequest.bind(this);
    this._getConfig = this._getConfig.bind(this);

    this._getI18n();
    this.$$FIELDS = $$FIELDS;
    this.$$CONFIG = $$CONFIG;
  }

  componentDidMount() {
    this._setupState();
  }

  componentDidUpdate(oldProps) {
    if (
      (oldProps.isFormComplete === undefined ||
        oldProps.isFormComplete === false) &&
      this.props.isFormComplete === true
    ) {
      if (this.props.onFormComplete) {
        this.props.onFormComplete();
      }
    }
  }
  _getFormFooter = () => {
    return (
      <a
        style={{ cursor: "pointer", whiteSpace: "nowrap" }}
        onClick={this._onPasswordResetRequest}
      >
        Mot de passe oublié ?
      </a>
    );
  };
  _onPasswordResetRequest = () => {
    const { onPasswordResetRequest } = this.props;
    // history.push('/rpwd')
    if (onPasswordResetRequest) onPasswordResetRequest();
  };
  render() {
    const {
      // loading,
      isFormComplete,
    } = this.props;
    if (isFormComplete && !this.props.onFormComplete) {
      return <Typography variant="h5">Déja connecté</Typography>;
    } else if (isFormComplete && this.props.onFormComplete) {
      return <span></span>;
    }

    return (
      <div style={{ maxWidth: 420, margin: "0 auto 20px" }}>
        {this._renderForm()}
      </div>
    );
  }
}

const mapStateToProps = ($$state) => {
  return {
    userRole: getCurrentUserRole($$state),
    loading: isFormRunning($$state),
    isFormErrored: isFormErrored($$state),
    error: getFormError($$state),
    isFormComplete: isFormComplete($$state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (formData) => {
      dispatch(formSubmitAction(formData));
    },
    doClearData: (formData) => {
      dispatch(clearData({ key: "home" }));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm),
);
