import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  logout as initiateLogout,
  isLoggedIn,
  getCurrentUserRole,
} from "ducks/authentication";

import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
import Header from "components/Header/Header";

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: undefined,
    };
  }
  componentDidMount() {
    this.props.onMount();
  }
  render() {
    const { userRole, isLoggedIn } = this.props;

    if (!isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: this.props.location },
          }}
        />
      );
    }
    return (
      <div className="home">
        <ScrollToTopOnMount />
        <Header
          width={this.props.width}
          userRole={userRole}
          current={"account"}
        />
        <div className="Logout">
          <h1>Déconnexion en cours...</h1>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ($$state) => {
  return {
    isLoggedIn: isLoggedIn($$state),
    userRole: getCurrentUserRole($$state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onMount: () => {
      dispatch(initiateLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
