import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles({
  paper: {
    //marginTop:'0px',
  },
  list: {
  },
});


export default function HeaderMenuItem(props) {
  const useHover=false;
  const {classes, item, selected} = props;
  const localClasses = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hover, setHover] = React.useState(-1);
  const hoverRef=React.useRef(hover);
  let history = useHistory();

  const hasSubMenu=item.subMenu!=undefined;

  useEffect(()=>{
    if(useHover && hasSubMenu && hover==0){
      console.log("useEffect", hover, hoverRef.current);
      const id=setTimeout(onMouseLeaveTimeout, 1000);
      return ()=>{
        console.log("clearTimeout", id);
        clearTimeout(id)
      };
    }
  }, [hover]);

  const onClick = (event) => {
    console.log("onClick");
    //console.log(event.currentTarget);
    if(hasSubMenu)
      setAnchorEl(event.currentTarget);
    else{
      if(item.target=='_blank')
        window.open(item.url);
      else
        history.push(item.url);
    }
  };

  const handleClose = (event, menu, url) => {
    console.log("handleClose");
    setAnchorEl(null);
    if(url!==undefined)
      history.push(url);
  };

  const onMouseOver = (event) => {
    if(!useHover)
      return;
    setHover(1);
    console.log("onMouseOver");
    if(hasSubMenu)
      setAnchorEl(event.currentTarget);
    
  };
  const onMouseOver2 = (event) => {
    if(!useHover)
      return;
      console.log("onMouseOver2");
      setHover(2);
  };
  const onMouseLeave = (event) => {
    if(!useHover)
      return;
    console.log("onMouseLeave", hover);
    setHover(0);
    //setTimeout(onMouseLeaveTimeout, 2000);
  };
  const onMouseLeaveTimeout = () => {
    if(!useHover)
      return;
    console.log("onMouseLeaveTimeout", hover, hoverRef.current);
    if(hasSubMenu)
      setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button aria-controls="simple-menu" aria-haspopup={hasSubMenu?"true":"false"} aria-label={item.title}
         onClick={onClick}
         onMouseOver={onMouseOver}
         onMouseLeave={onMouseLeave}
         classes={{ root: classes.button }}
         className={(selected ? 'selected' : '')}
         >
          {item.title}
      </Button>
      <If condition={hasSubMenu}>
        <Menu
          id={item.title}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          //transformOrigin={{ vertical: "top", horizontal: "center" }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: onMouseLeave, onMouseOver:onMouseOver2 }}
          classes={{
            paper: localClasses.paper,
            list: localClasses.list,
          }}
        >
          {Object.keys(item.subMenu).map((menu, i)=>
            <MenuItem key={menu} onClick={(event)=>handleClose(event, menu, item.subMenu[menu])}>
              {menu}
            </MenuItem>
          )}
        </Menu>
      </If>
    </React.Fragment>
  );
}
