import Immutable from "immutable";
import config from "config/api";

export default ($$attributes, props) => {
  const { needleField, needleUrl, needleId } = props;
  if (needleField === undefined) console.error("missing needleField");
  if (needleUrl === undefined) console.error("missing needleUrl");
  if (needleId === undefined) console.error("missing needleId");
  let needleSubstituteId =
    needleSubstituteId !== undefined ? needleSubstituteId : "id";

  if (
    $$attributes === undefined ||
    $$attributes.get(needleField) === null ||
    $$attributes.get(needleField) === undefined ||
    $$attributes.get(needleField) === false
  )
    return false;
  let guests;
  if ($$attributes.get(needleField).size)
    guests = $$attributes.get(needleField).toJS();
  else if (
    $$attributes.get(needleField) !== undefined &&
    $$attributes.get(needleField) !== null &&
    $$attributes.get(needleField) !== false
  ) {
    try {
      guests = JSON.parse($$attributes.get(needleField));
    } catch (e) {
      guests = [];
    }
  } else guests = [];

  guests.map((g, gIdx) => {
    guests[gIdx].dest =
      config.eventthumbpreview.replace(
        "{" + needleId + "}",
        $$attributes.get(needleSubstituteId),
      ) +
      "/" +
      g.dest;
  });
  return Immutable.fromJS(guests);
};
