import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Menu from './Menu';

import { Context } from './Context';
import ThreadList from './ThreadList';
import ThreadView from './ThreadView';


const styles = makeStyles((theme) => {
  return {
    root: {
    },
  };
});


export default function Main({  }) {
  const classes = styles();

  const queryParameters = new URLSearchParams(window.location.search)
  const debug = queryParameters.get("debug")
  const beta = queryParameters.get("beta")

  const [currentThread, setCurrentThread] = React.useState(null);

  return (
    <Context.Provider value={{currentThread, setCurrentThread, debug, beta}}>
    <div className={classes.root}>
      <Grid container spacing={0} alignItems="flex-start" justify="flex-start">
      <Grid item xs={12} md={12} className="">
        <Menu/>
        </Grid>
        <Grid item xs={3} md={3} className="">
        <ThreadList/>
        </Grid>
        <Grid item xs={9} md={9} className="">
          <ThreadView key={currentThread} currentThread={currentThread}/>
        </Grid>
     </Grid>
    </div>
    </Context.Provider>
  );
}
