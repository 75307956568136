import React, { useEffect, useReducer } from 'react';
import SeriesSection from './SeriesSection';
import Loading from 'components/UI/Loading';
import Error from 'components/UI/Error';
import apiFetch from 'components/UI/helpers/fetch';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const styles = makeStyles((theme) => {
  return {
    root: {
      padding: '2em 5%',
      textAlign: 'left',
      color: theme.palette.serie.title,
      backgroundColor: theme.palette.serie.pageBG,
    },
  };
});

export default function SeriesList({ type, category }) {
  const classes = styles();

  const sections = {
    replay: {
      continue: 'Reprendre ma lecture',
      news: 'Nouveautés',
      top: 'Top 3 des plus vues',
      others: 'Nos suggestions',
    },
    podcast: {
      continue: 'Reprendre ma lecture',
      news: 'Nouveautés',
      top: 'Top 3 des plus écoutés',
      others: 'Nos suggestions',
    },
  };

  const [list, setList] = React.useState(null);
  useEffect(() => {
    console.log('SeriesList init');
    fetchData({ type: type, category: category });
  }, [type, category]);

  const [error, setError] = React.useState(null);

  const fetchData = (params) => {
    apiFetch('/serie/front_list', params)
      .then((data) => {
        console.log('fetchData ok', data);
        setList(data);
        setError(null);
      })
      .catch(({ response, error }) => {
        console.log('fetchData error', error, response);
        setList(false);
        setError(error);
      });
  };

  //console.log('list', list);
  let count = 0;
  if (list)
    Object.keys(sections[type]).forEach((key) => {
      count += list[key].length;
    });

  return (
    <div className={classes.root}>
      <Choose>
        <When condition={list === null}>
          <Box my={6} align="center">
            <Loading />
          </Box>
        </When>
        <When condition={!error && count === 0}>
          <Box my={6} align="center">
            <Typography variant="h5" gutterBottom={true} className="">
              Aucun élément à afficher actuellement.
            </Typography>
          </Box>
        </When>
        <When condition={list}>
          {Object.keys(sections[type]).map((key) => (
            <If condition={list[key].length}>
              <SeriesSection key={key} type={type} category={category} title={sections[type][key]} rows={list[key]} />
            </If>
          ))}
        </When>
        <Otherwise>
          <Error result={error} />
        </Otherwise>
      </Choose>
    </div>
  );
}
