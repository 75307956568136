import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import config from "config/api";
import i18n from "helpers/i18n";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import { connect } from 'react-redux';
// import config from 'config/api'
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import queryString from 'query-string';

import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
// import Bubble from 'components/UI/Bubble';
import Header from "components/Header/Header";
import HeaderContent from "components/Header/HeaderContent";
import HeaderSub from "components/Header/HeaderSub";
import Footer from "components/Footer";
// import ContactForm from 'components/Forms/ContactForm';
// import BlockTitle from 'components/UI/BlockTitle';
// import MyEventsContainer from 'components/Event/MyEventsContainer';
// import MyPastEventsContainer from 'components/Event/MyPastEventsContainer';
import AccountSettingsForm from "./AccountSettingsForm";
// import BlockButton from 'components/UI/BlockButton';

import { isLoggedIn, getCurrentUserRole } from "ducks/authentication";

import {
  fetchData,
  getDataHome,
  // getLoadingError,
} from "../../ducks/asyncFetcher";

const AF_KEY = "home";

const styles = (theme) => {
  return {
    titleSmall: {
      fontSize: "2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
        textAlign: "center",
      },
    },
    headerImage: {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        textAlign: "center",
      },
    },
    appBar: {
      backgroundColor: "transparent",
      position: "relative",
      marginTop: 100,
      maxWidth: 1084,
      marginLeft: "auto",
      marginRight: "auto",
    },
    tabs: {
      maxWidth: 1084,
      margin: "0 auto",
    },
    tab: {
      color: "#585854",
      textTransform: "uppercase",
      fontSize: "1.1em",
    },
  };
};

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // tabSelected: 0
    };
    this._onSubscriptionComplete = this._onSubscriptionComplete.bind(this);
  }

  componentDidMount() {
    this.props.onMount();
    // this._applyTabs();
  }
  componentDidUpdate(oldProps) {
    // console.log(oldProps.location.search)
    // if (oldProps.location.search !== this.props.location.search)
    //   this._applyTabs();
  }
  // _applyTabs() {
  //   const { userRole } = this.props;
  //   const uri = queryString.parse(window.location.search);
  //   // if (uri && uri.tab && uri.tab == 'settings') this.setState({tabSelected: 1});
  //   if (uri && uri.tab && uri.tab === 'events') this.setState({tabSelected: 0});
  //   else if (uri && uri.tab && uri.tab === 'my_account') this.setState({tabSelected: 1});
  //   if (userRole === 'staff' || userRole === 'speaker') this.setState({tabSelected: 1});
  // }
  // _onTabChange = (event, value) => {
  //   this.setState({tabSelected: value });
  // }
  _onSubscriptionComplete() {
    this.props.onMount();
  }
  render() {
    const { onPanelToggle, classes, userRole, isLoggedIn, $$data } = this.props;

    // const dense = true;
    // const {
    //   tabSelected
    // } = this.state;

    if (!isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <div className="AppContent">
        <ScrollToTopOnMount />
        <Header
          onPanelToggle={onPanelToggle}
          userRole={userRole}
          current={"account"}
          headerContent={
            process.env.REACT_APP_SITE === "neonat" ? (
              <HeaderContent classes={classes} />
            ) : undefined
          }
        />

        <Box>
          <Box align="middle" mb={3}>
            <Typography variant="h1" className={classes.title}>
              Mon compte
            </Typography>
          </Box>

          <Choose>
            <When condition={isLoggedIn && $$data !== undefined}>
              <If condition={userRole === "member"}>
                {/*
            <div className={'HomeBlock fullWidth offsetTop'}>
            <Tabs value={tabSelected} centered={true} classes={{root: classes.tabs}} variant="fullWidth" onChange={this._onTabChange} textColor="secondary">
              <Tab label="Evénements" classes={{root: classes.tab}} color="secondary"/>
              <Tab label="Mon compte" classes={{root: classes.tab}}/>
            </Tabs>
          </div>
          */}
              </If>

              {/*<If condition={tabSelected === 0 && userRole === 'member'}>
            <div key="tab1">
              <MyEventsContainer title="Vos événements à venir" wrapperClass="" displayMode="horizontal" showMore={true} showRemainingTime={false} showDelete={true} $$data={$$data.get('myfutureevents')} onSubscriptionComplete={this._onSubscriptionComplete} showAgendaLink={true}/>

              <MyPastEventsContainer title="Vos événements passés" wrapperClass="condensed" $$data={$$data.get('mypastevents')}/>
            </div>
          </If>*/}
              {/*<If condition={tabSelected === 1}>*/}
              <div key="tab2">
                <Box maxWidth={1280} width={"90%"} mt={3} mx={"auto"}>
                  <AccountSettingsForm />
                </Box>

                {/*  <div className="HomeBlock fullWidth ContactUs">
                <BlockTitle title={'Besoin d’aide pour utiliser l’outil de webconférence ?'} size={'big'} color="white"/>
                <BlockButton label="Voir notre rubrique aide" size="big" variant="white" to="/help" style={{marginRight: 10}}/>
                <BlockButton label="Nous contacter" size="big" variant="white" to="/contact" style={{marginLeft: 10}}/>
              </div>*/}
              </div>
              {/*</If>*/}
            </When>
          </Choose>
        </Box>

        <Footer style={{ zIndex: 189, marginTop: -10 }} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  const props = {
    $$data: getDataHome(state),
    isLoggedIn: isLoggedIn(state),
    userRole: getCurrentUserRole(state),
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    onMount: () => {
      dispatch(fetchData({ key: AF_KEY, fetchUrl: config.home }));
    },
  };
}

// export default connect(mapStateToProps)(Header);

// export default ;
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Account)),
);
