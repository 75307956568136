import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import RemainingTime from "components/UI/RemainingTime";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import Template from 'components/UI/Template/Template';
import queryString from 'query-string';
import { connect } from "react-redux";
import config from "config/api";
// import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import isArray from "mout/lang/isArray";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import BlockRatio from "components/BlockRatio";
import Orchestrator from "components/UI/Orchestrator/Orchestrator";
import getRemainingTime from "helpers/getRemainingTime";
//import gotoLiveRoom from "helpers/gotoLiveRoom";
import gotoLiveRoom from "components/UI/helpers/gotoLiveRoom";
import Spinner from "components/UI/Spinner";
import HomeHelp from 'components/Home/HomeHelp';
import LiveMessage from "components/Lives/LiveMessage";
import { withRouter } from "react-router-dom";
import { fetchLive, getLiveData, clearLive } from "components/UI/Live/ducks/live";
import {
  fetchData,
  getDataHome,
  getLoadingError,
} from "ducks/asyncFetcher";
import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
} from "components/UI/Account/ducks/authentication";
import MuiAlert from "@material-ui/lab/Alert";
import i18n from "helpers/i18n";
// import { primaryColor } from "helpers/colors";
import { Link } from 'react-router-dom';
import Prettyfier from 'components/UI/Prettyfier';
import LoginButtons from 'components/UI/Login/LoginButtons';
import VideoTracker from 'components/UI/Replays/VideoTracker';

const AF_KEY = "home";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = (theme) => {
  // console.log(theme)
  return {
    root: {
      // maxWidth: "1280px",
      margin: "0 auto",
      minHeight: "600px",
      maxWidth: 1280,
    },
    notice: {
      //color: '#fff'
    },
    paperWrapper: {
      width: '100%',
      display: 'block',
      position: 'relative',
      zIndex: 0,
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.live && theme.palette.live.backgroundColor ? theme.palette.live.backgroundColor : theme.palette.primary.light,

        zIndex: -1
      }
    },
    paper: {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& > a': {
        color: 'inherit'
      }
    },
    breadCrumbs: {
      marginTop: theme.spacing(3),
      fontSize: '0.8125rem',
      color: theme.palette.replays && theme.palette.live.breadCrumbColor ? theme.palette.live.breadCrumbColor : theme.palette.primary.main,
      '& a': {
        color: theme.palette.replays && theme.palette.live.breadCrumbColor ? theme.palette.live.breadCrumbColor : theme.palette.primary.main,
        textDecoration:'underline'
      },
      '& .MuiTypography-subtitle1':{
        color: theme.palette.replays && theme.palette.live.breadCrumbColor ? theme.palette.live.breadCrumbColor : theme.palette.primary.main,
      },
    },
    fullWidth: {
      width: "100%",
    },
    mainWrapper: {
      fontSize: "14px",
    },
    mainTitle: {
      marginBottom: 40,
      textAlign: "center",
    },
    livePaper: {
      color: theme.palette.common.black,
      maxWidth: 1280,
      margin: "0 auto",
      padding: "40px 20px 50px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 20px",
      },
    },
    inline: {
      display: "inline-block",
    },
    header: {
      // height: 150,
      // [theme.breakpoints.down("sm")]: {
      //   height: 200
      // }
    },
    content: {
      // height: 'calc(100% - 150px - 120px)',
      // [theme.breakpoints.down("sm")]: {
      // height: 'auto'
      // }
    },
    regularHeight: {
      height: "auto",
    },
    footer: {
      width: "100%",
      // height: 120,
      // [theme.breakpoints.down("sm")]: {
      //   height: 'auto'
      // }
    },
    big: {
      fontSize: "20px",
      margin: '0 10px'
    },
    instructions: {
      color: 'inherit',
      marginBottom: 20,
    },
  };
};
class LivePlayerOrchestrator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logMessage: false,
    };
    this._gotoLiveRoom = this._gotoLiveRoom.bind(this);
    this._onMessageSent = this._onMessageSent.bind(this);
    this._handleSnackbarClose = this._handleSnackbarClose.bind(this);
    this._runRemainingTimer = this._runRemainingTimer.bind(this);
  }
  componentDidMount() {
    const liveId = this.props.match.params.id;
    this.props.onMount({
      liveId,
    });
    this._startTimer();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.$$liveData !== this.props.$$liveData) {
      this._startTimer();
    }
  }
  componentWillUnmount() {
    this._stopTimer();
  }
  _stopTimer() {
    // console.log('timer stop')
    if (this._remainingTimer) {
      clearInterval(this._remainingTimer);
    }
  }
  _startTimer() {
    // console.log('timer start')
    // const $$card = this._getCard();
    // console.log('test1')
    if (this._remainingTimer) return;
    const $$liveEvent = this._getCard();
    // console.log('test2', $e)
    if ($$liveEvent === undefined) return;
    // console.log('starting timer')
    // this._cardTimer = $$card.get('eventDate');
    this._remainingTimer = setInterval(this._runRemainingTimer, 1000);
    this._runRemainingTimer();
  }
  _runRemainingTimer() {
    // console.log('run1')
    // const { $$card } = this.props;
    // const $$liveEvent = $$card;
    const $$liveEvent = this._getCard();
    // console.log('run2', $e)
    if ($$liveEvent === undefined) {
      return this._stopTimer();
    }
    // console.log('run3')
    // console.log($e)
    // console.log($e.get('eventState'))
    const timeRemaining =
    $$liveEvent !== undefined && $$liveEvent.get("eventState") !== "live"
    ? getRemainingTime($$liveEvent.get("eventDate"))
    : {
      days: 0,
      hours: 0,
      minutes: 0,
    };
    // console.log(timeRemaining)
    if (
      timeRemaining.days <= 0 &&
      timeRemaining.hours <= 0 &&
      timeRemaining.minutes <= 0
    ) {
      this.setState(
        {
          showLiveButton: true,
        },
        () => {
          // console.log('STOPPPING')
          this._stopTimer();
        }
      );
    } else {
      // console.log('RUN')
      const { showLiveButton } = this.state;
      if (
        showLiveButton !== true &&
        timeRemaining.days <= 0 &&
        timeRemaining.hours <= 0 &&
        timeRemaining.minutes <= 15
      )
      this.setState({
        showLiveButton: true,
      });
      this.setState({
        timeRemaining,
      });
    }
  }
  _gotoLiveRoom(e, link) {
    // const { $$card } = this.props;
    const $$card = this._getFakeCard();
    if (e !== undefined && e.preventDefault !== undefined) e.preventDefault();
    gotoLiveRoom({$$event: $$card, link, callback: () => {
      console.log("/live/" + $$card.get("id"));
      this._goto("/live/" + $$card.get("id"));
    }});
  }
  _getCard() {
    const { $$liveData } = this.props;
    // const { id } = match.params;
    let found = undefined;
    // if ($$data !== undefined && $$data.get("live")) {
    //   found = $$data.get("live").filter((e) => e.get("id") === id);
    //   found = found.get(0) !== undefined ? found.get(0) : undefined;
    // }
    // if (
    //   found === undefined &&
    //   $$data !== undefined &&
    //   $$data.get("availableEvents")
    // ) {
    //   found = $$data.get("availableEvents").filter((e) => e.get("id") === id);
    //   found = found.get(0) !== undefined ? found.get(0) : undefined;
    // }
    if ($$liveData !== undefined) found = $$liveData.get("live");
    return found;
  }
  _getCardImage($$card) {
    let cardImage = false;
    // let cardPresentation = false;
    let decodedValues;
    if (
      $$card !== undefined &&
      $$card.get("eventThumb") !== undefined &&
      $$card.get("eventThumb") !== null &&
      $$card.get("eventThumb") !== false
    ) {
      try {
        decodedValues = JSON.parse($$card.get("eventThumb"));
      } catch (e) {}
      // console.log('decoded is', decodedValues)
      if (
        decodedValues !== undefined &&
        isArray(decodedValues) &&
        decodedValues[0] &&
        decodedValues[0].dest
      ) {
        cardImage =
        config.eventthumbpreview.replace("{eventId}", $$card.get("id")) +
        "/" +
        decodedValues[0].dest;
      }
    }
    return cardImage;
  }
  _getFakeCard() {
    const { match } = this.props;
    const { id } = match.params;
    if (id === 'doipjiodezjdiozjdeiij') return true;
    else return this._getCard();
  }

  _onMessageSent() {
    this.setState({
      logMessage: "Question envoyée !",
    });
  }
  _handleSnackbarClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      logMessage: false,
    });
  }
  _redirectToLogin = (e) => {
    let pn = window.location.pathname;
    // console.log(pn[pn.length -1])
    if (pn[pn.length -1] === '/') {
      pn = pn.substring(0, pn.length -1);
      // console.log('mathced!')
    }

    let returnUrl = pn+window.location.search;
    // console.log(returnUrl)
    returnUrl = encodeURIComponent(returnUrl);
    // console.log(returnUrl)
    this.props.history.push(
      "/login?_pn="+
      returnUrl
    )
  }
  render() {
    const { $$data, userRole, classes } = this.props;
    const { logMessage, showLiveButton } = this.state;
    // const showLiveButton = true;
    // const $$subscriptions =
    // userRole !== undefined &&
    // $$data !== undefined &&
    // $$data !== undefined &&
    // $$data.get("myfutureevents") !== undefined
    // ? $$data.get("myfutureevents").map((e) => e.get("id"))
    // : Immutable.Map();
    // const $$card = this._getCard();
    const $$card = this._getCard();
    // if($$card !== undefined) console.log('card is', $$card.toJS())
    // else console.log('CARD IS NOT FOUND !')
    const timeRemaining =
    $$card !== undefined ? getRemainingTime($$card.get("eventDate")) : {};
    //console.log($$card!=undefined ? $$card.toJS() : $$card);
    const hasEventLegalLiveFile= $$card!=undefined && $$card.get('eventLegalLiveFile')!==undefined && $$card.get('eventLegalLiveFile')!=='[]';

    // console.log({
    //   userRole,
    //   card: $$card
    // })
    // console.log($$data, userRole)
    const liveId = this.props.match.params.id;

    return (
      <Template current="live" headerPosition={'relative'}>
        <Box className={classes.paperWrapper} elevation={3}>
          <Paper square elevation={0} className={classes.paper}>

            <Choose>
              <When
                condition={$$data !== undefined && userRole === undefined}
                >
                <Box my={6} align="center">
                  <Typography
                    variant="h5"
                    gutterBottom={true}
                    className={classes.notice}
                    >
                    {i18n.get('liveMustLoginFirst', {pretty: false})}
                  </Typography>
                  <LoginButtons/>
                </Box>
              </When>
              <When
                condition={
                  $$data !== undefined &&
                  (userRole === "member" ||
                  userRole === "admin" ||
                  userRole === "speaker" ||
                  userRole === "staff")
                }
                >
                <If condition={$$card !== undefined}>
                  <If
                    condition={
                      $$card.get("eventState") === "live" ||
                      userRole === "speaker" ||
                      userRole === "admin" ||
                      userRole === "staff"
                    }
                    >
                    <Choose>
                      <When
                        condition={
                          $$card.get("eventLinkExternal") !== "" &&
                          $$card.get("eventLinkExternal") !== undefined &&
                          $$card.get("eventLinkExternal") !== null &&
                          ($$card.get("eventLinkExternal2") === "" ||
                          $$card.get("eventLinkExternal2") === undefined ||
                          $$card.get("eventLinkExternal2") === null)
                        }
                        >
                        <Box my={6} align="center">
                          <If
                            condition={
                              $$card.get("eventLinkInstructions") !==
                              undefined &&
                              $$card.get("eventLinkInstructions") !== ""
                            }
                            >
                            <Typography
                              variant={"subtitle1"}
                              gutterBottom={true}
                              >
                              <Paragrapher
                                prettyfy={true}
                                text={$$card.get("eventLinkInstructions")}
                                />
                            </Typography>
                          </If>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.big}
                            onClick={this._gotoLiveRoom}
                            >
                            {i18n.get('liveClickToRedirect', {pretty: false})}
                          </Button>
                        </Box>

                      </When>
                      <When
                        condition={
                          $$card.get("eventLinkExternal") !== "" &&
                          $$card.get("eventLinkExternal") !== undefined &&
                          $$card.get("eventLinkExternal") !== null &&
                          $$card.get("eventLinkExternal2") !== "" &&
                          $$card.get("eventLinkExternal2") !== undefined &&
                          $$card.get("eventLinkExternal2") !== null
                        }
                        >
                        <Box my={6} align="center">
                          <If
                            condition={
                              i18n.has("eventLinkInstructionsMultiple")
                            }
                            >
                            <Box pb={3} width="100%">
                              <Typography
                                variant={"h2"}
                                gutterBottom={true}
                                >
                                {i18n.get("eventLinkInstructionsMultiple", {pretty: true})}
                              </Typography>
                            </Box>
                          </If>
                          <Button
                            color={"default"}
                            className={classes.big}
                            onClick={(e) => this._gotoLiveRoom(e, 1)}
                            >
                            Langue Française
                          </Button>
                          <Button
                            color={"default"}
                            className={classes.big}
                            onClick={(e) => this._gotoLiveRoom(e, 2)}
                            >
                            English language
                          </Button>
                        </Box>
                      </When>
                      <When
                        condition={
                          $$card.get("eventEoleOwnerKey") !== "" &&
                          $$card.get("eventEoleOwnerKey") !==
                          undefined &&
                          $$card.get("eventEoleOwnerKey") !==
                          null &&
                          userRole === "admin"
                        }
                        >
                        <Box my={6} align="center">
                          <If
                            condition={
                              $$card.get(
                                "eventLinkInstructions"
                              ) !== undefined &&
                              $$card.get(
                                "eventLinkInstructions"
                              ) !== false
                            }
                            >
                            <Typography
                              variant={"subtitle1"}
                              gutterBottom={true}
                              >
                              {$$card.get("eventLinkInstructions")}
                            </Typography>
                          </If>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={this._gotoLiveRoom}
                            >
                            Cliquez pour être redirigé vers la salle
                            Administrateur de la webconférence
                          </Button>
                        </Box>
                      </When>
                      <When
                        condition={
                          $$card.get("eventEolePresenterKey") !==
                          "" &&
                          $$card.get("eventEolePresenterKey") !==
                          undefined &&
                          $$card.get("eventEolePresenterKey") !==
                          null &&
                          (userRole === "speaker" ||
                          userRole === "member")
                        }
                        >
                        <Box my={6} align="center">
                          <If
                            condition={
                              $$card.get(
                                "eventLinkInstructions"
                              ) !== undefined &&
                              $$card.get(
                                "eventLinkInstructions"
                              ) !== false
                            }
                            >
                            <Typography
                              variant={"subtitle1"}
                              gutterBottom={true}
                              >
                              {$$card.get("eventLinkInstructions")}
                            </Typography>
                          </If>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={this._gotoLiveRoom}
                            >
                            Cliquez pour être redirigé vers la salle
                            Présentateur de la webconférence
                          </Button>
                        </Box>
                      </When>

                      <When
                        condition={
                          $$card.get("eventEoleParticipantKey") !==
                          "" &&
                          $$card.get("eventEoleParticipantKey") !==
                          undefined &&
                          $$card.get("eventEoleParticipantKey") !==
                          null &&
                          userRole === "speaker"
                        }
                        >
                        <Box my={6} align="center">
                          <If
                            condition={
                              $$card.get(
                                "eventLinkInstructions"
                              ) !== undefined &&
                              $$card.get(
                                "eventLinkInstructions"
                              ) !== false
                            }
                            >
                            <Typography
                              variant={"subtitle1"}
                              gutterBottom={true}
                              >
                              {$$card.get("eventLinkInstructions")}
                            </Typography>
                          </If>
                          <Button
                            variant="contained"
                            color={"primary"}
                            onClick={this._gotoLiveRoom}
                            >
                            Cliquez pour être redirigé vers la salle
                            Participant de la webconférence
                          </Button>
                        </Box>
                      </When>
                      <Otherwise>
                        <If
                          condition={
                            $$card.get("eventLinkLive") !== undefined &&
                            $$card.get("eventLinkLive") !== false &&
                            $$card.get("eventLinkLive") !== null &&
                            $$card.get("eventLinkLive") !== ""
                          }
                          >
                          <Grid container alignContent="center">
                            <Grid item xs={12} className={classes.breadCrumbs}>
                              <Typography variant="subtitle1"><Link to="/">{i18n.get('home', {pretty: false})}</Link>&nbsp;&nbsp;&gt;&nbsp;&nbsp;<Prettyfier text={$$card.get('eventTitle')}/></Typography>
                            </Grid>

                            <If
                              condition={
                                $$card.get("eventLinkInstructions") !==
                                undefined &&
                                $$card.get("eventLinkInstructions") !== false
                              }
                              >
                              <Grid item xs={12}>
                                <Typography
                                  variant={"subtitle1"}
                                  align="center"
                                  className={classes.instructions}
                                  gutterBottom={true}
                                  >
                                  <Paragrapher
                                    prettyfy={true}
                                    text={$$card.get("eventLinkInstructions")}
                                    />
                                </Typography>
                              </Grid>
                            </If>


                            <Grid item xs={12}>
                              <BlockRatio ratio={"16_9"}>
                                <iframe
                                  id="player"
                                  title="replay"
                                  src={$$card.get("eventLinkLive")}
                                  className={"embed"}
                                  width="100%"
                                  height="100%"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen"
                                  allowFullScreen
                                  />
                                  <VideoTracker eventId={$$card.get("id")}/>
                              </BlockRatio>
                            </Grid>
                            <Grid item xs={12}>
                              <Box mt={0} mb={1} px={4} align="center">
                                <Typography variant="caption">
                                  <span className={classes.inline}>
                                    {i18n.get("liveCopyrightWarning")}
                                  </span>
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>

                              <Box my={2}>
                                <Typography variant="h5">
                                  Posez votre question ici :
                                </Typography>
                              </Box>
                              <Box h={56} w={'100%'}>
                                <LiveMessage
                                  $$event={$$card}
                                  onMessageSent={this._onMessageSent}
                                  />
                              </Box>
                              <Orchestrator
                                $$card={$$card}
                                $$data={$$data}
                                eventId={$$card.get("id")}
                                pull={true}
                                />
                                <If condition={hasEventLegalLiveFile}>
                                  <Box my={4} align="center">
                                    <Button variant="contained" color="primary"  className={classes.cardDownloadButton} onClick={(e) => {
                                      e.preventDefault();
                                      window.open(config.events+'/download/'+$$card.get('id')+'?f=eventLegalLiveFile')
                                    }}>{i18n.get('liveLegalFile')}</Button>
                                  </Box>
                                </If>

                            </Grid>
                          </Grid>

                        </If>

                        <If
                          condition={
                            (($$card.get("eventLinkLive") === undefined ||
                            $$card.get("eventLinkLive") === false ||
                            $$card.get("eventLinkLive") === null ||
                            $$card.get("eventLinkLive") === "") &&
                            ($$card.get("eventLinkExternal") === "" || $$card.get("eventLinkExternal") ===
                            undefined ||
                            $$card.get("eventLinkExternal") === null))
                          }
                          >
                          <Box w={'100%'} py={3} px={3} mt={2}>
                          <Typography variant="h3" align="center" >
                            {i18n.get('commonError', {pretty: false})}
                          </Typography>

                          <Typography variant="h6" align="center" >
                            {i18n.get('liveMustConfigureEmbed', {pretty: false})}
                          </Typography>
                          </Box>
                        </If>
                      </Otherwise>
                    </Choose>


                    <If
                      condition={
                        $$card.get("eventState") === "finished" &&
                        userRole !== "admin" &&
                        userRole !== "staff"
                      }
                      >
                      <Box my={8} align="center">
                        <Typography
                          variant="h5"
                          gutterBottom={true}
                          >
                          Votre événement est terminé !
                        </Typography>
                      </Box>
                    </If>
                  </If>
                  <If condition={$$card === undefined}>
                    <Box my={8} align="center">
                      <Typography
                        variant="h5"
                        gutterBottom={true}
                        >
                        Vous n'êtes pas inscrit(e) à cette webconférence :(
                        </Typography>
                        <Box mt={3}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              window.location.replace("/");
                            }}
                            >
                            Cliquez pour retourner à l'accueil
                          </Button>
                        </Box>
                      </Box>
                    </If>

                    <If
                      condition={
                        $$card.get("eventState") === "pending" &&
                        userRole !== "admin" &&
                        userRole !== "staff"
                      }
                      >
                      <Box my={8} align="center" pt={2} style={{backgroundColor: '#fff'}}>
                        <Typography
                          variant="h5"
                          gutterBottom={true}
                          >
                          <Prettyfier text={$$card.get("eventTitle")}/>
                        </Typography>
                        <Typography
                          variant="h6"
                          gutterBottom={true}
                          >
                          {""}
                          {i18n.get('liveYourEventStartsIn', {pretty: false})}
                        </Typography>
                        <RemainingTime
                          days={timeRemaining.days}
                          hours={timeRemaining.hours}
                          minutes={timeRemaining.minutes}
                          />

                        <If condition={showLiveButton === true}>
                          <Box align="center" py={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                window.location.replace(
                                  window.location.href
                                );
                              }}
                              >
                              {i18n.get('liveClickToRefreshPageAndGotoLive', {pretty: false})}
                            </Button>
                          </Box>
                        </If>
                        <If condition={showLiveButton !== true}>
                          <Box align="center" py={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                window.location.replace(
                                  window.location.href
                                );
                              }}
                              >
                              {i18n.get('liveClickToRefreshPage', {pretty: false})}
                            </Button>
                          </Box>
                        </If>
                      </Box>
                    </If>
                  </If>
                  <Box my={6}>
                    <HomeHelp/>
                  </Box>
                </When>
                <Otherwise>
                  <div className={"Loading"}>
                    <Spinner style={{ marginTop: 200 }}>
                      Chargement...
                    </Spinner>
                  </div>
                </Otherwise>
              </Choose>

              <Snackbar
                open={
                  logMessage !== "" &&
                  logMessage !== undefined &&
                  logMessage !== false
                }
                autoHideDuration={3000}
                onClose={this._handleSnackbarClose}
                >
                <Alert onClose={this._handleSnackbarClose} severity="success">
                  {logMessage}
                </Alert>
              </Snackbar>
            </Paper>
          </Box>
        </Template>
      );
    }
  }

  function mapStateToProps(state) {
    const props = {
      $$data: getDataHome(state),
      error: getLoadingError(state),
      isLoggedIn: isLoggedIn(state),
      userRole: getCurrentUserRole(state),
      $$user: getCurrentUser(state),
      $$liveData: getLiveData(state),
    };
    return props;
  }

  function mapDispatchToProps(dispatch) {
    return {
      onMount: ({ liveId }) => {
        const uri = queryString.parse(window.location.search);
        dispatch(
          fetchData({
            key: AF_KEY,
            fetchUrl: config.home,
          })
        );
        // console.log('PARAMS ARE', uri)
        dispatch(
          fetchLive({
            liveId,
            params: {k: uri.k, r: uri.r}
          })
        );
      },
      onUnmount: () => {
        dispatch(clearLive())
      }
    };
  }
  // export default ;
  export default
    withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LivePlayerOrchestrator))
  );
