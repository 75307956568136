import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import config from 'config/api';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { serieUrl, categoryLabel } from 'components/UI/Serie/helper';

const styles = makeStyles((theme) => {
  return {
    root: {
      padding: '80px 0',
      backgroundColor: '#fff',
      overflow: 'hidden',
    },
    innerWrapper: {
      margin: '0 auto',
      maxWidth: '1350px',
      padding: '0 20px 0 20px',
    },
    categTitle: {
      paddingBottom: '8px',
      borderBottom: '1px solid #D8DCDC',
    },
    categText: {
      marginTop: '32px',
      marginBottom: '40px',
    },
    categItems: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '32px',
    },
    categItem: {
      cursor: 'pointer',
      position: 'relative',
    },
    categLabel: {
      color: 'white',
      fontSize: '18pt',
      fontWeight: 'bold',
      position: 'absolute',
      left: '40px',
      bottom: '50px',
    },
  };
});

export default function HomeReplayCategs({}) {
  const classes = styles();
  let history = useHistory();

  return (
    <Grid container alignItems="center" justify="flex-start" className={classes.root}>
      <Box className={classes.innerWrapper}>
        <Typography variant="h2" component="div" gutterBottom className={classes.categTitle}>
          Mes replays
        </Typography>
        <Typography variant="body1" className={classes.categText}>
          Profitez de vidéos et mini-séries en replay animées par des experts de la BPCO, de l’Asthme, de l’Innovation en pneumologie et du parcours de soins du patient.
        </Typography>

        <Grid container spacing={0} className={classes.categItems}>
          {Object.keys(config.serie.replay.menus).map((category) => {
            if (category == 'tout') return;
            const item = config.serie.replay.menus[category];
            return (
              <Grid key={category} item className={classes.categItem} onClick={(e) => history.push(serieUrl('replay', category))}>
                {/*<Box className={classes.categLabel}>{item.label}</Box>*/}
                <img src={item.img} width={300} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Grid>
  );
}
