import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import queryString from "query-string";
import Immutable from "immutable";
import Template from "components/UI/Template/Template";
import LoginForm from "components/UI/Login/LoginForm";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {
  isLoggedIn,
  getCurrentUserRole,
} from "components/UI/Account/ducks/authentication";
import ResetPasswordRequestForm from "components/UI/ResetPassword/ResetPasswordRequestForm";
import RegistrationTwoStepsForm from "components/UI/Registration/RegistrationTwoStepsForm";
import i18n from "helpers/i18n";

const StyledRegistrationTwoStepsForm = withStyles((theme) => ({
  root: {
    "& .MuiTypography-h5": {
      fontSize: "1.075rem",
      fontWeight: 600,
    },
    "& .MuiButton-textSecondary": {
      color: theme.palette.primary.dark,
      textDecoration: "underline",
    },
    "& .MuiButton-containedPrimary": {},
    "& .MuiMenuItem-root": {
      display: "inline-block",
      color: theme.palette.secondary.dark,
      backgroundColor: "transparent",
      width: "50%",
      marginRight: 0,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
  column: {
    padding: 0,
    "&:before": {
      border: "none",
    },
  },
  formGroup: {
    "&:before": {
      border: "none",
    },
    marginBottom: theme.spacing(2),
  },
  jobForm: {
    maxWidth: 420,
    display: "block",
    margin: "0 auto",
    "& .MuiButton-containedPrimary": {
      display: "block",
    },
  },
  "& .MuiMenuItem-root": {
    display: "inline-block",
    color: theme.palette.secondary.dark,
    backgroundColor: "transparent",
    width: 200,
    marginRight: theme.spacing(2),
  },
  subscriptionFormNotice: {
    textAlign: "left",
  },
}))(RegistrationTwoStepsForm);

const styles = (theme) => {
  return {
    root: {
      minHeight: "100%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      "& .MuiTypography-h3": {
        textAlign: "center",
      },
      "& a": {
        textAlign: "left",
      },
      "& .MuiPaper-root": {
        backgroundColor: "transparent",
      },
      "& .MuiTypography-h4": {
        marginBottom: theme.spacing(3),
        color: theme.palette.secondary.dark,
        fontSize: "1.275rem",
        fontWeight: 600,
        textAlign: "center",
      },
    },

    paperWrapper: {
      width: "100%",
      display: "block",
      position: "relative",
    },

    paper: {
      padding: theme.spacing(0),
      position: "relative",
      "& .MuiTypography-h3": {},
    },
    pageImage: {
      backgroundImage: 'url('+require('assets/images/login.png')+')',
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "50% 50%",
      minHeight: "85vh",
      //height: "100%",
    },
  };
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      accountEventCategory: [],
    };
  }
  _onLoginPasswordReset = () => {
    this.setState({
      step: 3,
    });
  };
  _onRegistrationCancel = () => {
    this.setState({
      step: 1,
    });
  };
  _onAccountEventCategoryChange = (kind, o) => {
    this.setState({ accountEventCategory: o.value });
  };
  render() {
    const { classes, isLoggedIn, kind, showStep } = this.props;
    const { step } = this.state;
    const uri = queryString.parse(window.location.search);
    if (isLoggedIn) {
      const url = uri._pn !== undefined ? decodeURIComponent(uri._pn) : "/";
      return (
        <Redirect
          to={{
            pathname: url,
            state: { from: this.props.location },
          }}
        />
      );
    }
    const $$extraFields = {};
    const action = uri.action;
    return (
      <Template current={"login"}>
        <Box className={classes.paperWrapper}>
          <Grid
            container
            className={classes.root}
            justify="flex-end"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={7} lg={6} xl={6} style={{alignSelf: 'center'}}>
              <If condition={step === 1}>
                <Grid item xs={12}>
                  <Paper square elevation={0} className={classes.paper}>
                    <If condition={kind !== "private"}>
                      <Box>
                        <If
                          condition={
                            action === "register" || action === undefined
                          }
                        >
                          <StyledRegistrationTwoStepsForm
                            showRequiredLegend={false}
                            showRecap={true}
                            showStep={showStep}
                            showLogin={action !== undefined ? false : true}
                            scrollOnMount={false}
                            extraFields={$$extraFields}
                          />
                        </If>
                      </Box>
                      <If condition={action === "login"}>
                        <Box
                          maxWidth={"420px"}
                          style={{ margin: "0 auto", display: "block" }}
                        >
                          <Box mb={3}>
                            <Typography variant="h4">
                              {i18n.get("loginAction", { pretty: false })}
                            </Typography>
                          </Box>
                          <LoginForm
                            onFormComplete={this._onLoginFormComplete}
                            onPasswordResetRequest={this._onLoginPasswordReset}
                            footerAlign={"left"}
                            showRequiredLegend={false}
                            extraFields={Immutable.fromJS({ kind })}
                          />
                        </Box>
                      </If>
                    </If>
                    <If condition={kind === "private"}>
                      <Box
                        maxWidth={"420px"}
                        style={{ margin: "0 auto", display: "block" }}
                      >
                        <Box mb={3}>
                          <Typography variant="h4">
                            {i18n.get("accountAccessRestricted", {
                              pretty: false,
                            })}
                          </Typography>
                        </Box>
                        <LoginForm
                          onFormComplete={this._onLoginFormComplete}
                          onPasswordResetRequest={this._onLoginPasswordReset}
                          footerAlign={"left"}
                          showRequiredLegend={false}
                          extraFields={Immutable.fromJS({ kind })}
                        />
                      </Box>
                    </If>
                  </Paper>
                </Grid>
              </If>
              <If condition={step === 3}>
                <Grid item xs={12}>
                  <Paper square elevation={0} className={classes.paper}>
                    <Box align="left">
                      <Box
                        mb={3}
                        maxWidth={420}
                        style={{ display: "block", margin: "0 auto" }}
                      >
                        <Typography variant="h4">
                          {i18n.get("loginForgotPassword", { pretty: false })}
                        </Typography>

                        <ResetPasswordRequestForm
                          onFormCancel={this._onRegistrationCancel}
                          noTitle
                        />
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </If>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              lg={6}
              xl={6}
              className={classes.pageImage}
              container
            ></Grid>
          </Grid>
        </Box>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
    isLoggedIn: isLoggedIn(state),
  };
  return props;
}
export default withRouter(connect(mapStateToProps)(withStyles(styles)(Login)));
