import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import Immutable from "immutable";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import WarningIcon from "@material-ui/icons/Warning";
import EventSelector from "components/Event/EventSelector";
import Paragrapher from "components/UI/Paragrapher/Paragrapher";
import RemainingTime from "components/UI/RemainingTime";
import i18n from "helpers/i18n";
import { formatDate } from "helpers/stringUtils";
import getCardImage from "helpers/getCardImage";
import getRemainingTime from "helpers/getRemainingTime";
// import _compact from "lodash/compact";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const styles = (theme) => {
  return {
    root: {
      marginBottom: "30px",
    },
    whiteBg: {
      position: "relative",
      marginBottom: theme.spacing(4),
      "&:before": {
        position: "absolute",
        left: 0,
        top: 0,
        content: "' '",
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.paper,
        opacity: 0.9,
      },
    },
    relative: {
      position: "relative",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      // paddingLeft: theme.spacing(4),
      // paddingRight: theme.spacing(4),
    },
    paperPadder: {
      // color: '#fff',
      margin: "0 auto",
      padding: "40px 20px 40px",
      [theme.breakpoints.down("sm")]: {
        // margin: '0 20px',
      },
      // padding: '60px'
    },
    dateWrapper: {
      cursor: "pointer",
      color: "#fff",
      border: "1px #fff solid",
      padding: 10,
      backgroundColor: theme.palette.secondary.main,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        // marginBottom: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      transition: [
        theme.transitions.create("background-color", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        theme.transitions.create("color", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        theme.transitions.create("box-shadow", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      ],
      "&:hover": {
        boxShadow: theme.shadows[7],
      },
      "& .MuiTypography-body1": {
        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "1.17px",
        fontStyle: "normal",
        // color: theme.palette.common.black,
        // fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
          lineHeight: "16px",
        },
      },
      "& .MuiTypography-subtitle1, .MuiTypography-subtitle2 ": {
        color: "inherit",
      },
    },
    dateWrapperTitle: {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: "0.8rem",
    },
    inline: {
      display: "inline-block",
    },

    themeTitle: {
      fontSize: "2rem",
      // paddingBottom: theme.spacing(2),
      // borderBottom: '2px ' + theme.palette.primary.main + ' solid',
      color: "#fff",
      textAlign: "left",
      fontWeight: "normal",
      textTransform: "uppercase",
      marginBottom: 0,
    },
    themeDescription: {
      // fontSize: '2rem',
      // color: theme.palette.primary.main,
      fontSize: "1.1rem",
      // color: '#fff',
      color: "inherit",
      textAlign: "left",
      marginBottom: theme.spacing(2),
    },
    eventSubTitle: {
      // fontSize: '1.3rem',
      fontSize: "0.8rem",
      // paddingBottom: theme.spacing(2),
      // borderBottom: '2px ' + theme.palette.primary.main + ' solid',
      // color: '#fff',
      textAlign: "left",
      fontWeight: "normal",
      textTransform: "uppercase",
      marginBottom: 0,
    },
    eventTitle: {
      fontSize: "1.6rem",
      // paddingBottom: theme.spacing(2),
      // borderBottom: '2px ' + theme.palette.primary.main + ' solid',
      // color: '#fff',
      textAlign: "left",
      fontWeight: "normal",
      color: theme.palette.secondary.main,
      // textTransform: 'uppercase',
      marginBottom: 0,
    },

    description: {
      fontSize: "1.1rem",
      // fontSize: '2rem',
      // color: theme.palette.primary.main,
      // color: theme.palette.tertiary.main,
      // color: '#fff',
      color: "inherit",
      textAlign: "left",
      paddingRight: theme.spacing(2),
      "& a": {
        color: "inherit",
      },
      [theme.breakpoints.down("md")]: {
        paddingRight: 0,
        marginBottom: theme.spacing(2),
      },
    },
    dateWrapperSelected: {
      // backgroundColor: theme.palette.secondary.main,
      "& .MuiTypography-subtitle1, .MuiTypography-subtitle2 ": {
        color: theme.palette.text.light,
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
    dateWrapperActions: {
      padding: "0",
    },
    dateWrapperActionsButton: {
      transition: [
        theme.transitions.create("color", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        theme.transitions.create("opacity", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      ],
      backgroundImage: "none",
      color: theme.palette.text.light,
      backgroundColor: theme.palette.primary.main,
      fontSize: 22,
      fontWeight: "bold",
      letterSpacing: "1px",
      padding: "0px 20px",
      border: "1px transparent solid",
      display: "flex",
      "&:hover": {
        backgroundImage: "none",
        border: "1px #fff solid",
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
      },
    },
    subscriptionState: {
      position: "absolute",
      right: 5,
      top: 5,
      "& .MuiSvgIcon-root": {
        color: "inherit",
      },
    },
  };
};

class EventCardEmbed extends Component {
  render() {
    const {
      classes,
      $$card,
      selected,
      gIdx,
      $$group,
      themeColor,
      onToggleCardSelect,
      showReplay,
      onReplaySelected,
      showDescription,
      grid,
    } = this.props;
    const $$data = $$card;
    const cardImage = getCardImage($$data) || "";
    const hasCardImage =
      cardImage !== undefined && cardImage !== "" ? true : false;
    // console.log({cardImage})
    const containerGrid =
      grid !== undefined ? grid : { xs: 12, sm: 12, md: 12 };
    let isSelected;
    return (
      <Grid
        item
        xs={containerGrid.xs}
        sm={containerGrid.sm}
        md={containerGrid.md}
        className={clsx(classes.whiteBg)}
      >
        <Grid
          item
          xs={12}
          container
          spacing={0}
          alignItems="center"
          className={classes.relative}
        >
          <Paper elevation={3} className={classes.paperPadder}>
            <Grid container>
              <If condition={hasCardImage === true}>
                <Grid item xs={12} md={6}>
                  <img src={cardImage} />
                </Grid>
              </If>
              <Grid item xs={12} md={hasCardImage === true ? 6 : 12} container>
                <Grid item xs={12}>
                  <Typography variant={"h4"} className={classes.eventSubTitle}>
                    <Paragrapher
                      text={$$card.get("themeName")}
                      prettyfy={true}
                      element={"span"}
                    />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={
                    showDescription !== false || $$group === undefined ? 12 : 9
                  }
                >
                  <Typography variant={"h4"} className={classes.eventTitle}>
                    <Paragrapher
                      text={$$card.get("eventTitle")}
                      prettyfy={true}
                      element={"span"}
                    />
                  </Typography>
                  <If condition={$$card.get("eventGuestsDescription")}>
                    <div className={classes.description}>
                      <Paragrapher
                        text={$$card.get("eventGuestsDescription")}
                        prettyfy={true}
                        element={"span"}
                      />
                    </div>
                  </If>
                </Grid>
                <If condition={showDescription !== false}>
                  <Grid item xs={12}>
                    <div className={classes.description}>
                      <Paragrapher
                        text={$$card.get("eventDescription")}
                        prettyfy={true}
                      />
                    </div>
                  </Grid>
                </If>
                <Grid item xs={12} container>
                  <If condition={$$group !== undefined}>
                    {$$group
                      .map(($$e, eIdx) => {
                        isSelected = selected.indexOf($$e.get("id")) >= 0;
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={12}
                            key={"d_" + gIdx + "_" + eIdx}
                          >
                            <Paper
                              align="center"
                              elevation={3}
                              className={clsx(classes.dateWrapper, {
                                [classes.dateWrapperSelected]: isSelected,
                              })}
                              style={{
                                backgroundColor: isSelected
                                  ? themeColor
                                  : "inherit",
                                position: "relative",
                                color: isSelected ? undefined : themeColor,
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                onToggleCardSelect($$e.get("id"));
                              }}
                            >
                              <If condition={isSelected === true}>
                                <Typography
                                  variant={"caption"}
                                  gutterBottom
                                  className={classes.subscriptionState}
                                >
                                  <CheckBoxIcon style={{ color: "inherit" }} />
                                </Typography>
                              </If>
                              <If condition={isSelected !== true}>
                                <Typography
                                  variant={"caption"}
                                  gutterBottom
                                  className={classes.subscriptionState}
                                >
                                  <CheckBoxOutlineBlankIcon
                                    style={{ color: "inherit" }}
                                  />
                                </Typography>
                              </If>

                              <Box style={{ color: themeColor, width: "100%" }}>
                                <Typography
                                  variant={"subtitle1"}
                                  className={classes.dateWrapperTitle}
                                >
                                  {formatDate(
                                    $$e.get("eventDate"),
                                    "dddd dd mmmm",
                                  )}
                                </Typography>
                                <Typography variant={"subtitle2"}>
                                  <span className={classes.inline}>
                                    {formatDate(
                                      $$e.get("eventDate"),
                                      "HH'h'MM",
                                    )}
                                  </span>
                                </Typography>
                              </Box>
                            </Paper>
                          </Grid>
                        );
                      })
                      .toArray()}
                  </If>
                  <If condition={showReplay === true}>
                    <Box mt={2} width="100%" align="center">
                      <Button
                        variant="contained"
                        size="large"
                        onClick={onReplaySelected}
                      >
                        Voir le Replay
                      </Button>
                    </Box>
                  </If>
                </Grid>
              </Grid>
            </Grid>
          </Paper>{" "}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(EventCardEmbed));
