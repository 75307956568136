import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Redirect } from 'react-router-dom';
import i18n from 'helpers/i18n';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';

const styles = (theme) => {
  return {
  };
};

class LoginButtons extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
          <Button color="primary" variant="contained" id="header_connexion" className={classes.buttonMyAccount} onClick={(e) => {e.preventDefault(); this.props.history.push('/login?action=login')}}>{i18n.get('loginAction')}</Button>
          {" "}
          <Button color="primary" variant="contained" id="header_inscription" className={clsx(classes.buttonMyAccount, 'secondary')} onClick={(e) => {e.preventDefault(); this.props.history.push('/login?action=register')}}>{i18n.get('subscriptionTitle')}</Button>
      </React.Fragment>
        );
      }
    }

export default withRouter(withStyles(styles)(LoginButtons));
