import React, { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import config from 'config/api';
import Box from '@material-ui/core/Box';
import { serieUrl, categoryLabel } from 'components/UI/Serie/helper';
import { primaryColor } from 'helpers/colors';

import { makeStyles } from '@material-ui/core/styles';
const styles = makeStyles((theme) => {
  return {
    root: {
      margin: 0,
    },
    items: {
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      gap: '10px 25px',
      flexWrap: ' wrap',
      padding: '.2em .5em',
      margin: '1em 12%',
      [theme.breakpoints.down('sm')]: {
        margin: '1em 5%',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '1em 1%',
      },
    },
    item: {
      flexGrow: '1',
      minWidth: '100px',
      backgroundColor: 'white',
      color: '#2A566B',
      padding: '1em 1.5em',
      textAlign: ' center',
      cursor: 'pointer',
      borderRadius: '8px',
      '&:hover': {
        color: primaryColor,
      },
      '&.selected': {
        backgroundColor: '#2A566B',
        color: 'white',
      },
    },
  };
});

export default function SeriesFilters({ type, category }) {
  let history = useHistory();
  const classes = styles();
  const menus = config.serie[type].menus;

  const onClick = (event, category) => {
    history.push(serieUrl(type, category));
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.items}>
        {Object.keys(menus).map((key, i) => {
          return (
            <Box key={key} className={clsx(classes.item, key == category ? ' selected' : '')} onClick={(event) => onClick(event, key)}>
              {menus[key].label}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
