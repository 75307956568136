import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import config from 'config/api';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {serieUrl, categoryLabel} from 'components/UI/Serie/helper';

const styles = makeStyles((theme) => {
  return {    
  root: {
    padding: '80px 0',
  },
  innerWrapper:{
    margin:'0 auto',
    maxWidth:'1350px',
    padding:"0 20px 0 20px",
    minWidth: "1339px",//fix retrait Pneumo Innovations
    [theme.breakpoints.down('md')]: {//fix retrait Pneumo Innovations
      margin:'0 0',
      minWidth: "unset",
    },
  },  
  categTitle: {
    paddingBottom:'8px',
    borderBottom: '1px solid #D8DCDC',
  },
  categText: {
    marginTop: '32px',
    marginBottom: '40px',
  },
  categItems: {
    display: 'flex',
    alignItems: 'flexStart',
    gap: '40px',
    alignSelf: 'stretch',  
  },
  categItem: {
    cursor: 'pointer',
    position: 'relative',
  },
  categLabel: {
    color: 'white',
    fontSize: '18pt',
    fontWeight: 'bold',
    position: 'absolute',
    left: '40px',
    bottom: '50px',
  },
};
});

export default function HomePodcastCategs({ }) {
  const classes = styles();
  let history = useHistory();


  return (
    <Grid container alignItems="center" justify="flex-start" className={classes.root}>
      <Box className={classes.innerWrapper}>
      <Typography variant="h2" component="div" gutterBottom className={classes.categTitle}>
          Mes podcasts
      </Typography>
      <Typography variant="body1" className={classes.categText}>
      Découvrez toutes nos émissions de podcasts avec des intervenants spécialisés dans le domaine des pathologies respiratoires.
      </Typography>

      <Grid container spacing={0} className={classes.categItems}>
      {Object.keys(config.serie.podcast.menus).map(category=>{
          if(category=='tout')
            return;
          const item=config.serie.podcast.menus[category];
          return <Grid key={category} item className={classes.categItem} onClick={(e)=>history.push(serieUrl('podcast', category))}>
            {/*<Box className={classes.categLabel}>{item.label}</Box>*/}
            <img src={item.img} width={405}/>
          </Grid>
        })}
      </Grid>
      </Box>
    </Grid>
  );
}
