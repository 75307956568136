import React, { useEffect } from 'react';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import queryString from 'query-string';
import config from 'config/api';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import BlockRatio from 'components/BlockRatio';
import Spinner from 'components/UI/Spinner';
import Template from 'components/UI/Template/Template';
import { toHHMM } from 'components/UI/helpers/time';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Prettyfier from 'components/UI/Prettyfier';
import i18n from 'helpers/i18n';
import { Link } from 'react-router-dom';
import VimeoPlayer from '@vimeo/player';
import { makeStyles } from '@material-ui/core/styles';
//import VideoTracker from './VideoTracker';
import apiFetch from 'components/UI/helpers/fetch';
import getThumb from 'components/UI/helpers/thumb';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from 'react-router-dom';
import { serieUrl, categoryLabel } from 'components/UI/Serie/helper';
import Error from 'components/UI/Error';
import Share from 'components/UI/Share';
import NPS from 'components/UI/NPS';
import bg_serie from 'assets/images/bg_serie.svg';

const styles = makeStyles((theme) => {
  return {
    root: {
      '& .MuiTypography-subtitle1': {
        textAlign: 'left',
      },
      '& .MuiSvgIcon-root': {
        verticalAlign: 'middle',
        marginTop: -2,
      },
    },
    paperWrapper: {
      backgroundColor: theme.palette.serie.pageBG,
      paddingTop: theme.spacing(3),
      width: '100%',
      display: 'block',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        content: "' '",
        height: '160px',
        backgroundColor: theme.palette.replays && theme.palette.replays.backgroundColor ? theme.palette.replays.backgroundColor : theme.palette.primary.light,
        zIndex: -1,
      },
    },
    paper: {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(4),
      '& a': {
        color: 'inherit',
      },
    },
    inline: {
      display: 'inline-block',
    },

    breadCrumbsWrapper: {
      backgroundColor: theme.palette.serie.breadCrumbBG,
      borderTop: theme.palette.serie.breadCrumbBorder + ' 1px solid',
      //borderBottom:theme.palette.serie.breadCrumbBorder+' 1px solid',
    },
    breadCrumbs: {
      maxWidth: 1024,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: '0.8125rem',
      color: theme.palette.serie.breadCrumbText,

      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),

      '& a': {
        color: theme.palette.serie.breadCrumbText,
        textDecoration: 'none',
        display: 'inline-block',
        verticalAlign: 'middle',
      },
      '& *': {
        color: theme.palette.serie.breadCrumbText,
      },
    },
    leftBloc: {
      backgroundImage: "url('" + bg_serie + "')",
      backgroundSize: '87%',
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      minHeight: '320px',
    },
    rightBloc: {
      padding: '.5em .5em .5em 2em',
    },

    itemVideo: {
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
    itemDescription: {
      marginBottom: theme.spacing(2),
    },
    itemDuration: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
    },
    itemCopyright: {
      color: theme.palette.primary.main,
    },
    itemCode: {
      // color: theme.palette.primary.main
      textAlign: 'right',
      fontSize: '.6rem',
    },
    itemGuests: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    navWrapper: {
      marginTop: theme.spacing(4),
      position: 'relative',
      height: 60,
    },
    navButton: {
      position: 'absolute',
      bottom: 0,
      backgroundColor: theme.palette.serie.buttonBG,
      color: theme.palette.serie.buttonFG,
      '&:hover': {
        backgroundColor: theme.palette.serie.buttonFG,
        color: theme.palette.serie.buttonBG,
      },
    },
    navPrevious: {
      left: 0,
    },
    navNext: {
      right: 0,
    },
    itemTitle: {
      color: theme.palette.serie.title,
      marginBottom: theme.spacing(1),
    },
    itemTheme: undefined,
    itemTimecodesToggler: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '10px 10px 0 0',
      color: '#fff',
    },
    itemTimecodesWrapper: {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      position: 'absolute',
      top: 0,
      left: '-100%',
      height: '100%',
      width: '100%',
      maxWidth: 300,
      transition: [
        theme.transitions.create('left', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      ],
      '&.show': {
        left: 0,
      },
      '& .MuiTypography-h3': {
        color: 'inherit',
        marginBottom: theme.spacing(1),
      },
    },
    pdfBloc: {
      backgroundColor: '#fff',
      borderRadius: '6px',
      textAlign: 'center',
      padding: '1em',
    },
    itemTimecodes: {
      padding: theme.spacing(1) + 'px ' + theme.spacing(2) + 'px',
    },
    downloadButton: {
      backgroundColor: theme.palette.serie.buttonBG,
      color: theme.palette.serie.buttonFG,
      '&:hover': {
        backgroundColor: theme.palette.serie.buttonFG,
        color: theme.palette.serie.buttonBG,
      },
    },
  };
});

function NotConnected(classes) {
  return (
    <Box my={6} align="center">
      <Typography variant="h5" gutterBottom={true} className={clsx(classes.legend, classes.h6extends)}>
        Merci de vous connecter pour accéder à ce contenu
      </Typography>
      <Button variant="contained" color="primary" style={{ margin: '0 5px' }} onClick={(e) => this._redirectToLogin('login')}>
        {' '}
        {i18n.get('loginAction', { pretty: false })}
      </Button>
      <Button variant="contained" color="primary" style={{ margin: '0 5px' }} onClick={(e) => this._redirectToLogin('register')}>
        {' '}
        {i18n.get('subscribeAction', { pretty: false })}
      </Button>
    </Box>
  );
}

function Loading(classes) {
  return (
    <div className={'Loading'}>
      <Spinner style={{ marginTop: 200 }}>Chargement...</Spinner>
    </div>
  );
}

export default function Player({ id }) {
  const classes = styles();

  const verbose = true;
  let history = useHistory();

  const [error, setError] = React.useState(null);

  const [row, setrow] = React.useState(null);
  useEffect(() => {
    if (id) {
      fetchData();
    } else {
      console.error('Episode/List no serieId');
      setrow(false);
    }
    return () => {
      console.log('cleaned up');
    };
  }, [id]);

  const [player, setPlayer] = React.useState(null);
  useEffect(() => {
    loadPlayer();
  }, [row]);

  useEffect(() => {
    const time = readTimeFromHash();
    if (player && time) goto(time);
  }, [player]);

  const loadPlayer = () => {
    console.log(' load player');
    const iframe = document.querySelector('#player');
    if (!iframe) {
      console.log('error, #player not found');
      return false;
    }
    let player = new VimeoPlayer(iframe);
    //https://www.npmjs.com/package/@vimeo/player
    player.on('play', onPlay);
    player.on('playing', onPlaying);
    player.on('ended', onEnded);
    player.on('timeupdate', onTimeUpdate);
    player.on('pause', onPause);
    player.on('seeked', onSeeked);

    console.log('player loaded', player);
    setPlayer(player);
    return true;
  };

  const goto = (time) => {
    player.setCurrentTime(time).then(() => {
      player.play().catch(() => {
        console.log('play not allowed');
      });
    });
  };

  function readTimeFromHash() {
    if (window.location.hash === undefined) return null;
    const hash = window.location.hash.substring(1);
    const res = parseInt(hash, 10);
    if (isNaN(res)) return null;
    return res;
  }

  const fetchData = () => {
    apiFetch('/episode/get/' + id, {})
      .then((data) => {
        console.log('data', data);
        setrow(data);
        setError(null);
      })
      .catch(({ response, error }) => {
        console.log('fetchData error', error, response);
        setrow(false);
        setError(error);
      });
  };

  const fetchUpdate = (seconds) => {
    apiFetch('/episode/onUpdate/' + id, { time: seconds })
      .then((data) => {
        console.log('data', data);
      })
      .catch(({ response, error }) => {
        console.log('fetchData error', error, response);
      });
  };

  const onPlay = (data) => {
    console.log('onPlay', data);
    apiFetch('/episode/onPlay/' + id, {})
      .then((data) => {
        console.log('data', data);
      })
      .catch(({ response, error }) => {
        console.log('fetchData error', error, response);
      });
  };

  const onPlaying = (data) => {
    //fetchUpdate;
    if (verbose) console.log('VideoTracker onPlaying', data);
    fetchUpdate(data.seconds);
  };
  const onEnded = (data) => {
    if (verbose) console.log('VideoTracker onEnded', data);
    fetchUpdate('end');
  };
  const onTimeUpdate = (data) => {
    /*if(verbose)
      console.log("VideoTracker onTimeUpdate", data);*/
    if (Math.random() <= 0.2) fetchUpdate(data.seconds);
  };
  const onPause = (data) => {
    if (verbose) console.log('VideoTracker onPause', data);
    fetchUpdate(data.seconds);
  };
  const onSeeked = (data) => {
    if (verbose) console.log('VideoTracker onSeeked', data);
    fetchUpdate(data.seconds);
  };

  const navigate = (row) => {
    history.push(serieUrl('replay', row.serieCategory, 'episode', row.id));
  };

  if (error !== null) return <Error result={error} />;

  if (!row) return Loading(classes);
  console.log('row', row);
  let linkThumb = row.episodeLinkThumb != '' ? getThumb('episode', row.id + '/links', row.episodeLinkThumb) : '';
  let linkPDF = row.episodeFiles != '' ? getThumb('episode', row.id + '/files', row.episodeFiles) : null;
  const withPDF = linkPDF != null;
  return (
    <Box className={classes.paperWrapper} elevation={3}>
      <Paper square elevation={0} className={clsx(classes.root, classes.paper)}>
        <Grid container alignContent="center">
          <Grid item xs={12}>
            <If condition={row.episodeVideo != ''}>
              <BlockRatio ratio={'16_9'} className={classes.itemVideo}>
                <iframe id="player" title="replay" src={row.episodeVideo} className={'embed'} width="100%" height="100%" allow="autoplay; fullscreen" allowFullScreen />
                {/*<VideoTracker eventId={$$card.get("id")}/>*/}
              </BlockRatio>
              <Else />
              <Box mt={8}>
                <Paper>
                  <Typography variant="h3" align="center" style={{ padding: '32px 16px' }}>
                    Vidéo non disponible
                  </Typography>
                </Paper>
              </Box>
            </If>
          </Grid>

          {/* GAUCHE */}
          <Grid item xs={12} sm={6} className={classes.leftBloc}>
            <Typography variant="h3" component="div" gutterBottom className={classes.itemTitle}>
              <Paragrapher text={row.episodeTitle} elementClassName={classes.content} prettyfy={true} element={'div'} />
            </Typography>
            <If condition={row.episodeDescription != ''}>
              <Typography variant="body1" component="div" gutterBottom className={classes.itemDescription}>
                <Paragrapher text={row.episodeDescription} elementClassName={classes.content} prettyfy={true} element={'div'} />
              </Typography>
            </If>
            <If condition={row.episodeGuests != ''}>
              <Typography variant="body1" component="div" gutterBottom className={classes.itemGuests}>
                <Paragrapher text={row.episodeGuests} elementClassName={classes.content} prettyfy={true} element={'div'} />
              </Typography>
            </If>
            <Box mt={0} mb={1} px={1}>
              <Typography variant="body1" component="div" gutterBottom className={classes.itemDuration}>
                <If condition={row.episodeDuration > 0}>
                  <Typography variant="body1" component="div" gutterBottom>
                    <WatchLaterIcon /> {toHHMM(row.episodeDuration * 60)}
                  </Typography>
                </If>
              </Typography>
            </Box>
          </Grid>
          {/* DROITE */}
          <Grid item xs={12} sm={6} container alignItems="top" justify="center" className={classes.rightBloc}>
            <If condition={withPDF}>
              {/* AVEC PDF */}
              <Grid container item xs={12} className={classes.pdfBloc}>
                <If condition={linkThumb != ''}>
                  <Grid item xs={12}>
                    <img src={linkThumb} height={200} />
                  </Grid>
                </If>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    className={classes.downloadButton}
                    startIcon={<GetAppIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.replace(linkPDF);
                    }}
                  >
                    Je télécharge
                  </Button>
                </Grid>
              </Grid>
            </If>
            <Box style={{ textAlign: 'center' }}>
              <Grid item xs={12} className={classes.shareWrapper}>
                <NPS module="episode" id={row.id} data={row.nps} align="center" />
              </Grid>
              <Grid item xs={12} className={classes.shareWrapper}>
                <Share module="episode" id={row.id} title={row.episodeTitle} />
              </Grid>

              {/*<If condition={i18n.has('replayCopyrightWarning') && ($$card.get('eventLegalReplay') === undefined || $$card.get('eventLegalReplay') === '' || $$card.get('eventLegalReplay') === null)}>
                              <Typography variant="caption" className={classes.itemCopyright} gutterBottom>
                                <span className={classes.inline}>{i18n.get('replayCopyrightWarning')}</span>
                              </Typography>
                            </If>
                            <If condition={$$card.get('eventLegalReplay') !== undefined && $$card.get('eventLegalReplay') !== '' && $$card.get('eventLegalReplay') !== null}>
                              <Typography variant="caption" className={classes.itemCopyright} gutterBottom>
                                <span className={classes.inline}>$$card.get('eventLegalReplay')</span>
                              </Typography>
                            </If>
                            <If condition={$$card.get('eventCodeReplay') !== undefined && $$card.get('eventCodeReplay') !== '' && $$card.get('eventCodeReplay') !== null}>
                              <Typography variant="body1" className={classes.itemCode} gutterBottom>
                                <span className={classes.inline}>{$$card.get('eventCodeReplay')}</span>
                              </Typography>
                            </If>
                          */}
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.navWrapper}>
            <If condition={row.previous !== undefined}>
              <Button variant="contained" className={clsx(classes.navButton, classes.navPrevious)} onClick={(e) => navigate(row.previous)}>
                Épisode précédent
              </Button>
            </If>
            <If condition={row.next !== undefined}>
              <Button variant="contained" className={clsx(classes.navButton, classes.navNext)} onClick={(e) => navigate(row.next)}>
                Épisode suivant
              </Button>
            </If>
          </Grid>
        </Grid>
      </Paper>
      {/*
      <Paper square elevation={0} className={classes.breadCrumbsWrapper}>
        <Grid item xs={12} className={classes.breadCrumbs}>
          <Typography variant="subtitle1">
            <Link to="/">{i18n.get('home', { pretty: false })}</Link>
            &nbsp;&nbsp;&gt;&nbsp;&nbsp;
            <Link to={serieUrl('replay', row.serieCategory)}>{categoryLabel('replay', row.serieCategory)}</Link>
            &nbsp;&nbsp;&gt;&nbsp;&nbsp;
            <Link to={serieUrl('replay', row.serieCategory, 'serie', row.serieId)}>{row.serieTitle}</Link>
            &nbsp;&nbsp;&gt;&nbsp;&nbsp;
            <b>
              <Prettyfier text={row.episodeTitle} />
            </b>
          </Typography>
        </Grid>
      </Paper>
                        */}
    </Box>
  );
}
