import React, { Component } from "react";
import { connect } from "react-redux";
import config from "config/api";
import Spinner from "components/UI/Spinner";
import i18n from "helpers/i18n";
import Immutable from "immutable";
// import Prettyfier from 'components/UI/Prettyfier';
// import HomeMember from './HomeMember';
// import HomeSpeaker from './HomeSpeaker';
import Header from "../Header/Header";
import HeaderContent from "../Header/HeaderContent";
import HeaderSub from "../Header/HeaderSub";
import Footer from "../Footer";
import ScrollToTopOnMount from "components/UI/ScrollToTopOnMount";
// import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  fetchData,
  getDataHome,
  getLoadingError,
} from "../../ducks/asyncFetcher";
import {
  isLoggedIn,
  getCurrentUserRole,
  getCurrentUser,
  isStateKnown,
} from "ducks/authentication";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { primaryColor } from "../../helpers/colors";

const styles = (theme) => {
  return {
    root: {
      minHeight: "100%",
      height: "100%",
      width: "100%",
      //maxWidth: "1280px",
      margin: "0 auto",
    },
    wrapper: {
      // maxWidth: '1280px',
      // margin: '0 auto',
      // height: '100%',
      width: "100%",
      minHeight: "calc(100% - 160px)",
      // background: 'linear-gradient(180deg, rgba(241,243,244,0.42) 0%, rgba(223,227,229,0.42) 100%)',
      //borderBottom: "3px " + primaryColor + " solid",
      //paddingBottom:'40px',
    },
    titleSmall: {
      fontSize: "2rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
        textAlign: "center",
      },
    },
    headerImage: {
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        textAlign: "center",
      },
    },
  };
};

class Template extends Component {
  render() {
    const { classes, title, children, current, headerImage } = this.props;

    return (
      <Container
        disableGutters
        className={classes.root}
        fixed={false}
        maxWidth={false}
        style={{
          height: "100%",
          minHeight: "940px",
        }}
      >
        <Grid className={classes.wrapper} container alignContent="flex-start">
          <Header
            current={current}
            headerTitle={title}
            headerImage={headerImage}
            headerContent={
              process.env.REACT_APP_SITE !== "pneumo" &&
              process.env.REACT_APP_SITE !== "pneumo_medevent" ? (
                <HeaderContent />
              ) : undefined
            }
          />
          <ScrollToTopOnMount />
          {children}
        </Grid>
        <Footer />
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(Template));
