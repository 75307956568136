import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import config from 'config/api';
import isArray from 'mout/lang/isArray';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BlockRatio from 'components/BlockRatio';
import { toHHMM } from 'components/UI/helpers/time';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import getThumb from 'components/UI/helpers/thumb';
import SerieDetails from './SerieDetails';
import { serieUrl } from './helper';
import imageDefault from 'assets/images/event.png';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100%',
      paddingBottom: theme.spacing(3),
    },
    itemWrapper: {
      padding: '12px',
      cursor: 'pointer',
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
      borderRadius: 12,
      backgroundColor: '#fff',
      height: '100%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '& > div': {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: 0,
      },
    },
    pending: {
      opacity: 0.6,
    },
    mainGrid: {
      height: '100%',
      position: 'relative',
      paddingBottom: '55px',
    },
    itemContentWrapper: {
      paddingTop: theme.spacing(0),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    itemThumbnailWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    itemThumbnail: {
      borderRadius: 8,
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundSize: 'cover',
    },
    itemTitle: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      textAlign: 'left',
      fontSize: '100%',
      fontWeight: 'bold',
    },
    itemGuests: {
      textAlign: 'left',
      marginBottom: theme.spacing(3),
    },
    bottomActions: {
      marginBottom: theme.spacing(0),
      position: 'absolute',
      bottom: 0,
      left: '9px',
    },
    action: {
      display: 'inline-block',
      color: 'white',
      padding: '6px 12px',
      borderRadius: 16,
      fontSize: '90%',
    },
  };
});

export default function SerieCard({ type, row, category }) {
  console.log('card série row', row);
  const classes = useStyles();
  let history = useHistory();

  const [viewSerie, setViewSerie] = React.useState(false);

  const onOpenDetails = (event) => {
    if (type == 'podcast') {
      history.push(serieUrl(type, row.serieCategory, 'podcast', row.id, row.time)); //épisode podcast
    } else {
      if (row.episode !== undefined)
        //reprise épisode
        history.push(serieUrl(type, row.serieCategory, 'episode', row.episode, row.time));
      else {
        window.history.replaceState(null, null, serieUrl(type, category !== undefined ? category : row.serieCategory, 'serie', row.id));
        //history.replace(serieUrl(type, row.serieCategory, 'serie', row.id));
        //history.push(serieUrl(type, row.serieCategory, 'serie', row.id));
        setViewSerie(true);
      }
    }
  };
  const onCloseSerie = (event) => {
    setViewSerie(false);
  };

  let thumb = getThumb(type == 'podcast' ? 'podcastShow' : 'serie', row.id, row.serieThumb, 'url(' + imageDefault + ')');
  let guest = '';
  if (row.serieGuests !== undefined && row.serieGuests != '') guest = row.serieGuests;
  else if (row.podcastGuests !== undefined && row.podcastGuests != '') {
    let json = JSON.parse(row.podcastGuests);
    let list = [];
    json.forEach((element) => {
      list.push(element.attributes.name);
    });
    guest = list.join(', ');
  }
  const pending = (type == 'replay' && row.episode_count == 0) || (type == 'podcast' && row.podcastState == 'pending');

  return (
    <Grid item xs={12} container justify="flex-start" alignContent="flex-start" className={clsx(classes.root, 'SerieCard', 'categ_' + row.serieCategory)}>
      <If condition={viewSerie}>
        <SerieDetails id={row.id} initRow={row} onCloseSerie={onCloseSerie} category={category} />
      </If>
      <Paper className={clsx(classes.itemWrapper, pending ? classes.pending : null)} elevation={0} element="div" onClick={onOpenDetails}>
        <Grid container className={classes.mainGrid} alignContent="flex-start">
          <Grid item xs={12} container alignContent="center" style={{ position: 'relative' }}>
            <Box className={classes.itemThumbnailWrapper}>
              <BlockRatio ratio="4_3">
                <div className={classes.itemThumbnail} style={{ backgroundImage: thumb }}></div>
              </BlockRatio>
            </Box>
          </Grid>
          <Grid item xs={12} container alignContent="flex-start" className={classes.itemContentWrapper}>
            <Typography variant="h5" component="div" gutterBottom className={classes.itemTitle}>
              <Paragrapher text={row.serieTitle} elementClassName={classes.content} prettyfy={true} element={'div'} />
            </Typography>
            <If condition={guest != ''}>
              <Grid item xs={12} container>
                <Box className={classes.itemGuests} width={'100%'}>
                  <Typography variant="body1">
                    <Paragrapher text={guest} elementClassName={classes.content} prettyfy={true} element={'div'} />
                  </Typography>
                </Box>
              </Grid>
            </If>
            <If condition={type == 'replay'}>
              <Box className={classes.bottomActions} mb={2}>
                <Box className={clsx(classes.action, 'SerieAction')} mb={2}>
                  <If condition={row.episode !== undefined && row.episodeDuration != 0}>
                    {/*reprise épisode*/}
                    <WatchLaterIcon /> {toHHMM(row.episodeDuration * 60)}
                    <Else />
                    <If condition={row.episode_count > 0}>
                      {row.episode_count} épisode{row.episode_count >= 2 ? 's' : ''}
                      <Else />
                      Bientôt Disponible
                    </If>
                  </If>
                </Box>
              </Box>
            </If>
            <If condition={type == 'podcast'}>
              <Box className={classes.bottomActions} mb={2}>
                <If condition={row.podcastState == 'live'}>
                  <If condition={row.duration > 0}>
                    <Box className={clsx(classes.action, 'SerieAction')} mb={2}>
                      <WatchLaterIcon /> {toHHMM(row.duration * 60)}
                    </Box>
                  </If>
                </If>
                <If condition={row.podcastState == 'pending'}>
                  <Box className={clsx(classes.action, 'SerieAction')} mb={2}>
                    Bientôt Disponible
                  </Box>
                </If>
              </Box>
            </If>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
