import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicList from 'components/UI/DynamicList';
import config from 'config/api';
import Box from '@material-ui/core/Box';
import AccountListEmbed from 'components/UI/Account/AccountListEmbed';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

import { getCurrentUserRole } from 'components/UI/Account/ducks/authentication';
class AdminSerieList extends Component {
  render() {
    const { userRole, embed, showFilters, showActions } = this.props;
    const moduleConfig = {
      module: 'serie',
      fetchUrl: config.baseUrl + '/api/serie/list',
      deleteUrl: config.baseUrl + '/api/serie/delete',
      editUrl: 'staff/serie/edit',
      filter: this.props.filter,
      showFilters: showFilters,
      showActions: showActions,
      // deleteUrl: '/staff/serie/edit',
      // title: 'Evénements'
    };
    const panels = [
      {
        module: 'Subscribers',
        label: 'Personnes inscrites',
        component: AccountListEmbed,
      },
    ];
    return (
      <div>
        <If condition={embed !== true}>
          <Box align="center" mt={3} mb={2} px={2}>
            <Typography variant="h2" gutterBottom>
              Série
            </Typography>
            <If condition={userRole === 'admin' && embed !== true}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push('/staff/serie/edit');
                }}
              >
                Ajouter
              </Button>
            </If>
          </Box>
        </If>
        <DynamicList moduleConfig={moduleConfig} panels={panels} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
  };
  return props;
}
export default withRouter(connect(mapStateToProps)(AdminSerieList));
