import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import config from 'config/api';

import { toHHMM } from 'components/UI/helpers/time';
import getThumb from 'components/UI/helpers/thumb';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Paragrapher from 'components/UI/Paragrapher/Paragrapher';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BlockRatio from 'components/BlockRatio';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { useHistory } from 'react-router-dom';
import { serieUrl, categoryLabel } from 'components/UI/Serie/helper';
import imageDefault from 'assets/images/event.png';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100%',
      paddingBottom: theme.spacing(3),
    },
    itemWrapper: {
      cursor: 'pointer',
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
      backgroundColor: '#fff',
      height: '100%',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      '& > div': {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: 0,
      },
    },
    itemContentWrapper: {
      paddingTop: theme.spacing(0),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      backgroundColor: '#f6f6f6',
      borderRadius: '0 16px 16px 0 !important',
    },
    itemThumbnailWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    itemThumbnail: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundSize: 'cover',
      borderRadius: '16px 0 0 16px !important',
    },
    itemTitle: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.serie.title,
      fontWeight: 'bold',
    },
    liveNavigation: {
      '& .MuiTypography-body1': {
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        fontWeight: 700,
      },
    },
    itemDescription: {
      textAlign: 'left',
    },
    itemDuration: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: theme.palette.serie.title,
    },
    durationText: {
      color: '#888',
    },
  };
});

export default function Card({ row, variant = 'horizontal' }) {
  //console.log("card episode row", row);
  const classes = useStyles();
  let thumb = getThumb('episode', row.id, row.episodeThumb, 'url(' + imageDefault + ')');
  let history = useHistory();

  const onClick = (event) => {
    history.push(serieUrl('replay', row.serieCategory, 'episode', row.id));
  };

  return (
    <Grid item xs={12} container justify="flex-start" alignContent="flex-start" className={classes.root}>
      <Paper className={classes.itemWrapper} elevation={0} element="div" onClick={onClick}>
        <Grid container style={{ height: '100%' }} alignContent="flex-start">
          <Grid item xs={variant === 'horizontal' ? 5 : 12} container alignContent={variant !== 'horizontal' ? 'center' : 'flex-start'} style={{ position: 'relative' }}>
            <Box className={classes.itemThumbnailWrapper}>
              <BlockRatio ratio="3_1" style={{ height: variant === 'horizontal' ? '100%' : undefined }} contentStyle={{ borderRadius: '16px 0 0 16px' }}>
                <div className={classes.itemThumbnail} style={{ backgroundImage: thumb }}></div>
              </BlockRatio>
            </Box>
          </Grid>
          <Grid item xs={variant === 'horizontal' ? 7 : 12} container alignContent="flex-start" className={classes.itemContentWrapper}>
            <Typography variant="h5" component="div" gutterBottom className={classes.itemTitle}>
              <Paragrapher text={row.episodeTitle} elementClassName={classes.content} prettyfy={true} element={'div'} />
            </Typography>
            <Grid item xs={12} container>
              <Box className={classes.itemDescription} width={'100%'}>
                <Typography variant="body1">
                  <Paragrapher text={row.episodeDescription} elementClassName={classes.content} prettyfy={true} element={'div'} />
                </Typography>
              </Box>
            </Grid>
            <If condition={row.episodeDuration > 0}>
              <Grid item xs={12} className={classes.itemDuration}>
                <WatchLaterIcon /> <span className={classes.durationText}>{toHHMM(row.episodeDuration * 60)}</span>
              </Grid>
            </If>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
