import React, { Component } from "react";
import config from "config/api";
import Immutable from "immutable";
import BlockButton from "components/UI/BlockButton";
import Input from "components/UI/Form/Input";
import RemainingTime from "components/UI/RemainingTime";
import BlockTitle from "components/UI/BlockTitle";
import { connect } from "react-redux";
// import { formatDate } from 'helpers/stringUtils'
import getRemainingTime from "helpers/getRemainingTime";
// import colors from 'config/colors'
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import i18n from "helpers/i18n";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
// import getCardImage from 'helpers/getCardImage'
// import Grid from '@material-ui/core/Grid';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
import EventSubscriptionRecapSingle from "components/Event/EventSubscriptionRecapSingle";

import IconWarning from "@material-ui/icons/Warning";
// import IconCheck from '@material-ui/icons/Check';
// import {
//   getCurrentUserReferer,
//   // isLoggedIn,
// } from 'ducks/authentication';

const styles = (theme) => ({
  addButton: {
    backgroundColor: "transparent",
    padding: 0,
    boxShadow: "0 5px 20px rgba(0,0,0,0.21)",
    "&:hover": {
      boxShadow: "0 5px 5px rgba(0,0,0,0.21)",
      backgroundColor: "transparent",
    },
  },
  addIcon: {
    width: 50,
    height: 50,
    color: "#EB9250",
    verticalAlign: "middle",
    margin: "-5px",
  },
  mainColor: {
    color: "#585854",
  },
  altColor: {
    color: "#47B42A",
  },
  colorTitle: {
    color: theme.palette.primary.main,
    marginBottom: "20px",
  },
  bigButton: {
    fontWeight: "bold",
    borderRadius: "0 20px 20px 0",
    fontSize: "16px",
    letterSpacing: "1px",
    padding: "15px 30px",
    margin: "0 auto",
  },
});

class EventSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = { sending: false, log: false, email: false, complete: false };
    this._send = this._send.bind(this);
    this._onEmailChange = this._onEmailChange.bind(this);
    // this.onEventSelectionReset =
    // this._onSubscriptionComplete = this._onSubscriptionComplete.bind(this);
  }
  _onEmailChange(kind, target) {
    this.setState({ email: target.value });
  }
  _validateEmail($$field, value) {
    let check = true;
    if (value !== undefined)
      check = !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
    const log =
      check === true
        ? undefined
        : "Le champs " + $$field.get("label") + " est invalide";
    return log;
  }
  _send(e) {
    const {
      $$event,
      $$events,
      $$user,
      themeId,
      // onClose,
      registerFor,
      showRecap,
      onSubscriptionComplete,
      // referer
    } = this.props;
    const { sending } = this.state;

    if (sending === true) return;

    let url;
    if (this.props.unsubscribe === true) url = config.eventUnsubscribe;
    else url = config.eventSubscribe;

    this.setState({ sending: true, log: false });

    var formData = new FormData();

    if ($$event !== undefined) formData.append("rid", $$event.get("id"));
    else if ($$events !== undefined)
      formData.append(
        "rids",
        JSON.stringify($$events.map((e) => e.get("id")).toArray()),
      );

    if (themeId !== undefined) formData.append("themeId", themeId);

    // if (referer !== undefined)
    // formData.append('referer', referer);

    if (registerFor === true) {
      const check = this._validateEmail(
        Immutable.fromJS({ label: "Email" }),
        this.state.email,
      );
      if (check !== undefined) {
        // console.log('NOT OK', check)
        this.setState({ log: check, sending: false });
        return;
      } else {
        // console.log('check ok')
        formData.append("remail", this.state.email);
      }
    } else {
      formData.append("remail", $$user.get("id"));
    }

    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "X-Requested-With": "XMLHttpRequest", // needed by php api
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.registration && response.registration === "complete") {
          // if (this.props.onClose) this.props.onClose();
          if (showRecap !== false) this.setState({ complete: true });
          else if (onSubscriptionComplete !== undefined)
            onSubscriptionComplete();
        } else if (
          response.registration &&
          response.registration === "unknown_subsriber"
        ) {
          if (this.props.onSubscriptionError)
            this.props.onSubscriptionError("unknown_subsriber", {
              email: this.state.email,
            });
        } else if (
          response.registration &&
          response.registration === "already"
        ) {
          if (this.props.onSubscriptionError)
            this.props.onSubscriptionError("already", {
              email: this.state.email,
            });
        } else {
          this.setState({ sending: false });
        }
      });
  }
  render() {
    const {
      $$event,
      $$events,
      classes,
      unsubscribe,
      registerFor,
      showRecap,
      userRole,
      onClose,
      onSelectionReset,
    } = this.props;
    const {
      // visibleMode,
      sending,
      log,
      complete,
    } = this.state;
    // console.log('events', $$events)
    const timeRemaining =
      $$event !== undefined ? getRemainingTime($$event.get("eventDate")) : {};

    // let image;
    let buttonLabel;
    if (unsubscribe === true) {
      buttonLabel = "SE DESINSCRIRE";
    } else if (registerFor !== true)
      // buttonLabel = 'S\'INSCRIRE +';
      buttonLabel = i18n.get("subscriptionFormSelfButton", { pretty: false });
    else buttonLabel = "Valider l'inscription";
    // console.log('DEBUG', $$event)
    return (
      <div style={{ margin: "0 0 20px" }} className="Form">
        <If condition={$$event !== undefined}>
          <Box align="center">
            <Typography
              variant="h2"
              classes={{ root: classes.colorTitle }}
              gutterBottom
            >
              La conférence débute dans
            </Typography>
            <RemainingTime
              days={timeRemaining.days}
              hours={timeRemaining.hours}
              minutes={timeRemaining.minutes}
            />
          </Box>
        </If>
        <If condition={$$events !== undefined}>
          <If condition={registerFor !== undefined}>
            <Box align="middle" my={4}>
              <Typography variant="h2" gutterBottom={true}>
                {i18n.get("subscriptionForTitle")}
              </Typography>
            </Box>
          </If>
          {/*      <EventSubscriptionRecapSingle $$events={$$events} onSelectionReset={onSelectionReset} />*/}
        </If>

        <Box align="middle" pt={0} mb={3} ml={"10%"} width={"80%"}>
          <If condition={complete !== true && showRecap !== false}>
            <If
              condition={
                registerFor === undefined && i18n.has("subscriptionSelfText")
              }
            >
              <Typography variant={"body1"} gutterBottom>
                {i18n.get("subscriptionSelfText")}
              </Typography>
            </If>
            <If
              condition={
                registerFor !== undefined && i18n.has("subscriptionForText")
              }
            >
              <Typography variant={"body1"} gutterBottom>
                {i18n.get("subscriptionForText")}
              </Typography>
            </If>
          </If>

          <If condition={registerFor === true}>
            <Input
              name="email"
              id="email"
              value=""
              label="Email"
              onChange={this._onEmailChange}
              style={{ maxWidth: 280, margin: "0 auto" }}
            />
          </If>
          <If condition={log !== undefined && log !== false}>
            <Typography variant="body1" className="red" gutterBottom={true}>
              {log}
            </Typography>
          </If>
          <Box mt={4} pb={4} className="bottomActions">
            <If condition={complete !== true}>
              <If condition={unsubscribe === true}>
                <Button
                  size="large"
                  style={{ display: "inline-block", margin: "0 5px" }}
                  onClick={onClose}
                  disabled={sending}
                >
                  Annuler
                </Button>
              </If>
              <If
                condition={unsubscribe !== true && userRole === "member"}
              ></If>
              <Button
                size="large"
                className={classes.bigButton}
                onClick={this._send}
                disabled={sending}
                endIcon={<ArrowRightAltIcon />}
              >
                {buttonLabel}
              </Button>
            </If>
            <If condition={complete === true}>
              <If condition={unsubscribe === true}>
                <Typography
                  variant="h5"
                  classes={{ root: classes.mainColor }}
                  gutterBottom
                >
                  <IconWarning
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      width: 40,
                      height: 40,
                      marginTop: "-8px",
                      color: "#EB9250",
                    }}
                    classes={{ root: classes.mainColor }}
                  />{" "}
                  Désinscription enregistrée{" "}
                </Typography>
              </If>
              <If condition={unsubscribe !== true}>
                <Typography
                  variant="h2"
                  classes={{ root: classes.altColor }}
                  gutterBottom
                >
                  {i18n.get(
                    "subscription" +
                      (registerFor !== undefined ? "For" : "") +
                      "SuccessTitle",
                  )}
                </Typography>

                <If
                  condition={i18n.has(
                    "subscription" +
                      (registerFor !== undefined ? "For" : "") +
                      "SuccessText",
                  )}
                >
                  <Typography variant={"h5"} gutterBottom>
                    {i18n.get("subscriptionForSuccessText")}
                  </Typography>
                </If>

                <BlockButton
                  label="Fermer"
                  onClick={onClose}
                  style={{ margin: "20px auto 10px", display: "block" }}
                />
              </If>
            </If>
          </Box>
        </Box>
      </div>
    );
  }
}

// export default ;
export default withStyles(styles)(EventSubscription);
