import React, { Component } from "react";
import config from "config/api";
import DynamicForm from "components/UI/DynamicForm";

class PodcastShowForm extends Component {
  _getI18n() {
    this.i18n = {
      send: "Enregistrer",
      sending: "Enregistrement...",
    };
  }
  render() {
    const { onFormComplete } = this.props;
    const moduleConfig = {
      module: "podcastShow",
      fetchUrl: config.podcastShow + "/edit",
      postUrl: config.podcastShow + "/edit",
      formTitle: this.props.id !== undefined ? "Edition" : "Nouveau Podcast",
      redirectAfterEdit: false,
      afterEdit: "onFormComplete",
      afterEditLog: "",
      afterEditHideForm: true,
      onFormComplete,
    };

    return <DynamicForm moduleConfig={moduleConfig} {...this.props} />;
  }
}

export default PodcastShowForm;
