import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import i18n from "helpers/i18n";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import config from 'config/api';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    left: 0,
    color: theme.palette.rgpd && theme.palette.rgpd.color ? theme.palette.rgpd.color : theme.palette.primary.main,
    backgroundColor: theme.palette.rgpd && theme.palette.rgpd.backgroundColor ? theme.palette.rgpd.backgroundColor : theme.palette.primary.main,
    boxShadow: theme.shadows[8],
    zIndex: 9999,
    "& .MuiTypography-body1": {
      fontSize: "1rem",
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      '& a': {
        color: theme.palette.rgpd && theme.palette.rgpd.color ? theme.palette.rgpd.color : theme.palette.primary.main,
      }
    },
  },
}));

const RGPDBanner = () => {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const [acceptAnalytics, setAcceptAnalytics] = useState(config.cookieBannerConsent ? true : null);

  useEffect(() => {
    try {
      const cookieStr = getCookie("rgpd");
      const cookie = JSON.parse(cookieStr);
      if (cookie !== undefined && cookie.rgpd === true) {
        if (cookie.analytics === true) {
          injectAnalyticsTag();
        }
        setShow(false);
      }
    } catch (e) {}
  }, []);

  const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const injectAnalyticsTag = () => {
    //Pour l'instant config CanalPneumo, TODO config si besoin autre site
    if (!document.getElementById('matomo-script')) {
      var u="//www.matomo-dm.com/";
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      
      // Matomo main script
      g = d.createElement('script');
      g.id = 'matomo-script';
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);

      // Matomo Tag Manager script
      g = d.createElement('script');
      g.async=true; g.src='https://www.matomo-dm.com/js/container_hsvztTfT.js';
      s.parentNode.insertBefore(g,s);

      var _paq = window._paq = window._paq || [];
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '20']);
    }
  };

  const accept = () => {
    const d = new Date();
    const exdays = 10;
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    const cookieValue = {
      rgpd: true,
      analytics: acceptAnalytics,
    };
    document.cookie = "rgpd=" + JSON.stringify(cookieValue) + ";" + expires + ";path=/";

    if (acceptAnalytics) {
      injectAnalyticsTag();
    }

    setShow(false);
  };

  const toggleSwitch = () => {
    setAcceptAnalytics((prev) => !prev);
  };

  return show ? (
    <div className={classes.root}>
      <Box width="100%" p={2}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} md={config.cookieBannerConsent ? 8 : 10}>
            <Typography variant="body1">
              {i18n.get("rgpdPopin", { pretty: true })}
            </Typography>
          </Grid>
          {config.cookieBannerConsent && (
            <Grid item xs={12} md={2} container justify="center">
              <Switch
                checked={acceptAnalytics}
                onChange={toggleSwitch}
                color="secondary"
                name="acceptAnalytics"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography variant="body1">{acceptAnalytics ? 'J’accepte' : 'Je n’accepte pas'}</Typography>
            </Grid>
          )}
          <Grid item xs={12} md={config.cookieBannerConsent ? 2 : 2} container justify="center">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={accept}
            >
              {i18n.get("rgpdAccept")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  ) : null;
};

export default RGPDBanner;
