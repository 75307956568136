import React from "react";

import { makeStyles } from "@material-ui/core/styles";

// import styles from './BlockRatio.cssmodule.scss';

const useStyles = makeStyles((theme) => ({
  "ratio-square": {
    display: "inline-block",
    position: "relative",
    width: "100%",
  },
  "ratio-16_9": {
    display: "inline-block",
    position: "relative",
    width: "100%",
  },
  "dummy-16_9": {
    display: "block",
    paddingTop: "56%" /* 16:9; aspect ratio */,
  },
  "ratio-4_3": {
    display: "inline-block",
    position: "relative",
    width: "100%",
  },
  "dummy-4_3": {
    display: "block",
    paddingTop: "75%" /* 4:3 aspect ratio */,
  },
  "ratio-3_1": {
    display: "inline-block",
    position: "relative",
    width: "100%",
  },
  "dummy-3_1": {
    display: "block",
    paddingTop: "35%" /* 4:3 aspect ratio */,
  },
  "ratio-1_1": {
    display: "inline-block",
    position: "relative",
    width: "100%",
    maxHeight: "100%",
  },
  "dummy-1_1": {
    display: "block",
    paddingTop: "100%" /* 4:3 aspect ratio */,
  },
  content: {
    display: "block",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    overflow: "hidden",
  },
}));

const BlockRatio = ({ ratio, style, className, contentStyle, children }) => {
  const classes = useStyles();
  const wrapperProps = {
    className: classes["ratio-" + (ratio ? ratio : "1_1")],
  };
  // console.log('classes are')
  if (className) wrapperProps.className += className ? " " + className : "";
  if (style) wrapperProps.style = style;
  // if (key) wrapperProps.key = key;
  return (
    <div {...wrapperProps}>
      <div className={classes["dummy-" + ratio]}></div>
      <div className={classes.content} style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

export default BlockRatio;
