import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { isLoggedIn, getCurrentUserRole } from "ducks/authentication";
import i18n from "helpers/i18n";
import JobForm from "./JobForm";
import config from "config/api";
import Immutable from "immutable";
import DynamicForm from "components/UI/DynamicForm";
import Button from "@material-ui/core/Button";
import Template from "components/Template/Template";
import clsx from "clsx";

const styles = (theme) => {
  return {
    root: {
      // minHeight: "100%",
      height: "100%",
      minHeight: "900px",
      // display: "flex",
      // flexDirection: "column",
      // "& > *": {
      //   flex: 1,
      // },
    },
    header: {
      height: 150,
      [theme.breakpoints.down("sm")]: {
        height: 200,
      },
    },
    content: {
      height: "calc(100% - 150px - 120px)",
      minHeight: "600px",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    footer: {
      height: 120,
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    paperWrapper: {
      width: "100%",
      "& .MuiTypography-h2": {
        color: "#000000",
        fontSize: "2.5rem",
        textAlign: "center",
      },
    },
    paper: {
      padding: theme.spacing(4),
    },
  };
};

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      showRecap: false,
      logMessage: false,
    };

    this._onRegistrationCancel = this._onRegistrationCancel.bind(this);
    this._onJobFormComplete = this._onJobFormComplete.bind(this);
    this._onLoginPasswordReset = this._onLoginPasswordReset.bind(this);
    this._onLoginFormComplete = this._onFormComplete.bind(this);
    this._onRegistrationComplete = this._onRegistrationComplete.bind(this);
  }

  _onJobFormComplete(o) {
    this.setState({
      accountJob: o.values.accountJob,
      step: 2,
      isNewMember: true,
    });
  }

  _onRegistrationCancel() {
    this.setState({ accountJob: undefined, step: 1, isNewMember: false });
  }

  _onLoginPasswordReset() {
    this.setState({ accountJob: undefined, step: 3 });
  }

  _onFormComplete() {
    const { onClose, onFormComplete, closeOnComplete } = this.props;
    // console.log('BUBBLED !', onFormComplete)
    const { isNewMember, logMessage } = this.state;
    if (onFormComplete === undefined && onClose !== undefined) onClose();
    else if (onFormComplete !== undefined) {
      onFormComplete({ isNewMember: isNewMember, logMessage: logMessage });
      if (closeOnComplete === true && onClose !== undefined) onClose();
    }
  }

  _onRegistrationComplete(r) {
    this.setState({ showRecap: true });
  }

  render() {
    const {
      onPanelToggle,
      classes,
      userRole,
      $$event,
      $$events,
      isLoggedIn,
      history,
    } = this.props;
    const { step, accountJob, showRecap, logMessage } = this.state;
    const moduleConfig = {
      module: "account",
      fetchUrl: config.registration + "/edit",
      postUrl: config.registration + "/edit",
      redirectAfterEdit: false,
      afterEdit: "onFormComplete",
      afterEditLog: "",
      afterEditHideForm: true,
      btCancel: true,
      onFormCancel: this._onRegistrationCancel,
      btCancelLabel: "Retour",
      btSubmitLabel: "Envoyer",
      preActionsLegend: i18n.get("registrationPreLegend", { pretty: true }),
    };
    const hiddenFields = ["accountJob"];

    const jobCategories = config.getJobsCategories();
    // console.log({ jobCategories });
    const jobCategory =
      accountJob === undefined ? [] : jobCategories[accountJob];

    let ef = {};

    if ($$event !== undefined && $$event.get("id") !== undefined)
      ef = { fromEvent: $$event.get("id") };
    else if ($$events !== undefined && $$events.get(0) !== undefined)
      ef = { fromEvents: $$events.map((e) => e.get("id")).toArray() };
    ef.accountJob = accountJob;
    const extraFields = Immutable.fromJS(ef);
    const isNewMember = true;
    return (
      <Template>
        <Box
          id="mainform"
          className={clsx(classes.paperWrapper, "job_" + accountJob)}
        >
          <Paper square elevation={0} className={classes.paper}>
            <Typography
              variant="h2"
              color="primary"
              className={classes.title}
              align="center"
            >
              {i18n.get("registrationFormTitle")}
            </Typography>
            <If condition={step === 1}>
              <If condition={i18n.has("subscriptionFormVisitorTitle")}>
                <Typography
                  variant={"h2"}
                  className={classes.h2}
                  align="center"
                  gutterBottom
                >
                  {" "}
                  {i18n.get("subscriptionFormVisitorTitle")}
                </Typography>
              </If>

              <Box maxwidth={800} align="center">
                <Box my={4}>
                  <Typography variant={"h5"} gutterBottom={true} align="center">
                    {i18n.get("registrationJobWarning", { pretty: true })}
                  </Typography>
                </Box>
                <Grid
                  container={true}
                  spacing={0}
                  alignItems="flex-start"
                  justify="space-around"
                >
                  <Grid item={true} xs={12}>
                    <Box align="center">
                      <JobForm
                        userRole={undefined}
                        onFormComplete={this._onJobFormComplete}
                        showRequiredLegend={false}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </If>
            <If condition={step === 2}>
              <If condition={showRecap !== true}>
                <If
                  condition={i18n.has("registrationFormInstructions") === true}
                >
                  <Typography
                    variant={"h6"}
                    className={classes.h6}
                    gutterBottom
                  >
                    {i18n.get("registrationFormInstructions")}
                  </Typography>
                </If>
                <DynamicForm
                  moduleConfig={moduleConfig}
                  onFormComplete={this._onRegistrationComplete}
                  formFooter={
                    <If condition={i18n.has("registrationFormFooter")}>
                      <div>{i18n.get("registrationFormFooter")}</div>
                    </If>
                  }
                  extraFields={extraFields}
                  forceHiddenFields={hiddenFields}
                  onFormCancel={this._onRegistrationCancel}
                  initialCategories={jobCategory}
                />

                <br />
                <If condition={i18n.has("registrationLogLegend1")}>
                  <Typography
                    variant={"body1"}
                    classes={{ root: classes.legend }}
                    gutterBottom
                  >
                    {i18n.get("registrationLogLegend1", {
                      pretty: true,
                    })}
                  </Typography>
                  <hr />
                </If>
                <Typography
                  variant={"body1"}
                  classes={{ root: classes.legend }}
                  gutterBottom
                >
                  {i18n.get("registrationLogLegend2", {
                    pretty: true,
                  })}
                </Typography>
              </If>
              <If condition={showRecap === true}>
                <Box align="center" mb={2} color="primary.black">
                  <If
                    condition={
                      isNewMember === true &&
                      i18n.has("registrationSuccessTitle")
                    }
                  >
                    <Typography
                      variant={"h4"}
                      gutterBottom
                      className={classes.popinTitle}
                    >
                      {i18n.get("registrationSuccessTitle", {
                        pretty: true,
                        element: "div",
                      })}
                    </Typography>
                  </If>
                  <If
                    condition={
                      isNewMember !== true &&
                      i18n.has("registrationModifiedTitle")
                    }
                  >
                    <Typography
                      variant={"h4"}
                      gutterBottom
                      className={classes.popinTitle}
                    >
                      {i18n.get("registrationModifiedTitle", {
                        pretty: true,
                        element: "div",
                      })}
                    </Typography>
                  </If>
                </Box>
                <If
                  condition={
                    isNewMember === true &&
                    accountJob === "x1" &&
                    i18n.has("registrationSuccessLogX1")
                  }
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    align="center"
                    className={classes.popinText}
                  >
                    {i18n.get("registrationSuccessLogX1", { pretty: true })}{" "}
                  </Typography>
                </If>
                <If
                  condition={
                    isNewMember === true &&
                    accountJob === "x2" &&
                    i18n.has("registrationSuccessLogX2")
                  }
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    align="center"
                    className={classes.popinText}
                  >
                    {i18n.get("registrationSuccessLogX2", { pretty: true })}{" "}
                  </Typography>
                </If>
                <If
                  condition={
                    isNewMember === true &&
                    accountJob === "x3" &&
                    i18n.has("registrationSuccessLogX3")
                  }
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    align="center"
                    className={classes.popinText}
                  >
                    {i18n.get("registrationSuccessLogX3", { pretty: true })}{" "}
                  </Typography>
                </If>
                <If
                  condition={
                    isNewMember === true &&
                    accountJob === "x4" &&
                    i18n.has("registrationSuccessLogX4")
                  }
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    align="center"
                    className={classes.popinText}
                  >
                    {i18n.get("registrationSuccessLogX4", { pretty: true })}{" "}
                  </Typography>
                </If>
                <If
                  condition={
                    isNewMember === true &&
                    accountJob === "x5" &&
                    i18n.has("registrationSuccessLogX5")
                  }
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    align="center"
                    className={classes.popinText}
                  >
                    {i18n.get("registrationSuccessLogX5", { pretty: true })}{" "}
                  </Typography>
                </If>
                <If
                  condition={
                    isNewMember === true &&
                    accountJob === "x6" &&
                    i18n.has("registrationSuccessLogX6")
                  }
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    align="center"
                    className={classes.popinText}
                  >
                    {i18n.get("registrationSuccessLogX6", { pretty: true })}{" "}
                  </Typography>
                </If>
                <If
                  condition={
                    isNewMember === true &&
                    accountJob === "lb" &&
                    i18n.has("registrationSuccessLogLb")
                  }
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    align="center"
                    className={classes.popinText}
                  >
                    {i18n.get("registrationSuccessLogLb", { pretty: true })}{" "}
                  </Typography>
                </If>
                <If
                  condition={
                    isNewMember === true &&
                    accountJob === "au" &&
                    i18n.has("registrationSuccessLogAu")
                  }
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    align="center"
                    className={classes.popinText}
                  >
                    {i18n.get("registrationSuccessLogAu", { pretty: true })}{" "}
                  </Typography>
                </If>
                <If
                  condition={
                    isNewMember === true &&
                    accountJob !== "x1" &&
                    accountJob !== "x2" &&
                    accountJob !== "x3" &&
                    accountJob !== "x4" &&
                    accountJob !== "x5" &&
                    accountJob !== "x6" &&
                    accountJob !== "lb" &&
                    accountJob !== "au"
                  }
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    align="center"
                    className={classes.popinText}
                  >
                    {i18n.get("registrationLogSuccess", { pretty: true })}
                  </Typography>
                </If>

                <If
                  condition={
                    isNewMember !== true &&
                    i18n.has("subscriptionSelfSuccessText")
                  }
                >
                  <Typography
                    variant={"h6"}
                    gutterBottom={true}
                    align="center"
                    className={classes.popinText}
                  >
                    {i18n.get("subscriptionSelfSuccessText")}
                  </Typography>
                </If>

                <Button
                  variant="outlined"
                  color="secondary"
                  label="Retour à l'accueil"
                  onClick={() => this.props.history.push("/")}
                  style={{ margin: "20px auto 10px", display: "block" }}
                >
                  Retour à l'accueil
                </Button>
              </If>
            </If>
          </Paper>
        </Box>
      </Template>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    userRole: getCurrentUserRole(state),
    isLoggedIn: isLoggedIn(state),
  };
  return props;
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(Registration)),
);
