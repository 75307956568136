import config from "config/api";
import isArray from "mout/lang/isArray";

export default ($$card) => {
  let cardImage = false;
  // let cardPresentation = false;
  let decodedValues;
  if (
    $$card !== false &&
    $$card !== undefined &&
    $$card.get("eventThumb") !== undefined &&
    $$card.get("eventThumb") !== null &&
    $$card.get("eventThumb") !== false
  ) {
    try {
      decodedValues = JSON.parse($$card.get("eventThumb"));
    } catch (e) {}
    // console.log('decoded is', decodedValues)
    if (
      decodedValues !== undefined &&
      isArray(decodedValues) &&
      decodedValues[0] &&
      decodedValues[0].dest
    ) {
      cardImage =
        config.eventthumbpreview
          .replace("{eventId}", $$card.get("id"))
          .replace("{themeId}", $$card.get("themeId")) +
        "/" +
        decodedValues[0].dest;
    }
  }
  return cardImage;
};
